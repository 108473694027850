export default {
  samsaraConfiguration: {
    apiToken: undefined,
    samsaraTrailerIdPrefix1: undefined,
    samsaraTrailerIdPrefix2: undefined,
    apiTokenSuffix: undefined,
    samsaraContact: {
      person: {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: ''
      }
    }
  },
  uneditedSamsaraConfiguration: {
    apiToken: undefined,
    samsaraTrailerIdPrefix1: undefined,
    samsaraTrailerIdPrefix2: undefined,
    apiTokenSuffix: undefined,
    samsaraContact: {
      person: {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: ''
      }
    }
  }
}
