<template>
  <v-menu
    v-model="menuOpen"
    :close-on-content-click="false"
    right
  >
    <template #activator="{ on: menu, attrs }">
      <v-tooltip bottom color="primary">
        <template #activator="{ on: tooltip }">
          <v-icon
            v-on="{...tooltip, ...menu}"
            v-bind="attrs"
            color="primary"
            class="mr-2"
          >
            mdi-filter
          </v-icon>
        </template>
        <span class="subtitle-1 white--text">
          {{$t('filter')}}
        </span>
      </v-tooltip>
    </template>
    <v-card width="600px">
      <v-card-title class="primary white--text">
        {{$t('advancesFilter')}}
      </v-card-title>
      <v-card-text>
        <v-container fluid :style="this.containerStyle">
          <v-row>
            <v-col cols="auto" class="pt-n6">
              <DatePicker
                v-if="menuOpen"
                :dateLabel="$t('asOf')"
                :startDate="asAtDateBuilder"
                :max="now"
                @valid-date="(val) => { isValidStartDate = val }"
                @date-picked="asAtDateChosen"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-checkbox
              :label="$t('hideAdvancesWithZeroBalance')"
              v-model="hideZeroBalanceBuilder"
            />
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="pb-4">
        <v-btn color="primary" outlined @click="resetFilter">
          {{$t('reset')}}
        </v-btn>
        <v-spacer/>
        <v-btn
          class="primary"
          :disabled="!changed"
          @click="applyFilter"
        >
          {{$t('apply')}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { LocalStorageKeys } from '@/utils/LocalStorageActor'
import { getMenuContainerStyle } from '@/utils/componentHelpers'
import moment from 'moment'

export default {
  name: 'AdvancesFilter',

  components: {
    DatePicker: () => import('@/components/helper/DatePicker.vue')
  },

  props: {
    filter: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    menuOpen: false,
    hideZeroBalanceBuilder: undefined,
    asAtDateBuilder: undefined,
    containerStyle: undefined,
    changed: false
  }),

  watch: {
    'filter.hideZeroBalance' (newHideZeroBalance) {
      this.hideZeroBalanceBuilder = newHideZeroBalance
    },
    hideZeroBalanceBuilder () {
      this.changed = true
    },
    asAtDateBuilder () {
      this.changed = true
    },
    menuOpen (isOpen) {
      if (isOpen) {
        this.changed = false
        this.hideZeroBalanceBuilder = this.filter.hideZeroBalance
        this.asAtDateBuilder = this.filter.asAtDate
      }
    }
  },

  computed: {
    now () {
      return moment().format()
    }
  },

  async created () {
    this.hideZeroBalanceBuilder = this.filter.hideZeroBalance
    this.asAtDateBuilder = this.filter.asAtDate
    window.addEventListener('resize', this.setContainerStyle)
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.setContainerStyle)
  },

  methods: {
    asAtDateChosen (date) {
      this.asAtDateBuilder = date
    },

    resetFilter () {
      this.hideZeroBalanceBuilder = false
      this.asAtDateBuilder = this.now
      this.applyFilter()
    },

    applyFilter () {
      localStorage.setItem(LocalStorageKeys.HIDE_ADVANCES_WITH_ZERO_BALANCE, this.hideZeroBalanceBuilder.toString())
      this.$emit('filter-applied', {
        hideZeroBalance: this.hideZeroBalanceBuilder,
        asAtDate: this.asAtDateBuilder
      })

      this.menuOpen = false
    },

    setContainerStyle () {
      return getMenuContainerStyle(window.innerWidth, window.innerHeight)
    }
  }
}
</script>
