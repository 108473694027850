import ApiServer from '@/utils/api/ApiServer.js'
import { AccountingCategory } from '../../../utils/Enumerations'

export default {
  async fetchJournalEntries (context, contractMode) {
    try {
      context.commit('setjournalEntriesLoadingStatus', true)
      const response = await ApiServer.get(`journalEntries?byproduct=${contractMode === 1}&transfer=${contractMode === 2}`)
      context.commit('setJournalEntries', response.data)
      return response.data
    } finally {
      context.commit('setjournalEntriesLoadingStatus', false)
    }
  },
  async getJournalEntry (_, journalEntryId) {
    const path = `journalEntries/${journalEntryId}`
    const response = await ApiServer.get(path)
    return response.data
  },
  async getJournalEntriesForAccount (_, accountId) {
    const path = `journalEntries/?accountId=${accountId}`
    const response = await ApiServer.get(path)
    return response.data
  },
  async getJournalEntriesForTicket (_, ticketId) {
    const path = `tickets/${ticketId}/journalEntries`
    const response = await ApiServer.get(path)
    return response.data
  },
  async fetchUnpaidAccountPayablesAC (context) {
    const response = await ApiServer.get(`accountpayables?categoryType=${AccountingCategory.Accrual.value}`)
    context.commit('setAccountPayables', response.data)
    return response.data
  }
}
