<template>
  <v-container fluid>
  <v-row data-testid="company-information-form">
    <v-col xs12>
        <material-card
          data-testid="company-information-form"
          color="primary"
          class="padding-3"
          full-width
        >
        <template #header>
          <v-row>
            <v-col>
              <p
                class="headline font-weight-regular mb-2"
                v-text="$t('companyConfiguration')"
              />
              <p
                class="category font-weight-thin"
                v-text="$t('companyConfigurationSubtitle')"
              />
          </v-col>
          <v-col cols="auto">
            <CompanyLogo
              :companyInfo="companyInfo"/>
          </v-col>
        </v-row>
        </template>
          <v-container fluid>
            <v-row v-if="companyInfo">
              <v-col cols="12" v-if="showSaveChanges">
                <v-layout wrap>
                  <v-btn data-testid="cancel-company-information" class="cancel" color="black" text @click="reset">{{$t('cancel')}}</v-btn>
                  <v-spacer/>
                  <v-btn data-testid="save-company-information" class="primary" @click="updateInfo">{{$t('saveChanges')}}</v-btn>
                </v-layout>
              </v-col>
              </v-row>
              <v-row>
                <v-col cols="12"><span class="title">{{$t('administrativeInformation')}}</span></v-col>
                <v-col cols="12" sm="6" lg="4">
                  <LoadableComponent :loading="loading">
                    <v-text-field
                    v-model="companyInfo.code"
                    :label="$t('code')"
                    data-testid="company-info-code"
                    color="primary"
                    outlined
                    readonly
                    />
                  </LoadableComponent>
                </v-col>
                <v-col cols="12" xs="12" sm="6" lg="4">
                  <LoadableComponent :loading="loading">
                    <v-text-field
                    v-model="companyInfo.name"
                    :label="$t('name')"
                    data-testid="company-info-name"
                    color="primary"
                    readonly
                    outlined
                    />
                  </LoadableComponent>
                </v-col>
                <v-col cols="12" xs="12" sm="6" lg="4">
                  <LoadableComponent :loading="loading">
                    <v-text-field
                    v-model="companyInfo.tenantIdGuid"
                    :label="$t('tenantId')"
                    data-testid="company-info-tenant-id-guid"
                    color="primary"
                    readonly
                    outlined
                  />
                  </LoadableComponent>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-row>
                    <v-col align="left">
                      <span class="title">{{$t('options')}}</span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <CompanyOptions
                      :readonly="fieldsAreReadonly"
                      :config="config"
                      @config-changed="config = $event"
                      :samsaraHalted="companyInfo?.samsaraIntegrationHalted ?? false"
                      :loading="loading"
                      :unsavedChanges="showSaveChanges"
                      @refresh-companyInfo="getCompanyInfo"/>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6">
                  <v-row>
                    <v-col align="right">
                      <span class="title" style="text">{{$t('addressAndContacts')}}</span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <CompanyContactInfo
                      class="mb-4"
                      :mainContact="mainContact"
                      :transporterContact="transporterContact"
                      :loaderContact="loaderContact"
                      :readonly="fieldsAreReadonly"
                      :address="address"
                      :loading="loading"
                      @deleteTransporterContact="deleteTransporterContact"
                      @deleteLoaderContact="deleteLoaderContact"
                      @loaderSet="loaderContact = $event"
                      @transporterSet="transporterContact = $event"
                      @mainEdited="mainContact = $event"
                      @addressUpdated="address = $event"/>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <CompanyEntities
                  v-if="businessEntities"
                  :companyInfo="companyInfo"
                  @entity-mutated="getEntities"/>
                </v-col>
              </v-row>
              <v-dialog v-model="addDeckNowDialog" max-width="400">
                <ConfirmDialog
                  v-if="addDeckNowDialog"
                  :title="$t('addDeckNowTitle')"
                  :body="$t('addDeckNowBody')"
                  :confirmText="$t('addDeck')"
                  color="primary"
                  @confirm="showDeckForm"
                  @cancel="closeConfirmDialog"
                />
              </v-dialog>
            </v-container>
        </material-card>
    </v-col>
  </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { FinancialIntegrationType, AdjustingEntriesFrequency, UserClaims, SystemDayOfWeek, SamsaraIntegrationStatus } from '@/utils/Enumerations.js'
import { states } from '@/utils/constants.js'
import { mask } from 'vue-the-mask'
import isEqual from 'lodash/isEqual'
import timezones from './timezones.json'
import moment from 'moment'
import { userAssignedClaim } from '../../../utils/ClaimUtility'
import { isMobile } from '@/utils/DeviceUtility.js'

const PRODUCT_CODES = 'productCodes'
const BYPRODUCT_CODES = 'byproductCodes'
const EXPORT_CODES = 'exportCodes'
const GL_CODES = 'glCodes'
const ALL = 'all'

export default {
  name: 'CompanyInformation',

  components: {
    ConfirmDialog: () => import('@/components/helper/ConfirmDialog.vue'),
    CompanyEntities: () => import('./CompanyEntities.vue'),
    CompanyLogo: () => import('./CompanyLogo.vue'),
    CompanyContactInfo: () => import('./CompanyContactInfo.vue'),
    CompanyOptions: () => import('./CompanyOptions.vue'),
    LoadableComponent: () => import('@/components/helper/LoadableComponent.vue')
  },

  data: () => ({
    companyInfo: {
      address: {},
      code: '',
      name: '',
      financialIntegrationType: FinancialIntegrationType.enums[0]
    },
    mainContact: {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: ''
    },
    transporterContact: {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: ''
    },
    loaderContact: {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: ''
    },
    config: {
      financialIntegrationType: FinancialIntegrationType.enums[0],
      adjustingEntriesFrequency: undefined,
      performSecondaryBiPump: false,
      localDailyTicketReportHour: 0,
      requireApprovalForContractModifications: false,
      lastDailyTicketReportCreatedAt: undefined,
      accountPayableTemplateDayOfWeek: 0,
      accountPayableTemplateLocalHour: 0,
      samsaraIntegration: false
    },
    samsaraConfig: undefined,
    deleteSamsara: false,
    loading: false,
    addDeckNowDialog: false,
    deckForm: false,
    financialIntegrationType: FinancialIntegrationType.enums[0],
    phoneMask: '(###)-###-####',
    locationId: -1,
    address: {
      addressLine1: '',
      addressLine2: ''
    },
    locations: [],
    performSecondaryBiPump: false,
    requireApprovalForContractModifications: false,
    states,
    timezones,
    UserClaims,
    SystemDayOfWeek
  }),

  directives: {
    mask
  },

  watch: {
    'config.samsaraIntegration' (val) {
      if (val) return
      this.deleteSamsara = true
    }
  },

  async created () {
    await this.getCompanyInfo()
    await this.getEntities()
  },

  computed: {
    ...mapGetters('user', ['businessEntities']),
    ...mapGetters('samsara', ['samsaraConfigurationMutated', 'samsaraConfiguration', 'samsaraConfigurationMutated']),
    showSaveChanges () {
      const timezoneMutated = Number(this.config.selectedTimeZone?.offset) !== this.companyInfo.timeZoneOffset
      const mainContactMutated = !isEqual(this.mainContact, this.companyInfo.mainContact)
      const transporterContactMutated = !isEqual(this.transporterContact, this.companyInfo.transporterContact) &&
        !(this.checkForBlankContact(this.transporterContact) === undefined && this.companyInfo.transporterContact === null)
      const loaderContactMutated = !isEqual(this.loaderContact, this.companyInfo.loaderContact) &&
        !(this.checkForBlankContact(this.loaderContact) === undefined && this.companyInfo.loaderContact === null)
      const addressMutated = !isEqual(this.address, this.companyInfo.address)
      const financialIntegrationTypeMutated = !isEqual(this.config.financialIntegrationType, this.companyInfo.financialIntegrationType)
      const adjustingEntriesFrequencyMutated = this.config.adjustingEntriesFrequency?.value !== this.companyInfo.adjustingEntriesFrequency?.value
      const secondaryBiPumpMutated = this.config.performSecondaryBiPump !== this.companyInfo.performSecondaryBiPump
      const requireApprovalForContractModificationsMutated = this.config.requireApprovalForContractModifications !== this.companyInfo.requireApprovalForContractModifications
      const localDailyTicketReportHourMutated = this.config.localDailyTicketReportHour !== this.companyInfo.localDailyTicketReportHour
      const accountPaymentDayMutated = this.config.accountPayableTemplateDayOfWeek?.value !== this.companyInfo.recurringAccountPaymentDayOfWeek
      const accountPaymentTimeMutated = this.config.accountPayableTemplateLocalHour !== this.companyInfo.recurringAccountPaymentHour
      const integrateWithSamsaraMutated = this.config.samsaraIntegration !== this.integratesWithSamsara

      return mainContactMutated ||
        loaderContactMutated ||
        transporterContactMutated ||
        addressMutated ||
        timezoneMutated ||
        financialIntegrationTypeMutated ||
        adjustingEntriesFrequencyMutated ||
        secondaryBiPumpMutated ||
        requireApprovalForContractModificationsMutated ||
        localDailyTicketReportHourMutated ||
        accountPaymentDayMutated ||
        accountPaymentTimeMutated ||
        integrateWithSamsaraMutated ||
        this.samsaraConfigurationMutated
    },

    fieldsAreReadonly () {
      return !userAssignedClaim(UserClaims.GlobalAdmin)
    },

    enableDownloadIcon () {
      return this.companyInfo.financialIntegrationType.value !== FinancialIntegrationType.None.value
    },

    CSVDownloadOptions () {
      return [PRODUCT_CODES, BYPRODUCT_CODES, EXPORT_CODES, GL_CODES, ALL]
    },

    samsaraRequest () {
      const request = { ...this.samsaraConfiguration }
      request.samsaraContactId = request.samsaraContact.applicationUserId
      request.integrationStatus ??= SamsaraIntegrationStatus.Active.value
      return request
    },

    integratesWithSamsara () {
      return !(this.companyInfo.samsaraIntegrationStatus == null || this.companyInfo.samsaraIntegrationStatus === SamsaraIntegrationStatus.NotConfigured.value)
    }
  },

  methods: {
    ...mapActions('user', ['fetchCompanyInfo', 'updateCompanyInfo', 'fetchAllBusinessEntities']),
    ...mapActions('product', ['fetchProducts']),
    ...mapActions('account', ['fetchAccounts']),
    ...mapActions('activity-templates', ['fetchActivityTemplates']),
    ...mapActions('samsara', [
      'createSamsaraConfiguration',
      'updateSamsaraConfiguration',
      'deleteSamsaraConfiguration',
      'fetchSamsaraConfiguration',
      'resetSamsaraConfiguration'
    ]),
    userAssignedClaim,
    isMobile,
    moment,

    async getCompanyInfo () {
      this.loading = true
      try {
        const info = await this.fetchCompanyInfo()
        this.companyInfo = { ...info }
        const tz = (this.timezones.find(tz => Number(tz.offset) === this.companyInfo.timeZoneOffset))
        this.config.selectedTimeZone = tz

        this.companyInfo.financialIntegrationType = FinancialIntegrationType.forInt(info.financialIntegrationType)
        this.companyInfo.adjustingEntriesFrequency = AdjustingEntriesFrequency.forInt(info.adjustingEntriesFrequency)

        this.config.adjustingEntriesFrequency = AdjustingEntriesFrequency.forInt(info.adjustingEntriesFrequency)

        this.config.financialIntegrationType = this.companyInfo.financialIntegrationType
        this.config.performSecondaryBiPump = this.companyInfo.performSecondaryBiPump
        this.config.requireApprovalForContractModifications = info.requireApprovalForContractModifications
        this.config.localDailyTicketReportHour = this.companyInfo.localDailyTicketReportHour
        this.config.lastDailyTicketReportCreatedAt = this.companyInfo.lastDailyTicketReportCreatedAt
        this.config.samsaraIntegration = this.integratesWithSamsara
        this.config.accountPayableTemplateDayOfWeek = SystemDayOfWeek.forInt(info.recurringAccountPaymentDayOfWeek)
        this.config.accountPayableTemplateLocalHour = info.recurringAccountPaymentHour
        this.reset()
      } finally {
        this.loading = false
      }
    },

    async getEntities () {
      this.loading = true
      this.deckForm = false
      this.addDeckNowDialog = false
      this.locationId = -1
      await this.fetchAllBusinessEntities()
      this.loading = false
    },

    checkForBlankContact (contact) {
      if (contact?.email === '' &&
        contact?.firstName === '' &&
        contact?.lastName === '' &&
        contact?.phoneNumber === '') return undefined
      return contact
    },

    deleteTransporterContact () {
      this.transporterContact = {
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: ''
      }
    },

    deleteLoaderContact () {
      this.loaderContact = {
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: ''
      }
    },

    closeConfirmDialog () {
      this.locationId = -1
      this.addDeckNowDialog = false
    },

    showDeckForm () {
      this.addDeckNowDialog = false
      this.deckForm = true
    },

    async updateInfo () {
      if (!this.verifyRequest()) {
        return
      }
      const updateRequest = this.getUpdateRequest()
      await this.updateCompanyInfo(updateRequest)

      let refreshSamsara = false
      if (this.config.samsaraIntegration === false && this.integratesWithSamsara) {
        await this.deleteSamsaraConfiguration()
      } else if (this.integratesWithSamsara && this.samsaraConfigurationMutated) {
        await this.updateSamsaraConfiguration(this.samsaraRequest)
        refreshSamsara = true
      } else if (this.samsaraConfigurationMutated) {
        await this.createSamsaraConfiguration(this.samsaraRequest)
        refreshSamsara = true
      }

      await this.getCompanyInfo()
      if (!refreshSamsara) return

      await this.fetchSamsaraConfiguration()
    },

    reset () {
      if (this.companyInfo.mainContact) {
        this.mainContact = JSON.parse(JSON.stringify(this.companyInfo.mainContact))
      } else {
        this.mainContact = {
          firstName: '',
          lastName: '',
          phoneNumber: '',
          email: ''
        }
      }

      if (this.companyInfo.transporterContact) {
        this.transporterContact = JSON.parse(JSON.stringify(this.companyInfo.transporterContact))
      } else {
        this.transporterContact = {
          firstName: '',
          lastName: '',
          phoneNumber: '',
          email: ''
        }
      }

      if (this.companyInfo.loaderContact) {
        this.loaderContact = JSON.parse(JSON.stringify(this.companyInfo.loaderContact))
      } else {
        this.loaderContact = {
          firstName: '',
          lastName: '',
          phoneNumber: '',
          email: ''
        }
      }

      if (this.companyInfo.address) {
        this.address = JSON.parse(JSON.stringify(this.companyInfo.address))
      } else {
        this.address = {
          addressLine1: '',
          addressLine2: '',
          type: 0,
          city: '',
          state: '',
          postalCode: ''
        }
      }

      this.config.financialIntegrationType = this.companyInfo.financialIntegrationType
      this.config.requireApprovalForContractModifications = this.companyInfo.requireApprovalForContractModifications
      this.config.performSecondaryBiPump = this.companyInfo.performSecondaryBiPump
      this.config.samsaraIntegration = this.integratesWithSamsara

      if (this.config.adjustingEntriesFrequency) {
        this.config.adjustingEntriesFrequency = this.companyInfo.adjustingEntriesFrequency
      } else {
        this.config.adjustingEntriesFrequency = undefined
      }

      const tz = (this.timezones.find(tz => Number(tz.offset) === this.companyInfo.timeZoneOffset))
      if (tz) {
        this.config.selectedTimeZone = tz
      }

      this.resetSamsaraConfiguration()
    },

    getUpdateRequest () {
      this.mainContact.personId = 0
      this.transporterContact.personId = 0
      this.loaderContact.personId = 0
      this.address.addressId = 0

      const formattedContact = JSON.parse(JSON.stringify(this.mainContact))
      let formattedTransporterContact = JSON.parse(JSON.stringify(this.transporterContact))
      let formattedLoaderContact = JSON.parse(JSON.stringify(this.loaderContact))
      formattedContact.phoneNumber.replace('(', '').replace(')', '').replace('-', '').replace('-', '')
      formattedTransporterContact.phoneNumber.replace('(', '').replace(')', '').replace('-', '').replace('-', '')
      formattedLoaderContact.phoneNumber.replace('(', '').replace(')', '').replace('-', '').replace('-', '')

      if (formattedTransporterContact.email === '' &&
        formattedTransporterContact.firstName === '' &&
        formattedTransporterContact.lastName === '' &&
        formattedTransporterContact.phoneNumber === '') formattedTransporterContact = null

      if (formattedLoaderContact.email === '' &&
        formattedLoaderContact.firstName === '' &&
        formattedLoaderContact.lastName === '' &&
        formattedLoaderContact.phoneNumber === '') formattedLoaderContact = null

      return {
        mainContact: formattedContact,
        transporterContact: formattedTransporterContact,
        loaderContact: formattedLoaderContact,
        financialIntegrationType: this.config.financialIntegrationType.value,
        address: this.address,
        timeZoneOffset: Number(this.config.selectedTimeZone.offset),
        adjustingEntriesFrequency: this.config.adjustingEntriesFrequency.value,
        performSecondaryBiPump: this.config.performSecondaryBiPump,
        requireApprovalForContractModifications: this.config.requireApprovalForContractModifications,
        localDailyTicketReportHour: this.config.localDailyTicketReportHour,
        recurringAccountPaymentHour: this.config.accountPayableTemplateLocalHour,
        recurringAccountPaymentDayOfWeek: this.config.accountPayableTemplateDayOfWeek.value
      }
    },

    verifyRequest () {
      if (this.address.addressLine1 === '' ||
      this.address.city === '' ||
      this.address.postalCode === '' ||
      this.address.state === '') {
        this.setSnackError(this.$t('fillAddress'))
        return false
      }

      const contactMissingField = (contact) => {
        return (contact.firstName === '' ||
          contact.lastName === '' ||
          contact.phoneNumber === '' ||
          contact.email === ''
        )
      }

      if (contactMissingField(this.mainContact)) {
        this.setSnackError(this.$t('fillMainContact'))
        return false
      }

      if (this.config.samsaraIntegration &&
          (this.samsaraConfiguration.trailerIdPrefix === '' ||
          contactMissingField(this.samsaraConfiguration.samsaraContact))
      ) {
        this.setSnackError(this.$t('fillSamsaraConfiguration'))
        return false
      }

      return true
    }
  }

}
</script>
