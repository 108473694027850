<template>
  <ShortcutWrapper :nShortcutRequiredClaim="UserClaims.ContractManager" @n-shortcut="mutate(null, 'create')">
    <v-container fluid>
      <v-row dense>
        <v-col cols="12">

          <Dialog
          :stateId="dialogId"
          maxWidth="400px"
          @click:outside="close"
          @dialog-closing="close"
          @close="close">
            <ConfirmDelete
            v-if="confirmDeleteDialog"
            @delete="performDelete"
            @cancel-delete="close"
            :title="$t('loggingMethod')"/>
            <LoggingMethodForm
            v-else
            :propLoggingMethod="loggingMethod"
            @logging-method-mutated="refresh" />
          </Dialog>

          <v-row>
            <v-col style="max-width: 800px;">
              <DataTable
              defaultHeight="calc(100vh - 620px)"
              :customCells="customColumns"
              :headers="headers"
              :items="allLoggingMethods"
              :loading="loading"
              :showDense="false">

                <template #actions="{ item }">
                  <Icon
                  icon="mdi-pencil"
                  :tooltipText="$t('edit')"
                  @icon-clicked="mutate(item, 'edit')"
                  :disabled="!userAssignedClaim(UserClaims.ContractManager)"
                  elementId="logging-method-edit"/>
                  <Icon
                  icon="mdi-delete-forever"
                  :tooltipText="$t('delete')"
                  @icon-clicked="mutate(item, 'delete')"
                  :disabled="!userAssignedClaim(UserClaims.ContractManager)"
                  elementId="logging-method-delete"/>
                </template>

                <template #custom-controls>
                  <Icon
                  icon="mdi-refresh"
                  :tooltipText="$t('refresh')"
                  @icon-clicked="refresh"
                  :small="false"/>
                  <Icon icon="mdi-plus"
                  :tooltipText="$t('newLoggingMethod')"
                  @icon-clicked="mutate(null, 'create')"
                  :small="false"
                  :disabled="!userAssignedClaim(UserClaims.ContractManager)"
                  elementId="logging-method-create"/>
                </template>

              </DataTable>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </ShortcutWrapper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { UserClaims } from '@/utils/Enumerations.js'
import LoggingMethodHeaders from '@/headers/LoggingMethod'
import { userAssignedClaim } from '@/utils/ClaimUtility'
import { uniqueDialogId } from '@/utils/componentHelpers'

export default {
  name: 'TractSettings',

  components: {
    ConfirmDelete: () => import('@/components/helper/ConfirmDelete.vue'),
    ShortcutWrapper: () => import('@/components/core/ShortcutWrapper.vue'),
    Icon: () => import('@/components/helper/Icon.vue'),
    DataTable: () => import('@/components/core/table/DataTable.vue'),
    Dialog: () => import('@/components/Dialog.vue'),
    LoggingMethodForm: () => import('./LoggingMethodForm.vue')
  },

  created () {
    this.refresh()
  },

  computed: {
    ...mapGetters('logging-method', ['allLoggingMethods']),

    headers () {
      return LoggingMethodHeaders.loggingMethodHeaders()
    },

    customColumns () {
      return [{
        slotName: 'actions',
        value: 'actions'
      }]
    }
  },

  data: () => ({
    confirmDeleteDialog: false,
    loading: false,
    loggingMethod: undefined,
    dialogId: uniqueDialogId('logging-method-settings-dialog'),
    UserClaims
  }),

  methods: {
    ...mapActions('logging-method', ['fetchLoggingMethods', 'deleteLoggingMethod']),
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),

    userAssignedClaim,

    refresh () {
      this.close()
      this.loading = true
      this.fetchLoggingMethods().finally(() => {
        this.loading = false
      })
    },

    mutate (loggingMethod, mutationType) {
      this.loggingMethod = JSON.parse(JSON.stringify(loggingMethod))
      if (mutationType === 'delete') this.confirmDeleteDialog = true
      this.openOrUpdateDialog(this.dialogId)
    },

    close () {
      this.confirmDeleteDialog = false
      this.closeDialogsAtOrAbove(this.dialogId)
      this.loggingMethod = undefined
    },

    async performDelete () {
      await this.deleteLoggingMethod(this.loggingMethod.loggingMethodId)
      this.refresh()
    }
  }
}
</script>
