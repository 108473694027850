<template>
  <v-row>
    <v-col>
      <v-row class="mt-1">
        <v-col cols="12">
          <v-container fluid>
            <v-expansion-panels popout multiple tile v-model="openPanels">
              <v-expansion-panel v-if="!noTicketsReceivables" value="ticketReceivables">
                <v-expansion-panel-header>
                  {{$t('ticketReceivables')}}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <span v-if="primaryTickets.length > 0">
                    <p class="subtitle-1 mt-3 mb-n2">{{$t('contractPayments')}}</p>
                    <TicketFinancialsTable
                    :entries="primaryTickets"
                    @aggregateSelected="contractPaymentSelected"/>
                  </span>

                  <span v-if="primaryCorrections.length > 0">
                    <p class="subtitle-1 mt-6 mb-n2">{{$t('corrections')}}</p>
                    <TicketFinancialsTable
                    :entries="primaryCorrections"
                    isCorrections
                    @aggregateSelected="correctionSelected"/>
                  </span>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel v-if="!isByproducts && lysReceivables" value="logYardSaleReceivables">
                <v-expansion-panel-header>
                  {{$t('logYardSaleReceivables')}}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <TicketFinancialsTable
                  v-if="tickets.lys.receivables.length > 0"
                  :entries="tickets.lys.receivables"
                  @aggregateSelected="logYardSaleReceivableSelected"/>

                  <span v-if="corrections.lys.receivables.length > 0">
                    <p class="subtitle-1 mt-6 mb-n2">{{$t('corrections')}}</p>
                    <TicketFinancialsTable
                    :entries="corrections.lys.receivables"
                    isCorrections
                    @aggregateSelected="correctionSelected"/>
                  </span>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-container>
        </v-col>
      </v-row>
      <NoData v-if="showNoData" class="mb-4">
        {{ $t('noDataForSelection') }}
      </NoData>
    </v-col>
    <v-dialog v-model="detailDialogOpen" width="900px">
      <TicketwiseFinancialBreakdown
      v-if="detailDialogOpen"
      :isCorrection="focusedContractPaymentIsCorrection"
      :payableSummary="focusedContractPayment"
      :isByproducts="isByproducts"
      @close="closeDialogs"/>
    </v-dialog>
  </v-row>
</template>

<script>
import { formatMoney } from '@/utils/NumericMutations'
import AdjustingEntriesHeaders from '@/headers/AdjustingEntries.js'
import { MiscPaymentType } from '@/utils/Enumerations'
export default {
  name: 'ReceivablesAggregations',

  components: {
    TicketwiseFinancialBreakdown: () => import('@/components/accounting/adjusting-entries/v1/TicketwiseFinancialBreakdown.vue'),
    TicketFinancialsTable: () => import('@/components/accounting/adjusting-entries/v1/TicketFinancialsTable.vue'),
    NoData: () => import('@/components/core/NoData.vue')
  },

  props: {
    tickets: {
      type: Object,
      required: true
    },
    corrections: {
      type: Object,
      required: true
    },
    isByproducts: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    detailDialogOpen: false,
    focusedContractPayment: undefined,
    focusedContractPaymentIsCorrection: false,
    focusedReceivableIsTransfer: false,
    openPanels: [],
    MiscPaymentType
  }),

  mounted () {
    if (this.isByproducts) {
      this.openPanels = !this.noTicketsReceivables ? [0] : []
    } else {
      this.openPanels = ((!this.noTicketsReceivables && !this.lysReceivables) ||
      (this.noTicketsReceivables && this.lysReceivables)) ? [0] : []
    }
  },

  computed: {
    lysReceivables () {
      return this.tickets.lys.receivables.length > 0 || this.corrections.lys.receivables.length > 0
    },
    contractPaymentHeaders () {
      return AdjustingEntriesHeaders.ticketContractPayments()
    },
    correctionsHeaders () {
      return AdjustingEntriesHeaders.ticketContractPayments()
    },
    primaryTickets () {
      return this.isByproducts ? this.tickets.byproducts.receivables : this.tickets.logs.receivables
    },
    primaryCorrections () {
      return this.isByproducts ? this.corrections.byproducts.receivables : this.corrections.logs.receivables
    },
    noTicketsReceivables () {
      return this.primaryTickets.length === 0 && this.primaryCorrections.length === 0
    },
    showNoData () {
      const otherLogsFinancials = this.tickets.lys.receivables.length > 0 ||
        this.corrections.lys.receivables.length > 0
      return this.noTicketsReceivables && (this.isByproducts || !otherLogsFinancials)
    },
    contractColor () {
      return this.isByproducts ? 'byproduct' : 'primary'
    },
    borderStyle () {
      return `border: 3px solid ${this.isByproducts ? '#363636' : '#D15F27'}`
    }
  },

  methods: {
    formatMoney,

    contractPaymentSelected (contractPayment) {
      this.detailDialogOpen = true
      this.focusedContractPayment = contractPayment
    },

    correctionSelected (correction) {
      this.focusedContractPayment = correction
      this.focusedContractPaymentIsCorrection = true
      this.detailDialogOpen = true
    },

    logYardSaleReceivableSelected (logYardSaleReceivable) {
      this.focusedContractPayment = logYardSaleReceivable
      this.focusedReceivableIsLogYardSale = true
      this.detailDialogOpen = true
    },

    closeDialogs () {
      this.detailDialogOpen = false
      this.focusedContractPayment = undefined
      this.focusedContractPaymentIsCorrection = false
      this.focusedReceivableIsTransfer = false
      this.focusedReceivableIsLogYardSale = false
    }
  }
}
</script>
