export default {
  setjournalEntriesLoadingStatus (state, status) {
    state.receivableLoadingStatus = status
  },
  setJournalEntries (state, journalEntries) {
    state.journalEntries = journalEntries
  },
  addJournalEntry (state, receivable) {
    state.journalEntries.push(receivable)
  },
  updateJournalEntry (state, receivable) {
    const index = state.journalEntries.findIndex(d => d === receivable.receivableId)
    if (index !== -1) {
      Object.assign(state.journalEntries[index], receivable)
    }
  },
  removeJournalEntry (state, receivableId) {
    state.journalEntries = state.journalEntries.filter(d => d.receivableId !== receivableId)
  },
  setAccountPayables (state, accountPayables) {
    state.accountPayablesAC = accountPayables
  }
}
