// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'

// Components
import './components'

// Plugins
import './plugins'

// Sync router with store
import { sync } from 'vuex-router-sync'

// Application imports
import App from './App'
import i18n from '@/i18n'
import router from '@/router'
import store from '@/store'
import vuetify from '@/plugins/vuetify'

// Sync store with router
sync(store, router)

Vue.config.productionTip = false
Vue.use(require('../src/styles/mapbox-gl.css'))

// Global Helper Functions
Vue.mixin({
  methods: {
    addNameWithCode: (name, code) => {
      if (code === '' || code === undefined || code === null) {
        return name
      } else {
        return `${name} \u2016 ${code}`
      }
    },

    setSnack: (msg) => {
      store.dispatch('snackbar/setSnack', msg)
    },

    setSnackError: (msg) => {
      store.dispatch('snackbar/setSnackError', msg)
    }
  }
})

/* eslint-disable no-new */
new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

document.addEventListener('focusin', ({ target }) => {
  if (target.type === 'text' || target.type === 'number') {
    target.select()
    // target.setAttribute('autocomplete', 'off') // Placeholder for release. Needs extensive testing
  }
})
