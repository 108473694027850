<template>
  <v-card>
    <v-card-title class="primary">
      <span class="headline white--text">{{ $t('editingSamsaraConfig') }}</span>
      <v-spacer/>
      <Icon
      iconColor="white"
      icon="mdi-close"
      :small="false"
      large
      :tooltipText="$t('close')"
      @icon-clicked="$emit('close')"
      />
    </v-card-title>
    <v-card-text>
      <FormWrapper
      strongValidation
      @submit="done()"
      :buttonText="$t('done')"
      :disabled="loading || !config.samsaraContact?.name">
        <v-container fluid grid-list-xl>
          <LoadableComponent :loading="loading" :height="450">
            <v-row class="mt-2">
            <v-col>
              <span class="title">
                {{ $t('setup') }}
              </span>
            </v-col>
          </v-row>
          <v-row align="center" class="mt-n5">
            <v-col cols="12">
              <v-text-field
              :label="$t('apiKey')"
              maxLength="64"
              counter="64"
              :type="hideKey ? 'password' : ''"
              :rules="[rules.required]"
              v-model="config.apiToken"
              autocomplete="samsara-api-key">
                <template #append>
                  <Icon
                  :small="false"
                  :icon="hideKey ? 'mdi-eye-off' : 'mdi-eye'"
                  icon-color="black"
                  @icon-clicked="hideKey = !hideKey"
                  :tooltipText="hideKey ? $t('showApiKey') : $t('hideApiKey')"/>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
              :label="$t('trailerIdPrefix1')"
              maxLength="5"
              counter="5"
              :rules="[rules.required]"
              v-model="config.samsaraTrailerIdPrefix1"/>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
              :label="$t('trailerIdPrefix2')"
              maxLength="5"
              counter="5"
              v-model="config.samsaraTrailerIdPrefix2"
              clearable/>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <UserAutocomplete
              :requiredClaim="UserClaims.GlobalAdmin"
              @user-chosen="userChosen"
              :label="$t('contact')"
              :propUserId="config.samsaraContact?.applicationUserId"
              :clear="clearUserAutocomplete"
              :clearable="false"/>
            </v-col>
          </v-row>
          </LoadableComponent>
        </v-container>
      </FormWrapper>
      <v-dialog v-model="userProfileDialog" width="600">
        <v-card v-if="userProfileDialog && focusedUser">
          <v-card-title class="primary">
            <span class="headline white--text">{{ $t('userProfile') }}</span>
            <v-spacer/>
            <Icon
            iconColor="white"
            icon="mdi-close"
            :small="false"
            large
            :tooltipText="$t('close')"
            @icon-clicked="closeUserProfileDialog"
            />
          </v-card-title>
          <v-card-text>
            <FormWrapper
            strongValidation
            @submit="updateProfile">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                    v-model="person.firstName"
                    color="black"
                    maxlength="64"
                    counter
                    :label="$t('firstName')"
                    :rules="[rules.required]"
                    ref="firstName"/>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                    v-model="person.lastName"
                    color="black"
                    maxlength="64"
                    counter
                    :label="$t('lastName')"
                    :rules="[rules.required]"/>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                    v-model="person.email"
                    color="black"
                    maxlength="64"
                    :label="$t('email')"
                    counter
                    :rules="[rules.required, rules.email]"/>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                    v-model="person.phoneNumber"
                    v-mask="phoneMask"
                    :label="$t('phoneNumber')"
                    :rules="[rules.required]"
                    data-testid="phone-number"
                    color="black"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </FormWrapper>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import { mask } from 'vue-the-mask'
import fieldRules from '@/utils/rules'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { UserClaims } from '@/utils/Enumerations'
export default {
  name: 'SamsaraConfigForm',

  components: {
    Icon: () => import('@/components/helper/Icon.vue'),
    LoadableComponent: () => import('@/components/helper/LoadableComponent.vue'),
    FormWrapper: () => import('@/components/core/FormWrapper.vue'),
    UserAutocomplete: () => import('@/components/autocomplete/UserAutocomplete.vue')
  },

  directives: {
    mask
  },

  props: {
    isEditing: {
      type: Boolean,
      required: true
    }
  },

  data: () => ({
    UserClaims,
    phoneMask: '(###)-###-####',
    rules: fieldRules.rules,
    hideKey: true,
    config: {
      apiToken: undefined,
      samsaraContact: {},
      samsaraTrailerIdPrefix1: undefined,
      samsaraTrailerIdPrefix2: undefined
    },
    loading: false,
    focusedUser: undefined,
    userProfileDialog: false,
    clearUserAutocomplete: false,
    person: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: ''
    }
  }),

  async created () {
    this.loading = true
    this.config = { ...this.samsaraConfiguration }

    if (this.isEditing && !this.config.apiToken) {
      this.config.apiToken = await this.fetchSamsaraApiToken()
      this.setFetchedApiToken(this.config.apiToken)
    }
    this.loading = false
  },

  methods: {
    ...mapActions('samsara', ['fetchSamsaraApiToken']),
    ...mapActions('user', ['updateUserInfo']),
    ...mapMutations('samsara', ['setFetchedApiToken']),
    done () {
      this.config.apiTokenSuffix = this.config.apiToken[this.config.apiToken.length - 1]
      this.$emit('done-editing', this.config)
    },

    userChosen (user) {
      if (user == null) {
        this.config.samsaraContact = null
        return
      }
      if (user.person == null) {
        this.focusedUser = user
        this.userProfileDialog = true
        setTimeout(_ => {
          this.$refs.firstName.focus()
        }, 50)
        return
      }
      this.config.samsaraContact = user
    },

    closeUserProfileDialog () {
      this.focusedUser = undefined
      this.userProfileDialog = false
      this.clearUserAutocomplete = true
      this.$nextTick(() => {
        this.clearUserAutocomplete = false
      })
    },

    async updateProfile () {
      this.focusedUser.person = this.person
      await this.updateUserInfo(this.focusedUser)
      this.userProfileDialog = false
      this.config.samsaraContact = this.focusedUser
    }
  },

  computed: {
    ...mapGetters('samsara', ['samsaraConfiguration'])
  }
}
</script>
