<template>
  <v-card>
    <v-card-title class="primary">
      <v-row no-gutters>
        <v-col style="min-width: 128px">
          <span class="headline white--text">{{$t('loggerAllocation')}}</span>
        </v-col>
        <v-col cols="auto">
          <v-select
          v-model="selectedEndDate"
          :label="$t('timespan')"
          prepend-inner-icon="mdi-clock-outline"
          outlined
          rounded
          :items="timePeriodOptions"
          item-text="name"
          return-object
          dense
          dark
          class="mb-n6 mr-2"
          style="width: 220px"
          />
        </v-col>
        <v-col cols="auto">
          <BaseDialogActions hideRefresh />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-expansion-panels popout class="mt-4 timber-portfolio-logger-allocation" id="timber-portfolio-logger-allocation-panels">
        <v-expansion-panels popout tile accordion v-model="panel">
          <v-expansion-panel v-for="logger in loggersWithDecoratedTracts" :key="logger.name">
            <v-expansion-panel-header :style="$vuetify.breakpoint.xs ? '' : 'height: 64px'">
              <v-container fluid class="pl-0">
                <v-row align="center" no-gutters>
                  <v-col cols="12" sm="" class="pr-2">
                    {{ logger.name }}
                  </v-col>
                  <v-col cols="auto" :style="!$vuetify.breakpoint.xs ? { width: '180px' } : {}" class="body-2 py-1">
                    <v-row no-gutters>
                      <v-col cols="12">
                        <v-row no-gutters>
                          <v-col>
                            {{ $t('allocated') }}
                          </v-col>
                          <v-col cols="auto" class="pl-2">
                            {{ numberWithCommas(logger.totalAllocated, 3) }}
                          </v-col>
                        </v-row>
                        <v-row no-gutters>
                          <v-col>
                            {{ $t('active') }}
                          </v-col>
                          <v-col cols="auto" class="pl-2">
                            {{ numberWithCommas(logger.active, 3) }}
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="auto" style="width: 40px;">
                    <v-tooltip bottom color="black">
                      <template #activator="{ on }">
                        <v-progress-circular
                        :rotate="-90"
                        class="ml-2"
                        width="14"
                        size="40"
                        :style="{ stroke: logger.totalAllocated === 0 ? 'black' : 'inherit' }"
                        :value="logger.allocation"
                        v-on="on"
                        :color="success"
                        />
                      </template>
                      <p class="subtitle-1 white--text my-0">{{ `${numberWithCommas(logger.active, 3)} ${$t('active')}\n` }}</p>
                      <span class="subtitle-1 white--text">{{ `${numberWithCommas(logger.totalAllocated - logger.active, 3)} ${$t('planned')}` }}</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-container>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-data-table
                class="pt-2"
                :items="logger.tracts"
                :headers="tractsByLoggerHeaders"
                item-key="tractId"
                :hide-default-footer="logger.tracts?.length <= 10"
                :items-per-page="15"
                :footer-props="{ itemsPerPageOptions: [10, 15, 25, -1] }"
                mobile-breakpoint="0"
                calculate-widths
                style="word-break:break-word"
              >
                <template #header.timeline>
                  <v-row no-gutters justify="space-between">
                    <v-col cols="auto" class="ml-n4">
                      <v-icon class="mb-n7" style="width: 0; transform: translate(16px);" color="grey lighten-2">mdi-menu-down</v-icon>
                      <v-chip small>{{formatDate(today)}}</v-chip>
                    </v-col>
                    <v-col cols="auto" class="mr-n3" :style="{ transform: `translate(-${1 / daysBetweenTodayAndLatestDate * timelineWidth}px)` }">
                      <v-chip small>{{formatDate(selectedEndDate?.endDate ?? latestDate)}}</v-chip>
                      <v-icon class="mb-n7" style="width: 0; transform: translate(-16px);" color="grey lighten-2">mdi-menu-down</v-icon>
                    </v-col>
                  </v-row>
                </template>
                <template #item.status="{item}">
                  <TractStatusIcon :status="item.status"/>
                </template>
                <template #item.timeline="{item}">

                  <v-tooltip
                  :activator="`#timeline-container-${item.tractId}`"
                  bottom
                  :color="tooltipColor(item)"
                  max-width="230"
                  :nudge-right="tooltipNudge(item)">
                    <v-row no-gutters>
                      <v-col cols="12">
                        <span :class="`subtitle-1 ${textClass(item)}`">{{ item.name }}</span>
                      </v-col>
                      <v-col cols="12">
                        <span :class="`subtitle-1 ${textClass(item)}`">{{ !item.harvestStartDate && !item.harvestEndDate ? $t('notApplicable') : formatDate(item.harvestStartDate).concat(' \u2014 ').concat(formatDate(item.harvestEndDate)) }}</span>
                      </v-col>
                      <v-col cols="12" v-if="showContractInfo(item) && item.openContractCount > 0">
                        <v-row no-gutters justify="space-between">
                          <v-col cols="auto"> <span :class="`subtitle-1 ${textClass(item)}`">{{ $t('openContracts')+':' }}</span> </v-col>
                          <v-col cols="auto"> <span :class="`subtitle-1 ${textClass(item)}`">{{ item.openContractCount }}</span> </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" v-if="showContractInfo(item) && item.pausedContractCount > 0">
                        <v-row no-gutters justify="space-between">
                          <v-col cols="auto"> <span :class="`subtitle-1 ${textClass(item)}`">{{ $t('pausedContracts')+':' }}</span> </v-col>
                          <v-col cols="auto"> <span :class="`subtitle-1 ${textClass(item)}`">{{ item.pausedContractCount }}</span> </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-tooltip>

                  <div
                    :id="`timeline-container-${item.tractId}`"
                    :style="timelineContainerStyle" >

                    <div v-if="!missingDate(item)" :style="{ backgroundColor: 'black', opacity: 0.1, width: `${item.preHarvestWidth}px`, transition: 'inherit' }" />

                    <div :style="{ width: `${missingDate(item) ? timelineWidth : item.harvestWidth}px`, transition: 'inherit' }"
                      :class="timelineColor(item)"/>

                    <div v-if="!missingDate(item)" :style="{ backgroundColor: 'black', opacity: 0.1, width: `${item.postHarvestWidth}px`, transition: 'inherit' }" />

                  </div>

                </template>
                <template #item.related="{item}">
                  <v-row no-gutters justify="center">
                    <v-col cols="auto">
                      <Icon
                      :small="false"
                      icon="mdi-information-outline"
                      dataTestId="tract-detail"
                      :tooltipText="$t('tractDetail')"
                      @icon-clicked="tractDetailClicked(item.tractId)"/>
                    </v-col>
                  </v-row>
                </template>
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-expansion-panels>
    </v-card-text>
  </v-card>
</template>

<script>
import { numberWithCommas, convertToPercentage } from '@/utils/NumericMutations.js'
import tractHeaders from '@/headers/Tract.js'
import { findIndex } from 'lodash'
import moment from 'moment'
import { formatDate, utcToLocalDate, localToUTC } from '@/utils/DateFormatter.js'
import { LocalStorageKeys } from '@/utils/LocalStorageActor'

export default {
  name: 'LoggerAllocation',

  components: {
    TractStatusIcon: () => import('@/components/tract/TractStatusIcon.vue'),
    Icon: () => import('@/components/helper/Icon.vue'),
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue')
  },

  props: {
    timberPortfolio: {
      type: Object,
      required: true
    },
    focusedLoggerId: {
      type: Number,
      required: false
    }
  },

  data: () => ({
    panel: undefined,
    timelineWidth: 800,
    resizeObserver: undefined,
    today: utcToLocalDate(localToUTC(moment().format(), null), 'raw'),
    selectedEndDate: undefined
  }),

  computed: {
    tractsByLoggerHeaders () {
      return tractHeaders.timberPortfolioTractForLoggerHeaders().getColumns()
    },

    loggers () {
      if (this.timberPortfolio?.loggers) return this.timberPortfolio.loggers.filter(l => l.accountId !== 0 && l.name.length > 0)
      return []
    },

    latestDate () {
      return this.selectedEndDate?.endDate ?? moment.max(this.tracts.filter(t => !!t.harvestEndDate).map(t => utcToLocalDate(t.harvestEndDate, 'raw')))
    },

    daysBetweenTodayAndLatestDate () {
      return this.latestDate.diff(this.today, 'days') + 1 // Adding 1 because our day count is inclusive
    },

    tracts () {
      return this.timberPortfolio.loggers
        .filter(l => !!l.name) // This dialog doesn't care about tracts not assigned to a logger
        .flatMap(l => l.tracts)
    },

    success () {
      return this.$vuetify.theme.defaults.light.success
    },

    timelineContainerStyle () {
      return {
        borderRadius: '4px',
        height: '24px',
        width: `${this.timelineWidth}px`,
        display: 'flex',
        flexWrap: 'nowrap',
        overflow: 'clip',
        transition: '.15s',
        boxShadow: 'inset 0px 0px 0px 1px rgba(0,0,0,0.1)'
      }
    },

    timePeriodOptions () {
      return this.today ? [
        {
          id: 0,
          name: this.$t('nWeeks', { n: 6 }),
          endDate: this.today.clone().add(6, 'weeks')
        },
        {
          id: 1,
          name: this.$t('nMonths', { n: 3 }),
          endDate: this.today.clone().add(3, 'months')
        },
        {
          id: 2,
          name: this.$t('nMonths', { n: 6 }),
          endDate: this.today.clone().add(6, 'months')
        },
        {
          id: 3,
          name: this.$t('latest'),
          endDate: moment(
            moment.max(
              this.tracts.filter(t => !!t.harvestEndDate)
                .map(t => moment(t.harvestEndDate))
            )

          )
        }
      ] : []
    },

    loggersWithDecoratedTracts () {
      return this.loggers.map(l => ({
        ...l,
        tracts: l.tracts.map(t => {
          const startDate = t.harvestStartDate ? utcToLocalDate(t.harvestStartDate, 'raw') : undefined
          const endDate = t.harvestEndDate ? utcToLocalDate(t.harvestEndDate, 'raw') : undefined
          let preHarvestWidth = 0
          let harvestWidth = 0
          if (!this.missingDate(t)) {
            preHarvestWidth = this.getDisplayWidthForPreHarvest({
              start: this.today,
              end: startDate
            })
            harvestWidth = this.getDisplayWidthForHarvest({
              start: startDate,
              end: endDate
            })
          }
          return {
            ...t,
            harvestStartDate: startDate,
            harvestEndDate: endDate,
            preHarvestWidth: preHarvestWidth,
            harvestWidth: harvestWidth,
            postHarvestWidth: this.missingDate(t) ? 0 : Math.max(this.timelineWidth - preHarvestWidth - harvestWidth, 0)
          }
        })
      }))
    }
  },

  async mounted () {
    this.panel = findIndex(this.loggers, l => l.loggerAccountId === this.focusedLoggerId)
    this.resizeObserver = new ResizeObserver(this.panelResized).observe(document.getElementById('timber-portfolio-logger-allocation-panels'))
    this.selectedEndDate = this.timePeriodOptions.find(tpo => tpo.id === parseInt(localStorage.getItem(LocalStorageKeys.LOGGER_ALLOCATION_PERIOD) ?? 3))
  },

  async beforeDestroy () {
    if (this.resizeObserver) await this.resizeObserver.unobserve()
    if (this.selectedEndDate) localStorage.setItem(LocalStorageKeys.LOGGER_ALLOCATION_PERIOD, this.selectedEndDate.id)
  },

  methods: {
    numberWithCommas,
    convertToPercentage,
    formatDate,

    openTractDetail (id) {
      this.focusedLoggerId = undefined
      this.focusedTractId = id
      this.openOrUpdateDialog(this.dialogId)
    },

    closeDialog () {
      this.closeDialogsAtOrAbove(this.dialogId)
    },

    tractDetailClicked (id) {
      this.$emit('tract-detail-clicked', id)
    },

    panelResized (resizeObserver) {
      let { width } = resizeObserver[0].contentRect
      width = Math.max(width - 460, 300)
      this.timelineWidth = width
    },

    getDisplayWidthForHarvest ({ start, end }) {
      start = moment.max(moment(start), this.today)
      end = moment.min(moment(end), this.latestDate)
      return Math.max(
        (end.diff(start, 'days') + 1) / // Adding 1 because our day count is inclusive
          this.daysBetweenTodayAndLatestDate * this.timelineWidth,
        0)
    },

    getDisplayWidthForPreHarvest ({ start, end }) {
      start = moment.max(moment(start), this.today)
      end = moment.min(moment(end), this.latestDate)
      return Math.max(
        (end.diff(start, 'days')) / // Not inclusive
          this.daysBetweenTodayAndLatestDate * this.timelineWidth,
        0)
    },

    missingDate ({ harvestStartDate, harvestEndDate }) {
      return !harvestStartDate || !harvestEndDate
    },

    tooltipNudge ({ preHarvestWidth, harvestWidth }) {
      if (harvestWidth === 0) return 0
      return (this.timelineWidth / -2) + preHarvestWidth + (harvestWidth / 2)
    },

    tooltipColor (item) {
      if (this.missingDate(item)) return 'grey'
      if (item.openContractCount > 0) return 'success'
      if (item.pausedContractCount > 0) return 'warning'
      return 'black'
    },

    timelineColor (item) {
      if (this.missingDate(item)) return ''
      if (item.openContractCount > 0) return 'success'
      if (item.pausedContractCount > 0) return 'warning'
      return 'grey'
    },

    textClass (item) {
      if (item.pausedContractCount > 0 && item.openContractCount === 0) return 'black--text'
      return 'white--text'
    },

    tooltipIconColor (item) {
      if (item.pausedContractCount > 0 && item.openContractCount === 0) return 'black'
      return 'white'
    },

    showContractInfo ({ harvestStartDate }) {
      return moment(harvestStartDate).isSameOrBefore(this.today)
    }
  }
}
</script>

<style>
.v-data-table-header tr th .v-chip.v-size--small .v-chip__content { /* These are normal styles for small chips, our own header styles were just overriding them */
  font-size: 14px;
  font-weight: normal;
  font-family: 'Roboto';
}
</style>
