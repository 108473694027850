<template>
  <v-card min-height="600px">
    <v-card-title class="primary white--text">
      <span>
        {{ $t('createExportBatch') }}
      </span>
      <v-spacer/>
      <BaseDialogActions
        @refresh="refreshData()"
      />
    </v-card-title>
    <v-card-text>
      <v-container fluid>
        <v-row dense v-show="noDataTextConfiguration.showNoDataText === false">
          <v-col>
            <v-text-field
            v-model="label"
            :label="$t('label')"
            :rules="labelRules"
            ref="exportBatchLabelTextfield"
            data-testid="export-batch-label"
            color="black"
            counter
            maxlength="64"/>
          </v-col>
        </v-row>
        <v-row>
          <v-expansion-panels popout accordion tile v-model="openPanel" v-if="noDataTextConfiguration.showNoDataText === false">
            <v-expansion-panel>
              <v-expansion-panel-header
                active-class="primary white--text"
                hide-actions
                :disabled="tickets.length === 0"
                :style="`cursor: ${tickets.length === 0 ? 'default' : 'pointer'};${tickets.length === 0 ? 'background-color: #f7f7f7' : ''}`">
                <v-row>
                  <v-col>
                    {{ $t('logTickets') }}
                  </v-col>
                  <v-col cols="auto">
                    {{ getTitleString(selectedTickets, tickets) }}
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <ExportBatchSelectionTable
                  :items="tickets"
                  :selectedItems.sync="selectedTickets"
                  :headers="headers.ticketHeaders"
                  tableKey="ticketId"
                  :filterTypes="ticketFiltersForExport(true)"
                  @filter-type-set="storeFilterType($event, 'log')"
                  :filterTypeFromStorage="getFilterTypeFromStorage('log')"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header
                active-class="primary white--text"
                hide-actions
                :disabled="byproductTickets.length === 0"
                :style="`cursor: ${byproductTickets.length === 0 ? 'default' : 'pointer'};${byproductTickets.length === 0 ? 'background-color: #f7f7f7' : ''}`">
                <v-row>
                  <v-col>
                    {{ $t('byproductTickets') }}
                  </v-col>
                  <v-col cols="auto">
                    {{ getTitleString(selectedByproductTickets, byproductTickets) }}
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <ExportBatchSelectionTable
                  :items="byproductTickets"
                  :selectedItems.sync="selectedByproductTickets"
                  :headers="headers.byproductTicketHeaders"
                  tableKey="ticketId"
                  :filterTypes="ticketFiltersForExport(false)"
                  @filter-type-set="storeFilterType($event, 'byproduct')"
                  :filterTypeFromStorage="getFilterTypeFromStorage('byproduct')"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header
                active-class="primary white--text"
                hide-actions
                :disabled="transferTickets.length === 0"
                :style="`cursor: ${transferTickets.length === 0 ? 'default' : 'pointer'};${transferTickets.length === 0 ? 'background-color: #f7f7f7' : ''}`">
                <v-row>
                  <v-col>
                    {{ $t('transferTickets') }}
                  </v-col>
                  <v-col cols="auto">
                    {{ getTitleString(selectedTransferTickets, transferTickets) }}
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <ExportBatchSelectionTable
                  :items="transferTickets"
                  :selectedItems.sync="selectedTransferTickets"
                  :headers="headers.byproductTicketHeaders"
                  tableKey="ticketId"
                  :filterTypes="ticketFiltersForExport(false)"
                  @filter-type-set="storeFilterType($event, 'transfer')"
                  :filterTypeFromStorage="getFilterTypeFromStorage('transfer')"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header
                active-class="primary white--text"
                hide-actions
                :disabled="logYardSaleTickets.length === 0"
                :style="`cursor: ${logYardSaleTickets.length === 0 ? 'default' : 'pointer'};${logYardSaleTickets.length === 0 ? 'background-color: #f7f7f7' : ''}`">
                <v-row>
                  <v-col>
                    {{ $t('logYardSaleTickets') }}
                  </v-col>
                  <v-col cols="auto">
                    {{ getTitleString(selectedLogYardSaleTickets, logYardSaleTickets) }}
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <ExportBatchSelectionTable
                  :items="logYardSaleTickets"
                  :selectedItems.sync="selectedLogYardSaleTickets"
                  :headers="headers.byproductTicketHeaders"
                  tableKey="ticketId"
                  :filterTypes="ticketFiltersForExport(false)"
                  @filter-type-set="storeFilterType($event, 'logYardSale')"
                  :filterTypeFromStorage="getFilterTypeFromStorage('logYardSale')"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header
                active-class="primary whtie--text"
                hide-actions
                :disabled="totalCorrections === 0"
                :style="`cursor: ${totalCorrections === 0 ? 'default' : 'pointer'};${totalCorrections === 0 ? 'background-color: #f7f7f7' : ''}`">
                <v-row>
                  <v-col>
                    {{ $t('ticketCorrections') }}
                  </v-col>
                  <v-col cols="auto">
                    {{ ticketCorrectionsTitleString }}
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row class="mt-6" v-if="corrections.length > 0">
                  <v-col>
                    <span class="title">{{ $t('logTicketCorrections') }}</span>
                    <SettlementCorrections
                    :corrections="corrections"
                    :selectedItems.sync="selectedCorrections"
                    showSelect
                    :contractMode="logsContractMode"
                    :filterTypes="ticketFiltersForExport(true)"
                    @filter-type-set="storeFilterType($event, 'log')"
                    :filterTypeFromStorage="getFilterTypeFromStorage('log')"/>
                  </v-col>
                </v-row>
                <v-row class="mt-6" v-if="byproductCorrections.length > 0">
                  <v-col>
                    <span class="title">{{ $t('byproductCorrections') }}</span>
                    <SettlementCorrections
                    :corrections="byproductCorrections"
                    :selectedItems.sync="selectedByproductCorrections"
                    showSelect
                    :contractMode="byproductContractMode"
                    :filterTypes="ticketFiltersForExport(false)"
                    @filter-type-set="storeFilterType($event, 'byproduct')"
                    :filterTypeFromStorage="getFilterTypeFromStorage('byproduct')"/>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header
                active-class="primary white--text"
                hide-actions
                :disabled="totalMiscPayments === 0"
                :style="`cursor: ${totalMiscPayments === 0 ? 'default' : 'pointer'};${totalMiscPayments === 0 ? 'background-color: #f7f7f7' : ''}`">
                <v-row>
                  <v-col>
                    {{ $t('miscPayments') }}
                  </v-col>
                  <v-col cols="auto">
                    {{ miscPaymentsTitleString }}
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row class="mt-6" v-if="advances.length > 0">
                  <v-col>
                    <span class="title">{{ $t('advances') }}</span>
                    <ExportBatchSelectionTable
                    :items="advances"
                    :selectedItems.sync="selectedAdvances"
                    :headers="headers.advanceHeaders"
                    tableKey="advanceId"/>
                  </v-col>
                </v-row>
                <v-row class="mt-6" v-if="tractPayments.length > 0">
                  <v-col>
                    <span class="title">{{ $t('tractPayments') }}</span>
                    <ExportBatchSelectionTable
                    :items="tractPayments"
                    :selectedItems.sync="selectedTractPayments"
                    :headers="headers.tractPaymentHeaders"
                    tableKey="tractPayableId"/>
                  </v-col>
                </v-row>
                <v-row class="mt-6" v-if="accountPayments.length > 0">
                  <v-col>
                    <span class="title">{{ $t('accountPayments') }}</span>
                    <ExportBatchSelectionTable
                    :items="accountPayments"
                    :selectedItems.sync="selectedAccountPayments"
                    :headers="headers.accountPaymentHeaders"
                    tableKey="accountPayableId"
                    :customCells="[{ slotName: 'note', value: 'note' }, { slotName: 'actions', value: 'actions' }]">
                      <template #note="{item}">
                        <span>
                          {{ item.note }}
                          <Icon
                          v-if="item.accountPayableTemplateId"
                          icon="mdi-sync"
                          :tooltipText="$t('recurring')"
                          iconColor="black"
                          tooltipColor="black"/>
                        </span>
                      </template>
                      <template #actions="{item}">
                        <Icon
                        :disabled="!userAssignedClaim(UserClaims.AccountManager)"
                        icon="mdi-delete-forever"
                        iconColor="error"
                        :tooltipText="$t('delete')"
                        @icon-clicked="openDeleteAccountPayableConfirmation(item)"/>
                      </template>
                    </ExportBatchSelectionTable>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-row align="center" justify="center" v-else>
            <v-col cols="auto">
              <span class="headline black--text">{{ noDataTextConfiguration.noDataText }}</span>
            </v-col>
          </v-row>
        </v-row>
      </v-container>
      <Loading v-show="loader.loading"/>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-checkbox
      v-model="exportAllFinancials"
      :label="$t('exportAllFinancials')"
      class="mr-2"
      data-testid="export-all-financials-checkbox"
      color="primary"/>
      <v-btn
      :disabled="!hasSelectedFinancials"
      color="primary"
      @click="openExportBatchDialog">
        {{ $t('createExportBatch') }}
      </v-btn>
    </v-card-actions>
    <v-dialog v-model="confirmExportBatchDialog" width="400px">
      <ConfirmDialog
      :title="$t('createExportBatch')"
      :body="$t('confirmExportBatchCreationMessage')"
      :confirmText="$t('create')"
      color="primary"
      @confirm="save"
      @cancel="closeDialogs"
      />
    </v-dialog>
    <v-dialog v-model="deleteAccountPayableDialog" width="400px">
      <ConfirmDialog
      v-if="deleteAccountPayableDialog"
      :title="$t('deleteAccountPaymentTitle')"
      :body="$t('deleteAccountPaymentBody')"
      @cancel="closeDialogs"
      @confirm="confirmDeleteAccountPayable"/>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { ResourceLoader } from '@/utils/ResourceLoader'
import fieldRules from '@/utils/rules.js'
import { settlementTractPaymentHeaders, settlementAccountPaymentHeaders, settlementAdvanceHeaders, ticketCorrectionHeaders } from '@/headers/ExportBatch.js'
import TicketHeaders from '@/headers/Ticket.js'
import { PayPeriodStatus, ContractMode, UserClaims } from '@/utils/Enumerations.js'
import { LocalStorageKeys } from '@/utils/LocalStorageActor'
import { ticketFiltersForExport } from '@/utils/TicketFiltering'
import { userAssignedClaim } from '../../../utils/ClaimUtility'

const PERIOD_EXPORT_LABEL = 'Period Export'

export default {
  name: 'ExportBatchDialog',

  components: {
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue'),
    ExportBatchSelectionTable: () => import('@/components/settlements/export-batch-form/ExportBatchSelectionTable.vue'),
    SettlementCorrections: () => import('@/components/settlements/accounts-payable-module/other-modules/SettlementCorrections.vue'),
    Loading: () => import('@/components/core/Loading.vue'),
    ConfirmDialog: () => import('@/components/helper/ConfirmDialog.vue'),
    Icon: () => import('@/components/helper/Icon.vue')
  },

  data: () => ({
    ticketFiltersForExport,
    UserClaims,
    openPanel: undefined,
    loader: ResourceLoader.empty,
    tickets: [],
    transferTickets: [],
    logYardSaleTickets: [],
    byproductTickets: [],
    tractPayments: [],
    accountPayments: [],
    advances: [],
    corrections: [],
    byproductCorrections: [],
    exportAllFinancials: false,
    confirmExportBatchDialog: false,
    label: '',
    rules: fieldRules.rules,
    selectedTickets: [],
    selectedTractPayments: [],
    selectedAccountPayments: [],
    selectedByproductTickets: [],
    selectedTransferTickets: [],
    selectedLogYardSaleTickets: [],
    selectedAdvances: [],
    selectedCorrections: [],
    selectedByproductCorrections: [],
    deleteAccountPayableDialog: false,
    focusedAccountPayable: undefined
  }),

  async mounted () {
    this.loader = new ResourceLoader(async () => {
      await this.refreshData()
      this.$refs.exportBatchLabelTextfield.focus()
    })
    await this.loader.load()
  },

  computed: {
    ...mapGetters('settlements', ['dateConfiguration']),

    headers () {
      return {
        tractPaymentHeaders: settlementTractPaymentHeaders(),
        advanceHeaders: settlementAdvanceHeaders(this.$i18n.locale),
        ticketHeaders: TicketHeaders.ticketUnpostingHeaders(this.$i18n.locale),
        byproductTicketHeaders: TicketHeaders.ticketByproductUnpostingHeaders(this.$i18n.locale),
        correctionHeaders: ticketCorrectionHeaders(),
        accountPaymentHeaders: settlementAccountPaymentHeaders()
      }
    },

    logsContractMode () {
      return ContractMode.Logs
    },

    byproductContractMode () {
      return ContractMode.Byproducts
    },

    hasSelectedFinancials () {
      const anythingSelected = this.selectedTickets.length > 0 ||
        this.selectedByproductTickets.length > 0 ||
        this.selectedTractPayments.length > 0 ||
        this.selectedAdvances.length > 0 ||
        this.selectedCorrections.length > 0 ||
        this.selectedByproductCorrections.length > 0 ||
        this.selectedTransferTickets.length > 0 ||
        this.selectedLogYardSaleTickets.length > 0 ||
        this.selectedAccountPayments.length > 0

      return (this.exportAllFinancials && !this.noDataTextConfiguration.showNoDataText) || anythingSelected
    },

    labelRules () {
      return [this.rules.required, this.rules.doesNotInclude([PERIOD_EXPORT_LABEL], 'periodExportNameError')]
    },

    isValidName () {
      return this.label.trim().length > 0 && this.label.trim() !== PERIOD_EXPORT_LABEL
    },

    noDataTextConfiguration () {
      const hasAnyFinancials = this.tickets.length > 0 ||
        this.byproductTickets.length > 0 ||
        this.tractPayments.length > 0 ||
        this.advances.length > 0 ||
        this.corrections.length > 0 ||
        this.byproductCorrections.length > 0 ||
        this.transferTickets.length > 0 ||
        this.logYardSaleTickets.length > 0 ||
        this.accountPayments.length > 0

      const noDataText = this.dateConfiguration.payPeriod.status === PayPeriodStatus.Exported.value
        ? this.$t('payPeriodAlreadyExported')
        : this.$t('noFinancialsAvailableToExport')
      return {
        showNoDataText: hasAnyFinancials === false,
        noDataText: noDataText
      }
    },

    totalCorrections () {
      return this.corrections.length + this.byproductCorrections.length
    },

    totalMiscPayments () {
      return this.tractPayments.length + this.accountPayments.length + this.advances.length
    },

    miscPaymentsTitleString () {
      const selected = this.selectedTractPayments.length + this.selectedAccountPayments.length + this.selectedAdvances.length
      const total = this.totalMiscPayments
      return this.$t('xySelected', { x: selected, y: total })
    },

    ticketCorrectionsTitleString () {
      const selected = this.selectedCorrections.length + this.selectedByproductCorrections.length
      const total = this.totalCorrections
      return this.$t('xySelected', { x: selected, y: total })
    }
  },

  methods: {
    userAssignedClaim,
    ...mapActions('export-batch', ['getItemsForExporting', 'createExportBatch']),
    ...mapActions('pay-period', ['exportPeriod']),
    ...mapActions('account', ['deleteAccountPayable']),
    ...mapActions('settlements', ['clearStaleSubModuleData', 'refreshModuleData']),
    async refreshData () {
      const {
        tickets,
        tractPayments,
        advances,
        byproductTickets,
        transferTickets,
        logYardSaleTickets,
        corrections,
        byproductCorrections,
        accountPayments
      } = await this.getItemsForExporting({
        payPeriodId: this.dateConfiguration.payPeriod.payPeriodId,
        fiscalYearId: this.dateConfiguration.fiscalYear.fiscalYearId
      })

      this.tickets = tickets
      this.byproductTickets = byproductTickets
      this.transferTickets = transferTickets
      this.logYardSaleTickets = logYardSaleTickets
      this.tractPayments = tractPayments
      this.advances = advances
      this.corrections = corrections
      this.byproductCorrections = byproductCorrections
      this.accountPayments = accountPayments
    },

    getTitleString (selected, allItems) {
      return this.$t('xySelected', { x: selected.length, y: allItems.length })
    },

    openExportBatchDialog () {
      if (this.label.trim().length === 0) {
        this.setSnackError(this.$t('exportBatchLabelRequired'))
        this.$refs.exportBatchLabelTextfield.focus()
        return
      }

      this.confirmExportBatchDialog = true
    },

    async save () {
      if (this.hasSelectedFinancials === false) {
        this.setSnackError(this.$t('noFinancialItemsSelectedForExportBatch'))
        return
      }

      if (this.isValidName === false) {
        this.setSnackError(this.$t('periodExportNameError'))
        this.$refs.exportBatchLabelTextfield.focus()
        return
      }

      const exportBatchCreateRequest = this.exportAllFinancials === true
        ? {
          label: this.label,
          ticketIds: [
            ...this.tickets.map(t => t.ticketId),
            ...this.byproductTickets.map(t => t.ticketId),
            ...this.transferTickets.map(t => t.ticketId),
            ...this.logYardSaleTickets.map(t => t.ticketId)
          ],
          tractPaymentIds: this.tractPayments.map(t => t.tractPayableId),
          advanceIds: this.advances.map(t => t.advanceId),
          ticketCorrectionIds: [
            ...this.corrections.map(t => t.ticketCorrectionId),
            ...this.byproductCorrections.map(t => t.ticketCorrectionId)
          ],
          accountPaymentIds: this.accountPayments.map(ap => ap.accountPayableId)
        }
        : {
          label: this.label,
          ticketIds: [
            ...this.selectedTickets.map(t => t.ticketId),
            ...this.selectedByproductTickets.map(t => t.ticketId),
            ...this.selectedTransferTickets.map(t => t.ticketId),
            ...this.selectedLogYardSaleTickets.map(t => t.ticketId)
          ],
          tractPaymentIds: this.selectedTractPayments.map(t => t.tractPayableId),
          advanceIds: this.selectedAdvances.map(t => t.advanceId),
          ticketCorrectionIds: [
            ...this.selectedCorrections.map(t => t.ticketCorrectionId),
            ...this.selectedByproductCorrections.map(t => t.ticketCorrectionId)
          ],
          accountPaymentIds: this.selectedAccountPayments.map(ap => ap.accountPayableId)
        }

      await this.createExportBatch({ exportBatch: exportBatchCreateRequest, payPeriodId: this.dateConfiguration.payPeriod.payPeriodId })
      this.$emit('close', true)
    },

    getFilterTypeFromStorage (ticketType) {
      const localStorageKey = `${ticketType.toUpperCase()}_EXPORT_FILTER_BY`
      return localStorage.getItem(LocalStorageKeys[localStorageKey])
    },

    storeFilterType (val, ticketType) {
      const localStorageKey = `${ticketType.toUpperCase()}_EXPORT_FILTER_BY`
      localStorage.setItem(LocalStorageKeys[localStorageKey], val)
    },

    openDeleteAccountPayableConfirmation (item) {
      this.focusedAccountPayable = item
      this.deleteAccountPayableDialog = true
    },

    closeDialogs () {
      this.focusedAccountPayable = undefined
      this.deleteAccountPayableDialog = false
      this.confirmExportBatchDialog = false
    },

    async confirmDeleteAccountPayable () {
      await this.deleteAccountPayable({
        accountId: this.focusedAccountPayable.accountId,
        accountPayableId: this.focusedAccountPayable.accountPayableId
      })
      this.closeDialogs()
      await this.refreshData()
      await this.refreshModuleData(false)
      this.clearStaleSubModuleData({ isByproduct: false })
    }
  }
}
</script>
