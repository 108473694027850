import { SettlementSubKeys } from './SettlementKeys'

export const EbArchivePathSegments = {
  AP: {
    LogsSettlementsByPayee: 'logs-settlements-by-payee',
    ByproductSettlementsByPayee: 'bp-settlements-by-payee',
    LogsPaymentRegister: 'logs-payment-register',
    ByproductPaymentRegister: 'bp-payment-register',
    LogsProductionSummary: 'logs-production-summary'
  },
  AR: {
    LogsReceivablesRegister: 'logs-receivables-register',
    ByproductReceivablesRegister: 'bp-receivables-register',
    ByproductReceivableSummary: 'bp-receivable-summary'
  },
  AC: {
    LogsAccrualRegister: 'logs-accrual-register',
    ByproductAccrualRegister: 'bp-accrual-register'
  }
}

export const EbArchiveSegmentBySubmoduleKey = (key) => {
  switch (key) {
    case SettlementSubKeys.AP.SettlementsByContract:
    case SettlementSubKeys.AP.SettlementsByPayee:
      return EbArchivePathSegments.AP.LogsSettlementsByPayee
    case SettlementSubKeys.AP.PaymentRegister:
      return EbArchivePathSegments.AP.LogsPaymentRegister
    case SettlementSubKeys.AP.ProductionSummary:
      return EbArchivePathSegments.AP.LogsProductionSummary
    case SettlementSubKeys.AP.ByproductSettlementsByPayee:
      return EbArchivePathSegments.AP.ByproductSettlementsByPayee
    case SettlementSubKeys.AP.BPPaymentRegister:
      return EbArchivePathSegments.AP.ByproductPaymentRegister
    case SettlementSubKeys.AR.ReceivableRegister:
      return EbArchivePathSegments.AR.LogsReceivablesRegister
    case SettlementSubKeys.AR.BPReceivableRegister:
      return EbArchivePathSegments.AR.ByproductReceivablesRegister
    case SettlementSubKeys.AR.BPReceivableSummary:
      return EbArchivePathSegments.AR.ByproductReceivableSummary
    case SettlementSubKeys.AC.Register:
      return EbArchivePathSegments.AC.LogsAccrualRegister
    case SettlementSubKeys.AC.BPRegister:
      return EbArchivePathSegments.AC.ByproductAccrualRegister
    default:
      console.warn(`Could not find path segment for settlement key "${key}"`)
      return undefined
  }
}
