<template>
  <v-container fluid id="accounts-payable-byproducts">
    <v-tabs
    v-model="currentSubModuleTab">
      <v-tab v-for="subModuleName in subModuleNames" :key="subModuleName" :id="kebabCase(subModuleName).concat('-tab')">
        <span>
          {{subModuleName}}
        </span>
      </v-tab>
      <v-spacer/>
      <v-tab v-for="subModuleName in additionalSubmodules" :key="subModuleName" :id="kebabCase(subModuleName).concat('-tab')">
        <span>
          {{subModuleName}}
        </span>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="currentSubModuleTab" v-if="!loading && currentSubModule !== undefined && !showNoDataText" class="mt-2" touchless>
      <v-tab-item>
        <SettlementsByPayee
        v-if="currentSubModuleTab === 0"
        :settlementsObject="currentSubModule"
        isByproduct/>
      </v-tab-item>
      <v-tab-item>
        <PaymentRegister
        v-if="currentSubModuleTab === 1"
        isByproduct
        :paymentRegisterObject="currentSubModule"/>
      </v-tab-item>
      <v-tab-item>
        <SettlementCorrections
        v-if="currentSubModuleTab === 2"
        :corrections="currentSubModule"
        :contractMode="contractMode"/>
      </v-tab-item>
    </v-tabs-items>
    <v-row class="my-12 pa-12" v-if="loading" justify="center" align="center">
      <v-progress-circular indeterminate color="primary"/>
    </v-row>
    <v-row class="mt-4" v-if="showNoDataText" justify="center" align="center">
      <v-col cols="12">
        <NoData>
          {{ $t('noDataForSelection') }}
        </NoData>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { ContractMode } from '@/utils/Enumerations'
import { SettlementKeys, SettlementSubKeys, SubKeyToTabMap } from '@/components/settlements/SettlementKeys.js'
import { kebabCase } from 'lodash'

export default {
  name: 'BPAccountsPayableModule',

  components: {
    PaymentRegister: () => import('@/components/settlements/accounts-payable-module/payment-register/PaymentRegister.vue'),
    SettlementsByPayee: () => import('@/components/settlements/accounts-payable-module/settlements-by-payee/SettlementsByPayee.vue'),
    SettlementCorrections: () => import('@/components/settlements/accounts-payable-module/other-modules/SettlementCorrections.vue'),
    NoData: () => import('@/components/core/NoData.vue')
  },

  data: () => ({
    currentSubModuleTab: 0
  }),

  watch: {
    currentSubModuleTab (tab) {
      const subModuleKey = SubKeyToTabMap.AP.Byproduct.find(kv => kv.Tab === tab)?.Key ?? SettlementSubKeys.AP.ByproductSettlementsByPayee
      this.setCurrentModule({
        moduleKey: SettlementKeys.AccountsPayable,
        subModuleKey
      })
    }
  },

  computed: {
    ...mapGetters('settlements', ['payableModule', 'currentSubModuleKey', 'loading']),

    contractMode () {
      return ContractMode.Byproducts
    },

    subModuleNames () {
      return [this.$t('byPayee'), this.$t('paymentRegister')]
    },

    additionalSubmodules () {
      return [this.$t('corrections')]
    },

    currentSubModule () {
      return this.payableModule[this.currentSubModuleKey]
    },

    showNoDataText () {
      if (this.currentSubModule === undefined || this.loading) {
        return false
      }
      switch (this.currentSubModuleKey) {
        case SettlementSubKeys.AP.BPPaymentRegister:
          return this.currentSubModule.payments?.length === 0
        case SettlementSubKeys.AP.ByproductSettlementsByPayee:
          return this.currentSubModule.payees?.length === 0
        case SettlementSubKeys.AP.ByproductCorrections:
          return this.currentSubModule?.length === 0
      }
      return true
    }
  },

  created () {
    this.currentSubModuleTab = SubKeyToTabMap.AP.Byproduct.find(kv => kv.Key === this.currentSubModuleKey)?.Tab ?? 0
  },

  methods: {
    kebabCase,
    ...mapActions('settlements', ['setCurrentModule'])
  }
}
</script>
