<template>
  <v-data-table
    :items="propAdvances"
    :headers="headers"
    :dense="dense"
    :search="search"
  >
    <template #item.effectiveDate="{item}">
      {{ utcToLocalDate(item.effectiveDate) }}
    </template>
    <template #item.recoveryStartDate="{item}">
        {{ utcToLocalDate(item.recoveryStartDate)}}
    </template>
    <template #item.status="{item}">
      <Icon
      v-if="item.preIssued"
      icon="mdi-invoice-text-check"
      iconColor="black"
      :tooltipText="$t('preIssued')"
      />
      <Icon
      v-if="!!item.exportBatchId"
      icon="mdi-checkbox-marked-circle"
      iconColor="success"
      :tooltipText="$t('exported')"
      />
      <Icon
      v-if="item.recoveryStrategy === RecoveryStrategy.RecurringPayment.value"
      icon="mdi-sync"
      iconColor="black"
      :tooltipText="$t('recoversViaRecurringAccountPayment')"/>
    </template>
    <template #item.amount="{item}">
      <span>{{ formatMoney(item.unmodifiedAmount ? item.unmodifiedAmount : item.amount) }} </span>
      <Icon
        v-if="item.hasModifications > 0 && showModDialog"
        icon="mdi-dots-horizontal-circle-outline"
        iconColor="black"
        dataTestId="advance-mods-button"
        :tooltipText="$t('viewModifications')"
        @icon-clicked="viewAdvanceModifications(item)"
      />
    </template>
    <template #item.balance="{item}">
      <span>{{ formatMoney(item.balance) }}</span>
    </template>
    <template #item.recovery="{item}">
      <span>{{ getRecoveryColumn(item) }}</span>
    </template>
    <template #item.glCode='{item}'>
      {{getGLCodeText(item)}}
    </template>
    <template #item.tract="{item}">
      {{ item.tract ?? $t('notAvailable') }}
    </template>
    <template #item.actions={item}>
      <Icon
      iconColor="black"
      icon="mdi-filter-variant"
      dataTestId="advance-recoveries-button"
      :tooltipText="$t('recoveries')"
      @icon-clicked="$emit('viewRecoveries', item)" tooltipColor="black"
      elementId="advance-table-action-recoveries"
      />
      <Icon
      iconColor="success"
      icon="mdi-pencil"
      :disabled="editButtonDisabled || !userAssignedClaim(UserClaims.ContractManager)"
      dataTestId="advance-edit-button"
      :tooltipText="$t('edit')"
      @icon-clicked="$emit('edit', item)"
      elementId="advance-table-action-edit"
      />
      <Icon
      iconColor="error"
      icon="mdi-delete-forever"
      dataTestId="advance-delete-button"
      :tooltipText="$t('delete')"
      @icon-clicked="$emit('delete', item)" tooltipColor="error"
      :disabled="!userAssignedClaim(UserClaims.ContractManager)"
      elementId="advance-table-action-delete"
      />
    </template>
  </v-data-table>
</template>

<script>
import { formatMoney } from '@/utils/NumericMutations.js'
import AdvanceHeaders from '@/headers/Advances.js'
import { utcToLocalDate } from '@/utils/DateFormatter.js'
import { mapActions, mapMutations } from 'vuex'
import moment from 'moment'
import { UserClaims, RecoveryStrategy } from '@/utils/Enumerations.js'
import { userAssignedClaim } from '@/utils/ClaimUtility.js'

export default {
  name: 'AdvanceTable',

  props: {
    search: {
      type: String,
      default: ''
    },
    accountColumn: {
      type: Boolean,
      default: false
    },
    propAdvances: {
      type: Array
    },
    dense: {
      type: Boolean,
      default: false
    },
    showModDialog: {
      type: Boolean,
      default: true
    },
    asAtDate: {
      type: String,
      default: undefined
    }
  },

  components: {
    Icon: () => import('../../helper/Icon.vue')
  },

  data: () => ({
    UserClaims,
    RecoveryStrategy
  }),

  computed: {
    headers () {
      const includeStatus = !this.propAdvances || this.propAdvances.some(a => a.preIssued || !!a.exportBatchId || a.recoveryStrategy === RecoveryStrategy.RecurringPayment.value)
      return AdvanceHeaders.advanceHeaders({ includeAccount: this.accountColumn, includeStatus })
    },

    editButtonDisabled () {
      if (!this.asAtDate) return false

      return !moment().isSame(moment(this.asAtDate), 'day')
    }
  },

  methods: {
    ...mapMutations('advances', ['setAdvanceModificationsDialog']),
    ...mapActions('advances', ['fetchModificationsForAdvance']),
    utcToLocalDate,
    formatMoney,
    userAssignedClaim,
    getRecoveryColumn (item) {
      const { recoveryRate, recoveryType } = item

      const rate = recoveryType === 1 ? recoveryRate + '%' : this.formatMoney(recoveryRate)

      return rate
    },

    getGLCodeText (advance) {
      let glString = advance.glCode
      if (advance.glOffset) {
        glString += `(${advance.glOffset})`
      }

      return glString
    },

    async viewAdvanceModifications (advance) {
      const modifications = await this.fetchModificationsForAdvance(advance)
      this.setAdvanceModificationsDialog({ modifications: modifications, status: true })
    }
  }
}
</script>
