import ApiServer from '@/utils/api/ApiServer.js'
import { uuidv4 } from '@/utils/NumericMutations.js'

export default {
  async fetchPayPeriods (context, fiscalYearId) {
    try {
      context.commit('setLoading', true)
      const path = `fiscalYears/${fiscalYearId}/payPeriods`
      const response = await ApiServer.get(path)
      context.commit('setPayPeriods', response.data)
      return response.data
    } finally {
      context.commit('setLoading', false)
    }
  },

  async fetchPayPeriodsNoMutation (_, fiscalYearId) {
    const path = `fiscalYears/${fiscalYearId}/payPeriods`
    const response = await ApiServer.get(path)
    return response.data
  },

  async exportPeriod (_, { payPeriod }) {
    const path = `v2/payperiods/${payPeriod.payPeriodId}/exportbatches`
    const response = await ApiServer.post(path, { periodExport: true, label: uuidv4() })
    return response.data
  },

  async createPayPeriod (context, payPeriod) {
    const path = `fiscalYears/${payPeriod.fiscalYearId}/payPeriods`
    const response = await ApiServer.post(path, payPeriod)
    return response.data
  },

  async updatePayPeriod (context, payPeriod) {
    const path = `fiscalYears/${payPeriod.fiscalYearId}/payPeriods/${payPeriod.payPeriodId}`
    const response = await ApiServer.put(path, payPeriod)
    return response.data
  },

  async getPayPeriodWithId (_, { fiscalYearId, payPeriodId }) {
    const path = ApiServer.urlFor(`fiscalYears/${fiscalYearId}/payPeriods/${payPeriodId}`)
    const response = await ApiServer.get(path)
    return response.data
  },

  async deletePayPeriod (context, payPeriod) {
    const path = `fiscalYears/${payPeriod.fiscalYearId}/payPeriods/${payPeriod.payPeriodId}`
    const response = await ApiServer.delete(path)
    return response.data
  }
}
