<template>
  <GridChartCard
    :title="title"
    ref="chartCard"
    :hasData="data.data.length > 0"
    :disableCsv="!showCsv"
    @draw="createChart"
    @download-csv="data.generateCSV(selectedDestName, csvOptions.includeSummary)"
    :tooltip="tooltip"
  >
    <template #actions>
      <Icon
      v-if="data.type === 'itemizedDefect'"
      iconColor="white"
      :tooltipColor="contractMode.details.color"
      margin="mr-3"
      :tooltipText="$t(mismanufactureOnly ? 'viewAllDefects' : 'viewOnlyMismanufactureDefects', {category: $t('defect')})"
      :small="false"
      @icon-clicked="mismanufactureOnly = !mismanufactureOnly"
      :disabled="data.dataMMO.length === 0"
      :icon="mismanufactureOnly ? 'mdi-pine-tree-box' : 'mdi-saw-blade'"
      elementId="toggle-mismanufacture-defects"/>

      <Icon
      v-if="isInteractive && getIcon && showSecondaryGrouping"
      iconColor="white"
      :tooltipColor="contractMode.details.color"
      :tooltipText="secondaryGroupingTooltip"
      :small="false"
      margin="mr-3"
      :disabled="data.data.length === 0 || data.dataSets.sets.length <= 1"
      @icon-clicked="secondaryGrouping = !secondaryGrouping"
      :icon="getIcon"
      elementId="toggle-secondary-grouping"/>

      <Icon
      v-if="showChartToggle"
      iconColor="white"
      :tooltipColor="contractMode.details.color"
      :tooltipText="getToolTipText"
      :small="false"
      margin="mr-3"
      :disabled="data.data.length === 0"
      @icon-clicked="viewingTons = !viewingTons"
      :icon="viewingTons ?  'mdi-pound' : 'mdi-weight'"
      elementId="toggle-tons-load-count"/>
    </template>
  </GridChartCard>
</template>

<script>
import { Chart } from 'chart.js'
import { mapActions, mapGetters } from 'vuex'
import { getChartColors, getChartColor } from './ChartColors'
import IconHelpers from '@/utils/IconHelpers'
import { ContractMode } from '@/utils/Enumerations'

const indent = '    '

export default {
  name: 'StackedBarChartCard',

  components: {
    GridChartCard: () => import('./GridChartCard.vue'),
    Icon: () => import('@/components/helper/Icon.vue')
  },

  data: () => ({
    IconHelpers,
    chart: null,
    viewingTons: false,
    currentData: {},
    secondaryGrouping: true,
    mismanufactureOnly: false
  }),

  props: {
    data: {
      type: Object,
      required: true
    },
    elementId: {
      type: String,
      required: true
    },
    selectedDestName: {
      type: String,
      required: false,
      defualt: ''
    },
    selectedDestLocationId: {
      type: Number,
      required: false,
      default: -1
    },
    contractMode: {
      type: Object,
      default: () => ContractMode.Logs
    },
    overrideTitle: {
      type: String,
      default: ''
    },
    csvOptions: {
      type: Object,
      default: () => ({
        includeSummary: false
      })
    },
    type: String,
    customTitle: {
      type: Function,
      required: false,
      default: undefined
    },
    tooltip: {
      type: String,
      required: false,
      default: undefined
    },
    loadStatus: {
      type: String,
      required: false,
      default: undefined
    },
    allowToggle: {
      type: Boolean,
      required: false,
      default: true
    },
    loadsOrTonsInTitle: {
      type: Boolean,
      required: false,
      default: true
    },
    allowSecondaryGrouping: {
      type: Boolean,
      default: true
    },
    isInteractive: {
      type: Boolean,
      default: false
    }
  },

  created () {
    this.secondaryGrouping = this.allowSecondaryGrouping
  },

  watch: {
    data: {
      handler (_) {
        this.scheduleDraw()
      },
      deep: true
    },

    viewingTons () {
      this.scheduleDraw()
    },

    secondaryGrouping () {
      this.scheduleDraw()
    },

    mismanufactureOnly () {
      this.scheduleDraw()
    }
  },

  computed: {
    ...mapGetters('dashboard', ['interactiveChartTooltipFooter']),
    title () {
      const unit = this.viewingTons ? this.$t('tons') : this.$t('loads')
      let title = (this.overrideTitle || this.customTitle?.call(null, unit)) ?? `${this.loadStatus ? this.loadStatus + ' ' : ''}${unit} by ${this.type}`
      const basedOn = this.viewingTons ? this.$t('weight') : this.$t('count')
      if (!this.loadsOrTonsInTitle) {
        title = title + ' ' + this.$t('percentageBy', { basedOn })
      }
      if (this.mismanufactureOnly) {
        title = this.$t('itemizedMismanufactureDefectSummary')
      }

      return (this.selectedDestName)
        ? `${title} (${this.selectedDestName})`
        : title
    },

    headerStyle () {
      return this.isByproduct ? 'background-color: #363636; color: white;' : 'background-color: #d15f27; color: white;'
    },

    showCsv () {
      return ['LoadsInTransitByDestination', 'TicketsReadyForPickup', 'ForecastedDaysRemaining'].every(t => this.type !== t)
    },

    showChartToggle () {
      return [
        'LoadsInTransitByDestination',
        'TicketsReadyForPickup',
        'CreatedLoadsByDestination',
        'ForecastedDaysRemaining'
      ].every(t => this.type !== t) && this.allowToggle
    },

    getToolTipText () {
      return this.viewingTons ? this.countUnitToggleToolTip : this.weightUnitToggleToolTip
    },

    weightUnitToggleToolTip () {
      return (this.type === 'ItemizedDefectSummaryChart') ? this.$t('percentageByTons') : this.$t('tons')
    },

    countUnitToggleToolTip () {
      return (this.type === 'ItemizedDefectSummaryChart') ? this.$t('percentageByQuantity') : this.$t('loadCount')
    },

    disabledCsv () {
      return !this.csvOptions
    },

    secondaryGroupingTooltip () {
      return this.$t(this.secondaryGrouping
        ? 'disableGroupingByCategory'
        : 'groupByCategory',
      {
        category: this.$t(this.data.data[0]?.loadCountAndTract
          ? 'tract' : this.data.miniBarType)
      })
    },

    getIcon () {
      if (this.data.miniBarType) {
        return IconHelpers.getIconForObjectType(this.data.miniBarType, this.secondaryGrouping)
      } else if (this.data.data[0]?.loadCountAndTract) {
        return IconHelpers.getIconForObjectType('tract', this.secondaryGrouping)
      } else {
        return undefined
      }
    },

    viewTicketsStyle () {
      return {
        position: 'absolute'
      }
    },

    showPercentInTicks () {
      return ['ItemizedDefectSummaryChart'].includes(this.type)
    },

    yAxisTitle () {
      switch (this.type) {
        case 'ItemizedDefectSummaryChart':
          return `% by ${this.viewingTons ? this.$t('weight') : this.$t('count')}`
        default:
          return undefined
      }
    },

    dataVersion () {
      return this.mismanufactureOnly ? 'dataMMO' : 'data'
    },

    dataSetsVersion () {
      return this.mismanufactureOnly ? 'dataSetsMMO' : 'dataSets'
    },

    isByproduct () { // TODO MK: Refactor this file when you refactor chart color function(s)
      return this.contractMode.value === ContractMode.Byproducts.value
    },

    showSecondaryGrouping () {
      return this.data.miniBarType !== 'destination' || this.selectedDestLocationId === -1
    }
  },

  methods: {
    ...mapActions('dashboard', ['getNestedCSV', 'getWidgetInteractionStatus']),

    scheduleDraw () {
      this.$nextTick(() => {
        if (this.$refs.chartCard) this.$refs.chartCard.draw()
      })
    },

    percentOfTotal (value, loads, tractIndex) {
      const loadArray = loads.map(product => (this.viewingTons) ? product.tonData[tractIndex] : product.loadData[tractIndex])
      const total = loadArray.reduce((a, b) => a + b, 0)
      return (value / total * 100).toFixed(1)
    },

    async onDataClicked (e) {
      if (this.isInteractive) {
        const element = this.chart.getElementsAtEventForMode(e, 'point', true)
        const data = this.chart.config._config.data
        const xAxisValue = data.labels[element[0]?.index]
        const yAxisValue = data.datasets[element[0]?.datasetIndex]?.label
        if (await this.getWidgetInteractionStatus({ widgetId: this.widgetId, x: xAxisValue, y: yAxisValue })) {
          this.$emit('data-clicked', {
            xAxisValue: xAxisValue,
            yAxisValue: yAxisValue,
            details: { mismanufactureOnly: this.mismanufactureOnly }
          })
        }
      }
    },

    createChart (canvas) {
      const percentOfTotal = this.percentOfTotal
      const dataSets = this.data[this.dataSetsVersion]
      const tickFormat = (value) => this.showPercentInTicks ? `${value}%` : value
      const yAxisTitle = {
        display: this.yAxisTitle,
        text: this.yAxisTitle
      }
      var chart = new Chart(canvas, {
        type: 'bar',
        data: {
          labels: dataSets.labels,
          datasets: this.secondaryGrouping && this.showSecondaryGrouping ? dataSets.sets.map((set, index) => {
            return {
              label: set.label,
              data: this.viewingTons ? set.tonData : set.loadData,
              backgroundColor: getChartColor(index, dataSets.sets.length, this.isByproduct ? ContractMode.Byproducts.value : ContractMode.Logs.value)
            }
          }) : [{
            data: this.type !== 'ItemizedDefectSummaryChart'
              ? this.data[this.dataVersion].map(d => this.viewingTons ? d.tons : d.loadCount ?? d.loadCountAndTract.reduce((acc, curr) => acc + curr.loadCount, 0))
              : this.data[this.dataSetsVersion].sets.map(ds => this.viewingTons ? ds.tonData[0] : ds.loadData[0]),
            backgroundColor: getChartColors(dataSets.sets.length, this.isByproduct ? ContractMode.Byproducts.value : ContractMode.Logs.value),
            labels: this.type === 'ItemizedDefectSummaryChart'
              ? this.data[this.dataSetsVersion].sets.map(s => s.label)
              : undefined,
            chartType: this.type
          }]
        },
        options: {
          maintainAspectRatio: false,
          responsive: true,
          resizeDelay: 500,
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              callbacks: {
                label: (this.type === 'ItemizedDefectSummaryChart') ? function (toolTipItem) {
                  const labelArray = toolTipItem.dataset.labels[toolTipItem.dataIndex]
                  const toolTipText = [`${labelArray[0]}`]
                  const weightPercentage = labelArray[1]
                  const quantityPercentage = labelArray[4]
                  toolTipText.push(`${indent}Percentage by Weight: ${weightPercentage}`)
                  toolTipText.push(`${indent}Percentage by Quantity: ${quantityPercentage}`)
                  const tons = labelArray[2]
                  toolTipText.push(`${indent}Tons: ${tons}`)
                  const count = labelArray[3]
                  toolTipText.push(`${indent}Quantity: ${count}`)
                  return toolTipText
                } : this.secondaryGrouping && this.showSecondaryGrouping ? function (toolTipItem) {
                  const value = toolTipItem.parsed.y
                  if (this.secondaryGrouping && this.showSecondaryGrouping === false) {
                    return `${value}`
                  } else {
                    const label = toolTipItem.dataset.label
                    const percent = percentOfTotal(value, dataSets.sets, toolTipItem.dataIndex)
                    return `${label}: ${value} (${percent}%)`
                  }
                } : function (toolTipItem) {
                  return `${toolTipItem.parsed.y}`
                },
                title: function (titleItem) {
                  const chartType = titleItem[0]?.dataset?.chartType ?? undefined
                  return chartType === 'ItemizedDefectSummaryChart' ? undefined : titleItem[0].label
                }
              },
              titleFont: {
                family: 'Roboto, sans-serif',
                size: '14',
                weight: 'bolder'
              },
              bodyFont: {
                family: 'Roboto, sans-serif'
              },
              footerColor: '#FFFFFFAA',
              footerFont: {
                family: 'Roboto, sans-serif',
                weight: 'normal',
                style: 'italic'
              }
            }
          },
          scales: {
            x: {
              stacked: true
            },
            y: {
              stacked: true,
              ticks: {
                precision: 0,
                callback: function (value) {
                  return tickFormat(value)
                }
              },
              title: {
                display: yAxisTitle.display,
                text: yAxisTitle.text
              }
            }
          },
          onClick: (e) => this.onDataClicked(e)
        }
      })
      if (this.isInteractive) chart.options.plugins.tooltip.callbacks.footer = () => this.interactiveChartTooltipFooter
      this.chart = chart
    }
  }
}
</script>
