<template>
  <v-container fluid id="accruals-logs">
    <v-tabs
    v-model="currentSubModuleTab">
      <v-tab>
        {{ $t('register') }}
      </v-tab>
      <v-spacer/>
      <v-tab>
        {{ $t('miscPayments') }}
      </v-tab>
      <v-tab v-if="isExportBatch">
        {{ $t('depletion') }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="currentSubModuleTab" v-if="!loading && currentSubModule !== undefined && !showNoDataText" class="mt-2" touchless>
      <v-tab-item>
        <Register
        v-if="currentSubModuleTab === 0"
        :registerObject="currentSubModule"
        :isByproduct="false"/>
      </v-tab-item>
      <v-tab-item>
        <span v-if="currentSubModuleTab === 1">
          <MiscSettlementPayments
          :miscPayments="{ accountPayments: currentSubModule }"
          :isExportBatch="isExportBatch"/>
        </span>
      </v-tab-item>
      <v-tab-item>
        <DepletionModule
        v-if="currentSubModuleTab === 2"
        :depletionObj="currentSubModule"/>
      </v-tab-item>
    </v-tabs-items>
    <v-row class="my-12 pa-12" v-if="loading" justify="center" align="center">
      <v-progress-circular indeterminate color="primary"/>
    </v-row>
    <v-row class="mt-4" v-if="showNoDataText" justify="center" align="center">
      <v-col cols="12">
        <NoData>
          {{ $t('noDataForSelection') }}
        </NoData>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { SettlementKeys, SettlementSubKeys, SubKeyToTabMap } from '@/components/settlements/SettlementKeys.js'

export default {
  name: 'AccrualsModule',

  components: {
    Register: () => import('@/components/settlements/accruals-module/register/Register.vue'),
    MiscSettlementPayments: () => import('@/components/settlements/accounts-payable-module/other-modules/MiscSettlementPayments.vue'),
    DepletionModule: () => import('@/components/settlements/accruals-module/DepletionModule.vue'),
    NoData: () => import('@/components/core/NoData.vue')
  },

  data: () => ({
    currentSubModuleTab: 0
  }),

  watch: {
    currentSubModuleTab (tab) {
      const moduleConfiguration = {
        moduleKey: SettlementKeys.Accruals,
        subModuleKey: SubKeyToTabMap.AC.Logs.find(kv => kv.Tab === tab)?.Key ?? SettlementSubKeys.AC.Register
      }

      this.setCurrentModule(moduleConfiguration)
    },

    'dateConfiguration.exportBatch.exportBatchId' (val) {
      if (val === undefined) this.currentSubModuleTab = 0
    }
  },

  computed: {
    ...mapGetters('settlements', ['accrualsModule', 'currentModuleKey', 'currentSubModuleKey', 'loading', 'dateConfiguration']),

    isExportBatch () {
      return this.dateConfiguration.exportBatch.exportBatchId !== undefined
    },

    currentSubModule () {
      return this.accrualsModule[this.currentSubModuleKey]
    },

    showNoDataText () {
      if (this.currentSubModule === undefined || this.loading) {
        return false
      }

      switch (this.currentSubModuleKey) {
        case SettlementSubKeys.AC.Register:
          return this.currentSubModule.journalEntries.length === 0
        case SettlementSubKeys.AC.MiscAccruals:
          return this.currentSubModule?.length === 0
        case SettlementSubKeys.AC.Depletion:
          return this.currentSubModule.tractBalanceSummaries?.length === 0
      }

      return true
    }
  },

  created () {
    this.submoduleTab = SubKeyToTabMap.AC.Logs.find(kv => kv.Key === this.currentSubModuleKey)?.Tab ?? 0
  },

  methods: {
    ...mapActions('settlements', ['setCurrentModule'])
  }
}
</script>
