<template>
  <v-row>
    <v-col>
      <v-row class="mt-1">
        <v-col cols="12">
          <v-container fluid>
            <v-expansion-panels popout multiple tile v-model="openPanels">
              <v-expansion-panel v-if="!noTicketsAccruals" value="ticketAccruals">
                <v-expansion-panel-header>
                  {{$t('ticketAccruals')}}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <span v-if="primaryTickets.length > 0">
                    <p class="subtitle-1 mt-3 mb-n2">{{$t('contractPayments')}}</p>
                    <TicketFinancialsTable
                    :entries="primaryTickets"
                    @aggregateSelected="contractPaymentSelected"/>
                  </span>
                  <span v-if="primaryCorrections.length > 0">
                    <p class="subtitle-1 mt-6 mb-n2">{{$t('corrections')}}</p>
                    <TicketFinancialsTable
                    :entries="primaryCorrections"
                    isCorrections
                    @aggregateSelected="correctionSelected"/>
                  </span>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel v-if="!isByproducts && transferAccruals" value="transferAccruals">
                <v-expansion-panel-header>
                  {{$t('transferAccruals')}}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <TicketFinancialsTable
                  v-if="tickets.transfers.accruals.length > 0"
                  :entries="tickets.transfers.accruals"
                  @aggregateSelected="transferAccrualSelected"/>

                  <span v-if="corrections.transfers.accruals.length > 0">
                    <p class="subtitle-1 mt-6 mb-n2">{{$t('corrections')}}</p>
                    <TicketFinancialsTable
                    :entries="corrections.transfers.accruals"
                    isCorrections
                    @aggregateSelected="correctionSelected"/>
                  </span>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel v-if="!isByproducts && lysAccruals" value="logYardSaleAccruals">
                <v-expansion-panel-header>
                  {{$t('logYardSaleAccruals')}}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <TicketFinancialsTable
                  v-if="tickets.lys.accruals.length > 0"
                  :entries="tickets.lys.accruals"
                  @aggregateSelected="logYardSaleAccrualSelected"/>

                  <span v-if="corrections.lys.accruals.length > 0">
                    <p class="subtitle-1 mt-6 mb-n2">{{$t('corrections')}}</p>
                    <TicketFinancialsTable
                    :entries="corrections.lys.accruals"
                    isCorrections
                    @aggregateSelected="correctionSelected"/>
                  </span>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel v-if="!isByproducts && miscPayments.accountPayments.accountPaymentsJE.length > 0" value="accountPayments">
                <v-expansion-panel-header>
                  {{ $t('accountPayments') }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <MiscPaymentsAggregation
                  :miscPayments="miscPayments.accountPayments.accountPaymentsJE"
                  :miscPaymentType="MiscPaymentType.AccountPayment"/>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-container>
        </v-col>
      </v-row>
      <NoData v-if="showNoData" class="mb-4">
        {{ $t('noDataForSelection') }}
      </NoData>
    </v-col>
    <v-dialog v-model="detailDialogOpen" width="900px">
      <TicketwiseFinancialBreakdown
      v-if="detailDialogOpen"
      :isCorrection="focusedContractPaymentIsCorrection"
      :payableSummary="focusedContractPayment"
      :includeRecoveries="!focusedAccrualIsTransfer"
      :isByproducts="isByproducts"
      @close="closeDialogs"/>
    </v-dialog>
  </v-row>
</template>

<script>
import { formatMoney } from '@/utils/NumericMutations'
import AdjustingEntriesHeaders from '@/headers/AdjustingEntries.js'
import { MiscPaymentType } from '../../../../utils/Enumerations'
export default {
  name: 'AccrualsAggregations',

  components: {
    TicketwiseFinancialBreakdown: () => import('@/components/accounting/adjusting-entries/v1/TicketwiseFinancialBreakdown.vue'),
    TicketFinancialsTable: () => import('@/components/accounting/adjusting-entries/v1/TicketFinancialsTable.vue'),
    MiscPaymentsAggregation: () => import('@/components/accounting/adjusting-entries/v1/MiscPaymentsAggregation.vue'),
    NoData: () => import('@/components/core/NoData.vue')
  },

  props: {
    tickets: {
      type: Object,
      required: true
    },
    corrections: {
      type: Object,
      required: true
    },
    miscPayments: {
      type: Object,
      required: true
    },
    isByproducts: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    detailDialogOpen: false,
    focusedContractPayment: undefined,
    focusedContractPaymentIsCorrection: false,
    focusedAccrualIsTransfer: false,
    openPanels: [],
    MiscPaymentType
  }),

  mounted () {
    if (this.isByproducts) {
      this.openPanels = !this.noTicketsAccruals ? [0] : []
    } else {
      if ((!this.noTicketsAccruals +
        (this.transferAccruals) +
        (this.lysAccruals) +
        (this.miscPayments.accountPayments.accountPaymentsJE.length)) === 1
      ) {
        this.openPanels = [0]
      }
    }
  },

  computed: {
    transferAccruals () {
      return this.tickets.transfers.accruals.length > 0 || this.corrections.transfers.accruals.length > 0
    },
    lysAccruals () {
      return this.tickets.lys.accruals.length > 0 || this.corrections.lys.accruals.length > 0
    },
    contractPaymentHeaders () {
      return AdjustingEntriesHeaders.ticketContractPayments()
    },
    correctionsHeaders () {
      return AdjustingEntriesHeaders.ticketContractPayments()
    },
    paymentsEntries () {
      return Array.from(this.accruals?.contractPayments?.values() ?? [])
    },
    primaryTickets () {
      return this.isByproducts ? this.tickets.byproducts.accruals : this.tickets.logs.accruals
    },
    primaryCorrections () {
      return this.isByproducts ? this.corrections.byproducts.accruals : this.corrections.logs.accruals
    },
    noTicketsAccruals () {
      return this.primaryTickets.length === 0 && this.primaryCorrections.length === 0
    },
    showNoData () {
      const otherLogsFinancials = this.tickets.lys.accruals.length > 0 ||
        this.tickets.transfers.accruals.length > 0 ||
        this.corrections.transfers.accruals.length > 0 ||
        this.corrections.lys.accruals.length > 0

      return this.noTicketsAccruals && (this.isByproducts || !otherLogsFinancials)
    },
    contractColor () {
      return this.isByproducts ? 'byproduct' : 'primary'
    },
    borderStyle () {
      return `border: 3px solid ${this.isByproducts ? '#363636' : '#D15F27'}`
    }
  },

  methods: {
    formatMoney,

    contractPaymentSelected (contractPayment) {
      this.detailDialogOpen = true
      this.focusedContractPayment = contractPayment
    },

    correctionSelected (correction) {
      this.focusedContractPayment = correction
      this.focusedContractPaymentIsCorrection = true
      this.detailDialogOpen = true
    },

    transferAccrualSelected (transferAccrual) {
      this.focusedContractPayment = transferAccrual
      this.focusedAccrualIsTransfer = true
      this.detailDialogOpen = true
    },

    logYardSaleAccrualSelected (logYardSaleAccrual) {
      this.focusedContractPayment = logYardSaleAccrual
      this.focusedAccrualIsLogYardSale = true
      this.detailDialogOpen = true
    },

    closeDialogs () {
      this.detailDialogOpen = false
      this.focusedContractPayment = undefined
      this.focusedContractPaymentIsCorrection = false
      this.focusedAccrualIsTransfer = false
      this.focusedAccrualIsLogYardSale = false
    }
  }
}
</script>
