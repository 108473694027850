<template>
  <v-expansion-panels popout v-model="openPanels" tile>
    <v-expansion-panel
      v-for="(journalEntryByBusinessEntity) in journalEntriesByAccount"
      :key="journalEntryByBusinessEntity.businessEntityName"
    >
      <v-expansion-panel-header>
        {{ journalEntryByBusinessEntity.businessEntityName }}
        <v-spacer/>
        <template #actions>
          {{ formatMoney(journalEntryByBusinessEntity.amount) }}
          <v-icon
            class="ml-2"
          >
            mdi-chevron-down
          </v-icon
          >
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-data-table
          v-if="journalEntryByBusinessEntity.journalEntries.length > 0"
          :headers="headers"
          :items.sync="journalEntryByBusinessEntity.journalEntries"
          :hide-default-footer="journalEntryByBusinessEntity.journalEntries.length < 10"
        >
          <template #item.activityName="{ item }">
            <Icon icon="weight-alert" :tooltipText="$t('penalty')" :small="false" v-if="item.specialization === TemplateSpecialization.Penalty.value"/>
            {{item.activityName}}
          </template>
          <template v-slot:item.amount="{ item }">{{
            `${formatMoney(item.amount)}`
          }}</template>
          <template v-slot:item.rate="{ item }">
            <span>{{ formatMoney(item.computedAtRate) }}</span>
          </template>
          <template #item.payOn="{item}">{{
            payOn(item.computedAtPayOn)
          }}</template>
          <template #item.payBy="{item}">{{
            payBy(item.computedAtPayBy)
          }}</template>
          <template v-slot:item.createdAt="{ item }">{{
            utcToLocalDate(item.ticketCreatedAt)
          }}</template>
          <template v-slot:item.actions="{ item }">
            <Icon
              iconColor="black"
              icon="mdi-information-outline"
              dataTestId="entry-detail-button"
              :tooltipText="$t('details')"
              :disabled="item.recoveryMethod === 1"
              @icon-clicked="viewJournalEntry(item)"
            >
            </Icon>
          </template>
        </v-data-table>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { formatMoney } from '@/utils/NumericMutations.js'
import { utcToLocalDate } from '@/utils/DateFormatter.js'
import { ticketJournalEntryHeaders } from '@/headers/JournalEntry.js'
import { PayOn, PayBy, TemplateSpecialization } from '@/utils/Enumerations.js'
import { colorClassForContractType } from '@/utils/componentHelpers.js'
export default {
  name: 'JournalEntryExpansionPanels',

  props: {
    journalEntries: {
      type: Array,
      required: true
    },
    ticket: {
      type: Object,
      required: false
    }
  },

  components: {
    Icon: () => import('@/components/helper/Icon.vue')
  },

  data: () => ({
    TemplateSpecialization,
    openPanels: []
  }),

  created () {
    this.openPanels = this.journalEntriesByAccount.length === 1 ? 0 : null
  },

  computed: {
    headers () {
      const headers = ticketJournalEntryHeaders(this.$i18n.locale)
      headers.pop()
      return headers
    },

    journalEntriesByAccount () {
      const journalEntriesByAccount = []
      this.journalEntries.forEach(p => {
        const entityIndex = journalEntriesByAccount.findIndex(pbv => pbv.businessEntityName === p.businessEntityName)
        if (entityIndex === -1) {
          journalEntriesByAccount.push({
            businessEntityName: p.businessEntityName,
            amount: p.amount,
            journalEntries: [p]
          })
        } else {
          journalEntriesByAccount[entityIndex].journalEntries.push(p)
          journalEntriesByAccount[entityIndex].amount += p.amount
        }
      })

      return journalEntriesByAccount
    },

    contractTypeColor () {
      return colorClassForContractType(this.ticket?.contractType)
    }
  },

  methods: {
    formatMoney,
    utcToLocalDate,
    payOn: (x) => PayOn.fromInt(x),
    payBy: (x) => PayBy.fromInt(x)
  }
}
</script>
