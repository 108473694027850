<template>
  <v-row>
    <v-col cols="12">
      <v-tabs class="card-tabs" v-model="tab" touchless>
        <v-spacer/>
        <v-tab>
          {{ $t('primaryAddress') }}
        </v-tab>
        <v-tab>
          {{ $t('mainContact') }}
        </v-tab>
        <v-tab :disabled="checkForBlankContact(mainContact) === undefined">
          {{ $t('mobileAppContacts') }}
        </v-tab>
      </v-tabs>
      <v-card style="border-radius: 4px 0px 4px 4px" outlined>
        <v-window v-model="tab">
          <v-window-item>
            <LoadableComponent :loading="loading" :height="250">
              <v-col cols="12" md="6">
                <AddressCard
                :showEdit="!readonly"
                :showDelete="false"
                :address="mappedAddress"
                :minHeight="cardHeight"
                @edit-address-clicked="editAddress"/>
              </v-col>
            </LoadableComponent>
          </v-window-item>
          <v-window-item>
            <LoadableComponent :loading="loading" :height="250">
              <v-col cols="12" md="6">
                <ContactCard
                isMainContact
                :showCrud="!readonly"
                :contact="contactForCard(mainContact)"
                :addContact="checkForBlankContact(mainContact) === undefined"
                :minHeight="cardHeight"
                @click:edit="editMain = true; showDialog = true"
                @click:create="editMain = true; showDialog = true"/>
              </v-col>
            </LoadableComponent>
          </v-window-item>
          <v-window-item>
            <LoadableComponent :loading="loading" :height="250">
              <v-col cols="12">
                <v-row>
                  <v-col cols="12" md="6">
                    <Icon
                      v-if="checkForBlankContact(transporterContact) === undefined"
                      icon="mdi-plus"
                      :small="false"
                      :tooltipText="$t('addTransporterContact')"
                      @icon-clicked="createTransporter = true; showDialog = true"
                      :disabled="readonly"/>
                    <ContactCard
                    v-if="checkForBlankContact(transporterContact) !== undefined"
                    isMobileAppContactInfo
                    @click:edit="editTransporter = true; showDialog = true"
                    @click:delete="$emit('deleteTransporterContact')"
                    :contact="{...contactForCard(transporterContact), isTransporterContact: true}"
                    :showCrud="!readonly"
                    :minHeight="cardHeight"/>
                  </v-col>
                  <v-col cols="12" md="6">
                    <span>
                      <Icon
                      v-if="checkForBlankContact(loaderContact) === undefined"
                      icon="mdi-plus"
                      :small="false"
                      :tooltipText="$t('addLoaderContact')"
                      @icon-clicked="createLoader = true; showDialog = true"
                      :disabled="readonly"/>
                    </span>
                    <ContactCard
                    v-if="checkForBlankContact(loaderContact) !== undefined"
                    isMobileAppContactInfo
                    :contact="{...contactForCard(loaderContact), isLoaderContact: true}"
                    @click:delete="$emit('deleteLoaderContact')"
                    @click:edit="editLoader = true; showDialog = true"
                    :showCrud="!readonly"
                    :minHeight="cardHeight"/>
                  </v-col>
                </v-row>
              </v-col>
            </LoadableComponent>
          </v-window-item>
        </v-window>
      </v-card>
    </v-col>
    <v-dialog v-model="showDialog" max-width="50%" @input="closeDialog()">
      <ContactForm
      v-if="showDialog"
      isCompanyInfoContact
      :propContact="getPropContact()"
      @close="closeDialog()"
      @contact-updated="contactUpdated"
      @contact-created="contactCreated"/>
    </v-dialog>
    <v-dialog v-model="editAddressDialog" max-width="500" @input="closeDialog()">
      <AddressForm
      :propAddress="mappedAddress"
      @address-updated="addressUpdated"
      @close="closeDialog()"
      :propSubmitText="$t('done')"/>
    </v-dialog>
  </v-row>
</template>

<script>
const cardHeight = 150
export default {
  name: 'CompanyContactInfo',

  components: {
    ContactCard: () => import('../../account/contacts/ContactCard.vue'),
    Icon: () => import('../../../components/helper/Icon.vue'),
    ContactForm: () => import('../../account/contacts/ContactForm.vue'),
    AddressCard: () => import('@/components/account/address/AddressCard.vue'),
    AddressForm: () => import('@/components/account/address/AddressForm.vue'),
    LoadableComponent: () => import('@/components/helper/LoadableComponent.vue')
  },

  props: {
    mainContact: {
      type: Object,
      required: true
    },
    transporterContact: {
      type: Object,
      required: false
    },
    loaderContact: {
      type: Object,
      required: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    address: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },

  data: () => ({
    editMain: false,
    editTransporter: false,
    editLoader: false,
    createLoader: false,
    createTransporter: false,
    showDialog: false,
    tab: 0,
    editAddressDialog: false
  }),

  computed: {
    cardHeight: () => cardHeight,
    mappedAddress () {
      return { address: this.address }
    }
  },

  methods: {
    contactForCard (contact) {
      return {
        person: {
          firstName: contact?.firstName,
          lastName: contact?.lastName,
          position: 'Transporter Contact',
          email: contact?.email,
          phoneNumber: contact?.phoneNumber
        }
      }
    },

    closeDialog () {
      this.editMain = false
      this.editTransporter = false
      this.editLoader = false
      this.createLoader = false
      this.createTransporter = false
      this.showDialog = false
      this.editAddressDialog = false
    },

    getPropContact () {
      if (this.editMain) return this.checkForBlankContact(this.mainContact) === undefined ? undefined : this.contactForCard(this.mainContact)
      if (this.editTransporter) return this.contactForCard(this.transporterContact)
      if (this.editLoader) return this.contactForCard(this.loaderContact)
      return undefined
    },

    checkForBlankContact (contact) {
      if (contact?.email === '' &&
        contact?.firstName === '' &&
        contact?.lastName === '' &&
        contact?.phoneNumber === '') return undefined
      return contact
    },

    contactUpdated (contact) {
      if (this.editMain) this.$emit('mainEdited', contact.person)
      if (this.editTransporter) this.$emit('transporterSet', contact.person)
      if (this.editLoader) this.$emit('loaderSet', contact.person)
      this.closeDialog()
    },

    contactCreated (contact) {
      if (this.editMain) this.$emit('mainEdited', contact.person)
      if (this.createLoader) this.$emit('loaderSet', contact.person)
      if (this.createTransporter) this.$emit('transporterSet', contact.person)
      this.closeDialog()
    },

    editAddress () {
      this.editAddressDialog = true
    },

    addressUpdated (address) {
      this.$emit('addressUpdated', address.address)
      this.closeDialog()
    }
  }
}
</script>
