<template>
  <v-container>
    <v-row dense class="subtitle-1" style="font-weight: bold;">
      <v-col cols="7">
        {{ advance.note }}
      </v-col>
      <v-col cols="5" align="right">
        {{ formatMoney(advance.amount) }}
      </v-col>
    </v-row>
    <v-row dense class="mt-n3 subtitle-1">
      <v-col cols="4">
        {{ $t('balance') }}
      </v-col>
      <v-col cols="8" align="right">
        {{ formatMoney(advance.balance) }}
      </v-col>
    </v-row>
    <v-row dense class="mt-n3 subtitle-1">
      <v-col cols="4">
        {{ $t('recovers') }}
      </v-col>
      <v-col cols="8" align="right">
        {{ utcToLocalDate(advance.recoveryStartDate) }}
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { formatMoney } from '@/utils/NumericMutations.js'
import { utcToLocalDate } from '@/utils/DateFormatter.js'
export default {
  name: 'AdvanceAutocompleteCard',

  props: {
    advance: {
      type: Object,
      required: true
    }
  },

  methods: {
    formatMoney,
    utcToLocalDate
  }
}
</script>
