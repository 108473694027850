<template>
  <v-menu
    v-model="menuOpen"
    :close-on-content-click="false"
    right
  >
    <template #activator="{ on: menu, attrs }">
      <v-tooltip bottom :color="tooltipColor || iconColor">
        <template #activator="{ on: tooltip }">
          <v-icon
            v-on="{...tooltip, ...menu}"
            v-bind="attrs"
            :x-large="xLarge"
            :color="iconColor"
            data-testid="tract-filter-btn"
            class="mr-2"
          >
            mdi-filter
          </v-icon>
        </template>
        <span class="subtitle-1 white--text">
          {{$t('filter')}}
        </span>
      </v-tooltip>
    </template>
    <v-card width="600px">
      <v-card-title class="primary white--text">
        {{$t('tractFilter')}}
      </v-card-title>
      <v-card-text>
        <v-container fluid :style="this.containerStyle">
          <v-row dense>
            <v-col v-for="ts in tractStatuses" :key="`status-${ts.value}`" cols="6" sm="4" class="ma-0 pa-0">
              <v-checkbox :label="ts.name" v-model="filterBuilder.status" :value="ts.value"/>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="10" v-for="(f, i) in filters" :key="i">
              <LoadableComponent :loading="loading" type="input-dense">
                <v-select
                :items="f.items"
                :label="$t(f.id)"
                v-model="filterBuilder[f.builderKey]"
                :prepend-inner-icon="IconHelpers.getIconForObjectType(f.id)"
                :menu-props="{ offsetY: true }"
                dense
                multiple
                chips
                deletable-chips
                return-object
                item-text="name">
                </v-select>
              </LoadableComponent>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="pb-4 mt-n4">
        <v-row>
          <v-col>
            <v-btn color="primary" outlined @click="resetFilter">
              {{$t('reset')}}
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn
            color="primary"
            class="text--white"
            data-testid="tract-filter-apply-btn"
            :disabled="!changed"
            @click="emitFilter">
              {{$t('apply')}}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { TractStatus } from '@/utils/Enumerations.js'
import { LocalStorageKeys } from '@/utils/LocalStorageActor'
import { getMenuContainerStyle } from '../../utils/componentHelpers'
import IconHelpers from '@/utils/IconHelpers'

export default {
  name: 'TractFilter',

  components: {
    LoadableComponent: () => import('../helper/LoadableComponent.vue')
  },

  props: {
    filter: {
      type: Object,
      required: true
    },
    xLarge: {
      type: Boolean,
      required: false,
      default: false
    },
    iconColor: {
      type: String,
      required: false,
      default: 'primary'
    },
    tooltipColor: {
      type: String,
      required: false,
      default: undefined
    }
  },

  data: () => ({
    IconHelpers,
    menuOpen: false,
    allForesters: [],
    filterBuilder: {
      status: [TractStatus.Active.value],
      selectedTractTypes: [],
      selectedHarvestTypes: [],
      selectedLoggingMethods: [],
      selectedForesters: [],
      selectedTeams: [],
      myTracts: false
    },
    containerStyle: undefined,
    changed: false,
    dataFetched: false,
    loading: true
  }),

  watch: {
    filter: {
      handler (newFilter) {
        this.filterBuilder = JSON.parse(JSON.stringify(newFilter))
      },
      deep: true
    },

    menuOpen (isOpen) {
      if (isOpen) {
        this.changed = false
        this.loading = true
        this.refresh()
      } else {
        this.filterBuilder = JSON.parse(JSON.stringify(this.filter))
      }

      if (!this.dataFetched) {
        this.dataFetched = true
        this.initializeFilter()
      }
    },

    filterBuilder: {
      handler () {
        this.changed = true
      },
      deep: true
    }
  },

  computed: {
    ...mapGetters('tract-type', ['allTractTypes']),
    ...mapGetters('team', ['allTeams']),
    ...mapGetters('harvest-type', ['allHarvestTypes']),
    ...mapGetters('logging-method', ['allLoggingMethods']),

    filters () {
      return [
        {
          id: 'tractType',
          builderKey: 'selectedTractTypes',
          items: this.allTractTypes
        },
        {
          id: 'harvestType',
          builderKey: 'selectedHarvestTypes',
          items: this.allHarvestTypes
        },
        {
          id: 'loggingMethod',
          builderKey: 'selectedLoggingMethods',
          items: this.allLoggingMethods
        },
        {
          id: 'forester',
          builderKey: 'selectedForesters',
          items: this.allForesters
        },
        {
          id: 'team',
          builderKey: 'selectedTeams',
          items: this.allTeams
        }
      ].filter(f => f.items && f.items.length > 0)
    },

    tractStatuses () {
      return TractStatus.enums
    }
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.setContainerStyle)
  },

  methods: {
    ...mapActions('user', ['getAllForesterUsers']),
    ...mapActions('tract-type', ['fetchTractTypes']),
    ...mapActions('team', ['fetchTeams']),
    ...mapActions('harvest-type', ['fetchHarvestTypes']),
    ...mapActions('logging-method', ['fetchLoggingMethods']),

    async initializeFilter () {
      this.filterBuilder = JSON.parse(JSON.stringify(this.filter))
      window.addEventListener('resize', this.setContainerStyle)
    },

    async refresh () {
      const [foresterResponse] = await Promise.all([
        this.getAllForesterUsers(),
        this.fetchTractTypes(),
        this.fetchTeams(),
        this.fetchHarvestTypes(),
        this.fetchLoggingMethods()
      ])
      this.allForesters = foresterResponse
      this.loading = false
    },

    resetFilter () {
      this.filterBuilder = {
        status: [3],
        selectedTractTypes: [],
        selectedHarvestTypes: [],
        selectedLoggingMethods: [],
        selectedForesters: [],
        selectedTeams: [],
        myTracts: false
      }
      this.emitFilter()
    },

    emitFilter () {
      localStorage.setItem(LocalStorageKeys.TRACT_STATUS_FILTER, this.filterBuilder.status)
      this.$emit('filter-applied', this.filterBuilder)
      this.menuOpen = false
    },

    setContainerStyle () {
      return getMenuContainerStyle(window.innerWidth, window.innerHeight)
    }
  }
}
</script>
