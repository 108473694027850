<template>
  <v-row>
    <v-col cols="12">
      <v-data-table
      :items-per-page="-1"
      hide-default-footer
      :items="recoveries"
      :headers="aggregateHeaders">
        <template #item.businessEntity="{item}">
            <span id="table-shortcut" @click="recoverySelected(item)">{{ item.businessEntity }}</span>
        </template>
        <template #item.activity="{item}">
            <span id="table-shortcut" @click="recoverySelected(item)">{{ item.activity }}</span>
        </template>
        <template #item.glCode="{item}">
            <span id="table-shortcut" @click="recoverySelected(item)">{{ item.glCode }}</span>
        </template>
        <template #item.glOffset="{item}">
            <span id="table-shortcut" @click="recoverySelected(item)">{{ item.glOffset }}</span>
        </template>
        <template #item.recovered="{item}">
            <span id="table-shortcut" @click="recoverySelected(item)">{{ formatMoney(item.recovered) }}</span>
        </template>
      </v-data-table>
    </v-col>
    <v-dialog v-model="recoveriesDialogOpen" width="900px">
      <v-card v-if="recoveriesDialogOpen">
        <v-card-title class="headline py-4 primary white--text">
          <v-row>
            <v-col cols="10">
              <span style="overflow-wrap: anywhere; word-wrap: break-word; word-break: normal;">
                {{ $t('recoveriesByEntityAndActivity', {entity: focusedRecoveryAggregate.businessEntity, activity: focusedRecoveryAggregate.activity}) }}
              </span>
            </v-col>
            <v-spacer/>
            <v-col align="right">
              <Icon
              icon="mdi-close"
              icon-color="white"
              large
              :small="false"
              :tooltipText="$t('close')"
              @icon-clicked="closeDialog()"/>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-data-table
              :items="focusedRecoveryAggregate.advances"
              :headers="breakdownHeaders">
              <template #item.amount="{item}">
                {{ formatMoney(item.amount) }}
              </template>
              <template #item.advanceBalance="{item}">
                {{ formatMoney(item.advanceBalance) }}
              </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import AdjustingEntriesHeaders from '@/headers/AdjustingEntries.js'
import { formatMoney } from '@/utils/NumericMutations'
export default {
  name: 'RecoveriesAggregations',

  components: {
    Icon: () => import('@/components/helper/Icon.vue')
  },

  props: {
    recoveries: {
      type: Array,
      required: true
    }
  },

  data: () => ({
    recoveriesDialogOpen: false,
    focusedRecoveryAggregate: undefined
  }),

  computed: {
    aggregateHeaders () {
      return AdjustingEntriesHeaders.recoveriesAggregate()
    },

    breakdownHeaders () {
      return AdjustingEntriesHeaders.recoveriesBreakdown()
    }
  },

  methods: {
    formatMoney,
    recoverySelected (recoveryAggregate) {
      this.focusedRecoveryAggregate = recoveryAggregate
      this.recoveriesDialogOpen = true
    },

    closeDialog () {
      this.recoveriesDialogOpen = false
      this.focusedRecoveryAggregate = undefined
    }
  }
}
</script>
