export default {
  payables: [],
  unpaidAdvances: [],
  tractPayables: [],
  accountPayablesAP: [],
  entities: {},
  loading: 'not loading',
  bonds: [],
  bondsLoading: false
}
