<template>
  <v-row class="mx-1" align="center">
    <v-col>
      <LoadableComponent :loading="snapshotListLoading && !initialized" :width="260">
        <v-select
        v-if="snapshots.length > 0"
        v-model="selectedSnapshotRecord"
        :label="$t('snapshotDate')"
        :items="snapshots"
        :loading="snapshotListLoading"
        @change="snapshotSelected(selectedSnapshotRecord)"
        item-text="uploadedAt"
        style="width: 260px;"
        return-object>
          <template #selection="{item}">
            {{ formatDate(item.createdAt) }}
            <Icon
            v-if="recordIsUnUploaded(item)"
            icon="mdi-alert"
            margin="ml-1"
            :tooltipText="$t('snapshotploadInProgress')"/>
          </template>
          <template #item="{item}">
            <span class="mr-2">
              {{ formatDate(item.createdAt) }}
            </span>
            <Icon
            v-if="recordIsUnUploaded(item)"
            icon="mdi-alert"
            margin="ml-1"
            :tooltipText="$t('snapshotploadInProgress')"/>
          </template>
          <template #append-outer>
            <Icon
            @icon-clicked="refreshSnapshotList"
            icon="mdi-refresh"
            :small="false"
            :tooltipText="$t('refreshSnapshotList')"
            />
          </template>
        </v-select>
      </LoadableComponent>
    </v-col>
    <v-col cols="auto" align="right" v-if="!isOnDemand">
      <span class="title">{{ nextSnapshotAvailableAt }}</span>
    </v-col>
    <v-col cols="auto" align="right" v-else>
      <v-btn
        class="primary white--text"
        min-width="130px"
        @click="takeOnDemandSnapshotButton()"
        :disabled="disableOnDemandButton">
          {{ $t('takeOnDemandSnapshot') }}
      </v-btn>
    </v-col>
    <v-col cols="12">
      <LoadableComponent :loading="(snapshotLoading || snapshotListLoading) && !initialized" type="fill" :height="180">
        <v-progress-linear indeterminate v-if="snapshotLoading"/>
        <SnapshotBreakdown
          v-if="version === 1"
          :snapshot="selectedSnapshot"
          :isByproducts="isByproducts"
          :snapshotRecord="selectedSnapshotRecord"
          :snapshotPeriodEndDate="periodEndDateFromSnapshotUploadDate(selectedPeriodEndDate)"
          @refresh-snapshot="updateSnapshotList"/>
      </LoadableComponent>
    </v-col>
    <v-col cols="12" v-if="snapshots.length === 0 && !snapshotListLoading && !snapshotLoading">
      <NoData class="mb-4">
        {{ $t('noSnapshotAvailable') }}
      </NoData>
    </v-col>
    <v-col cols="12" v-if="uploadInProgressMessage">
      <v-row justify="space-around">
        <v-col cols="12" style="text-align: center;">
          <NoData class="mb-4">
            {{ $t('adjustingEntriesUnderway') }}
            <br>
            {{ $t('pleaseRefreshShortly') }}
          </NoData>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { AdjustingEntriesFrequency, AdjustingEntriesSnapshotUploadStatus } from '@/utils/Enumerations.js'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'AdjustingEntries',

  components: {
    SnapshotBreakdown: () => import('@/components/accounting/adjusting-entries/v1/SnapshotBreakdown.vue'),
    Icon: () => import('@/components/helper/Icon.vue'),
    LoadableComponent: () => import('@/components/helper/LoadableComponent.vue'),
    NoData: () => import('@/components/core/NoData.vue')
  },

  props: {
    contractMode: {
      type: Object,
      requried: true
    }
  },

  data: () => ({
    snapshots: [],
    selectedSnapshotRecord: undefined,
    selectedSnapshot: undefined,
    showManualSnapshotButtonToggle: false,
    selectedPeriodEndDate: undefined,
    snapshotListLoading: true,
    snapshotLoading: false,
    initialized: false
  }),

  computed: {
    ...mapGetters('user', ['companyInfo']),
    isOnDemand () {
      return this.companyInfo.adjustingEntriesFrequency === AdjustingEntriesFrequency.MonthEndOnDemand.value ||
        this.companyInfo.adjustingEntriesFrequency === AdjustingEntriesFrequency.PayPeriodOnDemand.value
    },
    version () {
      return this.selectedSnapshot?.version ?? 0
    },

    isByproducts () {
      return this.contractMode?.value === 1
    },
    nextSnapshotAvailableAt () {
      const nextMonth = moment().endOf('month').add(1, 'days').format('L')
      return this.$t('nextSnapshotAvailableDate', { date: nextMonth })
    },
    uploadInProgressMessage () {
      return this.selectedSnapshotRecord?.uploadStatus === AdjustingEntriesSnapshotUploadStatus.Unuploaded.value && !this.selectedSnapshot
    },
    disableOnDemandButton () {
      return this.snapshots[0]?.uploadStatus === AdjustingEntriesSnapshotUploadStatus.Unuploaded.value
    }
  },

  async created () {
    await this.updateSnapshotList()
  },

  methods: {
    ...mapActions('adjusting-entries', ['takeOnDemandSnapshot', 'snapshotsList', 'fetchSnapshot']),

    async takeOnDemandSnapshotButton () {
      try {
        await this.takeOnDemandSnapshot()
      } finally {
        await this.updateSnapshotList()
      }
    },

    async snapshotSelected (snapshot) {
      this.snapshotLoading = true
      this.selectedSnapshot = undefined
      this.selectedSnapshotRecord = snapshot
      this.selectedPeriodEndDate = snapshot.uploadedAt
      if (this.selectedSnapshotRecord.uploadStatus === AdjustingEntriesSnapshotUploadStatus.Uploaded.value) {
        this.selectedSnapshot = await this.fetchSnapshot(snapshot)
      }
      this.snapshotLoading = false
      this.initialized = true
    },

    async updateSnapshotList () {
      this.snapshotListLoading = true
      this.snapshots = (await this.snapshotsList()).sort((a, b) => a.createdAt > b.createdAt ? -1 : 1)
      this.selectedSnapshotRecord = this.snapshots[0] ?? undefined
      if (this.selectedSnapshotRecord) this.snapshotSelected(this.selectedSnapshotRecord)
      this.snapshotListLoading = false
    },

    adjustingEntriesFrequency: (x) => AdjustingEntriesFrequency.forInt(x),

    async refreshSnapshotList () {
      await this.updateSnapshotList()
    },

    periodEndDateFromSnapshotUploadDate (date) {
      return this.isOnDemand
        ? moment(date)
        : moment(date).add(-1, 'days')
    },

    formatDate (date) {
      const utcDate = moment.utc(date)
      if (!this.isOnDemand) utcDate.add(-1, 'days')

      return moment(utcDate).local().format(this.isOnDemand ? 'L - LT' : 'L')
    },

    recordIsUnUploaded (record) {
      return record.uploadStatus === AdjustingEntriesSnapshotUploadStatus.Unuploaded.value
    }
  }
}
</script>
