import i18n from '../i18n'
import { numberWithCommas } from '@/utils/NumericMutations.js'

export default {
  rules: {
    required: value => {
      const pattern = /^\s+$/
      return (!!value && !pattern.test(value)) || i18n.t('required')
    },
    requiredSelect: value => value.length > 0 || i18n.t('required'),
    email: value => {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return pattern.test(value) || i18n.t('invalidEmail')
    },
    number: value => { // Can't be blank
      const pattern = /^-?\d*?\.?\d+$/
      return pattern.test(value) || i18n.t('invalidNumber')
    },
    numberOrBlank: value => {
      const pattern = /^-?\d*?\.?\d*$/
      return pattern.test(value) || i18n.t('invalidNumber')
    },
    greaterThan: (minimum, message = undefined) => value => {
      return (parseFloat(value) > minimum) || message || i18n.t('mustBeGreaterThanNumber', { number: minimum })
    },
    positiveDecimal: value => {
      const pattern = /^[+]?([0-9]+(?:[.][0-9]*)?|\.[0-9]+)$/
      return pattern.test(value) || i18n.t('invalidDecimal')
    },
    decimal: value => {
      const pattern = /^-?\d*\.\d+$/
      return pattern.test(value) || i18n.t('mustBeDecimal')
    },
    integer: value => {
      const pattern = /^([+-]?[1-9]\d*|0)$/
      return pattern.test(value) || i18n.t('invalidNumber')
    },
    minFiveCharacters: value => {
      const pattern = /^.{5,}$/
      return pattern.test(value) || i18n.t('mustContainAtLeast5Characters')
    },
    positiveIntegerOrBlank: value => {
      const pattern = /^([+-]?[1-9]\d*|0)$/
      if (!value) return true
      return (pattern.test(value) && value >= 0) || i18n.t('mustBePositiveInteger')
    },
    positiveInteger: value => {
      const pattern = /^([+-]?[1-9]\d*|0)$/
      return (pattern.test(value) && parseInt(value) >= 0) || i18n.t('mustBePositiveInteger')
    },
    integerBetweenOrBlank: (low, high, errorMessageEntity = undefined) => (value) => {
      if (!value) return true
      const v = parseInt(value)
      return (v !== undefined && v <= high && v >= low) ||
        ((errorMessageEntity !== undefined) ? i18n.t('entityMustBeBetween', { entity: errorMessageEntity ?? i18n.t('number'), low, high }) : i18n.t('numberMustBeBetween', { low, high }))
    },
    positiveIntegerBetweenOrBlank: (low, high, errorMessageEntity = undefined) => (value) => {
      if (!value) return true
      const pattern = /^([+-]?[1-9]\d*|0)$/
      if (!pattern.test(value)) return i18n.t('mustBePositiveInteger')
      const v = parseInt(value)
      return (v !== undefined && v <= high && v >= low) ||
        ((errorMessageEntity !== undefined) ? i18n.t('entityMustBeBetween', { entity: errorMessageEntity ?? i18n.t('number'), low, high }) : i18n.t('numberMustBeBetween', { low, high }))
    },
    decimalBetweenOrBlankWithMessage: (low, high, errorMessageEntity = undefined) => (value) => {
      if (!value) return true
      const v = Number(parseFloat(value))
      return (v !== undefined && v <= high && v >= low) ||
        ((errorMessageEntity !== undefined) ? i18n.t('entityMustBeBetween', { entity: errorMessageEntity ?? i18n.t('number'), low, high }) : i18n.t('numberMustBeBetween', { low, high }))
    },
    decimalTwoPlacesBetweenOrBlank: (low, high, errorMessage, acceptUndefined = true) => (value) => {
      if (!value && acceptUndefined) return true
      const v = Number(parseFloat(value).toFixed(2))
      return (v !== undefined && v <= high && v >= low) || i18n.t(errorMessage)
    },
    sixDecimalPlacesOrFewer: value => {
      const pattern = /^-?\d*\.?\d{0,6}$/
      return !value || pattern.test(value) || i18n.t('mustNotExceedNDecimalPlaces', { n: 6 })
    },
    fourDecimalPlacesOrFewer: value => {
      const pattern = /^-?\d*\.?\d{0,4}$/
      return !value || pattern.test(value) || i18n.t('mustNotExceedNDecimalPlaces', { n: 4 })
    },
    twoDecimalPlacesOrFewer: value => {
      const pattern = /^-?\d*\.?\d{0,2}$/
      return !value || pattern.test(value) || i18n.t('mustNotExceedNDecimalPlaces', { n: 2 })
    },
    validLatitudeForTextBox: value => { // To be used in combination with sixDecimalPlacesOrFewer
      const pattern = /^-?([1-8]?[0-9]\.{1}\d+$|90\.{1}0+$)/
      return pattern.test(value) || i18n.t('invalidLatitude')
    },
    validLongitudeForTextBox: value => { // To be used in combination with sixDecimalPlacesOrFewer
      const pattern = /^-?((([1]?[0-7][0-9]|[1-9]?[0-9])\.{1}\d+$)|[1]?[1-8][0]\.{1}0+$)/
      return pattern.test(value) || i18n.t('invalidLongitude')
    },
    validDegreesNorthSouthForTextBox: value => {
      const pattern = /^-?([+-]?[1-8]?[0-9]$|90$)/
      return (pattern.test(value) && parseInt(value) >= 0) || i18n.t('invalidDegreesNorthSouth')
    },
    validDegreesEastWestForTextBox: value => {
      const pattern = /^-?((([+-]?[1]?[0-7][0-9]|[1-9]?[0-9])$)|[+-]?[1]?[1-8][0]$)/
      return (pattern.test(value) && parseInt(value) >= 0) || i18n.t('invalidDegreesEastWest')
    },
    validMinutesForTextBox: value => {
      const pattern = /^-?([+-]?[0-5]?[0-9]$|60$)/
      return (value >= 0 && pattern.test(value) && !value.toString().includes('.')) || i18n.t('invalidMinutes')
    },
    validSecondsForTextBox: value => {
      const pattern = /^-?([+-]?[0-5]?[0-9]\.{1}\d{1,4}$|60\.{1}0{1,4}$)/
      return (value >= 0 && pattern.test(value)) || i18n.t('invalidSeconds')
    },
    validLatitude: value => { // For validation
      const pattern = /^-?([1-8]?[0-9]\.{1}\d{1,6}$|90\.{1}0{1,6}$)/
      return pattern.test(value)
    },
    validLongitude: value => { // For validation
      const pattern = /^-?((([1]?[0-7][0-9]|[1-9]?[0-9])\.{1}\d{1,6}$)|[1]?[1-8][0]\.{1}0{1,6}$)/
      return pattern.test(value)
    },
    validMoney: value => {
      const pattern = /^\$-?\d+(?:\.\d+)?/
      const valueFloat = parseFloat(value.replace(/\$|,/g, ''))
      return ((Math.abs(valueFloat) < 9999999999999999n) && (pattern.test(value))) || i18n.t('invalidCurrency')
    },
    validTime: value => {
      const pattern = /^(0?[1-9]|1[0-2]):[0-5][0-9]?\s(?:AM|PM)$/
      return pattern.test(value) || i18n.t('invalidTime')
    },
    validDate: value => {
      const pattern = /^[0-3]?[0-9][- /.][0-3]?[0-9][- /.][0-9]?[0-9]$/
      if (value !== '' && pattern.test(value)) {
        const parts = value.split('/')
        const month = parseInt(parts[0], 10)
        const day = parseInt(parts[1], 10)
        const year = parseInt(parts[2], 10)

        if (month === 0 || month > 12) {
          return i18n.t('invalidDate')
        }

        const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

        if (year % 100 !== 0 && year % 4 === 0) {
          monthLength[1] = 29
        }
        if (day > 0 && day <= monthLength[month - 1]) {
          return true
        } else {
          return i18n.t('invalidDate')
        }
      } else return i18n.t('invalidDate')
    },
    validMonth: value => {
      const pattern = /^0*[1-9]\d*$/
      return (pattern.test(value) && value < 13) || i18n.t('mmInvalid')
    },
    validDay: value => {
      const pattern = /^0*[1-9]\d*$/
      return (pattern.test(value) && value < 32) || i18n.t('ddInvalid')
    },
    validYear: value => {
      const pattern = /^[0-9]\d*$/
      return pattern.test(value) || i18n.t('yyInvalid')
    },
    validPastDate: value => {
      return new Date(value) <= new Date() ? true : i18n.t('invalidFutureDate')
    },
    phoneNumber: value => {
      const pattern = /^\d{3}-\d{3}-\d{4}$/
      return pattern.test(value) || i18n.t('invalidPhoneNumber')
    },
    validPercentage: (allowZero = true) => value => {
      if (value === 0 && !allowZero) return i18n.t('invalidPercentage')
      const pattern = /^(?:100(?:\.00?)?|\d?\d(?:\.\d\d?)?)$/
      return pattern.test(value) || i18n.t('invalidPercentage')
    },
    validDefectDefaultPercentage: value => {
      return (value <= 100 && value >= 0) || i18n.t('invalidPercentage')
    },
    validWeightPounds: value => {
      const valueInt = parseInt(value)
      const valueFloat = parseFloat(value)
      return (value === '' || ((valueInt >= 0 && valueInt <= MAX_TICKET_WEIGHT_POUNDS) && valueFloat === valueInt)) || i18n.t('weightNotValidPounds')
    },
    validWeightTons: value => {
      const valueFloat = parseFloat(value)
      return (value === '' || (valueFloat >= 0 && valueFloat <= MAX_TICKET_WEIGHT_TONS)) || i18n.t('weightNotValidTons')
    },
    validAverageLength: value => {
      const valueFloat = parseFloat(value)
      return valueFloat <= 999999.99 || i18n.t('averageLengthNotValid')
    },
    validPieces: value => {
      const valueInt = parseInt(value)
      return (valueInt > 0 && valueInt <= 2500) || i18n.t('piecesNotValid')
    },
    validDistance: value => {
      const valueInt = parseInt(value)
      return (valueInt >= 0 && valueInt <= 250) || i18n.t('distanceNotValid')
    },
    validAcres: value => {
      const valueInt = parseInt(value)
      return (value === '' || (valueInt >= 0 && valueInt <= MAX_ACRES)) || i18n.t('acresNotValid')
    },
    validHarvestedTons: value => {
      const valueFloat = parseFloat(value)
      return (value === '' || (valueFloat >= 0 && valueFloat <= MAX_TONS_HARVESTED_EXPECTED)) || i18n.t('harvestedTonsNotValid')
    },
    validExpectedTons: value => {
      const valueFloat = parseFloat(value)
      return (value === '' || (valueFloat >= 0 && valueFloat <= MAX_TONS_HARVESTED_EXPECTED)) || i18n.t('expectedTonsNotValid')
    },
    validOnDeckTons: value => {
      const valueFloat = parseFloat(value)
      return (value === '' || (valueFloat >= 0 && valueFloat <= MAX_TONS_ON_DECK)) || i18n.t('onDeckTonsNotValid')
    },
    validConversionRate: value => {
      const valueFloat = parseFloat(value)
      return (valueFloat >= 0 && valueFloat <= MAX_CONVERSION_RATE) || i18n.t('conversionRateNotValid')
    },
    validTractCost: value => {
      const valueFloat = parseFloat(value.replace(/\$|,/g, ''))
      return (valueFloat <= MAX_TRACT_COST) || i18n.t('invalidTractCost')
    },
    validAdvanceAmount: value => {
      const valueFloat = parseFloat(value.replace(/\$|,/g, ''))
      return (valueFloat <= MAX_ADVANCE_AMOUNT) || i18n.t('invalidAdvanceAmount')
    },
    validAdvanceRecoveryRate: value => {
      const valueFloat = parseFloat(value.replace(/\$|,/g, ''))
      return (valueFloat < MAX_ADVANCE_RECOVERY_RATE) || i18n.t('invalidAdvanceRecoveryRate')
    },
    validMiscPayable: value => {
      const valueFloat = parseFloat(value.replace(/\$|,/g, ''))
      return (valueFloat >= MIN_MISC_PAYABLE && valueFloat <= MAX_MISC_PAYABLE) || i18n.t('invalidMiscPayable')
    },
    negative: value => {
      const valueFloat = parseFloat(value.replace(/\$|,/g, ''))
      return valueFloat < 0 || i18n.t('mustBeNegative')
    },
    validActivityRate: value => {
      const valueFloat = parseFloat(value.replace(/\$|,/g, ''))
      return (valueFloat <= MAX_ACTIVITY_RATE) || i18n.t('invalidActivityRate')
    },
    validRPI: value => {
      const valueFloat = parseFloat(value)
      return (!valueFloat || (valueFloat <= MAX_RPI && valueFloat >= 0)) || i18n.t('entityMustBeBetween', { entity: i18n.t('rpi'), low: 0, high: MAX_RPI })
    },
    validDBH: value => {
      const valueInt = parseInt(value)
      return (!valueInt || (valueInt <= MAX_DBH && valueInt >= 0)) || i18n.t('entityMustBeBetween', { entity: i18n.t('dbh'), low: 0, high: MAX_DBH })
    },
    validAge: value => {
      const valueInt = parseInt(value)
      return (!valueInt || (valueInt <= MAX_AGE && valueInt >= 0)) || i18n.t('entityMustBeBetween', { entity: i18n.t('age'), low: 0, high: MAX_AGE })
    },
    validIpAddress: value => {
      const pattern = /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}$/
      return pattern.test(value) || i18n.t('invalidIp')
    },
    validIpAddressOrBlank: value => {
      if (!value || value?.trim().length === 0) return true
      const pattern = /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}$/
      return pattern.test(value) || i18n.t('invalidIp')
    },
    validNumericRange: (lowerBound, upperBound) => value => {
      return (Number(value) >= lowerBound && Number(value) <= upperBound) || i18n.t('invalidRange', { lower: Number(lowerBound), upper: Number(upperBound) })
    },
    validUniqueStringCollection: (collection, ignoreCollection, message) => value => {
      if (ignoreCollection.has(value.trim().toLowerCase())) return true
      return !collection.has(value.trim().toLowerCase()) || i18n.t(message)
    },
    validUuid: value => {
      const pattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/
      return pattern.test(value) || i18n.t('invalidUuid')
    },
    integerWithCommasBetweenOrBlank: (low, high) => (value) => { // For validation on inputs masked to have thousands separator
      if (!value) return true
      const v = parseInt(value.toString().replace(',', ''))
      return (v !== undefined && v <= high && v >= low) || i18n.t('numberMustBeBetween', { low: numberWithCommas(low, 0), high: numberWithCommas(high, 0) })
    },
    doesNotInclude: (values, errorStringKey) => (value) => {
      values = values.map(v => v.toLowerCase().trim())
      value = value.toLowerCase().trim()
      return !values.includes(value) || i18n.t(errorStringKey)
    }
  }
}

const MAX_TONS_HARVESTED_EXPECTED = 50_000
const MAX_TONS_ON_DECK = 25_000
const MAX_ACRES = 50_000
const MAX_TICKET_WEIGHT_TONS = 250
export const MAX_TICKET_WEIGHT_POUNDS = MAX_TICKET_WEIGHT_TONS * 2_000
const MAX_CONVERSION_RATE = 5
export const MAX_TRACT_COST = 100_000_000
export const MAX_ADVANCE_AMOUNT = 100_000_000
export const MAX_MISC_PAYABLE = 100_000_000
export const MIN_MISC_PAYABLE = -100_000_000
export const MAX_ACTIVITY_RATE = 5_000
export const MAX_ADVANCE_RECOVERY_RATE = 1_000_000
export const MAX_RPI = 99.99
export const MAX_DBH = 100
export const MAX_AGE = 150
