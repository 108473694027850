<template>
  <v-card>
    <v-card-title class="primary">
      <v-row>
        <v-col cols="auto">
          <span class="headline white--text">{{titleText}}</span>
        </v-col>
      </v-row>
      <v-spacer/>
      <BaseDialogActions hideRefresh/>
    </v-card-title>
    <v-card-text>
      <FormWrapper
      :disabled="!validForm"
      :buttonText="formButtonText"
      @submit="formButtonPressed"
      strongValidation>
        <v-container grid-list-lg class="mt-4">
          <v-row class="mb-3">
            <v-col cols="12">
              <v-text-field
                style="font-size: 1.2em;"
                v-model="accountPayment.paymentNote"
                :hint="$t('paymentTitle')"
                persistent-hint
                color="black"
                maxLength="128"
                counter="128"
                auto-grow
                clearable
                dense
                ref="paymentNote"/>
            </v-col>
          </v-row>
          <v-row class="mb-3">
            <v-col cols="12" md="5" align="right">
              <v-row>
                <v-col cols="12">
                  <v-select
                  :label="$t('paymentType')"
                  return-object
                  :items="paymentTypes"
                  item-text="name"
                  v-model="paymentType"
                  :disabled="!isRecurring && isEditing"/>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <AdvanceAutocomplete
                  :disabled="advanceDisabled"
                  :accountId="accountId"
                  :advanceId="advance?.advanceId ?? accountPayment.advanceId"
                  @advance-chosen="advance = $event"
                  :filter="a => a.recoveryStrategy === RecoveryStrategy.RecurringPayment.value"
                  :hint="advanceDisabled ? $t('availableOnlyForRecurringAccountPayments') : ''"
                  persistentHint/>
                </v-col>
              </v-row>
            </v-col>
            <v-spacer/>
            <v-col cols="12" md="6">
              <TemplateAutocomplete
              :templateId="accountPayment.activityTemplateId"
              @template-chosen="accountPayment.activityTemplateId = $event?.activityTemplateId"
              :propAccountingCategories="accountingCategories"
              :disabled="!!advance"
              stacked
              :includeModifiers="false"
              :includeTransferActivities="false"
              :includeDepletionActivities="false"
              :includePenaltyActivities="false"
              />
              <v-row justify="end">
                <v-col cols="4">
                  <MoneyTextField
                    :initialValue="accountPayment.amount"
                    :label="$t('amount')"
                    allowNegative
                    @val-changed="accountPayment.amount = $event"
                    :extraRules="amountRules"
                  />
                </v-col>
                <v-col cols="8">
                  <DatePicker
                    dataTestId="issued-date"
                    :dateLabel="isRecurring ? $t('startDate') : $t('issuedAt')"
                    :startDate="issuedAtStartDate"
                    @valid-date="validIssuedAt = $event"
                    @date-picked="issuedAtStartDate = $event"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
        <template #left-action v-if="isRecurring">
          <v-container>
            <Icon
            icon="mdi-information-outline"
            :small="false"/>
            <em>
              {{ recurringPaymentInfoString }}
            </em>
          </v-container>
        </template>
        <template #right-action>
          <v-checkbox
          :disabled="recovers"
          v-model="accountPayment.preIssued"
          color="primary"
          :label="$t('preIssued')"
          class="mr-5"/>
        </template>
      </FormWrapper>
    </v-card-text>
  </v-card>
</template>

<script>
import { localToUTC, utcToLocalDate } from '@/utils/DateFormatter.js'
import { AccountingCategory, AccountPaymentType, RecoveryStrategy, SystemDayOfWeek } from '@/utils/Enumerations'
import { MAX_MISC_PAYABLE, MIN_MISC_PAYABLE } from '@/utils/rules'
import { formatMoney } from '@/utils/NumericMutations.js'
import { mapGetters } from 'vuex'
import timezones from '@/components/settings-components/company-config/timezones'
import moment from 'moment'
export default {
  name: 'AccountPayableForm',

  props: {
    propAccountPayable: {
      type: Object,
      default: undefined
    },
    accountId: {
      type: Number,
      required: true
    }
  },

  components: {
    FormWrapper: () => import('@/components/core/FormWrapper.vue'),
    TemplateAutocomplete: () => import('@/components/autocomplete/TemplateAutocomplete.vue'),
    MoneyTextField: () => import('@/components/helper/MoneyTextField.vue'),
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue'),
    DatePicker: () => import('@/components/helper/DatePicker.vue'),
    AdvanceAutocomplete: () => import('@/components/autocomplete/AdvanceAutocomplete.vue'),
    Icon: () => import('@/components/helper/Icon.vue')
  },

  data: () => ({
    accountPayment: {
      activityTemplateId: undefined,
      amount: 0,
      paymentNote: '',
      issuedAt: '',
      preIssued: false
    },
    validIssuedAt: true,
    issuedAtStartDate: undefined,
    paymentType: AccountPaymentType,
    advance: undefined,
    AccountingCategory,
    AccountPaymentType,
    RecoveryStrategy
  }),

  watch: {
    paymentType (v) {
      if (v !== AccountPaymentType.RecurringAdvance) this.advance = undefined
    },

    advance (a) {
      if (!a) return
      this.accountPayment.activityTemplateId = a.activityTemplateId
    },

    recovers (r) {
      if (!r) return
      this.accountPayment.preIssued = false
    }
  },

  computed: {
    ...mapGetters('user', ['companyInfo']),
    isEditing () {
      return !!this.propAccountPayable
    },
    titleText () {
      return this.isRecurring
        ? this.isEditing ? this.$t('editAccountPaymentTemplate') : this.$t('createAccountPaymentTemplate')
        : this.isEditing ? this.$t('editAccountPayment') : this.$t('createAccountPayment')
    },
    formButtonText () {
      return this.isEditing ? this.$t('saveChanges') : this.$t('create')
    },
    validForm () {
      const { activityTemplateId, amount, paymentNote } = this.accountPayment
      const requiredFieldsPopulated = (
        activityTemplateId > 0 &&
        amount !== 0 &&
        paymentNote &&
        this.validIssuedAt
      )

      return requiredFieldsPopulated && (this.paymentType !== AccountPaymentType.RecurringAdvance || this.advance !== undefined)
    },
    isRecurring () {
      return this.paymentType === AccountPaymentType.Recurring || this.paymentType === AccountPaymentType.RecurringAdvance
    },

    paymentTypes () {
      if (!this.isEditing) return AccountPaymentType.enums
      if (this.isRecurring) return [AccountPaymentType.Recurring, AccountPaymentType.RecurringAdvance]
      return [AccountPaymentType.OneTime]
    },

    recovers () {
      return this.paymentType === AccountPaymentType.RecurringAdvance
    },

    recurringPaymentInfoString () {
      const { timeZoneOffset, recurringAccountPaymentDayOfWeek, recurringAccountPaymentHour } = this.companyInfo
      const dayOfWeek = SystemDayOfWeek.fromInt(recurringAccountPaymentDayOfWeek)
      const time = moment(recurringAccountPaymentHour, 'H').format('LT')
      const timeZone = timezones.find(tz => Number(tz.offset) === timeZoneOffset).name
      return this.$t('recurringPaymentsCreatedAt', { time, dayOfWeek, timeZone })
    },

    advanceDisabled () {
      return this.paymentType === AccountPaymentType.OneTime || this.paymentType === AccountPaymentType.Recurring
    },

    amountRules () {
      const base = ['validMiscPayable']
      if (!this.advanceDisabled && this.accountPayment.amount > 0) base.push(['negative'])
      return base
    },

    accountingCategories () {
      return this.recovers
        ? [AccountingCategory.Payable.value]
        : [AccountingCategory.Payable.value, AccountingCategory.Accrual.value]
    }
  },

  created () {
    if (this.isEditing) {
      this.paymentType = !this.propAccountPayable.isRecurring
        ? AccountPaymentType.OneTime
        : this.propAccountPayable.advanceId ? AccountPaymentType.RecurringAdvance : AccountPaymentType.Recurring
      const initFn = this.isRecurring ? this.initializeFromSingleIssuePayable : this.initializeFromPayableTemplate
      initFn()
    } else {
      this.issuedAtStartDate = new Date().toISOString()
      this.paymentType = AccountPaymentType.OneTime
      requestAnimationFrame(_ => this.$refs.paymentNote.focus())
    }
  },

  methods: {
    formButtonPressed () {
      if (this.accountPayment.amount > MAX_MISC_PAYABLE || this.accountPayment.amount < MIN_MISC_PAYABLE) {
        this.setSnackError(this.$t('invalidAccountPaymentAmount', { min: formatMoney(MIN_MISC_PAYABLE), max: formatMoney(MAX_MISC_PAYABLE) }))
        return
      }

      const submitFn = this.isRecurring ? this.savePayableTemplate : this.savePayable
      submitFn()
    },

    initializeFromSingleIssuePayable () {
      this.accountPayment = JSON.parse(JSON.stringify(this.propAccountPayable))
      this.issuedAtStartDate = this.accountPayment.issuedAt ? utcToLocalDate(this.accountPayment.issuedAt) : utcToLocalDate(this.accountPayment.createdAt)
    },

    initializeFromPayableTemplate () {
      this.accountPayment = JSON.parse(JSON.stringify(this.propAccountPayable))
      this.issuedAtStartDate = this.accountPayment.startDate ? utcToLocalDate(this.accountPayment.startDate) : utcToLocalDate(this.accountPayment.createdAt)
    },

    savePayable () {
      this.accountPayment.issuedAt = localToUTC(this.issuedAtStartDate)
      const requestObject = this.accountPayment
      this.$emit(this.isEditing ? 'edit-payable' : 'create-payable', {
        accountPayable: { ...requestObject, accountId: this.accountId },
        accountId: this.accountId
      })
    },

    savePayableTemplate () {
      this.accountPayment.startDate = localToUTC(this.issuedAtStartDate)
      this.accountPayment.advanceId = this.paymentType === AccountPaymentType.RecurringAdvance ? this.advance?.advanceId : null
      const requestObject = this.accountPayment
      this.$emit(this.isEditing ? 'edit-template' : 'create-template', {
        accountPayableTemplate: { ...requestObject, accountId: this.accountId },
        accountId: this.accountId
      })
    }
  }
}
</script>
