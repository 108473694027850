<template>
  <v-data-table
  id="bundle-activity-definition-table"
  :items="filteredBuilders"
  :headers="headers"
  :expanded.sync="expanded"
  :hide-default-footer="filteredBuilders.length < 10"
  class="scrollable-table"
  item-key="activityBuilderId">
    <template #item.activityStatus="{ item }">
      <Icon
      dataTestId="activity-status-icon"
      :icon="item.templateStatus === 1 ? 'mdi-cancel' : 'mdi-checkbox-marked-circle'"
      :iconColor="item.templateStatus === 1 ? 'black' : 'success'"
      :tooltipColor="item.templateStatus === 1 ? 'black' : 'success'"
      :tooltipText="item.templateStatus === 1 ? $t('inactive') : $t('active')"
      :small="false"
      />
      <Icon
        icon-color="primary"
        dataTestId="activity-cost-type"
        :icon="costTypeIconFromInt(item.costBehavior)"
        :tooltipText="costTypeFromInt(item.costBehavior)"
        :small="false"
      />
      <Icon
      v-if="item.specialization === TemplateSpecialization.Penalty.value"
      icon="weight-alert"
      :tooltipText="$t('penalty')"
      :small="false"
      />
    </template>
    <template #item.counterpartyName="{ item }">
      <v-row>
        <span v-if="builderIsDynamicCounterparty(item)" :style="`background-color: ${ runtimeFieldResolvesToDuplicateCounterparty(item.activityTemplateId, item.runtimeField) ? '#ff000025' : 'unset'};`">
          <v-col>
            <Icon
            icon="mdi-lock-open"
            iconColor="black"
            :tooltipText="$t('switchToConcreteCounterparty')"
            :small="false"
            @icon-clicked="{ item.runtimeField = undefined, item.recoveryMode = RecoveryMode.None }"/>
            <Icon
            icon="mdi-alpha-r-circle-outline"
            :iconColor="!rcpCanResolve(item.runtimeField) ? 'warning' : 'primary'"
            :small="false"
            :tooltipText="counterpartyTextFromRuntimeField(item.runtimeField)"
            :tooltipColor="!rcpCanResolve(item.runtimeField) ? 'warning' : 'primary'"/>
            {{ runtimeCounterparty(item.runtimeField) }}
            <Icon
            v-if="runtimeFieldResolvesToDuplicateCounterparty(item.activityTemplateId, item.runtimeField)"
            icon="mdi-alert"
            :small="false"
            :tooltipText="$t('duplicateActivityBuilder')"/>
          </v-col>
        </span>
        <v-autocomplete
          v-else
          :background-color="counterpartyIsDuplicate(item.activityTemplateId, item.counterparty?.accountId) ? '#ff000025' : 'unset'"
          v-model="item.counterparty"
          item-text="name"
          item-value="name"
          return-object
          :persistent-hint="counterpartyIsDuplicate(item.activityTemplateId, item.counterparty?.accountId)"
          :hint="counterpartyIsDuplicate(item.activityTemplateId, item.counterparty?.accountId) ? $t('duplicateActivityBuilder') : ''"
          :placeholder="$t('account')"
          @input="duplicateCounterparties = getDuplicateCounterparties()"
          @change="item.recoveryMode = recoveryModeFromBuilder(item)"
          :items="allAccounts"
        >
          <template #prepend v-if="!nullOrUndefined(item.runtimeFieldFromTemplate)">
            <Icon
            icon="mdi-arrow-u-left-top-bold"
            :small="false"
            iconColor="black"
            :tooltipText="$t('switchToRuntimeCounterparty')"
            @icon-clicked="{ item.runtimeField = item.runtimeFieldFromTemplate, item.recoveryMode = defaultRecoveryModeFromAccountingCategory(item), delete item.counterparty }"/>
          </template>
        </v-autocomplete>
      </v-row>
    </template>
    <template #item.recovers="{ item }">
      <v-row>
        <Icon
        v-if="!builderIsDynamicCounterparty(item) && builderCanRecover(item) && (item.recoveryMode.value === RecoveryMode.None.value)"
        icon="mdi-alert"
        iconColor="warning"
        :small="false"
        :tooltipText="$t('counterpartyHasOutstandingAdvance')"/>
        <Icon
        v-if="builderIsDynamicCounterparty(item) && builderCanRecover(item)"
        icon="mdi-help-circle-outline"
        :tooltipText="$t('runtimeCounterpartyCannotRecoverFromSpecificAdvance')"/>
        <v-autocomplete
        :disabled="item.template.category !== AccountingCategory.Payable.value || (!builderIsDynamicCounterparty(item) && !item.counterparty?.hasAdvances) || isPenalty(item)"
        v-model="item.recoveryMode"
        :label="$t('recoveryMode')"
        return-object
        :items="mappedRecoveryModesByBuilder(item)"
        item-text="name"
        @change="item.advanceId = undefined">
          <template #item="{item}">
            {{ item.name }}
            <v-spacer/>
            <v-tooltip bottom color="primary" v-if="item.definition">
              <template #activator="{on}">
                <v-icon right color="primary" v-on="on" small>
                  mdi-help-circle-outline
                </v-icon>
              </template>
              <span class="subtitle-1 white--text" style="white-space: pre-line" disabled>
                {{ $t(item.definition, { tract: tract.name })}}
              </span>
            </v-tooltip>
          </template>
        </v-autocomplete>
      </v-row>
    </template>
    <template #item.category="{ item }">
      <v-tooltip bottom color="primary">
        <template #activator="{on}">
          <span v-on="on">
            {{ categoryFromInt(item.category).label }}
          </span>
        </template>
        <span class="subtitle-1 white--text">{{ categoryFromInt(item.category).tooltip }}</span>
      </v-tooltip>
    </template>
    <template #item.costBehavior="{ item }">
      {{ costBehaviorFromInt(item.costBehavior) }}
    </template>
    <template #item.baseRate="{ item }">
      <MoneyTextField
      v-if="!isApOrJePenalty(item)"
      :initialValue="item.baseRate"
      :itemId="item.activityBuilderId"
      :label="$t('rate')"
      :expectedValue="expectedValue(item.activityTemplateId)"
      dense
      @blur-entered="baseRateEnteredForActivity"
      :extraRules="['validActivityRate']"/>
      <div class="py-5" v-else>{{$t('notApplicable')}}</div>
    </template>
    <template #item.payOn="{ item }">
      {{ payOnFromInt(item.payOn)}}
    </template>
    <template #item.payBy="{ item }">
      {{ payByFromInt(item.payBy) }}
    </template>
    <template #item.modifier="{ item }">
      {{ modifierFromInt(item.modifier) }}
    </template>
    <template v-slot:expanded-item="{ headers, item: builder }">
      <td
      :key="builder.activityBuilderId"
      :colspan="headers.length + 2"
      v-if="showExpansion(builder)"
      >
        <v-container fluid>
          <v-row v-if="builder.recoveryMode.value === RecoveryMode.Specific.value && !builderIsDynamicCounterparty(builder)" no-gutters>
            <AdvanceAutocomplete
              :advanceId="builder.advanceId"
              :accountId="builder.counterparty.accountId"
              @advance-chosen="advance => setAdvanceForBuilder(builder, advance)"
              :filter="a => a.recoveryStrategy === RecoveryStrategy.Ticket.value"
            />
          </v-row>
          <v-row v-if="!builder.limitToBaseRate && products?.length > 0" align="center">
            <div v-if="builder.products?.length === 0" style="width: 30px; height: 150px"/>
            <v-col
            v-for="product in builder.products"
            :key="product.productId"
            cols="12" md="4" lg="3" xl="2"
            >
              <v-card>
                <v-card-title class="py-0 px-2">
                  <span style="font-size: 14px;" class="pa-1 ma-0">{{product.productName}}</span>
                  <v-spacer></v-spacer>
                  <Icon
                    v-if="product.inheritedFromCruise === true"
                    dataTestId="activity-inherited-from-cruise"
                    icon="mdi-forest"
                    :tooltipText="$t('inheritedFromCruise')"
                  />
                  <Icon
                    icon="mdi-close"
                    iconColor="grey"
                    @icon-clicked="removeProductDetail(builder, product)"
                    :tooltipText="$t('removeProductDetail')"
                  />
                </v-card-title>
                <v-card-text>
                  <MoneyTextField
                  :initialValue="product.baseRate"
                  :itemId="`${builder.activityBuilderId}-${product.productId}`"
                  :minimumValueConfig="{ min: builder.baseRate, error: $t('baseRateLowerThanActivity') }"
                  :label="$t('rate')"
                  :expectedValue="expectedValue(builder.activityTemplateId, product.productId)"
                  dense
                  @blur-entered="baseRateEnteredForProduct"
                  :extraRules="['validActivityRate']"/>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="auto">
              <v-menu v-if="products.length > 0">
                <template #activator="{ on: menu }">
                  <v-tooltip bottom color="grey">
                    <template #activator="{ on: tooltip }">
                      <v-icon
                      v-on="{ ...menu, ...tooltip }"
                      v-show="missingProducts(builder, products).length > 0"
                      height="24"
                      v-if="products.length > 0"
                      >
                        mdi-plus
                      </v-icon>
                    </template>
                    <span class="subtitle-1 white--text">{{$t('addProductDetail')}}</span>
                  </v-tooltip>
                </template>
                <v-list>
                  <v-list-item
                  v-for="product in missingProducts(builder, products)"
                  :key="product.productId"
                  link
                  @click="addProductDetail(builder, product)">
                    {{product.productName}}
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-container>
      </td>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import TractHeaders from '@/headers/Tract'
import { TractDestination } from '@/model/TractDestination'
import { PayOn, PayBy, AccountingCategory, ActivityModifier, ActivityStatus, CostType, RuntimeCounterparty, RecoveryMode, TemplateSpecialization, RecoveryStrategy } from '@/utils/Enumerations.js'
import { indexArrayBy } from '../../../utils/base'
import { TemplateSpecializationInfo } from '@/components/activity/ActivityMapping'
export default {
  name: 'BundleActivityDefinition',

  props: {
    tract: Object,
    tractDestination: TractDestination,
    propBundle: Object,
    products: Array,
    activityTemplates: Array,
    limitToTractLevelCapture: Boolean,
    useCruiseRateForStumpage: Boolean,
    templateStats: {
      type: Map,
      required: false,
      default: new Map()
    },
    contractAccount: Object,
    landowners: []
  },

  components: {
    MoneyTextField: () => import('@/components/helper/MoneyTextField'),
    AdvanceAutocomplete: () => import('@/components/autocomplete/AdvanceAutocomplete.vue'),
    Icon: () => import('@/components/helper/Icon.vue')
  },

  data: () => ({
    TemplateSpecializationInfo,
    TemplateSpecialization,
    loading: true,
    expanded: [],
    allBuilders: [],
    duplicateCounterparties: {},
    showVarianceWarning: false,
    runtimeFieldCounterpartyIdMap: new Map(),
    AccountingCategory,
    RecoveryMode,
    RecoveryStrategy
  }),

  watch: {
    allBuilders: {
      handler (val) {
        this.duplicateCounterparties = this.getDuplicateCounterparties()
        this.$emit('activity-builders-updated', val)
      },
      deep: true
    },

    filteredBuilders: {
      handler (val) {
        this.expanded = [...val]
        this.duplicateCounterparties = this.getDuplicateCounterparties()
      },
      deep: true
    },

    duplicateCounterparties: {
      handler (val) {
        this.$emit('duplicate-counterparties', val)
      },
      deep: true
    },

    useCruiseRateForStumpage (val) {
      this.toggleCruiseRates(val)
    },

    propBundle: {
      handler () {
        this.initializeBuilders()
      },
      deep: true,
      immediate: true
    },
    products: {
      handler () {
        this.initializeBuilders()
      },
      deep: true,
      immediate: true
    },
    contractAccount: {
      handler () {
        this.initializeCounterpartyRuntimeFieldMap()
        this.duplicateCounterparties = this.getDuplicateCounterparties()
      },
      deep: true
    }
  },

  computed: {
    ...mapGetters('account', ['allAccounts']),

    filteredBuilders () {
      return this.allBuilders.filter(b => !this.limitToTractLevelCapture || b.runtimeField || b.tractLevelCapture)
    },

    tractContents () {
      return this.tract.tractContents
    },

    headers () {
      return TractHeaders.templateBundleHeaders()
    },

    isStumpageTract () {
      return this.tract.type.category === 0
    },

    accountIndices () {
      return this.indexArrayBy(this.allAccounts, a => a.accountId)
    }
  },

  methods: {
    ...mapActions('tract', ['fetchTractLandowners']),
    indexArrayBy,
    costBehaviorFromInt: (x) => CostType.fromInt(x),
    payByFromInt: (val) => PayBy.fromInt(val),
    payOnFromInt: (val) => PayOn.fromInt(val),
    activityStatusFromInt: (val) => ActivityStatus.fromInt(val),
    costTypeFromInt: (val) => CostType.fromInt(val),
    runtimeCounterparty: (val) => RuntimeCounterparty.fromInt(val),
    nullOrUndefined: (val) => val === null || val === undefined,

    categoryFromInt (val) {
      switch (val) {
        case AccountingCategory.Payable.value:
          return { label: this.$t('ap'), tooltip: this.$t('accountsPayable') }
        case AccountingCategory.Receivable.value:
          return { label: this.$t('ar'), tooltip: this.$t('accountsReceivable') }
        case AccountingCategory.Accrual.value:
          return { label: this.$t('accr'), tooltip: this.$t('accruals') }
      }
    },

    costTypeIconFromInt (costType) {
      switch (costType) {
        case CostType.Unspecified.value:
          return 'mdi-alpha-u-circle-outline'
        case CostType.Production.value:
          return 'mdi-alpha-p-circle-outline'
        case CostType.Stumpage.value:
          return 'mdi-alpha-s-circle-outline'
        case CostType.NonInventory.value:
          return 'mdi-alpha-n-circle-outline'
      }
    },

    modifierFromInt (x) {
      return x === ActivityModifier.DistanceTraveled.value
        ? this.$t('distanceTraveledWithMiles', { distance: this.tractDestination.distance })
        : ActivityModifier.fromInt(x)
    },

    recoveryModeFromBuilder (builder) {
      if (builder.template.category !== AccountingCategory.Payable.value || this.isPenalty(builder)) return RecoveryMode.None
      if (builder.counterparty === undefined && !this.builderIsDynamicCounterparty(builder)) return RecoveryMode.None
      if (builder.counterparty?.hasAdvances || this.builderIsDynamicCounterparty(builder)) return RecoveryMode.Oldest
      return RecoveryMode.None
    },

    counterpartyTextFromRuntimeField (runtimeField) {
      if (runtimeField === RuntimeCounterparty.Driver.value) return this.$t('ticketDriver')
      const ids = this.runtimeFieldCounterpartyIdMap.get(runtimeField)
      switch (ids.length) {
        case 0:
          return this.$t('notSet')
        case 1:
          return this.findAccount(ids[0])?.name ?? this.$t('notSet')
        default:
          return this.$t('multiple')
      }
    },

    expectedValue (activityTemplateId, productId) {
      const activityStats = this.templateStats?.get(activityTemplateId)
      if (!productId) return activityStats?.baseRecentAverage
      return activityStats?.detailStats.find(p => p.productId === productId)?.recentAverage
    },

    baseRateEnteredForActivity ({ amount, itemId }) {
      const index = this.allBuilders.findIndex(a => a.activityBuilderId === itemId)

      if (index !== -1) {
        this.allBuilders[index].baseRate = amount
      }
    },

    toggleCruiseRates (shouldUseCruise) {
      const clonedBuilders = JSON.parse(JSON.stringify(this.allBuilders))
      this.allBuilders = []
      this.loading = true

      if (shouldUseCruise === true) {
        for (const builder of clonedBuilders) {
          if (this.shouldActivityUseCruiseData(builder)) {
            builder.products = builder.products.map(p => {
              const cruiseProduct = this.tractContents.find(tc => tc.productId === p.productId)
              return {
                productId: p.productId,
                productName: p.productName,
                inheritedFromCruise: cruiseProduct !== undefined,
                baseRate: p ? parseFloat(cruiseProduct.pricePerTon) : parseFloat(builder.baseRate)
              }
            })
          }
        }
      } else {
        for (const builder of clonedBuilders) {
          if (this.shouldActivityUseCruiseData(builder)) {
            builder.products = builder.products.map(p => {
              const detail = builder.template.details.find(pd => pd.productId === p.productId)
              return {
                productId: p.productId,
                productName: p.productName,
                baseRate: detail ? parseFloat(detail.rate) : parseFloat(builder.baseRate)
              }
            })
          }
        }
      }

      this.$nextTick(() => {
        this.allBuilders = clonedBuilders
        this.loading = false
      })
    },

    mappedRecoveryModesByBuilder (builder) {
      return RecoveryMode.enums.map(recoveryMode => ({
        ...recoveryMode,
        name: recoveryMode.name,
        definition: recoveryMode.explanation,
        excluded: (recoveryMode.value === RecoveryMode.Specific.value && this.builderIsDynamicCounterparty(builder)) ||
          (recoveryMode.value === RecoveryMode.OldestOnTract.value && this.tract === null)
      })).filter(recoveryMode => !recoveryMode.excluded)
    },

    initializeBuilders () {
      this.allBuilders = []
      this.loading = true
      const builders = JSON.parse(JSON.stringify(this.propBundle.activityBuilders))
      this.initializeCounterpartyRuntimeFieldMap()

      for (const activityBuilder of builders) {
        activityBuilder.template = this.activityTemplates.find(t => t.activityTemplateId === activityBuilder.activityTemplateId)
        if (activityBuilder.counterpartyId) activityBuilder.counterparty = this.findAccount(activityBuilder.counterpartyId)
        activityBuilder.runtimeFieldFromTemplate = activityBuilder.runtimeField
        activityBuilder.recoveryMode = this.recoveryModeFromBuilder(activityBuilder)
        if (!activityBuilder.limitToBaseRate && !this.isApOrJePenalty(activityBuilder)) {
          activityBuilder.products = this.products.map(product => {
            if (this.shouldActivityUseCruiseData(activityBuilder) === true && this.useCruiseRateForStumpage === true) {
              const cruiseProduct = this.tractContents.find(tc => tc.productId === product.productId)
              return {
                productId: product.productId,
                productName: product.productName,
                inheritedFromCruise: cruiseProduct !== undefined,
                baseRate: cruiseProduct ? parseFloat(cruiseProduct.pricePerTon) : parseFloat(activityBuilder.baseRate)
              }
            } else {
              const detail = activityBuilder.template.details.find(pd => pd.productId === product.productId)
              return {
                productId: product.productId,
                productName: product.productName,
                baseRate: detail ? parseFloat(detail.rate) : parseFloat(activityBuilder.baseRate)
              }
            }
          })
        } else {
          activityBuilder.products = []
        }
      }

      this.$nextTick(() => {
        this.allBuilders = builders
        this.loading = false
      })
    },

    initializeCounterpartyRuntimeFieldMap () {
      this.runtimeFieldCounterpartyIdMap = new Map()
      const valueOrEmptyArray = (value) => { return !this.nullOrUndefined(value) ? [value] : [] }
      this.runtimeFieldCounterpartyIdMap.set(RuntimeCounterparty.Landowner.value, this.landowners.map(lo => lo.accountId))
      this.runtimeFieldCounterpartyIdMap.set(RuntimeCounterparty.DefaultLogger.value, valueOrEmptyArray(this.tract.loggerAccountId))
      this.runtimeFieldCounterpartyIdMap.set(RuntimeCounterparty.ContractAccount.value, valueOrEmptyArray(this.contractAccount?.accountId))
      this.runtimeFieldCounterpartyIdMap.set(RuntimeCounterparty.Hauler.value, valueOrEmptyArray(this.tract.haulerAccountId))
      this.runtimeFieldCounterpartyIdMap.set(RuntimeCounterparty.Supplier.value, valueOrEmptyArray(this.tract.supplierAccountId))
      this.runtimeFieldCounterpartyIdMap.set(RuntimeCounterparty.DestinationAccount.value, valueOrEmptyArray(this.tractDestination.destinationAccountId))
      this.runtimeFieldCounterpartyIdMap.set(RuntimeCounterparty.ConsultingForester.value, valueOrEmptyArray(this.tract.consultingForesterAccountId))
    },

    builderCanRecover (builder) {
      return (this.builderIsDynamicCounterparty(builder) || (builder.counterparty?.hasAdvances ?? builder.counterpartyHasAdvances ?? true)) &&
        builder.template.category === AccountingCategory.Payable.value &&
        TemplateSpecializationInfo.get(builder.specialization).baseCost !== false &&
        builder.template.specialization !== TemplateSpecialization.Penalty.value
    },

    rcpCanResolve (rcp) {
      if (rcp === RuntimeCounterparty.Driver.value) return true
      const ids = this.runtimeFieldCounterpartyIdMap.get(rcp)
      return ids.length > 0
    },

    addProductDetail (builder, product) {
      const newDetail = {
        productId: product.productId,
        productName: product.productName,
        baseRate: builder.baseRate
      }

      builder.products.push(newDetail)
    },

    removeProductDetail (builder, detail) {
      const builderIndex = this.allBuilders.findIndex(b => b.activityBuilderId === builder.activityBuilderId)
      if (builderIndex === -1) return
      const productIndex = this.allBuilders[builderIndex].products.findIndex(pd => pd.productId === detail.productId)
      if (productIndex === -1) return
      const currentProducts = this.allBuilders[builderIndex].products
      this.allBuilders[builderIndex].products = [
        ...currentProducts.slice(0, productIndex),
        ...currentProducts.slice(productIndex + 1)
      ]
    },

    missingProducts (builder, products) {
      return products.filter(p => !(builder?.products ?? []).some(bp => bp.productId === p.productId))
    },

    baseRateEnteredForProduct ({ amount, itemId }) {
      let [activityBuilderId, productId] = itemId.split('-')
      activityBuilderId = parseInt(activityBuilderId)
      productId = parseInt(productId)

      const activityIndex = this.allBuilders.findIndex(a => a.activityBuilderId === activityBuilderId)

      if (activityIndex !== -1) {
        const productIndex = this.allBuilders[activityIndex].products.findIndex(d => d.productId === productId)

        if (productIndex !== -1) {
          this.allBuilders[activityIndex].products[productIndex].baseRate = amount
        }
      }
    },

    findAccount (accountId) {
      return this.allAccounts[this.accountIndices[accountId]]
    },

    shouldActivityUseCruiseData (activityBuilder) {
      return activityBuilder.category !== 1 && activityBuilder.costBehavior === 2
    },

    getDuplicateCounterparties () {
      const counts = {}
      const duplicates = {}
      for (const builder of this.allBuilders) {
        const activityTemplateId = builder?.activityTemplateId
        let targetCounterpartiesIds = !this.nullOrUndefined(builder.runtimeField) ? this.runtimeFieldCounterpartyIdMap.get(builder.runtimeField) : [builder?.counterparty?.accountId]
        targetCounterpartiesIds ??= []
        targetCounterpartiesIds.forEach(tcp => {
          if (tcp !== undefined) {
            const key = `${activityTemplateId}-${tcp}`
            counts[key] = (counts[key] !== undefined) ? counts[key] + 1 : 1
          }
        })
      }
      Object.keys(counts).forEach(key => {
        if (counts[key] > 1) {
          duplicates[key] = true
        }
      })
      return duplicates
    },

    counterpartyIsDuplicate (activityTemplateId, counterpartyId) {
      if (this.nullOrUndefined(counterpartyId)) return false
      return this.duplicateCounterparties[`${activityTemplateId}-${counterpartyId}`] ?? false
    },

    runtimeFieldResolvesToDuplicateCounterparty (activityTemplateId, runtimeField) {
      if (runtimeField === RuntimeCounterparty.Driver.value) return false
      const ids = this.runtimeFieldCounterpartyIdMap.get(runtimeField)
      return ids.some(id => this.duplicateCounterparties[`${activityTemplateId}-${id}`] ?? false)
    },

    setAdvanceForBuilder (builder, advance) {
      builder.advanceId = advance?.advanceId
    },

    builderIsDynamicCounterparty (builder) {
      return (builder.runtimeField !== undefined && builder.runtimeField !== null)
    },

    defaultRecoveryModeFromAccountingCategory (item) {
      return item.category === AccountingCategory.Payable.value ? RecoveryMode.Oldest : RecoveryMode.None
    },

    isPenalty (item) {
      return TemplateSpecialization.Penalty.value === item.specialization
    },

    isApOrJePenalty (item) {
      return TemplateSpecialization.Penalty.value === item.specialization && [AccountingCategory.Payable.value, AccountingCategory.Accrual.value].includes(item.category)
    },

    showExpansion (builder) {
      const canDefineProductRates = !builder.limitToBaseRate && this.products?.length > 0
      const isInventory = builder.costBehavior !== CostType.NonInventory.value

      const showAdvanceAutoselect = builder.recoveryMode.value === RecoveryMode.Specific.value
      const showProductDetailEdit = canDefineProductRates && isInventory && !this.isApOrJePenalty(builder)

      return showProductDetailEdit || showAdvanceAutoselect
    }
  }
}
</script>

<style scoped>
.scrollable-table {
  max-height: calc(100vh - 400px);
  overflow-y: auto;
}
</style>

<style>
#bundle-activity-definition-table>.v-data-table__wrapper tbody tr.v-data-table__expanded__content {
  background-color:#eee;
  box-shadow: inset 0 0 0 0 #00000000;
  transition: .05s;
}

#bundle-activity-definition-table>.v-data-table__wrapper tbody tr.v-data-table__expanded__content:hover {
  background-color:#e0e0e0;
  box-shadow: inset 0 0 0 0 #00000000;
  transition: .05s;
}
</style>
