import i18n from '@/i18n'
import { utcToLocalDate } from '../utils/DateFormatter'
import { SamsaraIntegrationRecordStatus, SamsaraIntegrationType } from '../utils/Enumerations'

export function samsaraIntegrationHeaders () {
  return [
    {
      sortable: true,
      text: i18n.t('completed'),
      value: 'completedAt',
      format: utcToLocalDate,
      align: 'center'
    },
    {
      sortable: true,
      text: i18n.t('integrationType'),
      value: 'samsaraIntegrationType',
      format: x => SamsaraIntegrationType.fromInt(x)
    },
    {
      sortable: true,
      text: i18n.t('status'),
      value: 'status',
      format: x => SamsaraIntegrationRecordStatus.fromInt(x),
      align: 'center'
    }
  ]
}

export function samsaraIntegrationCustomCells () {
  return [
    {
      slotName: 'status',
      value: 'status'
    },
    {
      slotName: 'completedAt',
      value: 'completedAt'
    },
    {
      slotName: 'integrationType',
      value: 'samsaraIntegrationType'
    }
  ]
}
