<template>
  <v-row justify-center data-testid="tract-table">
    <v-col md="12">
      <material-card
      :title="$t('tracts')"
      :elevation="0"
      full-width
      :text="$t('tractsSubtitle')">
        <v-container fluid class="ma-n2 pa-0">
          <DataTable
          :items.sync="mappedTracts"
          :headers="headers"
          :loading.sync="tractsLoading"
          :customCells="customCells"
          @refresh="$emit('refresh-tracts')"
          @create="emitTableEvent(null, 'new-tract')"
          :actions="tableActions"
          resize
          defaultHeight="calc(100vh - 453px)">
            <template #custom-controls>
              <v-col cols="auto">
                <Icon
                  v-if="deviceIsMobile"
                  icon="mdi-map-marker-radius"
                  :small="false"
                  :tooltipText="$t('identifyTract')"
                  @icon-clicked="$emit('identify-tract')"
                />
              </v-col>
              <v-col cols="auto">
                <TractContextMenu @download-csv="downloadCSV" @download-pt-form="openPtFormDialog"/>
              </v-col>
              <v-col cols="auto">
                <Icon
                @icon-clicked="myTractsClicked"
                :small="false"
                :icon="IconHelpers.getIconForObjectType('myTracts', filter.myTracts)"
                margin="mr-2"
                :tooltipText="filter.myTracts ? $t('allTracts') : $t('myTracts')"
                elementId="my-tracts-tract-table"/>
              </v-col>
              <v-col cols="auto">
                <TractFilter :filter="filter" @filter-applied="applyFilter"/>
              </v-col>
              <v-col cols="auto">
                <Icon
                margin="mr-2"
                :small="false"
                icon="mdi-map"
                iconColor="primary"
                dataTestId="tract-table-map-icon"
                :tooltipText="$t('viewMap')"
                @icon-clicked="interactiveMap = true"/>
              </v-col>
              <v-col cols="auto" class="pr-1">
                <TabbedColumnAdjuster
                  :propColumnSet="columnSet"
                  :propTabs="columnAdjusterTabs"
                  :dynamicSize="deviceIsMobile"
                  @new-columns-selected="newColumnsSelected"
                />
              </v-col>
            </template>
            <template #filters>
              <v-layout wrap class="mt-n4 mb-n2">
                <v-chip-group>
                  <v-chip v-for="f in filtersView"
                  :key="f.name"
                  color="secondary"
                  text-color="white"
                  close @click:close="removeFilter(f)">
                    <span v-if="f.filterType === 'status'">
                      <Icon :icon="TractStatus.forInt(f.status).details.icon" :iconColor="colorForTractStatus(f)"/>
                      {{f.name}}
                    </span>
                    <span v-else>
                      <v-icon small class="mr-1">{{ IconHelpers.getIconForObjectType(f.filterType) }}</v-icon>
                      {{ f.name }}
                    </span>
                  </v-chip>
                </v-chip-group>
              </v-layout>
            </template>
            <template #status="{item}">
              <TractStatusIcon :status="item.status"/>
              <Icon
              v-if="dateIsUnderCountUnitsAway(item.harvestEndDate, 1, 'week')"
              icon="mdi-alert-outline"
              iconColor="warning"
              :tooltipText="$t('tractHarvestWarning1w')"
              />
              <Icon
              v-if="item.paused"
              icon="mdi-pause-circle"
              iconColor="warning"
              :tooltipText="$t('tractPaused')"
              />
            </template>
            <template #name="{item}">
              <span id="table-shortcut" @click="emitTableEvent(item, 'tract-detail')">
                <Icon
                v-if="item.tractCertifications?.length > 0"
                icon="mdi-certificate-outline"
                iconColor="black"
                :tooltipText="$t('tractIsCertified')"
                />
                {{item.name}}
              </span>
            </template>
            <template #land-owner="{item}">
              <span v-if="item.landOwnerAccountName" id="table-shortcut" @click="emitTableEvent(item, 'tract-detail')">
                {{item.landOwnerAccountName}}
              </span>
              <span v-else-if="item.landownerCount > 1" @click="emitTableEvent(item, 'tract-detail')" style="cursor: pointer;">
                <v-tooltip bottom color="black">
                  <template #activator="{on}">
                    <v-icon v-on="on" color="black">
                      mdi-dots-horizontal
                    </v-icon>
                  </template>
                  <span class="subtitle-1 white--text">
                    {{ $t('multipleLandowners') }}
                  </span>
                </v-tooltip>
              </span>
              <span v-else id="table-shortcut" @click="emitTableEvent(item, 'tract-detail')">
                {{ $t('notAvailable') }}
              </span>
            </template>
            <template #default-logger="{item}">
              <AccountName
                :accountId="item.loggerAccountId"
                :certificationStatus="item.loggerAccountCertificationStatus"
              >
                <span id="table-shortcut" @click="emitTableEvent(item, 'tract-detail')">
                  {{item.defaultLoggerName || $t('notAvailable')}}
                </span>
              </AccountName>
            </template>
            <template #forester-user="{item}">
              <span id="table-shortcut" @click="emitTableEvent(item, 'tract-detail')">
                {{item.foresterUserName ?? $t('notAvailable')}}
              </span>
            </template>
            <template #cost="{item}">
              <span id="table-shortcut" @click="emitTableEvent(item, 'tract-detail')">
                {{formatMoney(item.cost)}}
              </span>
            </template>
            <template #advance-balance="{item}">
              <span id="table-shortcut" @click="emitTableEvent(item,'tract-detail')">
                  {{formatMoney(item.advanceBalance)}}
              </span>
            </template>
            <template #harvested-tons="{item}">
              <span>
                {{item.harvestedTons !== 0 ? numberWithCommas(item.harvestedTons, 3) : 0}}
              </span>
            </template>
            <template #expected-tons="{item}">
              <span>
                {{item.expectedTons !== 0 ? numberWithCommas(item.expectedTons, 3) : 0}}
              </span>
            </template>
            <template #purchase-date="{item}">
              <span id="table-shortcut" @click="emitTableEvent(item, 'tract-detail')">
                {{utcToLocalDate(item.purchaseDate)}}
              </span>
            </template>
            <template #bonds="{item}">
              <span id="table-shortcut" @click="emitTableEvent(item, 'tract-detail')">
                {{formatMoney(item.bondBalance)}}
              </span>
            </template>
            <template #harvest-start="{item}">
              <span id="table-shortcut" @click="emitTableEvent(item, 'tract-detail')">
                {{utcToLocalDate(item.harvestStartDate)}}
              </span>
            </template>
            <template #harvest-end="{item}">
              <span id="table-shortcut" @click="emitTableEvent(item, 'tract-detail')">
                {{utcToLocalDate(item.harvestEndDate)}}
              </span>
            </template>
            <template #days-till-harvest-end="{item}">
                <v-tooltip bottom color='#D15F27'>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                    >{{computeDaysRemainingTillHarvestEndForUI(item)}}</span>
                  </template>
                  <span class="subtitle-1 white--text" style="white-space: pre-line">Days</span>
                </v-tooltip>
            </template>
            <template #type="{item}">
              <span id="table-shortcut" @click="emitTableEvent(item, 'tract-detail')">
                {{item.typeName}}
              </span>
            </template>
            <template #category="{item}">
              <span id="table-shortcut" @click="emitTableEvent(item, 'tract-detail')">{{ item.category }}</span>
            </template>
            <template #loggers="{ item }">
              <span id="table-shortcut" @click="emitTableEvent(item, 'tract-detail')">{{ item.defaultLoggerName }}</span>
            </template>
            <template #consulting-forester="{item}">
              <span id="table-shortcut" @click="emitTableEvent(item, 'tract-detail')">{{ item.consultingForesterAccount ?? $t('notAvailable') }}</span>
            </template>
            <template #rainfall="{item}">
              <v-tooltip
              color="primary"
              open-on-hover
              bottom>
                <template v-slot:activator="{on}">
                  <span id="table-shortcut" @click="emitTableEvent(item, 'tract-detail')" v-on="on">
                    <span v-if="item.rainfallDataUpdatedOn">
                      {{ `${item.singleDayRainfallInches}"` }}
                    </span>
                    <span v-else>
                      {{ $t('notAvailable') }}
                    </span>
                  </span>
                </template>
                <span class="subtitle-1 white--text" style="white-space: pre-line">
                  <span v-if="item.rainfallDataUpdatedOn">
                    {{ $t('twentyFourHourPrecipitationUpdatedOn', { updatedOn: tractRainfallUpdated(item) }) }}
                  </span>
                  <span v-else>
                    {{ $t('precipitationDataUnavailable') }}
                  </span>
                </span>
            </v-tooltip>
          </template>
          <template #balance="{item}">
            <v-tooltip
            color="primary"
            open-on-hover
            bottom>
              <template v-slot:activator="{on}">
                <span id="table-shortcut" @click="emitTableEvent(item, 'tract-detail')" v-on="on">
                  <span v-if="item.balanceUpdatedOn">
                    {{ balanceTextForTract(item) }}
                  </span>
                  <span v-else>
                    {{ $t('notAvailable') }}
                  </span>
                </span>
              </template>
              <span class="subtitle-1 white--text" style="white-space: pre-line">
                <span v-if="item.balanceUpdatedOn">
                  {{ $t('balanceLastUpdated', { updatedOn: dateStringToLocal(item.balanceUpdatedOn) }) }}
                </span>
                <span v-else>
                  {{ $t('tractDoesNotUseDepletion') }}
                </span>
              </span>
            </v-tooltip>
          </template>
          <template #costBalance="{item}">
            <v-tooltip
            color="primary"
            open-on-hover
            bottom>
              <template v-slot:activator="{on}">
                <span id="table-shortcut" @click="emitTableEvent(item, 'tract-detail')" v-on="on">
                  <span v-if="item.balanceUpdatedOn">
                    {{ costBalanceTextForTract(item) }}
                  </span>
                  <span v-else>
                    {{ $t('notAvailable') }}
                  </span>
                </span>
              </template>
              <span class="subtitle-1 white--text" style="white-space: pre-line">
                <span v-if="item.balanceUpdatedOn">
                  {{ $t('balanceLastUpdated', { updatedOn: dateStringToLocal(item.balanceUpdatedOn) }) }}
                </span>
                <span v-else>
                  {{ $t('tractDoesNotUseDepletion') }}
                </span>
              </span>
            </v-tooltip>
          </template>
          <template #related="slotProps">
            <Icon
            margin="mr-2"
            :small="false"
            icon="mdi-information-outline"
            dataTestId="tract-detail"
            :tooltipText="$t('tractDetail')"
            @icon-clicked="emitTableEvent(slotProps.item, 'tract-detail')"/>
          </template>
          <template #actions="slotProps">
            <Icon
            :icon="slotProps.item.paused ? 'mdi-pause-circle' : 'mdi-pause-circle-outline'"
            iconColor="black"
            dataTestId="tract-pause"
            :tooltipText="slotProps.item.paused ? $t('unpauseTract') : $t('pauseTract')"
            @icon-clicked="emitTableEvent(slotProps.item, 'pause-tract')"
            :disabled="!userAssignedClaim(UserClaims.ContractManager)"
            />
            <Icon
            icon="mdi-pencil"
            iconColor="success"
            dataTestId="tract-edit"
            :tooltipText="$t('edit')"
            @icon-clicked="emitTableEvent(slotProps.item, 'edit-tract')"
            :disabled="!userAssignedClaim(UserClaims.ContractManager)"/>
            <Icon
            icon="mdi-delete-forever"
            iconColor="error"
            dataTestId="tract-delete"
            :tooltipText="$t('delete')"
            @icon-clicked="emitTableEvent(slotProps.item, 'delete-tract')"
            :disabled="!userAssignedClaim(UserClaims.ContractManager)"/>
          </template>
          <template #team="{item}">
            <v-row no-gutters>
              <v-col cols="auto">
                <span>{{ item.teamName ?? $t('notAvailable') }}</span>
              </v-col>
              <v-col cols="auto">
                <Icon
                v-if="!!item.teamName"
                iconColor="black"
                icon="mdi-dots-horizontal"
                margin="ml-1"
                :tooltipText="getTeamTooltip(item.users)"
                />
              </v-col>
            </v-row>
          </template>
        </DataTable>
      </v-container>
      </material-card>
      <v-dialog v-model="ptFormDialog" width="50vw">
        <TractPtFormDownload @close-form="ptFormDialog = false" v-if="ptFormDialog"/>
      </v-dialog>
      <v-dialog
      v-model="interactiveMap"
      max-width="80vw"
      :fullscreen="$vuetify.breakpoint.smAndDown">
        <InteractiveMap
        :height="$vuetify.breakpoint.smAndDown ? '100%' : '80vh'"
        :tracts.sync="mappedTracts"
        v-if="interactiveMap"
        includeSearchByTract
        :propFilter="filter"
        @filter-applied="applyFilter"
        @map-closed="interactiveMap = false"/>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { TractFilter } from '@/model/Tract.js'
import { mapGetters } from 'vuex'
import { TractTypeCategory, TractStatus, UserClaims } from '@/utils/Enumerations.js'
import { saveAs } from 'file-saver'
import moment from 'moment'
import { numberWithCommas, formatMoney } from '@/utils/NumericMutations'
import { utcToLocalDate, timeBetween, dateIsUnderCountUnitsAway } from '@/utils/DateFormatter.js'
import { generateCsvString } from '@/utils/CSVUtility'
import TractHeaders from '@/headers/Tract'
import { userAssignedClaim } from '@/utils/ClaimUtility.js'
import { isMobile } from '@/utils/DeviceUtility.js'
import { LocalStorageKeys } from '@/utils/LocalStorageActor'
import IconHelpers from '@/utils/IconHelpers'

export default {
  name: 'TractTable',

  components: {
    InteractiveMap: () => import('@/components/maps/InteractiveMap.vue'),
    TractFilter: () => import('@/components/tract/TractFilter.vue'),
    TractContextMenu: () => import('@/components/tract/TractContextMenu.vue'),
    TractPtFormDownload: () => import('@/components/tract/tract-detail/TractPtFormDownload.vue'),
    Icon: () => import('@/components/helper/Icon.vue'),
    TabbedColumnAdjuster: () => import('@/components/helper/TabbedColumnAdjuster.vue'),
    DataTable: () => import('@/components/core/table/DataTable.vue'),
    TractStatusIcon: () => import('@/components/tract/TractStatusIcon.vue'),
    AccountName: () => import('@/components/account/AccountName.vue')
  },

  props: {
    dialogOpen: Boolean,
    propFilter: Object
  },

  data: () => ({
    TractStatus,
    IconHelpers,
    mapView: false,
    interactiveMap: false,
    ptFormDialog: false,
    columnSet: null,
    filter: {
      status: [], // Active status has value 3.
      selectedTractTypes: [],
      selectedHarvestTypes: [],
      selectedLoggingMethods: [],
      selectedForesters: [],
      selectedTeams: [],
      myTracts: false
    },
    UserClaims
  }),

  computed: {
    ...mapGetters('tract', ['allTracts', 'tractsLoading']),
    ...mapGetters('user', ['userInfo']),

    headers () {
      if (!this.columnSet) return []
      return this.columnSet.getColumns()
    },

    tableActions () {
      return [
        {
          actionName: 'refresh',
          icon: 'mdi-refresh',
          text: this.$t('refresh')
        },
        {
          actionName: 'create',
          icon: 'mdi-plus',
          text: this.$t('new'),
          disabled: !userAssignedClaim(UserClaims.ContractManager)
        }
      ]
    },

    columnAdjusterTabs () {
      return ['properties', 'location', 'analysis']
    },

    mappedTracts () {
      const passesMyTracts = (t) => !this.filter.myTracts ||
          t.foresterUser?.applicationUserId === this.userInfo.applicationUserId ||
          t.users?.some(u => u.applicationUserId === this.userInfo.applicationUserId)
      const passesHarvestTypes = (t) => this.filter.selectedHarvestTypes.length === 0 || this.filter.selectedHarvestTypes.map(ht => ht.harvestTypeId).includes(t.harvestType?.harvestTypeId)
      const passesLoggingMethods = (t) => this.filter.selectedLoggingMethods.length === 0 || this.filter.selectedLoggingMethods.map(lm => lm.loggingMethodId).includes(t.loggingMethod?.loggingMethodId)

      return this.allTracts
        .filter(passesMyTracts)
        .filter(passesHarvestTypes)
        .filter(passesLoggingMethods)
        .map(t => ({
          ...t,
          harvestType: t.harvestType?.name,
          loggingMethod: t.loggingMethod?.name,
          landownerBundle: JSON.stringify({
            loAccount: t.landOwnerAccountName,
            loCount: t.landownerCount
          }),
          costBalance: Math.max(0, t.balance)
        }))
    },

    customCells () {
      return [
        {
          slotName: 'status',
          value: 'status'
        },
        {
          slotName: 'name',
          value: 'name'
        },
        {
          slotName: 'default-logger',
          value: 'defaultLoggerName'
        },
        {
          slotName: 'forester-user',
          value: 'foresterUserName'
        },
        {
          slotName: 'category',
          value: 'category'
        },
        {
          slotName: 'land-owner',
          value: 'landownerBundle'
        },
        {
          slotName: 'cost',
          value: 'cost'
        },
        {
          slotName: 'advance-balance',
          value: 'advanceBalance'
        },
        {
          slotName: 'type',
          value: 'typeName'
        },
        {
          slotName: 'loggers',
          value: 'loggers'
        },
        {
          slotName: 'harvested-tons',
          value: 'harvestedTons'
        },
        {
          slotName: 'expected-tons',
          value: 'expectedTons'
        },
        {
          slotName: 'purchase-date',
          value: 'purchaseDate'
        },
        {
          slotName: 'bonds',
          value: 'bondBalance'
        },
        {
          slotName: 'harvest-start',
          value: 'harvestStartDate'
        },
        {
          slotName: 'harvest-end',
          value: 'harvestEndDate'
        },
        {
          slotName: 'days-till-harvest-end',
          value: 'millisecondsTillHarvestEnd'
        },
        {
          slotName: 'actions',
          value: 'actions'
        },
        {
          slotName: 'related',
          value: 'related'
        },
        {
          slotName: 'consulting-forester',
          value: 'consultingForesterAccount'
        },
        {
          slotName: 'rainfall',
          value: 'singleDayRainfallInches'
        },
        {
          slotName: 'balance',
          value: 'balance'
        },
        {
          slotName: 'team',
          value: 'teamName'
        },
        {
          slotName: 'costBalance',
          value: 'costBalance'
        }
      ]
    },

    filtersView () {
      let result = []
      if (this.filter.myTracts) result = result.concat({ filterType: 'myTracts', name: this.$t('myTracts') })
      const statuses = this.filter.status.map(s => ({ filterType: 'status', name: TractStatus.fromInt(s), status: s }))
      const tractTypes = this.filter.selectedTractTypes.map(tt => ({ filterType: 'tractType', name: tt.name }))
      const harvestTypes = this.filter.selectedHarvestTypes.map(ht => ({ filterType: 'harvestType', name: ht.name }))
      const loggingMethods = this.filter.selectedLoggingMethods.map(lm => ({ filterType: 'loggingMethod', name: lm.name }))
      const foresters = this.filter.selectedForesters.map(f => ({ filterType: 'forester', name: f.name }))
      const teams = this.filter.selectedTeams.map(t => ({ filterType: 'team', name: t.name }))
      result = result.concat(statuses)
        .concat(tractTypes)
        .concat(harvestTypes)
        .concat(loggingMethods)
        .concat(foresters)
        .concat(teams)
      return result
    },

    deviceIsMobile () {
      return isMobile()
    }
  },

  created () {
    this.initializeHeaders()
    this.setFilter()
  },

  methods: {
    categoryFromInt: (x) => TractTypeCategory.fromInt(x),
    userAssignedClaim,
    numberWithCommas,
    formatMoney,
    utcToLocalDate,
    timeBetween,
    dateIsUnderCountUnitsAway,

    initializeHeaders () {
      this.columnSet = TractHeaders.tractHeaders()
    },

    newColumnsSelected (columnSet) {
      this.columnSet = columnSet
    },

    downloadCSV () {
      const csvString = generateCsvString(this.allTracts, [
        { label: 'status', value: tract => TractStatus.fromInt(tract.status) || undefined },
        { label: 'name', value: tract => tract.name },
        { label: 'category', value: tract => tract.type ? this.categoryFromInt(tract.type.category) : undefined },
        { label: 'tractType', value: tract => tract.type?.name },
        { label: 'harvestType', value: tract => tract.harvestType?.name },
        { label: 'loggingMethod', value: tract => tract.loggingMethod?.name },
        { label: 'landOwner', value: tract => tract.landownerCount > 1 ? 'M' : tract.landOwnerAccountName || '' },
        { label: 'logger', value: tract => tract.defaultLoggerName },
        { label: 'team', value: tract => tract.teamName },
        { label: 'forester', value: tract => tract.foresterUserName },
        { label: 'consultingForester', value: tract => tract.consultingForesterAccount },
        { label: 'cost', value: tract => `$${tract.cost}` },
        { label: 'costBalance', value: tract => tract.balanceUpdatedOn ? `$${Math.max(tract.balance, 0)}` : this.$t('notAvailable') },
        { label: 'depletionBalance', value: tract => tract.balanceUpdatedOn ? `$${tract.balance}` : this.$t('notAvailable') },
        { label: 'advanceBalance', value: tract => `$${tract.advanceBalance}` },
        { label: 'harvestedTons', value: tract => tract.harvestedTons },
        { label: 'expectedTons', value: tract => tract.expectedTons },
        { label: 'cutoutPercentage', value: tract => tract.harvestPercentage },
        { label: 'rpi', value: tract => tract.rpi },
        { label: 'dbh', value: tract => tract.dbh },
        { label: 'age', value: tract => tract.age },
        { label: 'loggingAccessibility', value: tract => tract.loggingAccessibilityTag },
        { label: 'precipitationInInches', value: tract => tract.rainfallDataUpdatedOn ? tract.singleDayRainfallInches : this.$t('notAvailable') },
        { label: 'acres', value: tract => tract.acres },
        { label: 'harvestedTonsPerAcre', value: tract => tract.harvestedTonsPerAcre },
        { label: 'county', value: tract => tract.spot.countrySecondarySubdivision },
        { label: 'state', value: tract => tract.spot.countrySubdivision },
        { label: 'purchaseDate', value: tract => tract.purchaseDate ? utcToLocalDate(tract.purchaseDate) : undefined },
        { label: 'bonds', value: tract => `$${tract.bondBalance}` },
        { label: 'harvestStart', value: tract => tract.harvestStartDate ? utcToLocalDate(tract.harvestStartDate) : undefined },
        { label: 'harvestEnd', value: tract => tract.harvestEndDate ? utcToLocalDate(tract.harvestEndDate) : undefined },
        { label: 'daysRemainingUntilHarvestEnd', value: tract => tract.harvestEndDate ? this.computeDaysRemainingTillHarvestEndForCSV(tract) : undefined }
      ], { emptyValue: this.$t('notAvailable') })
      const blob = new Blob([csvString], { type: 'text/plain;charset=utf-8' })
      saveAs(blob, `Tracts-${moment().format()}.csv`)
    },

    openPtFormDialog () {
      this.ptFormDialog = true
    },

    getCertificationString (tract) {
      const certifications = tract.certifications

      switch (certifications.length) {
        case 0:
          return this.$t('none')
        case 1:
          return certifications[0].code === '' ? this.$t('notAvailable') : certifications[0].code
        default:
          return this.$t('multiple')
      }
    },

    emitTableEvent (tract, type) {
      const event = {
        actionType: type,
        tract: tract
      }

      this.$emit('tract-action', event)
    },

    emitFilter () {
      const newFilter = new TractFilter()
      newFilter.includeInitiated = this.filter.status.includes(0)
      newFilter.includeCruised = this.filter.status.includes(1)
      newFilter.includeExecuted = this.filter.status.includes(2)
      newFilter.includeActive = this.filter.status.includes(3)
      newFilter.includeClosed = this.filter.status.includes(4)
      newFilter.includeArchived = this.filter.status.includes(5)
      newFilter.selectedForesters = this.filter.selectedForesters
      newFilter.selectedTractTypes = this.filter.selectedTractTypes
      newFilter.selectedTeams = this.filter.selectedTeams
      this.$emit('filter-applied', newFilter)
    },

    applyFilter (filter) {
      this.filter = filter
      this.emitFilter()
    },

    computeDaysRemainingTillHarvestEndForUI (tract) {
      if (tract.millisecondsTillHarvestEnd <= 0) {
        return '0'
      }
      if (tract.millisecondsTillHarvestEnd <= moment.duration(1, 'day').asMilliseconds()) {
        return '1'
      }
      if (tract.millisecondsTillHarvestEnd >= moment.duration(30, 'day').asMilliseconds()) {
        return '30+'
      }
      return timeBetween(Date.now(), tract.harvestEndDate).split(' ')[0]
    },

    computeDaysRemainingTillHarvestEndForCSV (tract) {
      if (tract.millisecondsTillHarvestEnd <= 0) {
        return '0'
      }
      if (tract.millisecondsTillHarvestEnd <= moment.duration(1, 'day').asMilliseconds()) {
        return '1'
      }
      return timeBetween(Date.now(), tract.harvestEndDate).split(' ')[0]
    },

    removeFilter (toRemove) {
      switch (toRemove.filterType) {
        case 'status':
          this.filter.status = this.filter.status.filter(s => TractStatus.fromInt(s) !== toRemove.name)
          localStorage.setItem(LocalStorageKeys.TRACT_STATUS_FILTER, this.filter.status)
          break
        case 'tractType':
          this.filter.selectedTractTypes = this.filter.selectedTractTypes.filter(tt => tt.name !== toRemove.name)
          break
        case 'harvestType':
          this.filter.selectedHarvestTypes = this.filter.selectedHarvestTypes.filter(ht => ht.name !== toRemove.name)
          break
        case 'loggingMethod':
          this.filter.selectedLoggingMethods = this.filter.selectedLoggingMethods.filter(lm => lm.name !== toRemove.name)
          break
        case 'forester':
          this.filter.selectedForesters = this.filter.selectedForesters.filter(f => f.name !== toRemove.name)
          break
        case 'team':
          this.filter.selectedTeams = this.filter.selectedTeams.filter(t => t.name !== toRemove.name)
          break
        case 'myTracts':
          this.filter.myTracts = false
          localStorage.setItem(LocalStorageKeys.TRACT_FILTER_MY_TRACTS, this.filter.myTracts)
          break
      }
      this.emitFilter()
    },

    dateStringToLocal (field) {
      return moment.utc(field).local().format('L LT')
    },

    tractRainfallUpdated (tract) {
      return tract
        ? moment.utc(tract.rainfallDataUpdatedOn).local().format('L LT')
        : undefined
    },

    setFilter () {
      if (this.propFilter.includeInitiated) { this.filter.status.push(0) }
      if (this.propFilter.includeCruised) { this.filter.status.push(1) }
      if (this.propFilter.includeExecuted) { this.filter.status.push(2) }
      if (this.propFilter.includeActive) { this.filter.status.push(3) }
      if (this.propFilter.includeClosed) { this.filter.status.push(4) }
      if (this.propFilter.includeArchived) { this.filter.status.push(5) }
      this.filter.selectedForesters = this.propFilter.selectedForesters
      this.filter.selectedTractTypes = this.propFilter.selectedTractTypes
      this.filter.selectedTeams = this.propFilter.selectedTeams
      this.filter.myTracts = localStorage.getItem(LocalStorageKeys.TRACT_FILTER_MY_TRACTS) === 'true'
    },

    balanceTextForTract (tract) {
      return tract.type.depletes ? formatMoney(tract.balance) : this.$t('notAvailable')
    },

    costBalanceTextForTract (tract) {
      return tract.type.depletes ? formatMoney(tract.costBalance) : this.$t('notAvailable')
    },

    getTeamTooltip (tractUsers) {
      return tractUsers
        .map((u) => u.name)
        .toString()
        .replaceAll(',', '\n')
    },

    myTractsClicked () {
      this.filter.myTracts = !this.filter.myTracts
      localStorage.setItem(LocalStorageKeys.TRACT_FILTER_MY_TRACTS, this.filter.myTracts)
    },

    colorForTractStatus ({ status }) {
      const color = TractStatus.forInt(status).details.color
      if (color !== 'secondary') return color
      return 'white'
    }
  }
}
</script>
