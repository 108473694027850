<template>
  <v-row>
    <v-col cols="12">
      <v-tabs class="card-tabs" v-model="tab" touchless>
        <v-tab>
          {{ $t('configuration') }}
          <v-spacer/>
          <div class="ml-2">
            <Icon
              :iconColor="tab === 0 ? 'success' : '#aaa'"
              :tooltipColor="tab === 0 ? 'success' : '#aaa'"
              icon="mdi-pencil"
              :tooltipText="$t('editConfig')"
              :disabled="readonly"
              @icon-clicked="openCompanyDialog"
            />
          </div>
        </v-tab>
        <v-tab v-if="showSamsara">
          {{ $t('samsara') }}
          <v-spacer/>
          <div class="ml-2">
            <Icon
              iconColor="success"
              icon="mdi-pencil"
              :tooltipText="$t('editSamsaraConfig')"
              :disabled="readonly || tab === 0"
              @icon-clicked="openSamsaraDialog"
            />
            <Icon
              :iconColor="samsaraHalted ? 'error' : 'primary'"
              :icon="samsaraHalted ? 'mdi-alert-outline' : 'mdi-history'"
              :small="false"
              :tooltipText="samsaraHalted ? $t('integrationHalted') : $t('integrationRequests')"
              @icon-clicked="openFailuresDialog"
              :disabled="samsaraIconsDisabled"
            />
            <Icon
              :iconColor="tab === 1 ? 'black' : '#aaa'"
              icon="mdi-cloud-sync"
              :small="false"
              :tooltipText="$t('syncProductAndAccountTags')"
              :disabled="samsaraIconsDisabled"
              @icon-clicked="trySyncTags"/>
          </div>
        </v-tab>
      </v-tabs>
      <v-card style="border-radius: 0px 4px 4px 4px" outlined>
          <v-window v-model="tab">
            <v-window-item>
              <LoadableComponent :loading="loading" :height="450">
                <v-col cols="12">
                <v-container>
                  <v-row>
                    <v-col cols="6">
                      {{ $t('companyTimezone') }}:
                    </v-col>
                    <v-spacer/>
                    <v-col align="right" cols="6">
                      <span v-if="!config.selectedTimeZone" id="table-shortcut" @click="openCompanyDialog">
                        {{ $t('notSet') }}
                        <Icon
                        icon="mdi-alert-circle-outline"
                        icon-color="error"
                        :tooltipText="$t('clickToSet')"
                        @icon-clicked="openCompanyDialog"/>
                      </span>
                      <span v-else>
                        {{ config.selectedTimeZone?.name }}
                      </span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      {{ $t('financialIntegration') }}:
                    </v-col>
                    <v-spacer/>
                    <v-col align="right" cols="6">
                      {{ config.financialIntegrationType?.name }}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      {{ $t('adjustingEntriesFrequency') }}:
                    </v-col>
                    <v-spacer/>
                    <v-col align="right" cols="6">
                      {{ config.adjustingEntriesFrequency?.name }}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      {{ $t('dailyTicketReportTime') }}:
                    </v-col>
                    <v-spacer/>
                    <v-col align="right" cols="6">
                      {{ moment(config.localDailyTicketReportHour, 'H').format('LT') }}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      {{ $t('replicateBiData') }}:
                    </v-col>
                    <v-spacer/>
                    <v-col align="right" cols="6">
                      <Icon
                      :icon="config.performSecondaryBiPump ? 'mdi-checkbox-marked-circle' : 'mdi-close'"
                      :iconColor="config.performSecondaryBiPump ? 'success' : 'error'"/>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="8">
                      {{ $t('requireApprovalForContractModifications') }}:
                    </v-col>
                    <v-spacer/>
                    <v-col align="right" cols="4">
                      <Icon
                      :icon="config.requireApprovalForContractModifications ? 'mdi-checkbox-marked-circle' : 'mdi-close'"
                      :iconColor="config.requireApprovalForContractModifications ? 'success' : 'error'"/>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      {{ $t('recurringAccountPaymentsCreated') }}:
                    </v-col>
                    <v-spacer/>
                    <v-col cols="6" align="right">
                      {{ recurringAccountPaymentsString }}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      {{ $t('integrateWithSamsara') }}:
                    </v-col>
                    <v-spacer/>
                    <v-col align="right" cols="6">
                      <Icon
                      :icon="config.samsaraIntegration ? 'mdi-checkbox-marked-circle' : 'mdi-close'"
                      :iconColor="config.samsaraIntegration ? 'success' : 'error'"/>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
              </LoadableComponent>
            </v-window-item>
            <v-window-item>
              <LoadableComponent :loading="samsaraLoading" :height="200">
                <v-col cols="12">
                  <v-container>
                    <v-row>
                      <v-col cols="2">
                        {{ $t('apiToken') }}:
                      </v-col>
                      <v-spacer/>
                      <v-col cols="10" align="right">
                        <span v-if="missingSamsaraApiKey">
                          <span id="table-shortcut" @click="openSamsaraDialog">
                            {{ $t('notSet') }}
                          </span>
                          <Icon
                          icon="mdi-alert-circle-outline"
                          icon-color="error"
                          :tooltipText="$t('clickToSet')"
                          @icon-clicked="openSamsaraDialog"/>
                        </span>
                        <span v-else type="password">
                          {{ apiKeyText }}
                        </span>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        {{ $t('trailerIdPrefix1') }}:
                      </v-col>
                      <v-spacer/>
                      <v-col cols="6" align="right">
                        <span v-if="missingSamsaraTrailerIdPrefix1">
                          <span id="table-shortcut" @click="openSamsaraDialog">
                            {{ $t('notSet') }}
                          </span>
                          <Icon
                          icon="mdi-alert-circle-outline"
                          icon-color="error"
                          :tooltipText="$t('clickToSet')"
                          @icon-clicked="openSamsaraDialog"/>
                        </span>
                        <span v-else>
                          {{ samsaraConfiguration.samsaraTrailerIdPrefix1 }}
                        </span>
                      </v-col>
                    </v-row>
                    <v-row v-if="samsaraConfiguration.samsaraTrailerIdPrefix2">
                      <v-col cols="6">
                        {{ $t('trailerIdPrefix2') }}:
                      </v-col>
                      <v-spacer/>
                      <v-col cols="6" align="right">
                        {{ samsaraConfiguration.samsaraTrailerIdPrefix2 }}
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="4">
                        {{ $t('samsaraContact') }}:
                      </v-col>
                      <v-spacer/>
                      <v-col align="right" cols="8">
                        <span v-if="!samsaraConfiguration.samsaraContact?.person?.firstName">
                          <span id="table-shortcut" @click="openSamsaraDialog">
                          {{ $t('notSet') }}
                          </span>
                          <span>
                            <Icon
                            icon="mdi-alert-circle-outline"
                            icon-color="error"
                            :tooltipText="$t('clickToSet')"
                            @icon-clicked="openSamsaraDialog"/>
                          </span>
                        </span>
                        <span v-else>
                          {{ samsaraConfiguration.samsaraContact?.person?.firstName }} {{ samsaraConfiguration.samsaraContact?.person?.lastName }}
                          <Icon
                          icon="mdi-phone"
                          iconColor="info"
                          :tooltipText="formattedPhoneNumber"/>
                          <Icon
                          iconColor="info"
                          icon="mdi-email"
                          :tooltipText="samsaraConfiguration.samsaraContact?.person?.email"/>
                        </span>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="4">
                        {{ $t('status') }}:
                      </v-col>
                      <v-spacer/>
                      <v-col cols="8" align="right">
                        <span>
                          <span :id="samsaraInterrupted ? 'table-shortcut' : ''" @click="samsaraInterrupted ? openFailuresDialog() : () => {}">
                          {{ samsaraStatus?.name ?? $t('notAvailable')}}
                          </span>
                          <Icon
                          v-if="samsaraInterrupted"
                          @icon-clicked="openFailuresDialog"
                          icon="mdi-alert"
                          iconColor="error"
                          :tooltipText="$t('integrationHalted')"/>
                        </span>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
              </LoadableComponent>
            </v-window-item>
          </v-window>
        </v-card>
    </v-col>
    <Dialog :stateId="integrationsDialogId" @dialog-closing="closeDialog" @close="closeDialog">
      <IntegrationRecords
      v-if="integrationFailureDialog"
      :readonly="readonly"
      :integrationStatus="SamsaraIntegrationStatus.forInt(samsaraConfiguration.integrationStatus)"
      :integrationStatusReason="SamsaraIntegrationStatusReason.forInt(samsaraConfiguration.integrationStatusReason)"
      :unsavedChanges="unsavedChanges"
      @patchIntegration="patchIntegration"/>
      <CompanyConfigForm
      v-if="editingConfig"
      @close="closeDialog"
      :propOptions="config"
      @done-editing="doneEditing"/>
      <SamsaraConfigForm
      :propOptions="samsaraConfiguration"
      v-if="editingSamsara"
      @close="closeDialog"
      @done-editing="doneEditingSamsara"
      :isEditing="samsaraConfigExists"/>
    </Dialog>
  </v-row>
</template>

<script>
import moment from 'moment'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { uniqueDialogId } from '@/utils/componentHelpers'
import { SamsaraIntegrationStatus, SamsaraIntegrationStatusReason } from '../../../utils/Enumerations'
export default {
  name: 'CompanyOptions',

  components: {
    Icon: () => import('@/components/helper/Icon.vue'),
    CompanyConfigForm: () => import('./CompanyConfigForm.vue'),
    Dialog: () => import('@/components/Dialog.vue'),
    SamsaraConfigForm: () => import('./SamsaraConfigForm.vue'),
    IntegrationRecords: () => import('./IntegrationRecords.vue'),
    LoadableComponent: () => import('@/components/helper/LoadableComponent.vue')
  },

  props: {
    readonly: {
      type: Boolean,
      default: false
    },
    config: {
      type: Object,
      required: true
    },
    samsaraHalted: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    unsavedChanges: {
      type: Boolean,
      required: true
    }
  },

  data: () => ({
    SamsaraIntegrationStatus,
    SamsaraIntegrationStatusReason,
    tab: 0,
    editingConfig: false,
    editingSamsara: false,
    hideKey: true,
    integrationFailureDialog: false,
    integrationsDialogId: uniqueDialogId('companyInfoDialog'),
    samsaraConfigExists: false,
    samsaraLoading: false
  }),

  watch: {
    async tab (tab) {
      const refreshSamsara = (this.samsaraConfigExists && tab === 1)
      if (refreshSamsara) {
        await this.refreshSamsara()
      }
    },

    loading (val, oldVal) {
      if (!oldVal) return
      this.samsaraConfigExists = this.config.samsaraIntegration
    }
  },

  created () {
    this.samsaraConfigExists = this.config.samsaraIntegration
  },

  computed: {
    ...mapGetters('samsara', ['samsaraConfiguration']),
    showSamsara () {
      return this.config.samsaraIntegration && !this.readonly
    },

    missingSamsaraApiKey () {
      return !this.samsaraConfiguration.apiTokenSuffix
    },

    missingSamsaraTrailerIdPrefix1 () {
      return !this.samsaraConfiguration.samsaraTrailerIdPrefix1
    },

    apiKeyText () {
      const numBullets = 41
      const prefix = this.samsaraConfiguration.apiTokenSuffix
      return `${'\u2022'.repeat(numBullets)}${prefix}`
    },

    recurringAccountPaymentsString () {
      if (this.config.accountPayableTemplateDayOfWeek == null) return this.$t('notAvailable')
      const time = moment(this.config.accountPayableTemplateLocalHour, 'H').format('LT')
      const dayOfWeek = this.config.accountPayableTemplateDayOfWeek?.name
      return this.$t('pluralAtTime', { object: dayOfWeek, time })
    },

    formattedPhoneNumber () {
      const number = this.samsaraConfiguration.samsaraContact?.person?.phoneNumber
      return /^\d{3}\d{3}\d{4}/.test(number) ? number.replace(/(\d{3})(\d{3})(\d{4})/, '($1)-$2-$3') : number
    },

    samsaraIconColor () {
      if (this.samsaraHalted) return 'error'
      return this.tab === 1 ? 'primary' : '#aaa'
    },

    samsaraIconsDisabled () {
      return this.tab === 0 || this.readonly || this.samsaraConfiguration.integrationStatus == null
    },

    samsaraStatus () {
      return SamsaraIntegrationStatus.forInt(this.samsaraConfiguration.integrationStatus)
    },

    samsaraInterrupted () {
      return this.samsaraStatus === SamsaraIntegrationStatus.Interrupted
    }
  },

  methods: {
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),
    ...mapActions('samsara', ['fetchSamsaraConfiguration',
      'createSamsaraConfiguration',
      'updateSamsaraConfiguration',
      'syncTags',
      'patchSamsaraConfiguration',
      'setSamsaraConfiguration']),
    ...mapMutations('samsara', ['mutateSamsaraConfiguration']),
    moment,
    doneEditing (config) {
      if (config.samsaraIntegration && !this.config.samsaraIntegration) this.tab = 1
      this.closeDialog()
      this.$emit('config-changed', config)
    },
    doneEditingSamsara (samsara) {
      this.mutateSamsaraConfiguration(samsara)
      this.closeDialog()
    },
    closeDialog () {
      this.integrationFailureDialog = false
      this.editingConfig = false
      this.editingSamsara = false
      this.closeDialogsAtOrAbove(this.integrationsDialogId)
    },
    openFailuresDialog () {
      this.integrationFailureDialog = true
      this.openOrUpdateDialog({ id: this.integrationsDialogId, width: '35vw' })
    },
    openSamsaraDialog () {
      this.editingSamsara = true
      this.openOrUpdateDialog({ id: this.integrationsDialogId, width: '500' })
    },
    openCompanyDialog () {
      this.editingConfig = true
      this.openOrUpdateDialog({ id: this.integrationsDialogId, width: '500' })
    },
    async refreshSamsara () {
      this.samsaraLoading = true
      await this.fetchSamsaraConfiguration()
      this.samsaraLoading = false
    },
    async trySyncTags () {
      if (this.unsavedChanges) {
        this.setSnackError(this.$t('mustSaveChangesBeforeSync'))
        return
      }
      await this.syncTags()
    },
    async patchIntegration () {
      const body = [{
        op: 'add',
        path: '/integrationstatus',
        value: SamsaraIntegrationStatus.Active.value
      }]

      await this.patchSamsaraConfiguration(body)
      await this.refreshSamsara()
      this.$emit('refresh-companyInfo')
    }
  }
}

</script>
