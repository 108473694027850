<template>
  <div
  class="loadable-component"
  :style="{
    width: loaderWidth && loading ? loaderWidth.toString().concat('px') : '100%',
    height: loaderHeight && loading ? loaderHeight.toString().concat('px') : '100%'
  }">
    <slot v-if="!loading"/>
    <v-skeleton-loader
      v-else-if="!invisible"
      :class="'fill-height fill-width '.concat(loaderClass)"
      :type="loaderType"
      :width="loaderWidth"
      :height="loaderHeight"
    />
  </div>
</template>

<script>
export default {
  name: 'LoadableComponent',

  props: {
    loading: {
      type: Boolean,
      required: true
    },
    invisible: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      required: false,
      default: 'input' // Autocomplete, text field, select
    },
    height: {
      type: Number,
      required: false,
      default: undefined
    },
    width: {
      type: Number,
      required: false,
      default: undefined
    }
  },

  computed: {
    loaderType () {
      switch (this.type) {
        case 'stats-card-value': return 'chip'
        case 'button':
        case 'input':
        case 'fill':
        default: return 'image' // The v-skeleton-loader "image" type is a simple rectangle (https://v2.vuetifyjs.com/en/api/v-skeleton-loader/#props-type)
      }
    },

    loaderHeight () {
      if (this.height) return this.height
      switch (this.type) {
        case 'stats-card-value': return 32
        case 'card-text': return 18
        case 'subtitle-1': return 28
        case 'icon-med':
        case 'button': return 36
        case 'input': return 70
        case 'input-dense': return 58
        case 'fill':
        default: return undefined
      }
    },

    loaderWidth () {
      if (this.width) return this.width
      switch (this.type) {
        case 'button':
        case 'input':
        case 'fill':
        default: return undefined
      }
    },

    loaderClass () {
      switch (this.type) {
        case 'dashboard-widget': return 'rounded-lg'
        default: return undefined
      }
    }
  }
}
</script>

<style>
.loadable-component .v-skeleton-loader>div{
  height: 100%;
  width: 100%;
}
</style>
