<template>
  <GridChartCard
  :title="title"
  ref="chartCard"
  :hasData="!noDataText"
  @download-csv="exportCSV"
  @draw="createChart"
  >
    <template #actions>
      <Icon
        iconColor="white"
        :tooltipText="isPlf ? $t('viewAverageSED') : $t('viewPlf')"
        :small="false"
        :disabled="noDataText"
        @icon-clicked="isPlf = !isPlf"
        icon="mdi-information-outline"
        elementId="toggle-plf-sed"
      />
    </template>
  </GridChartCard>
</template>

<script>
import { Chart } from 'chart.js'
import { saveAs } from 'file-saver'
import moment from 'moment'
import { LocalStorageKeys } from '@/utils/LocalStorageActor'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'LogQualityAnalysisChart',

  components: {
    GridChartCard: () => import('./GridChartCard.vue'),
    Icon: () => import('@/components/helper/Icon.vue')
  },

  props: {
    logQualityAnalysis: {
      type: Array,
      required: true
    },
    elementId: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    selectedLocation: {
      type: Object
    },
    isInteractive: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: () => ({
    chart: undefined,
    chartColor: '#D15F27',
    isPlf: localStorage.getItem(LocalStorageKeys.DEFAULT_LOG_QUALITY_UNIT_ISPLF) !== 'false'
  }),

  computed: {
    ...mapGetters('dashboard', ['interactiveChartTooltipFooter']),

    noDataText () {
      return this.selectedLocation.locationId === undefined ||
        this.selectedLocation.locationId === -1 ||
        this.logQualityAnalysis.length === 0
    },

    datasets () {
      if (this.logQualityAnalysis?.length) {
        return [{
          data: this.isPlf
            ? this.logQualityAnalysis.map(i => i.plf)
            : this.logQualityAnalysis.map(i => i.sed),
          label: this.isPlf
            ? 'Average PLF'
            : 'Average SED',
          borderColor: this.chartColor,
          pointBackgroundColor: this.chartColor,
          cubicInterpolationMode: 'monotone'
        }]
      }

      return []
    },

    labels () {
      if (this.logQualityAnalysis?.length > 0) {
        return this.logQualityAnalysis.map(i => moment(i.day).format('MM/DD/YYYY'))
      }

      return []
    }
  },

  watch: {
    logQualityAnalysis: {
      handler (_) {
        this.triggerDraw()
      },
      deep: true
    },

    isPlf (val) {
      if (val) {
        localStorage.setItem(LocalStorageKeys.DEFAULT_LOG_QUALITY_UNIT_ISPLF, 'true')
      } else {
        localStorage.setItem(LocalStorageKeys.DEFAULT_LOG_QUALITY_UNIT_ISPLF, 'false')
      }
      this.triggerDraw()
    }
  },

  methods: {
    ...mapActions('dashboard', ['getWidgetInteractionStatus']),

    triggerDraw () {
      this.$nextTick(() => this.$refs.chartCard.draw())
    },

    async onDataClicked (e) {
      if (this.isInteractive) {
        const element = this.chart.getElementsAtEventForMode(e, 'nearest', true)
        const data = this.chart.config._config.data
        const xAxisValue = data.labels[element[0]?.index]
        if (await this.getWidgetInteractionStatus({ widgetId: this.widgetId, x: xAxisValue })) this.$emit('data-clicked', { xAxisValue: xAxisValue })
      }
    },

    createChart (canvas) {
      const chart = new Chart(canvas, {
        type: 'line',
        data: {
          labels: this.labels,
          datasets: this.datasets
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          resizeDelay: 500,
          interaction: {
            mode: 'index',
            intersect: false
          },
          scales: {
            x: {
              grid: {
                display: true
              }
            },
            y: {
              display: true,
              grid: {
                display: true
              }
            }
          },
          plugins: {
            tooltip: {
              titleFont: {
                family: 'Roboto, sans-serif',
                size: '14',
                weight: 'bolder'
              },
              bodyFont: {
                family: 'Roboto, sans-serif'
              },
              footerColor: '#FFFFFFAA',
              footerFont: {
                family: 'Roboto, sans-serif',
                weight: 'normal',
                style: 'italic'
              }
            }
          },
          onClick: (e) => this.onDataClicked(e)
        }
      })
      if (this.isInteractive) chart.options.plugins.tooltip.callbacks.footer = () => this.interactiveChartTooltipFooter
      this.chart = chart
    },

    exportCSV () {
      let dataString = 'Date, PLF, SED \n'
      this.logQualityAnalysis.forEach(i => {
        dataString += `${i.day}, ${i.plf}, ${i.sed} \n`
      })
      const blob = new Blob([dataString], { type: 'text/plain;charset=utf-8' })
      saveAs(blob, `${this.selectedLocation.name.replace(/\s/g, '')}SmallEndDiameter.csv`)
    }
  }
}
</script>
