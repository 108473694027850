export default {
  getIconForObjectType: (objectType, enabled = true) => {
    switch (objectType.toLowerCase()) {
      case 'logger':
        return 'mdi-axe'
      case 'destination':
        return enabled ? 'mdi-domain' : 'mdi-domain-off'
      case 'account':
        return enabled ? 'mdi-account' : 'mdi-account-outline'
      case 'fromaccount':
        return 'mdi-account-arrow-right'
      case 'tract':
        return enabled ? 'mdi-crosshairs-gps' : 'mdi-crosshairs-off'
      case 'product':
        return enabled ? 'mdi-pine-tree-variant' : 'mdi-pine-tree-variant-outline'
      case 'region':
        return enabled ? 'mdi-map-marker' : 'mdi-map-marker-off'
      case 'landowner':
        return 'mdi-target-account'
      case 'tracttype':
        return 'mdi-target-variant'
      case 'defect':
        return enabled ? 'mdi-alert-decagram' : 'mdi-alert-decagram-outline'
      case 'date':
        return 'mdi-clock-outline'
      case 'certification':
        return 'mdi-certificate-outline'
      case 'reservation':
        return enabled ? 'truck-account' : 'truck-account-outline' // This is a custom icon, caller must be able to associate with our SVG
      case 'mytracts':
        return enabled ? 'mdi-account-circle' : 'account-circle-outline-variant' // Custom
      case 'driver':
        return enabled ? 'mdi-truck' : 'mdi-truck-outline'
      case 'setting':
        return 'mdi-map-marker-circle'
      case 'payable':
        return 'mdi-bank-transfer-out'
      case 'receivable':
        return 'mdi-bank-transfer-in'
      case 'journalentry':
      case 'accrual':
        return 'mdi-bank-transfer'
      case 'harvesttype':
        return 'mdi-image-filter-center-focus'
      case 'loggingmethod':
        return 'mdi-hand-saw'
      case 'forester':
        return 'mdi-forest'
      case 'team':
        return 'mdi-account-group'
    }
  },

  getColorForIcon: (icon) => {
    const iconName = icon.replace('mdi-', '')
    switch (iconName) {
      case 'pencil':
      case 'checkbox-marked-circle': return 'success'
      case 'bank-transfer-out':
      case 'delete-forever': return 'error'
      case 'pause-circle': return 'warning'
      case 'transfer':
      case 'bank-transfer':
      case 'check-circle-outline': return 'grey'
      case 'wallet-bifold':
      case 'cancel':
      case 'certificate-outline':
      case 'bank-transfer-in':
      case 'weight-alert':
      case 'rollout':
      case 'loader':
      case 'receiver':
      case 'transporter':
      case 'yard-operator':
      case 'kiosk':
      case 'laptop':
      case 'circle-outline':
      case 'receipt-text-send-outline':
      case 'email-newsletter': return 'secondary'
      default: return 'primary'
    }
  }
}
