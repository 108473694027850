export const SettlementKeys = {
  AccountsPayable: 'accountsPayable',
  AccountsReceivable: 'accountsReceivable',
  Accruals: 'accruals'
}

export const SettlementSubKeys = {
  AP: {
    SettlementsByContract: 'settlementsByContract',
    PaymentRegister: 'paymentRegister',
    BPPaymentRegister: 'bpPaymentRegister',
    ProductionSummary: 'productionSummary',
    TicketRegister: 'ticketRegister',
    SettlementsByPayee: 'settlementsByPayee',
    Corrections: 'corrections',
    ByproductCorrections: 'byproductCorrections',
    ByproductSettlementsByPayee: 'byproductSettlementsByPayee',
    MiscPayments: 'miscPayments'
  },
  AR: {
    ReceivableRegister: 'receivableRegister',
    BPReceivableRegister: 'bpReceivableRegister',
    BPReceivablesByPayer: 'bpReceivablesByPayer',
    BPReceivableSummary: 'bpReceivableSummary',
    ProductionSummary: 'productionSummary',
    SalesAnalysis: 'salesAnalysis'
  },
  AC: {
    Register: 'register',
    BPRegister: 'bpRegister',
    Depletion: 'depletion',
    MiscAccruals: 'miscAccruals'
  }
}

export const SubKeyToTabMap = {
  AP: {
    Logs: [
      {
        Key: SettlementSubKeys.AP.ProductionSummary,
        Tab: 0
      },
      {
        Key: SettlementSubKeys.AP.SettlementsByContract,
        Tab: 1
      },
      {
        Key: SettlementSubKeys.AP.SettlementsByPayee,
        Tab: 2
      },
      {
        Key: SettlementSubKeys.AP.PaymentRegister,
        Tab: 3
      },
      {
        Key: SettlementSubKeys.AP.TicketRegister,
        Tab: 4
      },
      {
        Key: SettlementSubKeys.AP.Corrections,
        Tab: 5
      },
      {
        Key: SettlementSubKeys.AP.MiscPayments,
        Tab: 6
      }
    ],
    Byproduct: [
      {
        Key: SettlementSubKeys.AP.ByproductSettlementsByPayee,
        Tab: 0
      },
      {
        Key: SettlementSubKeys.AP.BPPaymentRegister,
        Tab: 1
      },
      {
        Key: SettlementSubKeys.AP.ByproductCorrections,
        Tab: 2
      }
    ]
  },
  AR: {
    Logs: [
      {
        Key: SettlementSubKeys.AR.ReceivableRegister,
        Tab: 0
      },
      {
        Key: SettlementSubKeys.AR.ProductionSummary,
        Tab: 1
      },
      {
        Key: SettlementSubKeys.AR.SalesAnalysis,
        Tab: 2
      }
    ],
    Byproduct: [
      {
        Key: SettlementSubKeys.AR.BPReceivableRegister,
        Tab: 0
      },
      {
        Key: SettlementSubKeys.AR.BPReceivablesByPayer,
        Tab: 1
      },
      {
        Key: SettlementSubKeys.AR.BPReceivableSummary,
        Tab: 2
      }
    ]
  },
  AC: {
    Logs: [
      {
        Key: SettlementSubKeys.AC.Register,
        Tab: 0
      },
      {
        Key: SettlementSubKeys.AC.MiscAccruals,
        Tab: 1
      },
      {
        Key: SettlementSubKeys.AC.Depletion,
        Tab: 2
      }
    ],
    Byproduct: [
      {
        Key: SettlementSubKeys.AC.BPRegister,
        Tab: 1
      }
    ]
  }
}
