<template>
  <v-container fluid id="settlement-misc-payments">
    <v-row v-if="miscPayments.advances?.length > 0">
      <v-col>
        <SettlementAdvances
        :advances="miscPayments.advances"
        :isExportBatch="isExportBatch"/>
      </v-col>
    </v-row>
    <v-row v-if="miscPayments.tractPayments?.length > 0">
      <v-col>
        <v-container fluid>
          <span class="title">{{ $t('tractPayments') }}</span>
          <DataTable
          :items="miscPayments.tractPayments"
          :headers="settlementTractPaymentHeaders()"
          :showSearch="false"
          :showDense="false"/>
        </v-container>
      </v-col>
    </v-row>
    <v-row v-if="miscPayments.accountPayments?.length > 0">
      <v-col>
        <v-container fluid>
          <span class="title">{{ $t('accountPayments') }}</span>
          <DataTable
          :items="miscPayments.accountPayments"
          :headers="settlementAccountPaymentHeaders()"
          :showSearch="false"
          :showDense="false"
          :customCells="customCells">
            <template #actions="{item}">
              <Icon
              :disabled="!userAssignedClaim(UserClaims.AccountManager)"
              icon="mdi-delete-forever"
              iconColor="error"
              :tooltipText="$t('delete')"
              @icon-clicked="deletePayable(item)"/>
            </template>
            <template #note="{item}">
              <span>
                {{ item.note }}
                <Icon
                v-if="!!item.accountPayableTemplateId"
                icon="mdi-sync"
                :tooltipText="$t('recurring')"
                iconColor="black"
                tooltipColor="black"/>
              </span>
            </template>
          </DataTable>
        </v-container>
      </v-col>
    </v-row>
    <v-dialog v-model="deleteAccountPayable" v-if="confirmDelete" width="400">
      <ConfirmDialog
      v-if="confirmDelete"
      :title="$t('deleteAccountPaymentTitle')"
      :body="$t('deleteAccountPaymentBody')"
      @cancel="closeDialogs"
      @confirm="handleConfirmDelete"/>
    </v-dialog>
  </v-container>
</template>

<script>
import { ContractMode, UserClaims } from '../../../../utils/Enumerations'
import { settlementTractPaymentHeaders, settlementAccountPaymentHeaders } from '@/headers/ExportBatch.js'
import { mapGetters, mapActions } from 'vuex'
import { userAssignedClaim } from '../../../../utils/ClaimUtility'
export default {
  name: 'MiscSettlementPayments',

  components: {
    DataTable: () => import('@/components/core/table/DataTable.vue'),
    SettlementAdvances: () => import('@/components/settlements/accounts-payable-module/other-modules/SettlementAdvances.vue'),
    Icon: () => import('@/components/helper/Icon.vue'),
    ConfirmDialog: () => import('@/components/helper/ConfirmDialog.vue')
  },

  props: {
    miscPayments: {
      type: Object,
      required: true
    },
    isExportBatch: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    ContractMode,
    UserClaims,
    focusedAccountPayment: undefined,
    confirmDelete: false,
    customCells: [
      {
        slotName: 'actions',
        value: 'actions'
      },
      {
        slotName: 'note',
        value: 'note'
      }
    ]
  }),

  computed: {
    ...mapGetters('settlements', ['dateConfiguration'])
  },

  methods: {
    ...mapActions('account', ['deleteAccountPayable']),
    ...mapActions('settlements', ['clearStaleSubModuleData', 'refreshModuleData']),
    settlementTractPaymentHeaders,
    settlementAccountPaymentHeaders,
    userAssignedClaim,

    deletePayable (item) {
      if (this.dateConfiguration.exportBatch?.exportBatchId !== undefined) {
        this.setSnackError(this.$t('cannotEditOrDeleteExportedAccountPayments'))
        return
      }
      this.focusedAccountPayment = item
      this.confirmDelete = true
    },

    closeDialogs () {
      this.focusedAccountPayment = undefined
      this.confirmDelete = false
    },

    async handleConfirmDelete () {
      await this.deleteAccountPayable({
        accountId: this.focusedAccountPayment.accountId,
        accountPayableId: this.focusedAccountPayment.accountPayableId
      })
      this.closeDialogs()
      await this.refreshModuleData(false)
      this.clearStaleSubModuleData({ isByproduct: false })
    }
  }
}
</script>
