<template>
  <v-container fluid>
    <v-row v-if="buyerSummary.fromAccountSummaries.length > 0" class="my-3">
      <span class="title">
        {{ $t('fromAccount') }}
      </span>
    </v-row>
    <v-row v-for="(fromAccount, index) in buyerSummary.fromAccountSummaries" dense :key="`buyerSummary-${index}`">
      <v-col cols="12">
        <span class="subtitle-1 black--text">{{fromAccount.name}}</span>
      </v-col>
      <v-col cols="12">
        <DataTable
        :items.sync="fromAccount.locationProductSummaries"
        :headers="headers"
        :propDense="true"
        :customCells="customCells"
        :hideHeader="true"
        :actions=[]>
          <template #weight="{item}">
            {{getTonsWithMetric(item.weight)}}
          </template>
          <template #amount="{item}">
            {{formatMoney(item.amount)}}
          </template>
          <template #rate="{item}">
            {{getRate(item)}}
          </template>
          <template #profit-loss="{item}">
            <span :style="`color: ${item.pl < 0 ? 'error' : 'black'}`">
              {{getPLString(item.pl)}}
            </span>
          </template>
          <template #body.append>
            <tr class="font-weight-bold">
            <td class="text-left">{{$t('totals')}}</td>
            <td></td>
            <td class="text-right">{{ getTotalLoads(fromAccount.locationProductSummaries)}}</td>
            <td class="text-right">{{ getTotalWeight(fromAccount.locationProductSummaries) }}</td>
            <td class="text-right">{{ getTotalAmount(fromAccount.locationProductSummaries) }}</td>
            </tr>
          </template>
        </DataTable>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { tractProductionHeaders } from '@/headers/Cycles.js'
import { tonStringFromPounds, formatMoney } from '@/utils/NumericMutations.js'
import { mapGetters } from 'vuex/dist/vuex.common.js'
import { NumericConstants } from '@/utils/constants.js'
export default {
  name: 'BPReceivableCard',

  props: {
    buyerSummary: {
      type: Object,
      required: true
    }
  },

  components: {
    DataTable: () => import('@/components/core/table/DataTable.vue')
  },

  data: () => ({
    customCells: [
      {
        slotName: 'weight',
        value: 'weight'
      },
      {
        slotName: 'amount',
        value: 'amount'
      },
      {
        slotName: 'profit-loss',
        value: 'pl'
      },
      {
        slotName: 'rate',
        value: 'rate'
      }
    ]
  }),

  computed: {
    ...mapGetters('settlements', ['useByproductMetricTons']),
    headers () {
      return tractProductionHeaders()
    }
  },

  methods: {
    tonStringFromPounds,
    formatMoney,

    getTotalAmount (productSummaries) {
      return formatMoney(productSummaries.reduce((a, b) => a + b.amount, 0))
    },

    getTotalLoads (productSummaries) {
      return productSummaries.reduce((a, b) => a + b.loads, 0)
    },

    getTotalWeight (productSummaries) {
      return tonStringFromPounds(productSummaries.reduce((a, b) => a + b.weight, 0), 3, this.useByproductMetricTons)
    },

    getRate ({ amount, weight }) {
      const divisor = this.useByproductMetricTons ? NumericConstants.MetricTonDivisor : NumericConstants.ShortTonDivisor
      return formatMoney(amount / (weight / divisor).toFixed(2))
    },
    getTonsWithMetric (x) {
      return tonStringFromPounds(x, 3, this.useByproductMetricTons)
    }
  }
}
</script>
