<template>
  <v-row>
    <v-col cols="12" xl="6" :md="contractHasTract ? 4 : 6">
      <DetailCard :title="$t('properties')">
        <template #title.append>
          <v-row no-gutters>
            <v-col cols="auto" v-for="attribute in attributes" :key="attribute.text">
              <Icon
              dataTestId="contract-attribute"
              :key="attribute.text"
              :icon="attribute.icon"
              :tooltipText="attribute.text"
              :iconColor="attribute.color"
              :tooltipColor="attribute.color"
              />
            </v-col>
          </v-row>
        </template>
        <v-row>
          <v-col cols="12" xl="auto">
            <v-card color="grey lighten-4" flat class="fill-height">
              <v-card-text>
                <v-row align="center" justify="space-between" no-gutters v-for="(contractKey, i) in contractKeys" :key="i" class="flex-nowrap">
                  <v-col cols="auto"><Icon margin="mr-2" :icon="IconHelpers.getIconForObjectType(contractKey)" iconColor="grey darken-1" :small="false" :tooltipText="$t(contractKey)"></Icon></v-col>
                  <v-col style="text-wrap: nowrap; overflow: hidden;" class="text-right">
                    <Icon v-if="keyIsCertified(contractKey)" icon="mdi-certificate-outline" iconColor="info" :small="false" :tooltipText="$t('certified')"></Icon>
                    <v-tooltip bottom color="grey darken-1">
                      <template #activator="{on}">
                        <span v-on="on" class="subtitle-1">{{contract[contractKey]}}</span>
                      </template>
                      <span class="subtitle-1 white--text">{{$t('code').concat(': ').concat(contract[`${contractKey}Code`])}}</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" xl="" :class="$vuetify.breakpoint.xl ? 'px-6' : ''">
            <v-row dense data-testid="contract-details-prop-table">
              <v-col cols="12">
                <v-row no-gutters>
                  <v-col> {{$t('type')}}: </v-col>
                  <v-col> {{contractTypeFromInt(contract.type)}} </v-col>
                </v-row>
              </v-col>
              <v-col v-if="contractHasTract" cols="12">
                <v-row no-gutters>
                  <v-col> {{$t('tractType')}}: </v-col>
                  <v-col> {{contract.tractType}} </v-col>
                </v-row>
              </v-col>
              <v-col v-if="contractHasTract" cols="12">
                <v-row no-gutters>
                  <v-col> {{$t('logger')}}: </v-col>
                  <v-col> {{contract.tractDefaultLogger ?? $t('notAvailable')}} </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" v-if="contract.distance > 0">
                <v-row no-gutters>
                  <v-col> {{$t('distance')}}: </v-col>
                  <v-col> {{ contract.distance }} </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" v-if="contract.tareWeight">
                <v-row no-gutters>
                  <v-col> {{$t('tareWeight')}}: </v-col>
                  <v-col> {{ numberWithCommas(contract.tareWeight) }} {{ $t('lbsUpper') }}</v-col>
                </v-row>
              </v-col>
              <v-col cols="12" v-if="contractTypeIsByproduct">
                <v-row no-gutters>
                  <v-col> {{ $t('allowsLoadPickup')}}: </v-col>
                  <v-col>
                    <Icon
                    :tooltipText="contract.allowPickupLoads ? $t('allowsLoadPickup') : $t('notAllowsLoadPickup')"
                    :icon="contract.allowPickupLoads ? 'mdi-checkbox-marked-circle' : 'mdi-close'"
                    :iconColor="contract.allowPickupLoads ? 'success' : 'error'"/>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-row no-gutters>
                  <v-col> {{$t('active')}}: </v-col>
                  <v-col>
                    <span>{{utcToLocalDate(contract.effectiveDate)}} - </span>
                    <span>{{utcToLocalDate(contract.expirationDate)}}</span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

      </DetailCard>
    </v-col>
    <v-col cols="12" sm="6" md="4" xl="3" v-if="contractHasTract">
      <DetailCard :title="$t('tractLandowners')">
        <v-data-table
        :mobile-breakpoint="0"
        fixed-header
        :height="`${ $vuetify.breakpoint.xl ? 150 : 310 }px`"
        dense
        @click:row="openLandownerDialog"
        :items-per-page="-1"
        hide-default-footer
        :items="tractLandowners"
        :headers="[{text: $t('name'), value: 'accountName'},{text: $t('ownership'), value: 'ownership', align: 'right'}]">
          <template #item.ownership="{item}">
            {{ formatPercentage(item.ownership) }}
          </template>
        </v-data-table>
      </DetailCard>
    </v-col>
    <v-col cols="12" sm="6" :md="contractHasTract ? 4 : 6" :xl="contractHasTract ? 3 : 6">
      <DetailCard :title="$t('products')">
        <template #title.append>
          <Icon
          dataTestId="contract-load-icon"
          iconColor="info"
          :tooltipText="contract.acceptsAnyLoad ? $t('acceptsAnyProduct') : $t('restrictedProducts')"
          :icon="contract.acceptsAnyLoad ? 'mdi-lock-open-variant-outline' : 'mdi-lock-outline'"/>
        </template>
        <v-data-table
        :mobile-breakpoint="0"
        v-if="contract.products.length > 0"
        fixed-header
        :height="`${ $vuetify.breakpoint.xl ? 150 : 310 }px`"
        dense
        @click:row="openProductDialog"
        :items-per-page="-1"
        hide-default-footer
        :items="contract.products"
        :headers="productTableHeaders">
        <template #header.grossProfit="{header}">
          <v-row align="center" no-gutters justify="end">
            <v-col cols="auto" v-if="showPayByLoadWarning">
              <Icon
              iconColor="info"
              icon="mdi-information-outline"
              :tooltipText="$t('profitPerTonCannotBeCalculatedForPayByLoadEditing')"/>
            </v-col>
            <v-col cols="auto" v-if="showNonInventoryWarning">
              <Icon
              iconColor="info"
              icon="mdi-information-outline"
              :tooltipText="$t('nonInventoryCostsExcluded')"/>
            </v-col>
            <v-col cols="auto">
              <span>{{ header.text }}</span>
            </v-col>
          </v-row>
        </template>
        <template #item.grossProfit="{item}">
          {{ getGrossProfitColumn(item) }}
        </template>
        <template #item.name="{item}">
          <div style="max-width: 100px;">
            <span >{{item.name}}</span>
          </div>
        </template>
        </v-data-table>
        <span v-else>{{$t('noProductsSpecified')}}</span>
      </DetailCard>
    </v-col>
    <Dialog :stateId="dialogId" @dialog-closing="closeDialog">
      <ProductDialog
      v-if="productDialog"
      :products="contract.products"
      :profitByProduct="profitByProduct"
      :contract="contract"
      @close="closeDialog"/>
      <v-card v-if="landownerDialog">
        <v-card-title class="primary">
          <v-row>
            <v-col>
              <span class="headline white--text">
                {{ $t('landowners') }}
              </span>
            </v-col>
            <v-col cols="auto">
              <BaseDialogActions hideRefresh hideFullscreen @close="closeDialog"/>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-container fluid style="max-height: 60vh; overflow-y: auto;">
            <v-data-table
            :mobile-breakpoint="0"
            fixed-header
            dense
            :items-per-page="-1"
            hide-default-footer
            :items="tractLandowners"
            :headers="[{text: $t('name'), value: 'accountName'},{text: $t('ownership'), value: 'ownership', align: 'right'}]">
              <template #item.ownership="{item}">
                {{ formatPercentage(item.ownership) }}
              </template>
            </v-data-table>
          </v-container>
        </v-card-text>
      </v-card>
    </Dialog>
  </v-row>
</template>

<script>
import ProductHeaders from '@/headers/Product'
import { numberWithCommas, formatMoney, formatPercentage } from '@/utils/NumericMutations'
import { ContractType, TemplateAccountingCategory, CostType, ActivityModifier, PayBy, TemplateCostType, CertificationStatus } from '@/utils/Enumerations.js'
import { utcToLocalDate } from '@/utils/DateFormatter.js'
import { uniqueDialogId } from '@/utils/componentHelpers'
import { mapActions } from 'vuex'
import IconHelpers from '@/utils/IconHelpers'

export default {
  name: 'ContractInformation',

  components: {
    Dialog: () => import('@/components/Dialog.vue'),
    ProductDialog: () => import('@/components/contract/contract-detail/ProductDialog.vue'),
    Icon: () => import('@/components/helper/Icon.vue'),
    DetailCard: () => import('@/components/core/DetailCard.vue'),
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue')
  },

  props: {
    contract: {
      required: true,
      type: Object
    },
    allActivities: {
      type: Array,
      default: undefined,
      required: true
    },
    tractLandowners: {
      type: Array,
      default: () => []
    }
  },

  data: () => ({
    IconHelpers,
    productDialog: false,
    landownerDialog: false,
    profitByProduct: undefined,
    dialogId: uniqueDialogId('product-dialog')
  }),

  computed: {
    contractTypeIsWoodsSale () {
      return this.contract.type === ContractType.WoodsSale.value
    },

    contractTypeIsByproduct () {
      return this.contract.type === ContractType.ByproductSale.value
    },

    contractTypeIsByproductPurchase () {
      return this.contract.type === ContractType.ByproductPurchase.value
    },

    productHeaders () { return ProductHeaders.productHeaders(this.$i18n.locale) },

    attributes () {
      const attributes = []

      if (!this.contract.isExternal) {
        attributes.push({
          text: this.$t('internal'),
          icon: 'mdi-home',
          color: 'black'
        })
      }

      if (this.contract.isDraft) {
        attributes.push({
          text: this.contract.hasPendingTickets ? this.$t('hasPendingTickets') : this.$t('draftContract'),
          icon: 'mdi-file',
          color: this.contract.hasPendingTickets ? 'error' : 'black'
        })
      }

      if (this.contract.requiresLogger) {
        attributes.push({
          text: this.$t('requiresLogger'),
          icon: 'mdi-axe',
          color: 'brown'
        })
      }

      if (this.contract.requiresExt1) {
        attributes.push({
          text: this.$t('requiresExt1'),
          icon: 'mdi-pound',
          color: 'info'
        })
      }

      if (this.contract.requiresExt2) {
        attributes.push({
          text: this.$t('requiresExt2'),
          icon: 'mdi-pound',
          color: 'info'
        })
      }

      return attributes
    },

    isLogsContract () {
      return this.contract?.type === ContractType.Production.value || this.contract?.type === ContractType.WoodsSale.value
    },

    contractHasTract () {
      return this.isLogsContract ||
        (this.contract?.type === ContractType.ByproductPurchase.value && this.contract.tract)
    },

    contractHasFromAccount () {
      return !this.isLogsContract && this.contract.fromAccountId
    },

    requiresLoggerText () { return this.contract.requiresLogger ? this.$t('requiresLogger') : this.$t('noLoggerRequired') },

    minCardHeightForPropertiesAndProducts () { if (this.contract.distance > 0 || this.contract.products.length > 4) { return '240px' } else { return '210px' } },

    showPayByLoadWarning () {
      return this.allActivities?.flatMap(a => a.activityDetails).some(ad => ad.cost.payBy === PayBy.Load.value)
    },

    showNonInventoryWarning () {
      return this.allActivities?.some(a => a.activityTemplate.costType === TemplateCostType.nonInventoryCost.value)
    },

    isCertified () {
      return CertificationStatus.isCertified(this.contract.accountCertificationStatus)
    },

    cardColumnWidth () {
      return this.tractLandowners.length > 0 && this.tractLandowners.length < 6 ? 4 : 6
    },

    landownersPanelString () {
      return `${this.tractLandowners.reduce((a, lo) => { return lo.ownership > a.ownership ? lo : a }).accountName}${this.tractLandowners?.length > 1 ? ', ' + this.$t('nMore', { n: this.tractLandowners.length - 1 }) : ''}`
    },

    landownerHeaders () {
      return [
        {
          text: this.$t('name'),
          value: 'accountName'
        },
        {
          text: this.$t('ownership'),
          value: 'ownership',
          align: 'right'
        }
      ]
    },

    contractKeys () {
      var result = ['destination']
      if (this.contractHasFromAccount) {
        result.unshift('account')
        result.unshift('fromAccount')
      }
      if (this.contractHasTract) result.unshift('tract')
      if (this.isLogsContract) result.push('setting')
      if (this.contractHasTract) result.push('account')
      return result
    },

    productTableHeaders () {
      var result = [{ text: this.$t('name'), value: 'name' }]
      if (this.contractTypeIsWoodsSale) result.push({ text: this.$t('grossProfit'), value: 'grossProfit', align: 'right', sortable: false })
      return result
    }
  },

  created () {
    if (this.contractTypeIsWoodsSale) this.initializeProfitByProductsMap()
  },

  methods: {
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),
    utcToLocalDate,
    numberWithCommas,
    formatMoney,
    formatPercentage,
    contractTypeFromInt: (type) => ContractType.fromInt(type),

    getProductAtIndex (index) {
      if (this.contract.products.length - 1 >= index - 1) {
        return this.contract.products[index - 1].name
      } else {
        return ''
      }
    },

    getProductProfitRateAtIndex (index) {
      if (this.contract.products.length - 1 >= index - 1) {
        return this.profitByProduct.get(this.contract.products[index - 1].productId)
      }
    },

    getProductProfitRate (product) {
      return this.profitByProduct.get(product.productId)
    },

    initializeProfitByProductsMap () {
      if (this.showPayByLoadWarning) return undefined
      this.profitByProduct = this.allActivities
        .filter(a => a.activityTemplate.costType !== CostType.NonInventory.value)
        .reduce((productMap, activity) => {
          const factor = activity.activityTemplate.category === TemplateAccountingCategory.AccountsReceivable.value ? 1 : -1
          activity.activityDetails.forEach(ad => {
            const amount = activity.activityTemplate.modifier === ActivityModifier.DistanceTraveled.value
              ? ad.cost.rate * this.contract.distance * factor
              : ad.cost.rate * factor

            const existingAmount = productMap.get(ad.product.productId) ?? 0
            const updatedAmount = existingAmount + amount

            productMap.set(ad.product.productId, updatedAmount)
          })
          return productMap
        }, new Map())
    },

    openProductDialog () {
      this.landownerDialog = false
      this.productDialog = true
      this.openOrUpdateDialog({ id: this.dialogId, width: '400' })
    },

    closeDialog () {
      this.productDialog = false
      this.landownerDialog = false
      this.closeDialogsAtOrAbove(this.dialogId)
    },

    openLandownerDialog () {
      this.productDialog = false
      this.landownerDialog = true
      this.openOrUpdateDialog({ id: this.dialogId, width: '400' })
    },

    keyIsCertified (contractKey) {
      switch (contractKey) {
        case 'account': return this.contract.accountCertificationStatus === 1
        case 'tract': return this.contract.tractIsCertified
        default: return false
      }
    },

    getGrossProfitColumn (item) {
      return !this.showPayByLoadWarning ? `${formatMoney(this.getProductProfitRate(item))}/t` : ''
    }
  }
}
</script>
