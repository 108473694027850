export default {
  allPayables: (state) => state.payables,
  unpaidAdvances: (state) => state.unpaidAdvances,
  tractPayables: (state) => state.tractPayables,
  focusedAccount: (state) => state.focusedAccount,
  entities: (state) => state.entities,
  entityNames: (state) => Object.keys(state.entities),
  allBonds: (state) => state.bonds,
  bondsLoading: (state) => state.bondsLoading
}
