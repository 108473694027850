import ApiServer from '@/utils/api/ApiServer.js'
import { AccountingCategory } from '@/utils/Enumerations'

export default {
  async resetState (context) {
    context.commit('resetState')
  },

  async fetchPayables (context, contractMode) {
    const queryString = `byproduct=${contractMode.value === 1}&transfer=${contractMode.value === 0}&logYardSale=${contractMode.value === 0}`
    const response = await ApiServer.get(`payables?${queryString}`)
    context.commit('setPayables', response.data)
    return response.data
  },

  async fetchAllTractPayables (context) {
    const response = await ApiServer.get('tractPayables')
    context.commit('setTractPayables', response.data)
    return response.data
  },

  async fetchUnpaidAdvances (context) {
    const response = await ApiServer.get('v2/advances?limitToUnpaid=true')
    context.commit('setUnpaidAdvances', response.data)
    return response.data
  },

  async getPayable (_, payableId) {
    const path = `payables/${payableId}`
    const response = await ApiServer.get(path)
    return response.data
  },

  async getPayablesForAccount (_, accountId) {
    const path = `payables/?accountId=${accountId}`
    const response = await ApiServer.get(path)
    return response.data
  },

  async fetchUnpaidAccountPayablesAP (context) {
    const response = await ApiServer.get(`accountpayables?categoryType=${AccountingCategory.Payable.value}`)
    context.commit('setAccountPayables', response.data)
    return response.data
  },

  async fetchBonds (context, { asAt, includeZeroBalance, tractStatuses }) {
    context.commit('setBondsLoading', true)
    const path = ApiServer.urlFor('bonds', {
      version: 1,
      params: {
        asAt,
        includeZeroBalance,
        tractStatuses
      }
    })
    try {
      const response = await ApiServer.get(path)
      context.commit('setBonds', response.data)
      return response.data
    } finally {
      context.commit('setBondsLoading', false)
    }
  }
}
