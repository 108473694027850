<template>
  <div id="interactive-map" :style="`height: ${height}`">
    <v-row class="map-button-row-top" dense>
      <v-col cols="auto">
        <div class="map-option-button" v-if="includeSearchByTract">
          <Icon
          icon="mdi-magnify"
          @icon-clicked="searchEnabled = !searchEnabled"
          :tooltipText="searchIconTooltip"
          xLarge
          :small="false"
          iconColor="white"
          margin="mr-2"
          tooltipColor="black"
          />
        </div>
        <div class="map-option-button" v-if="$vuetify.breakpoint.smAndDown && !tickets">
          <Icon
          class="mr-0"
          icon="mdi-cloud"
          @icon-clicked="toggleWeatherOverlay"
          :tooltipText="weatherToggleTooltip"
          xLarge
          :small="false"
          iconColor="white"
          tooltipColor="black"
          />
        </div>
        <div class="map-option-button" v-if="$vuetify.breakpoint.smAndDown && includeSearchByTract">
          <TractFilter class="map-option-button" :filter="propFilter" @filter-applied="applyFilter" xLarge iconColor="white" tooltipColor="black" margin="ma-0"/>
        </div>
      </v-col>
      <v-col v-if="includeSearchByTract" style="min-width:200px;" cols="8">
        <TractAutocomplete
        :class="searchEnabled ? 'visible in-front' : 'invisible behind'"
        data-testid="contract-tract"
        id="map-search-text"
        :clearable="true"
        :propTractId="this.tract?.tractId"
        @tract-chosen="tractChosen"
        @tract-cleared="tractCleared"
        :showLabel=false
        :propFilter="propFilter"
        :staleSearch="focusedTractNotInList ? this.tract : undefined"
        soloTheme
        />
      </v-col>
      <v-spacer v-if="!$vuetify.breakpoint.smAndDown"/>
      <v-col cols="auto" v-if="!$vuetify.breakpoint.smAndDown && !tickets">
        <div
        class="map-option-button"
        >
          <Icon
          class="mr-0"
          icon="mdi-cloud"
          @icon-clicked="toggleWeatherOverlay"
          :tooltipText="weatherToggleTooltip"
          xLarge
          :small="false"
          iconColor="white"
          tooltipColor="black"
          />
        </div>
      </v-col>
      <v-col v-if="includeSearchByTract && !$vuetify.breakpoint.smAndDown" cols="auto">
        <div
        class="map-option-button"
        >
          <TractFilter class="map-option-button" :filter="propFilter" @filter-applied="applyFilter" xLarge iconColor="white" tooltipColor="black" margin="ma-0"/>
        </div>
      </v-col>
      <v-col v-if="tickets && transitStatusFilter" cols="auto">
        <v-menu
          v-model="transitStatusFilterOpen"
          :close-on-content-click="false"
          right
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
            class="map-option-button"
            v-on="on"
            v-bind="attrs"
            x-large
            color="white"
          >
            mdi-filter
          </v-icon>
          </template>
          <v-card>
            <v-card-title class="primary white--text">
              {{$t('ticketTransitStatusFilter')}}
            </v-card-title>
            <v-card-text>
              <v-container fluid>
                <v-row dense>
                  <v-col v-for="ts in transitStatus" :key="`status-${ts.label}`" cols="12" class="ma-0 pa-0">
                    <v-checkbox :label="getCheckboxLabel(ts.label, ts.value)" v-model="transitStatusFilter" :value="ts.value"/>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-menu>
      </v-col>
      <v-col v-if="isLocationMap || ticket" cols="auto">
        <div
        class="map-option-button"
        >
          <Icon
          icon="mdi-crosshairs-gps"
          @icon-clicked="showNearbyTracts = !showNearbyTracts"
          :tooltipText="showNearbyTractsTooltip"
          xLarge
          :small="false"
          iconColor="white"
          tooltipColor="black"
          />
        </div>
      </v-col>
      <v-spacer v-if="$vuetify.breakpoint.smAndDown"/>
      <v-col v-if="$vuetify.breakpoint.smAndDown && !isLocationMap" cols="auto">
        <div
        class="map-option-button"
        >
          <Icon
          class="mr-0"
          icon="mdi-close"
          @icon-clicked="$emit('map-closed')"
          :tooltipText="$t('close')"
          xLarge
          :small="false"
          iconColor="white"
          tooltipColor="black"
          />
        </div>
      </v-col>
    </v-row>
    <v-tooltip bottom color="black">
      <template
        v-slot:activator="{on}">
        <v-btn
          class="scale-bar-toggle-button"
          v-on="on"
          :ripple="false"
          elevation="0"
          color="#00000000"
          @click="toggleScaleBar()">
        </v-btn>
      </template>
      <span class="subtitle-1 white--text" style="white-space: pre-line">{{ scaleBarToggleTooltip }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import * as atlas from 'azure-maps-control'
import mapMarkers from '@/images/markers/markers.js'
import { azureMapsApiKey } from '../../../env-var.js'
import { TractTypeCategory, TicketStatus, TransitStatus } from '@/utils/Enumerations.js'
import shp from 'shpjs'
import { shapefileClient } from '../../utils/Shapefiles'
import { LocalStorageKeys } from '@/utils/LocalStorageActor'
import { mapActions } from 'vuex'
import { TractFilter } from '@/model/Tract.js'
import { numberWithCommas } from '@/utils/NumericMutations'
import { utcToLocalDate } from '@/utils/DateFormatter.js'
import { addMapControlScript, removeMapControlScript, mapResizeTimeout } from '@/utils/MapHelpers'

const defaultCenterCoords = [-80, 39]

export default {
  name: 'InteractiveMap',

  components: {
    TractAutocomplete: () => import('../autocomplete/TractAutocomplete.vue'),
    Icon: () => import('@/components/helper/Icon.vue'),
    TractFilter: () => import('@/components/tract/TractFilter.vue')
  },

  props: {
    tracts: { type: Array, required: false, default: undefined },
    tickets: { type: Array, required: false, default: undefined },
    ticket: { type: Object, required: false, default: undefined },
    reRender: { type: Boolean, required: false, default: false },
    height: { type: String, required: false, default: '80vh' },
    includeSearchByTract: { type: Boolean, required: false, default: false },
    propFilter: { type: Object, required: false, default: undefined },
    isLocationMap: { type: Boolean, required: false, default: false },
    containerId: { type: String, required: false, default: undefined }
  },

  data: () => ({
    interactiveMap: null,
    openMarker: {
      justAdded: false,
      marker: null
    },
    searchEnabled: true,
    tractId: null,
    tract: null,
    mapLayers: [],
    boundarySources: [],
    boundariesLoading: false,
    currentWeatherOverlay: undefined,
    weatherLayer: undefined,
    scaleBarRendered: false,
    currentScaleBar: 'imperial',
    scaleBarImport: undefined,
    scaleBarOptions: ['imperial', 'metric'],
    showNearbyTracts: false,
    nearbyTractMarkers: [],
    transitStatusFilterOpen: false,
    transitStatusFilter: [TransitStatus.Ready.value, TransitStatus.Paused.value],
    resizeObserver: undefined
  }),

  watch: {
    tractId (val) {
      if (val) {
        this.interactiveMap.popups.clear()
        this.resetMap()
      } else {
        this.resetMap(false)
      }
    },

    tracts (val) {
      if (this.tract || this.ticket) {
        this.resetMap(false, false)
      } else {
        this.resetMap()
      }
    },

    showNearbyTracts (val) {
      if (val === false) this.interactiveMap.popups.clear()
      this.resetMap(false, false)
    },

    transitStatusFilter () {
      this.resetMap()
    }
  },

  mounted () {
    this.$nextTick(_ => {
      this.initMap()
      this.setupMap(true)
      addMapControlScript()
      window.addEventListener('resize', this.onResize)
      if (this.containerId) {
        this.resizeObserver = new ResizeObserver(this.onResize).observe(document.getElementById(this.containerId))
      }
    })
  },

  beforeDestroy () {
    this.clearAllBoundaries().then(async _ => {
      window.removeEventListener('resize', this.onResize)
      if (this.resizeObserver) await this.resizeObserver.unobserve()
      await this.interactiveMap.markers.clear()
      this.interactiveMap = null
      removeMapControlScript()
    })
  },

  computed: {
    searchIconTooltip () {
      return this.searchEnabled ? this.$t('hideSearchBar') : this.$t('showSearchBar')
    },
    focusedTractNotInList () {
      return this.tract && this.tractId && !this.tracts.includes(this.tract)
    },
    weatherLayerName () {
      return this.currentWeatherOverlay ? `microsoft.weather.${this.currentWeatherOverlay}.main` : undefined
    },
    weatherToggleTooltip () {
      return this.$t('currentWeatherOverlay', { type: this.$t(this.currentWeatherOverlay || 'none') })
    },
    scaleBarToggleTooltip () {
      return this.$t('changeToUnits', { units: this.$t(this.currentScaleBar === 'imperial' ? 'metric' : 'imperial') })
    },
    showNearbyTractsTooltip () {
      return this.$t('toggleNearbyTracts', { toggle: this.$t(this.showNearbyTracts ? 'hide' : 'show') })
    },
    transitStatus () {
      return [TransitStatus.Ready, TransitStatus.Paused]
    }
  },

  methods: {
    ...mapActions('tract', ['fetchTracts', 'fetchTractsOnce']),
    resetMap (changeZoom = true, animateSearched = true) {
      this.interactiveMap.markers.clear()
      this.clearAllBoundaries()
      this.$nextTick(_ => {
        this.setupMap(false, changeZoom, animateSearched)
      })
      this.onResize()
    },

    initMap () {
      const apiKey = azureMapsApiKey.includes('VUE') ? 'FMG4SRqJhWH0ix1NBc7lvPLIKxOtE_qrNpFbo078fgg' : azureMapsApiKey
      const mapOptions = {
        center: defaultCenterCoords,
        zoom: 10,
        view: 'Auto',
        style: 'satellite_road_labels',
        authOptions: {
          authType: 'subscriptionKey',
          subscriptionKey: apiKey
        }
      }

      this.interactiveMap = new atlas.Map('interactive-map', mapOptions)
    },

    async setupMap (addClickListener, changeZoom = true, animateSearched = true) {
      const tractsSortedByLatitude = this.tracts || this.showNearbyTracts ? await this.getTractsToMark() : undefined
      const ticketsSortedByLatitude = this.tickets ? await this.getTicketsToMark() : undefined

      this.interactiveMap.events.add('ready', () => {
        if (this.tracts || this.showNearbyTracts) {
          const tractMarkers = this.createTractMarkers(tractsSortedByLatitude, animateSearched && !this.isLocationMap)
          this.addMarkers(tractMarkers)
          const ticketsTract = this.ticket ? tractsSortedByLatitude?.find(t => t.tractId === this.ticket.tractId) : undefined
          if (ticketsTract != null) {
            this.setBoundary(ticketsTract.tractId)
          } else if (tractsSortedByLatitude.length === 1 && !this.includeSearchByTract && !this.showNearbyTracts) {
            this.setBoundary(tractsSortedByLatitude[0].tractId)
          }
        }

        if (this.tickets) {
          const ticketMarkers = this.createTicketMarkers(ticketsSortedByLatitude)
          this.addMarkers(ticketMarkers)
        }

        if (this.ticket) {
          const ticketMarkers = this.createTicketMarkers()
          this.addMarkers(ticketMarkers)
        }

        if (addClickListener) {
          this.interactiveMap.events.add('click', () => {
            if (this.openMarker.marker !== null && this.openMarker.justAdded === false && this.openMarker.marker.options.popup) {
              this.openMarker.marker.options.popup.remove()
              this.openMarker.marker = null
              this.openMarker.justAdded = false
            } else {
              this.openMarker.justAdded = false
            }
          })
        }

        if (changeZoom) {
          if (this.ticket && !this.noLastKnownMarker()) {
            this.interactiveMap.setCamera({
              bounds: atlas.data.BoundingBox.fromLatLngs([[this.ticket.longitude, this.ticket.latitude], [this.ticket.lastKnownLongitude, this.ticket.lastKnownLatitude]]),
              padding: 150
            })
          } else if (this.tracts) {
            this.centerVisibleTractPoints(tractsSortedByLatitude.filter(t => (this.tractId === null || this.tractId === t.tractId)))
          } else {
            this.centerVisibleTicketPoints(ticketsSortedByLatitude || [this.ticket])
          }
        }

        if (!this.weatherLayer) {
          this.weatherLayer = new atlas.layer.TileLayer({
            opacity: 0.9,
            tileSize: 256
          })
          if (this.interactiveMap) {
            this.interactiveMap.layers.add(this.weatherLayer, 'labels')
          }
        }

        if (!this.scaleBarRendered) {
          this.scaleBarRendered = true
          import('@/utils/azure-maps-scale-bar-control.js').then(
            sb => {
              this.scaleBarImport = sb
              this.toggleScaleBar(true)
            })
        }
      })
    },

    async getTractsToMark () {
      const tractsToMark = []
      if (this.showNearbyTracts) {
        const activeTracts = await this.fetchTractsOnce(new TractFilter())
        activeTracts.forEach(t => { tractsToMark.push(t) })
      }
      if (this.tracts) {
        this.tracts.forEach(t => { tractsToMark.push(t) })
      }
      const tractsSortedByLatitude = tractsToMark.length > 1 ? tractsToMark.sort((t1, t2) => t2.spot.latitude - t1.spot.latitude) : tractsToMark
      if (this.isLocationMap) {
        this.tract = this.tracts[0]
        this.tractId = this.tract.tractId
      }
      if (this.tractId) {
        if (this.focusedTractNotInList) {
          tractsSortedByLatitude.push(this.tract)
        } else {
          tractsSortedByLatitude
            .push(tractsSortedByLatitude
              .splice(tractsSortedByLatitude
                .indexOf(tractsSortedByLatitude
                  .filter(t => t.tractId === this.tractId)[0]), 1)[0]) // Moves focused tract so its marker appears in front
        }
      }
      return tractsSortedByLatitude
    },

    async getTicketsToMark () {
      return this.tickets
        .filter(t => t.status === TicketStatus.InTransit.value && this.transitStatusFilter.includes(t.transitStatus))
        .toSorted((t1, t2) => t2.latitude - t1.latitude)
    },

    async toggleWeatherOverlay () {
      const options = [undefined, 'radar', 'infrared']
      this.currentWeatherOverlay = options[(options.indexOf(this.currentWeatherOverlay) + 1) % options.length]
      this.weatherLayer.setOptions({
        tileUrl: this.currentWeatherOverlay ? `https://{azMapsDomain}/map/tile?api-version=2.0&tilesetId=${this.weatherLayerName}&zoom={z}&x={x}&y={y}` : undefined
      })
    },

    async toggleScaleBar (firstRender = false) {
      if (firstRender) {
        this.currentScaleBar = this.scaleBarOptions[parseInt(localStorage.getItem(LocalStorageKeys.TRACT_MAP_SCALE_BAR_TYPE))] ?? this.currentScaleBar
      } else {
        const options = this.scaleBarOptions
        this.currentScaleBar = options[(options.indexOf(this.currentScaleBar) + 1) % options.length]
        localStorage.setItem(LocalStorageKeys.TRACT_MAP_SCALE_BAR_TYPE, options.indexOf(this.currentScaleBar))
        if (this.scaleBarControl) {
          this.interactiveMap.controls.remove(this.scaleBarControl)
          this.scaleBarControl = undefined
        }
      }
      if (this.currentScaleBar) {
        this.scaleBarControl = new this.scaleBarImport.atlas.control.ScaleBarControl({ maxBarLength: 120, units: this.currentScaleBar })
        this.interactiveMap.controls.add(this.scaleBarControl)
      }
    },

    async clearAllBoundaries () {
      if (this.mapLayers.length > 0) {
        this.mapLayers.forEach(layer => {
          if (this.interactiveMap) {
            this.interactiveMap.layers.remove(layer.getId())
          }
        })
      }
      this.mapLayers = []
      if (this.boundarySources.length > 0) {
        this.boundarySources.forEach(source => {
          source.clear()
          this.interactiveMap.sources.remove(source.getId())
        })
      }
      this.boundarySources = []
    },

    async setBoundary (tractId) {
      if (!this.boundariesLoading) {
        this.boundariesLoading = true
        let shapefile
        try {
          shapefile = await shapefileClient.getShapefile(tractId)
        } catch (e) {
          this.boundariesLoading = false
          return
        }
        const geojson = await shp(shapefile)
        const boundarysource = new atlas.source.DataSource()
        boundarysource.setShapes(geojson)
        this.boundarySources.push(boundarysource)
        this.interactiveMap.sources.add(boundarysource)
        this.mapLayers.push(new atlas.layer.PolygonLayer(boundarysource, null, {
          strokeColor: 'error',
          strokeWidth: 2,
          filter: ['any', ['==', ['geometry-type'], 'Polygon'], ['==', ['geometry-type'], 'MultiPolygon']]
        }))
        this.mapLayers.push(new atlas.layer.LineLayer(boundarysource, null, {
          strokeColor: 'white',
          strokeWidth: 2,
          filter: ['any', ['==', ['geometry-type'], 'Polygon'], ['==', ['geometry-type'], 'MultiPolygon']]
        }))
        if (this.interactiveMap) {
          this.interactiveMap.layers.add(this.mapLayers, 'labels')
        }
        this.boundariesLoading = false
      }
    },

    addMarkers (markers) {
      markers.forEach(marker => {
        this.interactiveMap.events.add('click', marker, () => {
          if (this.openMarker.marker?.options.popup) {
            this.openMarker.marker.options.popup.remove()
          }
          this.openMarker = {
            marker: marker,
            justAdded: true
          }
          marker.togglePopup()
        })

        this.interactiveMap.markers.add(marker)
      })
    },

    createTractMarkers (tractsSorted, animateSearched = true) {
      const tractMarkers = []
      tractsSorted.forEach(tract => {
        const coordinate = [tract.spot.longitude, tract.spot.latitude]
        const progress = this.getHarvestedProgress(tract)
        const popup = this.getTractPopup(tract, progress)
        const markerSVG = this.getMarkerSVG(progress, tract.type.category !== 1 ? '#44e94b' : '#FFFFFF', tract.type.category !== 1 ? '#0d7011' : '#808080') // old green was #39ff14
        const htmlContent = tract.tractId === this.tractId && animateSearched
          ? `<div class="important-tract bounce"> ${markerSVG} </div><div class="${tract.type.category !== 1 ? 'stumpage' : 'delivered'}"/>`
          : tract.tractId === this.tractId
            ? `<div class="important-tract"> ${markerSVG} </div>`
            : markerSVG

        const markerOptions = {
          htmlContent,
          position: coordinate,
          popup
        }
        tractMarkers.push(new atlas.HtmlMarker(markerOptions))
      })
      return tractMarkers
    },

    createTicketMarkers (ticketsSorted = undefined) {
      const ticketMarkers = []
      if (ticketsSorted) {
        ticketsSorted.forEach(ticket => {
          const coordinate = [ticket.longitude, ticket.latitude]
          const popup = this.getTicketPopup(ticket)
          const markerSVG = mapMarkers.load(this.getColorByTransitStatus(ticket.transitStatus))
          const htmlContent = markerSVG

          const markerOptions = {
            htmlContent,
            position: coordinate,
            popup
          }
          ticketMarkers.push(new atlas.HtmlMarker(markerOptions))
        })
      } else {
        ticketMarkers.push(new atlas.HtmlMarker({
          position: [this.ticket.longitude, this.ticket.latitude],
          htmlContent: this.noLastKnownMarker(this.ticket) ? mapMarkers.load('#F9F6DF') : mapMarkers.home,
          popup: this.getTicketPopup(this.ticket)
        }))
        if (!this.noLastKnownMarker(this.ticket)) {
          ticketMarkers.push(new atlas.HtmlMarker({
            position: [this.ticket.lastKnownLongitude, this.ticket.lastKnownLatitude],
            htmlContent: mapMarkers.load('#F9F6DF'),
            popup: this.getTicketPopup(this.ticket)
          }))
        }
      }

      return ticketMarkers
    },

    centerVisibleTractPoints (tracts) {
      const targetOptions = (tracts.length > 1) ? {
        bounds: atlas.data.BoundingBox.fromPositions(tracts
          .map(t => new atlas.data.Position(t.spot.longitude, t.spot.latitude, 0))),
        padding: 150
      } : tracts.length > 0 ? {
        center: [tracts[0].spot.longitude, tracts[0].spot.latitude],
        zoom: 14
      } : this.ticket ? {
        center: [this.ticket.longitude, this.ticket.latitude],
        zoom: 14
      } : {
        center: defaultCenterCoords,
        zoom: 3
      }

      this.interactiveMap.setCamera({
        ...targetOptions,
        type: 'fly',
        duration: 500
      })
    },

    centerVisibleTicketPoints (tickets) {
      const targetOptions = (tickets.length > 1) ? {
        bounds: atlas.data.BoundingBox.fromPositions(tickets
          .map(t => new atlas.data.Position(t.longitude, t.latitude, 0))),
        padding: 150
      } : tickets.length > 0 ? {
        center: [tickets[0].longitude, tickets[0].latitude],
        zoom: 14
      } : this.ticket ? {
        center: [this.ticket.longitude, this.ticket.latitude],
        zoom: 14
      } : {
        center: defaultCenterCoords,
        zoom: 3
      }

      this.interactiveMap.setCamera({
        ...targetOptions,
        type: 'fly',
        duration: 500
      })
    },

    getTractPopup (tract, progress) {
      if (progress >= 1) {
        progress = 1
      }
      const css =
        'color: #F9F6DF; font-weight: bold; font-size: 20px; padding: 7px;'

      const interiorHtml = `
      <p id="map-marker-popup-name">${tract.name}</p>
      <hr class="map-marker-popup-divider">
      <p class="map-marker-popup-line">${tract.type.name}</p>
      <p class="map-marker-popup-line">${TractTypeCategory.fromInt(tract.type.category)}</p>
      <p class=" ${tract.loggingAccessibilityTag ? 'map-marker-popup-access' : 'omit'}">${tract.loggingAccessibilityTag || ''}</p>
      <p class="map-marker-popup-line">${tract.rainfallDataUpdatedOn ? this.$t('24HPrecipitationInches', { rainfall: tract.singleDayRainfallInches }) : this.$t('precipitationDataUnavailable')}</p>
      
      <span class="map-marker-popup-harvested-tons">${numberWithCommas(tract.harvestedTons, 3)} t</span>

      <div
        style='border: 2px solid #F9F6DF;
        height: 20px;
        width: 100%;
      '>
        <div style='
          margin-bottom:0.5%;
          height: 100%;
          width: ${progress * 100}%;
          background-color: #D15F27;
        '/>
      </div>
      <div class="tract-popup-tail"></div>
`

      const popupOptions = {
        content: `<div style='${css}'>${interiorHtml}</div>`,
        pixelOffset: [0, tract.tractId === this.tractId ? -45 : -35],
        closeButton: false,
        showPointer: true
      }

      return new atlas.Popup(popupOptions)
    },

    getTicketPopup (ticket) {
      const css =
        'color: #F9F6DF; font-weight: bold; font-size: 20px; padding: 7px;'

      const tractHtml = ticket.tract ? `<span class="mdi mdi-crosshairs-gps map-marker-popup-line"/> <span class="map-marker-popup-line">${ticket.tract}</span> <br>` : ''
      const fromAccountHtml = ticket.fromAccount ? `<span class="mdi mdi-account-arrow-right map-marker-popup-line"/> <span class="map-marker-popup-line">${ticket.fromAccount}</span> <br>` : ''

      const interiorHtml = this.ticket
        ? `
        <span id="map-marker-popup-name">#${ticket.ticketNumber}</span>
        <hr class="map-marker-popup-divider">
        ${fromAccountHtml}
        ${tractHtml}
        <span class="mdi ${ticket.isExternal ? 'mdi-navigation' : 'mdi-home'} map-marker-popup-line"/>
        <span class="map-marker-popup-line">${ticket.destination}</span> 
        <br>
        <span class="mdi mdi-pine-tree-variant map-marker-popup-line"/> 
        <span class="map-marker-popup-line">${ticket.product}</span> 
        <br>
        <span class="map-marker-popup-line" style="font-style:italic;font-weight:300;">Created ${utcToLocalDate(ticket.loadCreatedAt)}</span>
        <div class="ticket-popup-tail"></div>
        `
        : `
        <div style="display: flex; justify-content: space-between;">
          <div>
            <span id="map-marker-popup-name">#${ticket.ticketNumber}</span>
          </div>
          <div class="map-popup-spacer"></div>
          <div>
            <span class="map-marker-popup-trailer-id">${ticket.trailerIdentifier}</span>
            <span class="mdi mdi-truck map-marker-popup-trailer-id"/> 
          </div>
        </div>
        <hr class="map-marker-popup-divider">
        <span class="map-marker-popup-transit-status" style="color:${this.getColorByTransitStatus(ticket.transitStatus)};">${this.$t(this.transitStatus.find(ts => ts.value === ticket.transitStatus).label)}</span> 
        <br>
        ${fromAccountHtml}
        ${tractHtml}
        <br>
        <span class="mdi ${ticket.isExternal ? 'mdi-navigation' : 'mdi-home'} map-marker-popup-line"/>
        <span class="map-marker-popup-line">${ticket.destination}</span> 
        <br>
        <span class="mdi mdi-pine-tree-variant map-marker-popup-line"/> 
        <span class="map-marker-popup-line">${ticket.product}</span> 
        <br>
        <span class="map-marker-popup-line" style="font-style:italic;font-weight:300;">Created ${utcToLocalDate(ticket.loadCreatedAt)}</span>
        <div class="ticket-popup-tail"></div>
        `
      const popupOptions = {
        content: `<div style='${css}'>${interiorHtml}</div>`,
        pixelOffset: [0, -35],
        closeButton: false,
        showPointer: true
      }

      return new atlas.Popup(popupOptions)
    },

    getMarkerSVG (progress, color, outlineColor) {
      if (progress <= 0.05) {
        return mapMarkers.empty(color, outlineColor)
      } else if (progress <= 0.35) {
        return mapMarkers.twentyFive(color, outlineColor)
      } else if (progress > 0.35 && progress < 0.75) {
        return mapMarkers.fifty(color, outlineColor)
      } else if (progress >= 0.75 && progress <= 0.89) {
        return mapMarkers.seventyFive(color, outlineColor)
      } else if (progress >= 0.9) {
        return mapMarkers.oneHundred(color, outlineColor)
      }
    },

    getHarvestedProgress (tract) {
      let progress = 0.00

      const harvested = tract.harvestedTons
      const expected = tract.expectedTons

      if (expected <= 0 || harvested <= 0) {
        return progress
      } else {
        progress = parseFloat((harvested / expected).toFixed(2))

        if (progress > 1) {
          return 1
        } else {
          return progress
        }
      }
    },

    tractChosen (val) {
      if (val) {
        this.tractId = val.tractId
        this.tract = val
      }
    },

    tractCleared () {
      this.tractId = null
      this.tract = null
    },

    applyFilter (filter) {
      this.$emit('filter-applied', filter)
    },

    onResize () {
      if (this.interactiveMap) {
        setTimeout(() => this.interactiveMap.map.resize(), mapResizeTimeout)
      }
    },

    noLastKnownMarker () {
      const ticket = this.ticket
      const imagesOrWeights = ticket.hasImages || ticket.status !== TicketStatus.InTransit.value
      const noLastKnownLocation = ticket.latitude === ticket.lastKnownLatitude && ticket.longitude === ticket.lastKnownLongitude
      return noLastKnownLocation || imagesOrWeights
    },

    getCheckboxLabel (name, value) {
      return `${this.$t(name)} (${this.tickets.filter(t => t.transitStatus === value && t.status === TicketStatus.InTransit.value).length})`
    },

    getColorByTransitStatus (status) {
      return status === TransitStatus.Ready.value ? '#44e94b' : '#f55a4e'
    }
  }
}
</script>

<style scoped src="@/styles/map-scoped.css"/>
<style src="@/styles/map-global.css"/>
