<template>
  <Icon
    :icon="icon(status)"
    :iconColor="isHeader ? 'tertiary' : color(status)"
    :tooltipColor="isHeader ? 'primary' : undefined"
    :tooltipText="statusLabels[status]"
    :small="!isHeader"
    margin=""
  />
</template>

<script>
import { TractStatus } from '@/utils/Enumerations.js'
export default {
  name: 'TractStatusIcon',

  props: {
    status: {
      type: Number
    },
    isHeader: {
      type: Boolean
    }
  },

  components: {
    Icon: () => import('@/components/helper/Icon.vue')
  },

  computed: {
    statusLabels () {
      return TractStatus.names
    }
  },

  methods: {
    icon (status) {
      return TractStatus.forInt(status).details.icon
    },

    color (status) {
      return TractStatus.forInt(status).details.color
    }
  }
}
</script>
