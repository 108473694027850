<template>
  <v-card outlined rounded :min-height="minHeight">
    <v-card-title>
      <v-spacer/>
      <Icon
      icon="mdi-pencil"
      v-if="showEdit"
      iconColor="success"
      dataTestId="address-edit-button"
      :tooltipText="$t('edit')"
      tabindex="0"
      @icon-clicked="editAddress"/>
      <Icon
      icon="mdi-delete-forever"
      iconColor="error"
      v-if="showDelete"
      dataTestId="address-delete-button"
      :tooltipText="$t('delete')"
      tabindex="0"
      @icon-clicked="confirmDeleteDialog = true"/>
      <Icon
      v-if="showType"
      iconColor="black"
      dataTestId="address-type"
      :icon="address.address.type === 0 ? 'mdi-package-variant' : 'mdi-currency-usd'"
      :tooltipText="address.address.type === 0 ? $t('shippingAddress') : $t('billingAddress')"
      />
    </v-card-title>
    <v-card-text class="mt-n8">
      <v-container data-testid="address-card" fluid>
        <v-row no-gutters>
          <v-col data-testid="address-card-line1" cols="12">
            <span>{{address.address.addressLine1}}</span>
          </v-col>
          <v-spacer/>
          <v-col data-testid="address-card-line2" cols="12">
            <span>{{address.address.addressLine2}}</span>
          </v-col>
          <v-col data-testid="address-card-city-state-zip" cols="12" v-if="address.address.city?.length > 0">
            <span>{{`${address.address.city}, ${address.address.state}`}} {{address.address.postalCode}}</span>
          </v-col>
          <v-col cols="12">
            <br v-if="address.address.addressLine2 === ''"/>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-dialog v-model="confirmDeleteDialog" width="400">
      <ConfirmDialog
      :title="$t('deleteAddressTitle')"
      :body="$t('deleteAddressBody')"
      @confirm="deleteAddress"
      @cancel="confirmDeleteDialog = false"/>
    </v-dialog>
  </v-card>
</template>

<script>
export default {
  name: 'AddressCard',

  components: {
    Icon: () => import('@/components/helper/Icon.vue'),
    ConfirmDialog: () => import('@/components/helper/ConfirmDialog.vue')
  },

  props: {
    address: {
      type: Object,
      required: true
    },
    showEdit: {
      type: Boolean,
      default: true
    },
    showDelete: {
      type: Boolean,
      default: true
    },
    showType: {
      type: Boolean,
      default: false
    },
    minHeight: {
      type: Number,
      default: undefined
    }
  },

  data: () => ({
    confirmDeleteDialog: false
  }),

  methods: {
    editAddress () {
      this.$emit('edit-address-clicked', this.address)
    },

    deleteAddress () {
      this.confirmDeleteDialog = false
      this.$emit('delete-address-clicked', this.address)
    }
  }
}
</script>
