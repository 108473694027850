<template>
  <GridChartCard
  :title="title"
  :hasData="hasData"
  ref="chartCard"
  @download-csv="data.generateCSV()"
  @draw="createChart"
  />
</template>

<script>
import { Chart } from 'chart.js'
import { BarChartTonsByCertification } from '@/model/BarChart.js'
import { getChartColors } from './ChartColors'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'BarChartCardTonsByCertification',

  components: {
    GridChartCard: () => import('./GridChartCard.vue')
  },

  props: {
    data: {
      type: BarChartTonsByCertification,
      required: true
    },
    selectedDestName: {
      type: String,
      required: false,
      default: ''
    },
    isInteractive: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    chart: null
  }),

  watch: {
    yValues: {
      handler (_) {
        this.$nextTick(() => {
          this.$refs.chartCard.draw()
        })
      },
      deep: true
    }
  },

  computed: {
    ...mapGetters('dashboard', ['interactiveChartTooltipFooter']),

    title () {
      return (this.selectedDestName !== '')
        ? `${this.$t('tonsByTractCertification')} (${this.selectedDestName})`
        : this.$t('tonsByTractCertification')
    },

    headerStyle () {
      return 'background-color: #d15f27; color: white;'
    },

    xValues () {
      return this.data.xValues
    },

    yValues () {
      return this.data.yValues()
    },

    elementId () {
      return this.data.elementId
    },

    hasData () {
      return this.data.yValues().reduce((v, p) => v + p, 0) > 0
    }
  },

  methods: {
    ...mapActions('dashboard', ['getWidgetInteractionStatus']),

    async onDataClicked (e) {
      if (this.isInteractive) {
        const element = this.chart.getElementsAtEventForMode(e, 'point', true)
        const data = this.chart.config._config.data
        let xAxisValue = data.labels[element[0]?.index]
        if (xAxisValue === 'Uncertified') xAxisValue = 'N/A'
        if (await this.getWidgetInteractionStatus({ widgetId: this.widgetId, x: xAxisValue })) this.$emit('data-clicked', { xAxisValue: xAxisValue })
      }
    },

    createChart (canvas) {
      const colors = getChartColors(this.yValues.filter(y => y > 0).length)
      const chart = new Chart(canvas, {
        type: 'bar',
        data: {
          labels: this.xValues,
          datasets: [{
            label: '',
            data: this.yValues,
            backgroundColor: colors
          }]
        },
        options: {
          maintainAspectRatio: false,
          responsive: true,
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              titleFont: {
                family: 'Roboto, sans-serif',
                size: '14',
                weight: 'bolder'
              },
              bodyFont: {
                family: 'Roboto, sans-serif'
              },
              footerColor: '#FFFFFFAA',
              footerFont: {
                family: 'Roboto, sans-serif',
                weight: 'normal',
                style: 'italic'
              }
            }
          },
          onClick: (e) => this.onDataClicked(e)
        }
      })
      if (this.isInteractive) chart.options.plugins.tooltip.callbacks.footer = () => this.$t('clickToViewTickets')
      this.chart = chart
    }
  }
}
</script>
