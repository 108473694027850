<template>
  <v-card id="sbp-email-dialog" :class="containerClass">
    <v-card-title class="headline white--text primary">
      <span>
        {{$t('emailSettlementReport')}}
      </span>
      <v-spacer/>
      <BaseDialogActions hideRefresh hideFullscreen/>
    </v-card-title>
    <v-card-text v-if="payeeContactsLoader.loading" style="text-align: center">
      <v-progress-circular indeterminate color="primary" class="ma-12"/>
    </v-card-text>
    <v-card-text v-else class="mt-6">
      <v-row dense no-gutters>
        <v-col cols="12">
          <v-select
            :items="payeeContacts"
            v-model="selectedContact"
            :label="$t('sendTo')"
            item-value="accountContactId"
            return-object
          >
            <template #item="{ item, on, attrs }">
              <v-list-item v-on="on" v-bind="attrs">
                <v-list-item-content>
                  <v-list-item-title>
                    <v-icon v-if="item.receivesSettlementReport" color="black" small class="mb-1">mdi-receipt-text-send-outline</v-icon>
                    {{item.person.firstName + ' ' + item.person.lastName}}
                  </v-list-item-title>
                  <v-list-item-subtitle>{{ item.person.email }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
            <template #selection="{ item, on, attrs }">
              <v-list-item v-on="on" v-bind="attrs">
                <v-list-item-content>
                  <v-list-item-title>
                    <v-icon v-if="item.receivesSettlementReport" color="black" small class="mb-1">mdi-receipt-text-send-outline</v-icon>
                    {{item.person.firstName + ' ' + item.person.lastName}}
                  </v-list-item-title>
                  <v-list-item-subtitle>{{ item.person.email }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
            <template #append-outer>
              <Icon
                icon="mdi-plus"
                iconColor="primary"
                :tooltipText="$t('createContact')"
                :small="false"
                @icon-clicked="openContactForm"
                elementId="create-contact"
              />
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row class="px-2 pb-2">
        <v-btn
          color="primary"
          outlined
          @click="downloadAndAttach"
          :disabled="formDisabled"
          id="download-and-attach">
          {{$t('downloadAndAttach')}}
        </v-btn>
        <v-spacer/>
        <v-btn
          color="primary"
          @click="sendViaAngel"
          :disabled="formDisabled"
          id="send">
          {{ $t('send') }}
        </v-btn>
      </v-row>
    </v-card-text>
    <Dialog :stateId="dialogId">
      <ContactForm @contact-created="saveAndRefreshContacts" @close="closeDialogsAtOrAbove(dialogId)"/>
    </Dialog>
  </v-card>
</template>

<script>
import { ResourceLoader } from '../../../../utils/ResourceLoader'
import { mapActions } from 'vuex'
import { uniqueDialogId } from '../../../../utils/componentHelpers'
export default {
  name: 'SBPEmailDialog',

  components: {
    ContactForm: () => import('@/components/account/contacts/ContactForm.vue'),
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue'),
    Icon: () => import('@/components/helper/Icon.vue')
  },

  props: {
    payeeId: {
      type: Number,
      required: true
    },
    containerClass: {
      type: String,
      default: undefined,
      required: false
    }
  },

  data: () => ({
    dialogId: uniqueDialogId('sbp-email-dialog'),
    payeeContacts: [],
    selectedContact: undefined,
    payeeContactsLoader: ResourceLoader.empty
  }),

  computed: {
    formDisabled () {
      return !this.selectedContact?.person?.email
    }
  },

  async mounted () {
    this.payeeContactsLoader = new ResourceLoader(async () => {
      this.payeeContacts = (this.payeeId > 0)
        ? await this.fetchContacts(this.payeeId)
        : []
      this.selectedContact = this.payeeContacts?.find(c => c.receivesSettlementReport) || this.payeeContacts?.[0]
    })
    await this.payeeContactsLoader.load()
  },

  methods: {
    ...mapActions('account-contacts', ['fetchContacts', 'createContact']),
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),

    openContactForm () {
      this.openOrUpdateDialog({ id: this.dialogId, width: '50%' })
    },

    async saveAndRefreshContacts (contact) {
      this.closeDialogsAtOrAbove(this.dialogId)
      const r = {
        identifiers: { accountId: this.payeeId },
        contact
      }
      const newContact = await this.createContact(r)
      this.payeeContacts = (this.payeeId > 0)
        ? await this.fetchContacts(this.payeeId)
        : []
      this.selectedContact = this.payeeContacts.find(pc => pc.accountContactId === newContact.accountContactId)
    },

    sendViaAngel () {
      this.$emit('submit', {
        type: 'upload',
        accountId: this.payeeId,
        accountContactId: this.selectedContact.accountContactId
      })
    },

    downloadAndAttach () {
      this.$emit('submit', {
        type: 'download',
        emailAddress: this.selectedContact.person.email,
        fileFormat: this.fileFormat
      })
    }
  }
}
</script>
