import ApiServer from '@/utils/api/ApiServer.js'

export default {
  async fetchSamsaraIntegrationRequests (_) {
    const path = 'samsara/integrationrequests'
    const response = await ApiServer.get(path)
    return response.data
  },

  async fetchSamsaraConfiguration (context) {
    const path = 'samsara/config'
    const response = await ApiServer.get(path)
    context.commit('initSamsaraConfiguration', response.data)
    return response.data
  },

  async createSamsaraConfiguration (_, createRequest) {
    const path = 'samsara/config'
    const response = await ApiServer.post(path, createRequest)
    return response.data
  },

  async updateSamsaraConfiguration (_, updateRequest) {
    const path = 'samsara/config'
    const response = await ApiServer.put(path, updateRequest)
    return response.data
  },

  async fetchSamsaraApiToken (_) {
    const path = 'samsara/config/apitoken'
    const response = await ApiServer.get(path)
    return response.data
  },

  async deleteSamsaraConfiguration (context) {
    const path = 'samsara/config'
    const response = await ApiServer.delete(path)
    context.commit('wipeSamsaraConfiguration')
    return response.data
  },

  async syncTags (_) {
    const path = 'samsara/synctags'
    const response = await ApiServer.post(path)
    return response.data
  },

  async patchSamsaraConfiguration (_, body) {
    const path = 'samsara/config'
    const response = await ApiServer.patch(path, body)
    return response.data
  },

  resetSamsaraConfiguration: (context) => context.commit('resetSamsaraConfiguration')
}
