<template>
  <v-row>
    <v-col>
      <v-row class="mt-1">
        <v-col cols="12">
          <v-container fluid>
            <v-expansion-panels popout multiple tile v-model="openPanels">
              <v-expansion-panel v-if="!noTicketsPayables" value="ticketPayables">
                <v-expansion-panel-header>
                  {{$t('ticketPayables')}}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <span v-if="primaryTickets.length > 0">
                    <p class="subtitle-1 mt-3 mb-n2">{{$t('contractPayments')}}</p>
                    <TicketFinancialsTable
                    :entries="primaryTickets"
                    @aggregateSelected="contractPaymentSelected"/>
                  </span>
                  <span v-if="recoveries.length > 0">
                    <p class="subtitle-1 mt-6 mb-n2">{{ $t('recoveries') }}</p>
                    <RecoveriesAggregations
                    :recoveries="recoveries"/>
                  </span>
                  <span v-if="primaryCorrections.length > 0">
                    <p class="subtitle-1 mt-6 mb-n2">{{$t('corrections')}}</p>
                    <TicketFinancialsTable
                    :entries="primaryCorrections"
                    isCorrections
                    @aggregateSelected="correctionSelected"/>
                  </span>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel v-if="!isByproducts && transferPayables" value="transferPayables">
                <v-expansion-panel-header>
                  {{$t('transferPayables')}}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <TicketFinancialsTable
                  v-if="tickets.transfers.payables.length > 0"
                  :entries="tickets.transfers.payables"
                  @aggregateSelected="transferPayableSelected"/>

                  <span v-if="corrections.transfers.payables.length > 0">
                    <p class="subtitle-1 mt-6 mb-n2">{{$t('corrections')}}</p>
                    <TicketFinancialsTable
                    :entries="corrections.transfers.payables"
                    isCorrections
                    @aggregateSelected="correctionSelected"/>
                  </span>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel v-if="!isByproducts && lysPayables" value="logYardSalePayables">
                <v-expansion-panel-header>
                  {{$t('logYardSalePayables')}}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <TicketFinancialsTable
                  v-if="tickets.lys.payables.length > 0"
                  :entries="tickets.lys.payables"
                  @aggregateSelected="logYardSalePayableSelected"/>

                  <span v-if="corrections.lys.payables.length > 0">
                    <p class="subtitle-1 mt-6 mb-n2">{{$t('corrections')}}</p>
                    <TicketFinancialsTable
                    :entries="corrections.lys.payables"
                    isCorrections
                    @aggregateSelected="correctionSelected"/>
                  </span>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel v-if="!isByproducts && miscPayments.tractPayments.length > 0" value="tractPayments">
                <v-expansion-panel-header>
                  {{$t('tractPayments')}}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <MiscPaymentsAggregation
                  :miscPayments="miscPayments.tractPayments"
                  :miscPaymentType="MiscPaymentType.TractPayment"/>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel v-if="!isByproducts && miscPayments.advances.length" value="advances">
                <v-expansion-panel-header>
                  {{$t('advances')}}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <AdvancePayablesAggregations
                  :advancePayables="miscPayments.advances"/>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel v-if="!isByproducts && miscPayments.accountPayments.accountPaymentsAP.length > 0" value="accountPayments">
                <v-expansion-panel-header>
                  {{ $t('accountPayments') }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <MiscPaymentsAggregation
                  :miscPayments="miscPayments.accountPayments.accountPaymentsAP"
                  :miscPaymentType="MiscPaymentType.AccountPayment"/>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-container>
        </v-col>
      </v-row>
      <NoData v-if="showNoData" class="mb-4">
        {{ $t('noDataForSelection') }}
      </NoData>
    </v-col>
    <v-dialog v-model="detailDialogOpen" width="900px">
      <TicketwiseFinancialBreakdown
      v-if="detailDialogOpen"
      :isCorrection="focusedContractPaymentIsCorrection"
      :payableSummary="focusedContractPayment"
      :includeRecoveries="!focusedPayableIsTransfer"
      :isByproducts="isByproducts"
      @close="closeDialogs"/>
    </v-dialog>
  </v-row>
</template>

<script>
import { formatMoney } from '@/utils/NumericMutations'
import AdjustingEntriesHeaders from '@/headers/AdjustingEntries.js'
import { MiscPaymentType } from '../../../../utils/Enumerations'
export default {
  name: 'PayablesAggregations',

  components: {
    TicketwiseFinancialBreakdown: () => import('@/components/accounting/adjusting-entries/v1/TicketwiseFinancialBreakdown.vue'),
    TicketFinancialsTable: () => import('@/components/accounting/adjusting-entries/v1/TicketFinancialsTable.vue'),
    MiscPaymentsAggregation: () => import('@/components/accounting/adjusting-entries/v1/MiscPaymentsAggregation.vue'),
    AdvancePayablesAggregations: () => import('@/components/accounting/adjusting-entries/v1/AdvancePayablesAggregations.vue'),
    RecoveriesAggregations: () => import('@/components/accounting/adjusting-entries/v1/RecoveriesAggregations.vue'),
    NoData: () => import('@/components/core/NoData.vue')
  },

  props: {
    isByproducts: {
      type: Boolean,
      default: false
    },
    tickets: {
      type: Object,
      required: true
    },
    corrections: {
      type: Object,
      required: true
    },
    miscPayments: {
      type: Object,
      required: true
    },
    propRecoveries: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    detailDialogOpen: false,
    focusedContractPayment: undefined,
    focusedContractPaymentIsCorrection: false,
    focusedPayableIsTransfer: false,
    openPanels: [],
    MiscPaymentType
  }),

  mounted () {
    if (this.isByproducts) {
      this.openPanels = !this.noTicketsPayables ? [0] : []
    } else {
      if (!this.noTicketsPayables +
        (this.transferPayables) +
        (this.lysPayables) +
        (this.miscPayments.tractPayments.length > 0) +
        (this.miscPayments.advances.length > 0) +
        (this.miscPayments.accountPayments.accountPaymentsAP.length > 0) === 1
      ) {
        this.openPanels = [0]
      }
    }
  },

  computed: {
    transferPayables () {
      return this.tickets.transfers.payables.length > 0 || this.corrections.transfers.payables.length > 0
    },
    lysPayables () {
      return this.tickets.lys.payables.length > 0 || this.corrections.lys.payables.length > 0
    },
    contractPaymentHeaders () {
      return AdjustingEntriesHeaders.ticketContractPayments()
    },
    correctionsHeaders () {
      return AdjustingEntriesHeaders.ticketContractPayments()
    },
    noTicketsPayables () {
      return this.primaryTickets.length === 0 && this.primaryCorrections.length === 0 && this.recoveries.length === 0
    },
    primaryTickets () {
      return !this.isByproducts ? this.tickets.logs.payables : this.tickets.byproducts.payables
    },
    primaryCorrections () {
      return !this.isByproducts ? this.corrections.logs.payables : this.corrections.byproducts.payables
    },
    recoveries () {
      return !this.isByproducts ? this.propRecoveries.logs : this.propRecoveries.byproducts
    },
    showNoData () {
      const otherLogsFinancials = this.tickets.lys.payables.length > 0 ||
        this.tickets.transfers.payables.length > 0 ||
        this.miscPayments.tractPayments.length > 0 ||
        this.miscPayments.advances.length > 0 ||
        this.miscPayments.accountPayments.accountPaymentsAP.length > 0 ||
        this.corrections.lys.payables.length > 0 ||
        this.corrections.transfers.payables.length > 0

      return this.noTicketsPayables && (this.isByproducts || !otherLogsFinancials)
    },
    contractColor () {
      return this.isByproducts ? 'byproduct' : 'primary'
    },
    borderStyle () {
      return `border: 3px solid ${this.isByproducts ? '#363636' : '#D15F27'}`
    }
  },

  methods: {
    formatMoney,

    contractPaymentSelected (contractPayment) {
      this.detailDialogOpen = true
      this.focusedContractPayment = contractPayment
    },

    correctionSelected (correction) {
      this.focusedContractPayment = correction
      this.focusedContractPaymentIsCorrection = true
      this.detailDialogOpen = true
    },

    transferPayableSelected (transferPayable) {
      this.focusedContractPayment = transferPayable
      this.focusedPayableIsTransfer = true
      this.detailDialogOpen = true
    },

    logYardSalePayableSelected (logYardSalePayable) {
      this.focusedContractPayment = logYardSalePayable
      this.focusedPayableIsLogYardSale = true
      this.detailDialogOpen = true
    },

    closeDialogs () {
      this.detailDialogOpen = false
      this.focusedContractPayment = undefined
      this.focusedContractPaymentIsCorrection = false
      this.focusedPayableIsTransfer = false
      this.focusedPayableIsLogYardSale = false
    }
  }
}
</script>
