<template>
  <div style="width: 100; height: 100%;">
    <v-data-table
      :headers="headers"
      :loading="recoveriesLoading"
      :items="recoveries"
      :no-data-text="$t('noRecoveries')">
        <template #item.recoveryMethod="{item}">
          <span>{{recoveryMethods[item.recoveryMethod]}}</span>
        </template>
        <template #item.ticketNumber="{item}">
          <span>{{item.ticketNumber ?? $t('notApplicable')}}</span>
        </template>
        <template #item.extTicketNumber1="{item}">
          <span>{{item.extTicketNumber1 ?? $t('notApplicable')}}</span>
        </template>
        <template #item.extTicketNumber2="{item}">
          <span>{{item.extTicketNumber2 ?? $t('notApplicable')}}</span>
        </template>
        <template #item.amount="{item}">
          <span>{{formatMoney(item.amount)}}</span>
        </template>
        <template #item.paidAt="{item}">
          <span>{{utcToLocalDate(item.paidAt)}}</span>
        </template>
        <template #item.actions="{item}">
          <Icon
          iconColor="black"
          icon="mdi-currency-usd"
          dataTestId="recovery-detail-button"
          :tooltipText="$t('viewPayable')"
          :disabled="!recoveryHasPayable(item)"
          @icon-clicked="viewPayable(item)"/>
          <Icon
          iconColor="success"
          icon="mdi-pencil"
          dataTestId="recovery-edit-button"
          :tooltipText="$t('edit')"
          :disabled="!canEditRecovery(item) || !userAssignedClaim(UserClaims.ContractManager)"
          @icon-clicked="$emit('edit-recovery', item)"/>
          <Icon
          iconColor="error"
          icon="mdi-delete-forever"
          dataTestId="recovery-delete-button"
          :tooltipText="$t('delete')"
          :disabled="!canDeleteRecovery(item) || !userAssignedClaim(UserClaims.ContractManager)"
          @icon-clicked="$emit('delete-recovery', item)"/>
        </template>
      </v-data-table>
      <Dialog :stateId="dialogId" maxWidth="70%">
        <PayableDetail :payableId="focusedPayableId"/>
      </Dialog>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { formatMoney } from '@/utils/NumericMutations.js'
import RecoveryHeaders from '@/headers/Recovery.js'
import { RecoveryMethods, UserClaims } from '@/utils/Enumerations.js'
import { utcToLocalDate } from '@/utils/DateFormatter.js'
import { uniqueDialogId } from '../../../utils/componentHelpers'
import { userAssignedClaim } from '@/utils/ClaimUtility.js'

export default {
  name: 'RecoveriesTable',
  props: {
    recoveries: Array
  },
  components: {
    Dialog: () => import('@/components/Dialog.vue'),
    Icon: () => import('../../helper/Icon.vue'),
    PayableDetail: () => import('@/components/accounting/accounts-payable/PayableDetail.vue')
  },

  data: () => ({
    dialogId: uniqueDialogId('recoveries-table'),
    focusedPayableId: {},
    UserClaims
  }),

  computed: {
    ...mapGetters('recovery', ['recoveriesLoading', 'advanceRecoveries']),
    headers () { return RecoveryHeaders.recoveryHeaders(this.$i18n.locale) },
    recoveryMethods () { return RecoveryMethods.names }
  },

  methods: {
    ...mapMutations('global', ['setRouterJump']),
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),
    utcToLocalDate,
    formatMoney,
    userAssignedClaim,
    viewPayable (recovery) {
      this.focusedPayableId = recovery.payableId
      this.openOrUpdateDialog({ id: this.dialogId, width: '70%' })
    },

    recoveryHasPayable (recovery) {
      return recovery.recoveryMethod === RecoveryMethods.AutoCalculated.value
    },

    canEditRecovery (recovery) {
      return recovery.recoveryMethod === RecoveryMethods.ManualPayment.value
    },

    canDeleteRecovery (recovery) {
      return recovery.recoveryMethod === RecoveryMethods.ManualPayment.value
    }
  }
}
</script>
