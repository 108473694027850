<template>
<v-container fluid>
  <v-row dense>
    <v-col>
      <v-tabs v-model="currentTab" class="card-tabs">
        <v-tab>{{ $t('issued') }}</v-tab>
        <v-tab>{{ $t('recurring') }}</v-tab>
        <v-spacer/>
        <Icon
        :small="false"
        icon="mdi-refresh"
        :tooltipText="$t('refresh')"
        @icon-clicked="refreshPayables"/>
        <Icon
        :small="false"
        icon="mdi-plus"
        :tooltipText="$t('new')"
        @icon-clicked="createPaymentDialog"
        :disabled="!userAssignedClaim(UserClaims.ContractManager)"/>
      </v-tabs>

    <v-card outlined style="border-radius: 0px 4px 4px 4px;">
      <v-window v-model="currentTab">
        <v-window-item> <!-- Issued account payments -->
          <v-col cols="12" class="py-0 px-3">
            <DataTable
            :items="accountPayables"
            :headers="headers"
            :actions="[]"
            :customCells="customPayableColumns"
            @refresh="refreshPayables"
            @create="createPaymentDialog"
            defaultSort="paymentNote"
            containerClass="px-0">
            <template #custom-controls>
              <div class="mx-1"></div>
              <ColumnAdjuster
              v-if="columnSetIssued"
              :propColumnSet.sync="columnSetIssued"
              @new-columns-selected="newIssuedColumnsSelected"/>
            </template>
              <template #status="{item}">
                <Icon
                  v-if="!!item.exportBatchId"
                  icon="mdi-checkbox-marked-circle"
                  iconColor="success"
                  :tooltipText="$t('exported')"
                />
                <Icon
                  v-if="!!item.accountPayableTemplateId"
                  icon="mdi-sync"
                  :tooltipText="$t('recurring')"
                  iconColor="black"
                  tooltipColor="black"/>
                <Icon
                  v-if="item.preIssued"
                  icon="mdi-invoice-text-check"
                  iconColor="black"
                  :tooltipText="$t('preIssued')"/>
              </template>
              <template #amount="{item}">
                {{ formatMoney(item.amount) }}
              </template>
              <template #payDateStatus="{item}">
                <v-row justify="center" class="flex-nowrap">
                    <Icon
                      dataTestId="recovery-period-status"
                      :icon="statusFromInt(item.payPeriodStatus).icon"
                      :tooltipText="statusFromInt(item.payPeriodStatus).tooltipText"
                      :iconColor="statusFromInt(item.payPeriodStatus).color"
                    />
                    <span>{{ moment(item.payDate).format('MM/DD/YYYY') }}</span>
                </v-row>
              </template>
              <template #actions="{item}">
                <Icon
                icon="mdi-pencil"
                iconColor="success"
                dataTestId="payable-edit"
                :tooltipText="$t('edit')"
                @icon-clicked="editPaymentDialog(item, { isRecurring: false })"
                :disabled="!userAssignedClaim(UserClaims.ContractManager)"
                elementId="account-detail-payment-edit"
                />
                <Icon
                icon="mdi-delete-forever"
                iconColor="error"
                dataTestId="payable-delete"
                :tooltipText="$t('delete')"
                @icon-clicked="deletePayable(item, { isRecurring: false })"
                :disabled="!userAssignedClaim(UserClaims.ContractManager)"
                elementId="account-detail-payment-delete"
                />
              </template>
            </DataTable>
          </v-col>
        </v-window-item>
        <v-window-item> <!-- Account Payable Templates -->
          <v-col cols="12" class="py-0 px-3">
            <DataTable
            :items="accountPayableTemplates"
            :headers="headersRecurring"
            :actions="[]"
            :customCells="customTemplateColumns"
            @refresh="refreshPayables"
            @create="createPaymentDialog"
            defaultSort="paymentNote"
            containerClass="px-0">
              <template #custom-controls>
                <div class="mx-1"></div>
                <ColumnAdjuster
                v-if="columnSetRecurring"
                :propColumnSet.sync="columnSetRecurring"
                @new-columns-selected="newRecurringColumnsSelected"/>
              </template>
              <template #status="{item}">
                <Icon
                v-if="item.preIssued"
                icon="mdi-invoice-text-check"
                iconColor="black"
                :tooltipText="$t('preIssued')"
                />
                <Icon
                v-if="item.advanceId !== null"
                icon="mdi-cash-multiple"
                :tooltipText="$t('recoversFromX', { x: item.advanceName })"
                iconColor="black"
                tooltipColor="black"/>
              </template>
              <template #actions="{item}">
                <Icon
                icon="mdi-pencil"
                iconColor="success"
                dataTestId="payable-edit"
                :tooltipText="$t('edit')"
                @icon-clicked="editPaymentDialog(item, { isRecurring: true })"
                :disabled="!userAssignedClaim(UserClaims.ContractManager)"
                elementId="account-detail-payment-edit"
                />
                <Icon
                icon="mdi-delete-forever"
                iconColor="error"
                dataTestId="payable-delete"
                :tooltipText="$t('delete')"
                @icon-clicked="deletePayable(item, { isRecurring: true })"
                :disabled="!userAssignedClaim(UserClaims.ContractManager)"
                elementId="account-detail-payment-delete"
                />
                <Icon
                :icon="playPauseStyleForTemplate(item).icon"
                :tooltipText="$t(playPauseStyleForTemplate(item).tooltipText)"
                iconColor="black"
                @icon-clicked="patchPauseStatus(item)"/>
                <Icon
                icon="mdi-history"
                :tooltipText="$t('viewModifications')"
                @icon-clicked="auditTemplate(item)"/>
              </template>
            </DataTable>
          </v-col>
        </v-window-item>
      </v-window>
    </v-card>

    </v-col>
    <Dialog :stateId="dialogId" maxWidth="80vw" @close="closeDialogs(true)">
      <AccountPayableForm
      v-if="payableForm"
      :accountId="accountId"
      :propAccountPayable="focusedPayment"
      @edit-payable="fireUpdateRequest"
      @create-payable="fireCreateRequest"
      @edit-template="fireUpdateTemplateRequest"
      @create-template="fireCreateTemplateRequest"
      />
      <ModificationHistory
      v-if="auditingTemplate"
      entityType="accountPayableTemplate"
      :entityId="focusedPayment.accountPayableTemplateId"
      :entityName="$t('accountPayableTemplate')"/>
    </Dialog>
    <v-dialog v-model="confirmDelete" v-if="confirmDelete" width="400">
      <ConfirmDialog
      :title="$t('deleteAccountPaymentTitle')"
      :body="$t('deleteAccountPaymentBody')"
      @cancel="closeDialogs"
      @confirm="handleConfirmDelete"/>
    </v-dialog>
  </v-row>
</v-container>
</template>

<script>
import { mapActions } from 'vuex'
import { userAssignedClaim } from '@/utils/ClaimUtility.js'
import { PayPeriodStatus, UserClaims } from '@/utils/Enumerations.js'
import { accountPayableHeaders, accountPayableTemplateHeaders } from '@/headers/Account.js'
import { uniqueDialogId } from '../../../utils/componentHelpers'
import { formatMoney } from '@/utils/NumericMutations.js'
import { utcToLocalDate } from '../../../utils/DateFormatter'
import moment from 'moment'

export default {
  name: 'AccountPayments',

  props: {
    accountId: {
      type: Number,
      required: true
    }
  },

  components: {
    ModificationHistory: () => import('@/components/admin/ModificationHistory.vue'),
    DataTable: () => import('@/components/core/table/DataTable.vue'),
    Dialog: () => import('@/components/Dialog.vue'),
    Icon: () => import('../../helper/Icon.vue'),
    AccountPayableForm: () => import('@/components/account/account-payables/AccountPayableForm.vue'),
    ConfirmDialog: () => import('../../helper/ConfirmDialog.vue'),
    ColumnAdjuster: () => import('@/components/core/ColumnAdjuster.vue')
  },

  data: () => ({
    accountPayables: [],
    accountPayableTemplates: [],
    payableForm: false,
    focusedPayment: undefined,
    dialogId: uniqueDialogId('account-payable-form'),
    confirmDelete: false,
    currentTab: 0,
    auditingTemplate: false,
    UserClaims,
    moment,
    columnSetIssued: null,
    columnSetRecurring: null
  }),

  computed: {
    headers () {
      return this.columnSetIssued?.getColumns() ?? []
    },

    headersRecurring () {
      return this.columnSetRecurring?.getColumns() ?? []
    },

    customPayableColumns () {
      return [
        {
          slotName: 'status',
          value: 'status'
        },
        {
          slotName: 'issuedAt',
          value: 'issuedAt',
          dateFormat: 'MM/DD/YYYY'
        },
        {
          slotName: 'createdAt',
          value: 'createdAt',
          dateFormat: 'MM/DD/YYYY'
        },
        {
          slotName: 'payDateStatus',
          value: 'payDate'
        },
        {
          slotName: 'amount',
          value: 'amount'
        },
        {
          slotName: 'actions',
          value: 'actions'
        }
      ]
    },

    customTemplateColumns () {
      return [
        {
          slotName: 'status',
          value: 'status'
        },
        {
          slotName: 'type',
          value: 'type'
        },
        {
          slotName: 'actions',
          value: 'actions'
        }
      ]
    }
  },

  async created () {
    await this.refreshPayables()
    this.columnSetIssued = accountPayableHeaders()
    this.columnSetRecurring = accountPayableTemplateHeaders()
  },

  methods: {
    formatMoney,
    userAssignedClaim,
    utcToLocalDate,
    statusFromInt (status) {
      switch (status) {
        case PayPeriodStatus.Closed.value:
          return {
            color: 'black',
            tooltipText: this.$t('payPeriodStatus') + ': ' + this.$t('closed'),
            icon: 'mdi-cancel'
          }
        case PayPeriodStatus.Open.value:
          return {
            color: 'success',
            tooltipText: this.$t('payPeriodStatus') + ': ' + this.$t('open'),
            icon: 'mdi-checkbox-marked-circle'
          }
        case PayPeriodStatus.Inactive.value:
          return {
            color: 'black',
            tooltipText: this.$t('payPeriodStatus') + ': ' + this.$t('inactive'),
            icon: 'mdi-circle-outline'
          }
        case PayPeriodStatus.Exported.value:
          return {
            color: 'black',
            tooltipText: this.$t('payPeriodStatus') + ': ' + this.$t('exported'),
            icon: 'mdi-export'
          }
        default:
          return {
            color: 'black',
            tooltipText: this.$t('error'),
            icon: 'mdi-exclamation'
          }
      }
    },
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),
    ...mapActions('account', ['fetchAccountPayables',
      'createAccountPayable',
      'updateAccountPayable',
      'deleteAccountPayable',
      'fetchAccountPayableTemplates',
      'createAccountPayableTemplate',
      'updateAccountPayableTemplate',
      'deleteAccountPayableTemplate',
      'patchAccountPayableTemplate']),
    async refreshPayables () {
      this.accountPayables = await this.fetchAccountPayables(this.accountId)
      this.accountPayableTemplates = await this.fetchAccountPayableTemplates(this.accountId)
    },

    createPaymentDialog () {
      this.payableForm = true
      this.openOrUpdateDialog({ id: this.dialogId, width: '50vw' })
    },

    editPaymentDialog (item, { isRecurring }) {
      if (!isRecurring && !this.validateMutable(item)) return
      this.payableForm = true
      this.focusedPayment = { ...item, isRecurring }
      this.openOrUpdateDialog({ id: this.dialogId, width: '50vw' })
    },

    closeDialogs (refresh = false) {
      this.closeDialogsAtOrAbove(this.dialogId)
      this.payableForm = false
      this.confirmDelete = false
      this.focusedPayment = undefined
      this.auditingTemplate = false
      if (refresh) {
        this.refreshPayables()
      }
    },

    deletePayable (item, { isRecurring }) {
      const validationFn = isRecurring
        ? this.validateDeleteTemplate
        : this.validateUnexported

      if (!validationFn(item)) return
      this.focusedPayment = { ...item, isRecurring }
      this.confirmDelete = true
    },

    async handleConfirmDelete () {
      const deleteFn = this.focusedPayment.isRecurring ? this.fireDeleteTemplateRequest : this.fireDeleteRequest
      await deleteFn()
      this.closeDialogs(true)
    },

    async fireCreateRequest (reqObj) {
      await this.createAccountPayable(reqObj)
      this.closeDialogs()
    },

    async fireUpdateRequest (reqObj) {
      await this.updateAccountPayable(reqObj)
      this.closeDialogs()
    },

    async fireCreateTemplateRequest (reqObj) {
      await this.createAccountPayableTemplate(reqObj)
      this.closeDialogs()
    },

    async fireUpdateTemplateRequest (reqObj) {
      await this.updateAccountPayableTemplate(reqObj)
      this.closeDialogs()
    },

    async fireDeleteRequest () {
      await this.deleteAccountPayable({
        accountId: this.accountId,
        accountPayableId: this.focusedPayment.accountPayableId
      })
    },

    async fireDeleteTemplateRequest () {
      await this.deleteAccountPayableTemplate({
        accountId: this.accountId,
        accountPayableTemplateId: this.focusedPayment.accountPayableTemplateId
      })
    },

    validateMutable (payable) {
      if (payable.accountPayableTemplateId !== null) {
        this.setSnackError(this.$t('cannotEditAccountPaymentCreatedFromTemplate'))
        return false
      }
      return true
    },

    validateDeleteTemplate (item) {
      if (this.accountPayables.some(ap => ap.accountPayableTemplateId === item.accountPayableTemplateId)) {
        this.setSnackError(this.$t('cannotDeleteTemplateWithCreatedAccountPayables'))
        return false
      }
      return true
    },

    validateUnexported (payable) {
      if (payable.exportBatchId !== null) {
        this.setSnackError(this.$t('cannotEditOrDeleteExportedAccountPayments'))
        return false
      }
      return true
    },

    playPauseStyleForTemplate (template) {
      return {
        icon: template.paused ? 'mdi-play-circle' : 'mdi-pause-circle',
        tooltipText: template.paused ? 'unpauseAccountPaymentTemplate' : 'pauseAccountPaymentTemplate'
      }
    },

    auditTemplate (template) {
      this.focusedPayment = template
      this.auditingTemplate = true
      this.openOrUpdateDialog({ id: this.dialogId, width: '50vw' })
    },

    async patchPauseStatus (template) {
      const body = [
        {
          op: 'add',
          path: '/paused',
          value: !template.paused
        }
      ]
      await this.patchAccountPayableTemplate({
        accountId: this.accountId,
        accountPayableTemplateId: template.accountPayableTemplateId,
        modifiedAt: template.modifiedAt,
        body
      })
      await this.refreshPayables()
    },

    newIssuedColumnsSelected (columnSet) {
      this.columnSetIssued = columnSet
    },

    newRecurringColumnsSelected (columnSet) {
      this.columnSetRecurring = columnSet
    }
  }
}
</script>
