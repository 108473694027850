<template>
  <GridCard :titleStyle="titleStyle" :titleClasses="titleClasses">
    <template #title>
      <v-row>
        <v-col :style="wordBreakStyle">
          <v-tooltip bottom :color="titleClasses" v-if="tooltip">
            <template #activator="{ on }">
              <span v-on="on">{{title}}</span>
            </template>
            <span class="white--text subtitle-1">{{tooltip}}</span>
          </v-tooltip>
          <span v-else>{{title}}</span>
        </v-col>
        <v-col cols="auto">
          <slot name="actions"/>
          <Icon
            v-if="downloadCSV"
            :disabled="!hasData"
            iconColor="white"
            :tooltipColor="contractMode.details.color"
            icon="mdi-file-delimited-outline"
            :tooltipText="csvTooltip ?? $t('downloadCSV')"
            @icon-clicked="$emit('download-csv')"
            :small="false"
            elementId="chart-card-csv-download"
          />
        </v-col>
      </v-row>
    </template>
    <div
      ref="container"
      v-show="hasData"
      :id="realContainerId"
      style="position: relative; height: 98%; width: 100%"
      class="pa-4"
    >
      <canvas ref="canvas" :id="realCanvasId"></canvas>
    </div>
    <v-col cols="12" class="fill-height pa-4" v-show="!hasData">
      <NoData class="fill-height">
        {{noDataText || $t('noData')}}
      </NoData>
    </v-col>
  </GridCard>
</template>

<script>
import { Chart } from 'chart.js'
import { mapGetters } from 'vuex'
import { colorClassForContractMode } from '@/utils/componentHelpers'
export default {
  name: 'GridChartCard',

  components: {
    GridCard: () => import('@/components/dashboard/GridCard.vue'),
    Icon: () => import('@/components/helper/Icon.vue'),
    NoData: () => import('@/components/core/NoData.vue')
  },

  props: {
    canvasId: {
      type: String,
      default: undefined
    },
    containerId: {
      type: String,
      default: undefined
    },
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      default: ''
    },
    hasData: {
      type: Boolean,
      default: true
    },
    tooltip: {
      type: String,
      default: '',
      required: false
    },
    disableCsv: {
      type: Boolean,
      required: false,
      default: false
    },
    noDataText: {
      type: String,
      required: false,
      default: undefined
    },
    textColor: {
      type: String,
      required: false,
      default: '#ffffff'
    },
    csvTooltip: {
      type: String,
      required: false,
      default: undefined
    }
  },

  watch: {
    hasData (has) {
      if (has) {
        this.draw()
      }
    }
  },

  data: () => ({
    generatedCanvasId: 'canvas',
    generatedContainerId: 'container'
  }),

  created () {
    this.generatedCanvasId = this.canvasId || `canvas-${Math.floor(Math.random() * 65536).toString(16).padStart(4, '0')}`
    this.generatedContainerId = this.containerId || `container-${Math.floor(Math.random() * 65536).toString(16).padStart(4, '0')}`
  },

  computed: {
    ...mapGetters('ticket', ['contractMode']),

    realCanvasId () {
      return this.canvasId || this.generatedCanvasId
    },
    realContainerId () {
      return this.containerId || this.generatedContainerId
    },
    downloadCSV () {
      return !this.disableCsv && this.$listeners['download-csv'] !== undefined
    },
    titleStyle () {
      return `color: ${this.textColor};`
    },
    wordBreakStyle () {
      return `word-break: ${this.$vuetify.breakpoint.smAndDown ? 'break-word' : 'normal'};`
    },
    titleClasses () {
      return colorClassForContractMode(this.contractMode.value)
    }
  },

  async mounted () {
    await this.$nextTick()
    this.$nextTick(this.draw)
  },

  methods: {
    draw (force = false) {
      if ((!force && (!this.$refs)) || !this.hasData) return
      const chart = Chart.getChart(this.$refs.canvas)
      if (chart) chart.destroy()
      this.$emit('draw', this.$refs.canvas, this.$refs.container)
    }
  }
}
</script>
