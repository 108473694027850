<template>
  <v-card v-if="ticket" id="ticket-detail">
    <v-card-title :class="headerClass">
      <Icon
      v-if="ticket.status !== undefined"
      dataTestId="ticket-detail-status-icon"
      :icon="ticketStatusIcon(ticket.status, ticket.transitStatus).icon"
      iconColor="tertiary"
      :small="false"
      :tooltipText="ticketStatusIcon(ticket.status, ticket.transitStatus).tooltipText"/>
      <span class="headline mr-2" v-if="ticket.status !== undefined">{{title}}</span>
      <Icon
      v-if="ticket.proximityTriggered"
      icon="mdi-alert-circle-outline"
      dataTestId="ticket-detail-prox-icon"
      iconColor="tertiary"
      :small="false"
      :tooltipText="$t('loadCreatedOffsite')"/>
      <Icon
      v-if="missingLogCounts(ticket)"
      icon="mdi-numeric-off"
      dataTestId="ticket-detail-missing-log-counts"
      iconColor="tertiary"
      :small="false"
      :tooltipText="$t('missingLogCounts')"
      />
      <Icon
      iconColor="tertiary"
      icon="mdi-file"
      dataTestId="ticket-detail-draft-icon"
      v-if="ticket.isOnDraftContract"
      :tooltipText="$t('draftTicket')"
      />
      <Icon
      v-if="ticket.consumed"
      iconColor="tertiary"
      icon="mdi-saw-blade"
      dataTestId="ticket-detail-consume-icon"
      :tooltipText="$t('consumed')"
      :small="false"
      />
      <v-spacer/>
      <BaseDialogActions
      @close="$emit('close')"
      hideRefresh>
        <template v-slot:left>
          <v-menu
            bottom
            left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                class="mr-2"
                v-bind="attrs"
                color="tertiary"
                v-on="on">mdi-dots-vertical</v-icon>
            </template>

            <v-list id="ticket-detail-header-actions">
              <v-list-item
                v-for="(item, i) in headerActions"
                :key="i"
                :disabled="item.disabled"
                @click="item.action"
                :id="item.id"
              >
                <v-avatar left>
                  <v-icon color="black" :disabled="item.disabled">{{item.icon}}</v-icon>
                </v-avatar>
                <v-list-item-title class="subtitle-1">{{ item.tooltipText }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </BaseDialogActions>
    </v-card-title>
    <v-card-text class="pt-6">
      <v-row no-gutters>
        <v-col cols="12">
          <TicketInformation
          :ticket="mappedTicketWithCorrectedDriver"
          :corrections="ticketCorrections"
          :shouldFetchImages="ticket.hasImages"
          v-if="ticket"
          v-on:logCounts="logCountsDialog"/>
        </v-col>
        <v-col cols="12" class="mt-n4 mb-5">
          <CorrectionTable
          :ticketId="ticketId"
          :ticket="ticket"
          :ticketCorrections="ticketCorrections"
          @refresh="refreshTicket"
          v-if="isExported && ticket && ticketCorrections?.length > 0"
          v-bind="{ contractMode }"/>
        </v-col>
        <v-col cols="12">
          <TicketFinancials
          v-if="ticket"
          :ticket="ticket"
          :ticketId="ticketId"
          :refreshToggle="correctionRefreshToggle"
          :contractMode="contractMode.value"/>
        </v-col>
      </v-row>
      <Dialog :stateId="dialogId" @close="resetDialogs" height="80vh" fullscreenBreakPoint="smAndDown">
        <InteractiveMap
        v-if="ticketMap"
        :ticket="ticket"
        @map-closed="resetDialogs"
        :height="$vuetify.breakpoint.smAndDown ? '100%' : '80vh'">
        </InteractiveMap>

        <TicketCorrectionForm
        :ticket="ticket"
        :defects="allTicketDefects"
        @close="resetDialogs"
        v-if="ticketCorrectionDialog"
        :contractMode="contractMode"
        @correction-created="correctionCreated"/>

        <LogCountChart v-if="logCounts"
        :ticketId="ticket.ticketId"
        v-on:close="resetDialogs"/>

        <ModificationHistory
        v-if="ticket && modificationHistoryDialog"
        entityType="ticket"
        :entityId="ticket?.ticketId"
        :entityName="`${$t('ticket')} #${ticket.ticketNumber}`"
        :headerClass="headerClass"
        />
      </Dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { getTicketPDF } from '@/utils/pdf-formatters/Ticket.js'
import { TicketStatus, TicketStatusIcon, UserClaims } from '@/utils/Enumerations.js'
import { LocalStorageKeys } from '@/utils/LocalStorageActor'
import RouterJump from '@/model/RouterJump.js'
import { uniqueDialogId, colorClassForContractType, colorClassForContractMode } from '@/utils/componentHelpers'
import { userAssignedClaim } from '@/utils/ClaimUtility.js'
export default {
  name: 'TicketDetails',

  props: {
    ticketId: {
      type: Number,
      required: true
    },
    showCrud: {
      type: Boolean,
      default: true
    },
    contractMode: {
      type: Object,
      default: () => ({ value: 0 })
    }
  },

  components: {
    CorrectionTable: () => import('@/components/ticket/corrections/CorrectionTable.vue'),
    ModificationHistory: () => import('@/components/admin/ModificationHistory.vue'),
    Icon: () => import('@/components/helper/Icon.vue'),
    InteractiveMap: () => import('@/components/maps/InteractiveMap.vue'),
    TicketFinancials: () => import('@/components/ticket/ticket-details/TicketFinancials.vue'),
    TicketInformation: () => import('@/components/ticket/ticket-details/TicketInformation'),
    TicketCorrectionForm: () => import('@/components/ticket/corrections/form/CorrectionForm.vue'),
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue'),
    LogCountChart: () => import('@/components/ticket/ticket-details/LogCountChart.vue')
  },

  data: () => ({
    ticket: undefined,
    currentTab: 0,
    ticketMap: false,
    focusedCorrection: null,
    ticketCorrections: [],
    logCounts: false,
    shouldRefreshCorrections: false,
    correctionRefreshToggle: false,
    ticketCorrectionDialog: false,
    modificationHistoryDialog: false,
    dialogId: uniqueDialogId('ticket-details'),
    correctedDriver: undefined,
    UserClaims
  }),

  created () {
    this.refreshTicket(true)
  },

  computed: {
    ...mapGetters('user', ['companyInfo']),
    ...mapGetters('locations', ['allLocations']),
    ...mapGetters('defects', ['allTicketDefects']),
    title () {
      return `Ticket #${this.ticket.ticketNumber}`
    },

    headerClass () {
      return this.ticket.contractType
        ? colorClassForContractType(this.ticket.contractType)
        : colorClassForContractMode(this.contractMode.value)
    },

    isExported () {
      return this.ticket.status === TicketStatus.Exported.value
    },

    isReadOnly () {
      return (this.ticket.status === TicketStatus.Posted.value ||
        this.ticket.status === TicketStatus.Recalculating.value ||
        this.isExported)
    },

    headerActions () {
      const actions = [
        (this.isExported) ? {
          icon: 'mdi-auto-fix',
          disabled: this.ticketCorrections.length > 0 || !userAssignedClaim(UserClaims.TicketManager),
          tooltipText: this.$t('correctTicket'),
          action: this.openTicketCorrectionForm,
          id: 'correct'
        } : undefined,
        (this.showCrud) ? {
          icon: 'mdi-pencil',
          disabled: this.isReadOnly || !userAssignedClaim(UserClaims.TicketManager),
          tooltipText: this.$t('editTicket'),
          action: this.editTicket,
          id: 'edit'
        } : undefined,
        {
          icon: 'mdi-history',
          tooltipText: this.$t('viewModifications'),
          action: this.openModificationHistoryDialog,
          id: 'modifications'
        },
        {
          icon: 'mdi-map',
          tooltipText: this.$t('viewOnMap'),
          action: this.openMap,
          disabled: this.ticket.latitude === 0 && this.ticket.longitude === 0,
          id: 'map'
        },
        {
          icon: 'mdi-printer',
          tooltipText: this.$t('printTicket'),
          action: this.printTicket,
          id: 'print-tickets'
        }
      ].filter(a => a)

      return actions
    },

    mappedTicketWithCorrectedDriver () {
      return {
        ...this.ticket,
        correctedDriver: this.correctedDriver
      }
    }
  },

  methods: {
    ...mapMutations('global', ['setRouterJump']),
    ...mapActions('ticket', ['fetchTicketById', 'getCorrectionsForTicket']),
    ...mapActions('locations', ['fetchLocationWithId']),
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),
    ...mapActions('account', ['fetchAccountById']),
    ticketStatus: (x) => TicketStatus.fromInt(x),
    userAssignedClaim,
    ticketStatusIcon (x, transitStatus) {
      return TicketStatusIcon(x, transitStatus)
    },

    missingLogCounts (ticket) {
      if (ticket.destinationAccountId === undefined || this.contractMode?.value !== 0) return false
      const selectedLocation = this.allLocations.find(location => location.accountId === ticket.destinationAccountId)
      return selectedLocation?.collectLogCounts && !ticket.hasLogCounts
    },

    async refreshTicket (initialRefresh) {
      const response = await this.fetchTicketById(this.ticketId)
      this.ticket = response

      if (this.isExported) {
        this.refreshCorrections(initialRefresh)
      }
    },

    editTicket () {
      const currentPath = this.$router.app._route.path
      if (currentPath === '/tickets' || currentPath === '/tracts') {
        this.$emit('edit-ticket', {
          actionType: 'open-form',
          ticket: this.ticket
        })
      } else {
        this.jumpToTickets()
      }
    },

    async refreshCorrections (initialRefresh) {
      if (this.ticket?.correctionType === null) {
        this.ticketCorrections = []
        this.correctedDriver = undefined
      }

      this.ticketCorrections = await this.getCorrectionsForTicket(this.ticketId)
      if (!initialRefresh) {
        this.correctionRefreshToggle = !this.correctionRefreshToggle
      }
      if (this.ticketCorrections.length === 0) return
      const driverAccountId = this.ticketCorrections[this.ticketCorrections.length - 1].correction.driverAccountId
      if (!driverAccountId) return
      this.correctedDriver = (await this.fetchAccountById(driverAccountId)).name
    },

    logCountsDialog () {
      this.logCounts = true
      this.openOrUpdateDialog({ id: this.dialogId, width: '40vw' })
    },

    jumpToTickets () {
      if (!this.ticket.tract) {
        localStorage.setItem(LocalStorageKeys.BP_TICKETS_VIEW, true)
      } else {
        localStorage.setItem(LocalStorageKeys.BP_TICKETS_VIEW, false)
      }
      const jump = new RouterJump(
        'TicketDetail',
        'tickets',
        this.ticket
      )
      this.setRouterJump(jump)
      this.$router.push('tickets')
    },

    printTicket () {
      getTicketPDF(this.ticket, this.companyInfo)
    },

    openMap () {
      this.ticketMap = true
      this.openOrUpdateDialog({ id: this.dialogId, width: '80vh' })
    },

    openTicketCorrectionForm () {
      this.ticketCorrectionDialog = true
      this.openOrUpdateDialog({ id: this.dialogId, width: '600px' })
    },

    openModificationHistoryDialog () {
      this.modificationHistoryDialog = true
      this.openOrUpdateDialog({ id: this.dialogId, width: '80vw' })
    },

    resetDialogs () {
      this.closeDialogsAtOrAbove(this.dialogId)
      this.modificationHistoryDialog = false
      this.ticketCorrectionDialog = false
      this.logCounts = false
      this.ticketMap = false
    },

    correctionCreated ({ ticketCorrectionId, ticketId }) {
      this.focusedCorrection = {
        correctionId: ticketCorrectionId,
        ticketId: ticketId
      }
      this.resetDialogs()
      this.refreshTicket()
    }
  }
}
</script>
