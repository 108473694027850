import i18n from '../i18n'

export default {
  bondHeaders (groupedByTract = true) {
    return [
      {
        sortable: true,
        text: i18n.t('title'),
        value: 'paymentNote'
      },
      groupedByTract ? undefined : {
        sortable: true,
        text: i18n.t('tract'),
        value: 'tractName'
      },
      {
        align: 'right',
        sortable: true,
        text: i18n.t('issuedAt'),
        value: 'issuedAt'
      },
      {
        align: 'right',
        sortable: true,
        text: i18n.t('amount'),
        value: 'amount'
      }
    ].filter(c => c)
  },

  tractWithBondHeaders () {
    return [
      {
        sortable: true,
        text: i18n.t('status'),
        value: 'status',
        align: 'center'
      },
      {
        sortable: true,
        text: i18n.t('tract'),
        value: 'name'
      },
      {
        sortable: true,
        text: i18n.t('account'),
        value: 'accountName'
      },
      {
        align: 'right',
        sortable: true,
        text: i18n.t('harvestEnd'),
        value: 'harvestEndDate'
      },
      {
        align: 'right',
        sortable: true,
        text: i18n.t('balance'),
        value: 'bondBalance'
      }
    ]
  },

  accountWithBondHeaders () {
    return [
      {
        sortable: true,
        text: i18n.t('account'),
        value: 'name'
      },
      {
        align: 'right',
        sortable: true,
        text: i18n.t('balance'),
        value: 'bondBalance'
      }
    ]
  }
}
