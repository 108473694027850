import { cloneDeep } from 'lodash'

export default {
  mutateSamsaraConfiguration (state, config) {
    state.samsaraConfiguration = config
  },
  resetSamsaraConfiguration (state) {
    state.samsaraConfiguration = cloneDeep(state.uneditedSamsaraConfiguration)
  },
  initSamsaraConfiguration (state, config) {
    state.samsaraConfiguration = cloneDeep(config)
    state.uneditedSamsaraConfiguration = cloneDeep(config)
  },
  wipeSamsaraConfiguration (state) {
    const baseConfig = {
      apiToken: undefined,
      samsaraTrailerIdPrefix1: undefined,
      samsaraTrailerIdPrefix2: undefined,
      apiTokenSuffix: undefined,
      samsaraContact: {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: ''
      }
    }

    state.samsaraConfiguration = cloneDeep(baseConfig)
    state.uneditedSamsaraConfiguration = cloneDeep(baseConfig)
  },
  setFetchedApiToken (state, token) {
    state.samsaraConfiguration.apiToken = token
    state.uneditedSamsaraConfiguration.apiToken = token
  }
}
