<template>
  <v-row no-gutters id="sales-analysis">
    <v-col cols="12">
      <v-expansion-panels accordion popout v-model="openPanels" tile>
        <v-expansion-panel
          v-for="(destination, index) in salesAnaylsisObject.destinations"
          :key="`destination-sales-analysis-${index}`"
        >
          <v-expansion-panel-header>
            <v-row dense>
              <v-col cols="auto">
                {{ destination.destinationName }}
              </v-col>
            </v-row>
            <template #actions>
              <table style="font-size: 16px">
                <tr>
                  <td>Revenue:</td>
                  <td class="text-right">
                    {{ formatMoney(destination.totalRevenue) }}
                  </td>
                </tr>
                <tr>
                  <td>Cost:</td>
                  <td class="text-right">
                    {{ formatMoney(destination.totalCost) }}
                  </td>
                </tr>
                <tr>
                  <td>Gross Profit:</td>
                  <td class="text-right">
                    {{ formatMoney(destination.grossProfit) }}
                  </td>
                </tr>
              </table>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="lg_expansion-panel_wrapper">
              <v-container fluid>
                <v-row>
                  <v-col> {{ $t('totalLoadsCount', { loadCount: destination.loads }) }} </v-col>
                  <v-col class="text-center">
                    {{ getNetTonsTextForPanel(destination) }}
                  </v-col>
                  <v-col class="text-right">
                    Gross Profit Percentage:
                    {{ (destination.percentageGrossProfit * 100).toFixed(2) }}%
                  </v-col>
                  <v-col cols="12">
                    <v-divider></v-divider>
                  </v-col>
                </v-row>
                <v-row
                  v-for="tract in destination.tracts"
                  :key="`${tract.tractId}-${destination.destinationAccountId}`"
                  dense
                >
                  <v-col cols="12" class="title">
                    {{ tract.tractName }}
                  </v-col>
                  <v-col cols="12">
                    <SalesAnalysisTable :tract="tract" />
                  </v-col>
                </v-row>
                <!-- Removed Decks from Sales Analysis -->
                <!-- <v-row
                v-for="deck in destination.decks"
                :key="`${deck.deckId}-${destination.destinationAccountId}`"
                dense
                >
                  <v-col cols="12" class="title">
                    <span>{{`${deck.fromAccountName} - ${deck.deckName} - ${formatMoney(deck.deckAverageValuePerTon)}/t`}}</span>
                  </v-col>
                  <v-col cols="12">
                    <SalesAnalysisTable :deck="deck" />
                  </v-col>
                </v-row> -->
              </v-container>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-row>
</template>

<script>
import { formatMoney, tonStringFromPounds } from '@/utils/NumericMutations.js'
import { mapGetters } from 'vuex'
export default {
  name: 'SalesAnalysis',

  props: {
    salesAnaylsisObject: {
      type: Object,
      required: true
    }
  },

  components: {
    SalesAnalysisTable: () => import('@/components/settlements/accounts-receivable-module/sales-analysis/SalesAnalysisTable.vue')
  },

  data: () => ({
    openPanels: []
  }),

  watch: {
    'salesAnaylsisObject.destinations': {
      handler (val) {
        this.openPanels = val?.length === 1 ? [0] : []
      }
    }
  },

  mounted () {
    this.openPanels = this.salesAnaylsisObject?.destinations?.length === 1 ? [0] : []
  },

  computed: {
    ...mapGetters('settlements', ['useMetricTons'])
  },

  methods: {
    formatMoney,
    tonStringFromPounds,

    getNetTonsTextForPanel (destination) {
      return this.useMetricTons
        ? this.$t('netTonsLabel', { netTons: destination.netMetricTons.toFixed(3) })
        : this.$t('netTonsLabel', { netTons: destination.netTons.toFixed(3) })
    }
  }
}
</script>

<style scoped>
td {
  min-width: 100px;
}
</style>
