import i18n from '../i18n'
import { formatMoney } from '@/utils/NumericMutations'
import { utcToLocalDate } from '../utils/DateFormatter'
import { SETTING_KEYS } from '@/utils/UserSettings.js'
import { DataTableColumn, ColumnSet } from '@/model/DataTableColumn.js'

export default {
  accountHeaders () {
    return ([
      {
        sortable: true,
        text: i18n.t('name'),
        value: 'name'
      },
      {
        sortable: true,
        text: i18n.t('code'),
        value: 'code'
      },
      {
        sortable: true,
        text: i18n.t('exportCode'),
        value: 'exportCode'
      },
      {
        sortable: true,
        text: i18n.t('insuranceExpiryDate'),
        value: 'insuranceExpiryDate',
        align: 'center'
      },
      {
        sortable: false,
        text: i18n.t('tags'),
        value: 'accountTags',
        align: 'center'
      },
      {
        sortable: false,
        text: i18n.t('related'),
        value: 'related',
        align: 'center'
      },
      {
        sortable: false,
        text: i18n.t('actions'),
        value: 'actions',
        align: 'center'
      }
    ])
  }
}

export function addressTableHeaders () {
  return [
    {
      sortable: true,
      text: i18n.t('streetAddressOne'),
      value: 'addressLine1'
    },
    {
      sortable: true,
      text: i18n.t('streetAddressTwo'),
      value: 'addressLine2'
    },
    {
      sortable: true,
      text: i18n.t('city'),
      value: 'city'
    },
    {
      sortable: true,
      text: i18n.t('zipCode'),
      value: 'postalCode'
    },
    {
      sortable: true,
      text: i18n.t('state'),
      value: 'state'
    },
    {
      sortable: false,
      text: i18n.t('actions'),
      value: 'actions',
      align: 'center'
    }
  ]
}

export function accountBulkImportPreviewHeaders () {
  return ([
    {
      sortable: true,
      text: i18n.t('name'),
      value: 'name'
    },
    {
      sortable: true,
      text: i18n.t('code'),
      value: 'code'
    },
    {
      sortable: true,
      text: i18n.t('exportCode'),
      value: 'exportCode'
    },
    {
      sortable: true,
      text: i18n.t('shippingAddress'),
      value: 'shipping'
    },
    {
      sortable: true,
      text: i18n.t('billingAddress'),
      value: 'billing'
    },
    {
      sortable: false,
      text: i18n.t('tags'),
      value: 'tags'
    }
  ])
}

export function accountPayableHeaders () {
  const columns = ([
    new DataTableColumn({
      id: 1,
      sortable: true,
      text: '',
      value: 'status',
      align: 'center',
      hidable: false
    }),
    new DataTableColumn({
      id: 2,
      sortable: true,
      text: i18n.t('title'),
      value: 'paymentNote',
      hidable: false
    }),
    new DataTableColumn({
      id: 3,
      sortable: true,
      text: i18n.t('activity'),
      value: 'activityName',
      hidable: false
    }),
    new DataTableColumn({
      id: 4,
      sortable: true,
      text: i18n.t('entity'),
      value: 'businessEntityName',
      hidable: true
    }),
    new DataTableColumn({
      id: 5,
      sortable: true,
      text: i18n.t('account'),
      value: 'accountName',
      align: 'left',
      hidable: true
    }),
    new DataTableColumn({
      id: 6,
      sortable: true,
      text: i18n.t('issuedAt'),
      value: 'issuedAt',
      align: 'center',
      hidable: false
    }),
    new DataTableColumn({
      id: 7,
      sortable: true,
      text: i18n.t('payDate'),
      value: 'payDate',
      align: 'center',
      hidable: false
    }),
    new DataTableColumn({
      id: 8,
      sortable: true,
      text: i18n.t('glCode'),
      value: 'glCode',
      align: 'center',
      hidable: true
    }),
    new DataTableColumn({
      id: 9,
      sortable: true,
      text: i18n.t('glOffset'),
      value: 'glOffset',
      align: 'center',
      hidable: true
    }),
    new DataTableColumn({
      id: 10,
      sortable: true,
      text: i18n.t('createdBy'),
      value: 'createdByUser',
      align: 'center',
      hidable: true
    }),
    new DataTableColumn({
      id: 11,
      sortable: true,
      text: i18n.t('createdAt'),
      value: 'createdAt',
      align: 'center',
      hidable: true
    }),
    new DataTableColumn({
      id: 12,
      sortable: true,
      text: i18n.t('amount'),
      value: 'amount',
      align: 'right',
      hidable: false
    }),
    new DataTableColumn({
      id: 13,
      sortable: false,
      text: i18n.t('actions'),
      value: 'actions',
      align: 'center',
      hidable: false
    })
  ])

  return new ColumnSet({
    columns,
    setName: 'AccountDetailPaymentsHeaders',
    userSettingKey: SETTING_KEYS.ACCOUNT_DETAIL_PAYMENTS_HEADERS
  })
}

export function accountPayableTemplateHeaders () {
  const columns = ([
    new DataTableColumn({
      id: 1,
      hidable: false,
      sortable: true,
      text: '',
      value: 'status',
      align: 'center'
    }),
    new DataTableColumn({
      id: 2,
      hidable: false,
      sortable: true,
      text: i18n.t('title'),
      value: 'paymentNote'
    }),
    new DataTableColumn({
      id: 3,
      hidable: false,
      sortable: true,
      text: i18n.t('activity'),
      value: 'activityName'
    }),
    new DataTableColumn({
      id: 4,
      hidable: true,
      sortable: true,
      text: i18n.t('entity'),
      value: 'businessEntityName'
    }),
    new DataTableColumn({
      id: 5,
      hidable: true,
      sortable: true,
      text: i18n.t('account'),
      value: 'accountName',
      align: 'left'
    }),
    new DataTableColumn({
      id: 6,
      hidable: false,
      sortable: true,
      text: i18n.t('startDate'),
      value: 'startDate',
      align: 'center',
      format: utcToLocalDate
    }),
    new DataTableColumn({
      id: 7,
      hidable: true,
      sortable: true,
      text: i18n.t('glCode'),
      value: 'glCode',
      align: 'center'
    }),
    new DataTableColumn({
      id: 8,
      hidable: true,
      sortable: true,
      text: i18n.t('glOffset'),
      value: 'glOffset',
      align: 'center'
    }),
    new DataTableColumn({
      id: 9,
      hidable: true,
      sortable: true,
      text: i18n.t('createdBy'),
      value: 'createdByUser',
      align: 'center'
    }),
    new DataTableColumn({
      id: 10,
      hidable: true,
      sortable: true,
      text: i18n.t('createdAt'),
      value: 'createdAt',
      align: 'center',
      format: c => utcToLocalDate(c, 'MM/DD/YYYY')
    }),
    new DataTableColumn({
      id: 11,
      hidable: false,
      sortable: true,
      text: i18n.t('lastTriggered'),
      value: 'lastInstantiated',
      align: 'center',
      format: c => utcToLocalDate(c, 'MM/DD/YYYY')
    }),
    new DataTableColumn({
      id: 12,
      hidable: false,
      sortable: true,
      text: i18n.t('amount'),
      value: 'amount',
      align: 'right',
      format: formatMoney
    }),
    new DataTableColumn({
      id: 13,
      hidable: false,
      sortable: false,
      text: i18n.t('actions'),
      value: 'actions',
      align: 'center'
    })
  ])

  return new ColumnSet({
    columns,
    setName: 'AccountDetailPaymentTemplateHeaders',
    userSettingKey: SETTING_KEYS.ACCOUNT_DETAIL_PAYMENT_TEMPLATE_HEADERS
  })
}

export function accountPaymentRegisterHeaders () {
  return [
    {
      sortable: true,
      text: i18n.t('title'),
      value: 'note',
      align: 'left'
    },
    {
      sortable: true,
      text: i18n.t('activity'),
      value: 'activity',
      align: 'center'
    },
    {
      sortable: true,
      text: i18n.t('glCode'),
      value: 'glCode',
      align: 'center'
    },
    {
      sortable: true,
      text: i18n.t('glOffset'),
      value: 'glOffset'
    },
    {
      sortable: true,
      text: i18n.t('amount'),
      value: 'amount',
      align: 'right'
    }
  ]
}

export function overweightThresholdHeaders (isCRUD = false) {
  var result = [{
    value: 'threshold',
    text: i18n.t('thresholdLb'),
    align: 'right'
  },
  {
    value: 'penaltyAmount',
    text: i18n.t('penalty'),
    align: 'right'
  }]

  if (isCRUD) {
    result.push(
      {
        value: 'actionLeft',
        text: '',
        align: 'left'
      },
      {
        value: 'actionRight',
        text: '',
        align: 'center'
      }
    )
  }

  return result
}
