<template>
  <v-container fluid class="pb-5" id="inventorySummaryDeckSelector">
    <v-row justify="end" no-gutters>
      <v-col cols="auto" class="mr-n3">
        <DeckSelector
        v-if="!locationsLoading"
        isDashboard
        @deck-selected="deckSelected"
        @location-selected="locationSelected"
        :contentType="deckContentType"
        />
      </v-col>
      <v-col cols="12">
        <GridContainer
        class="pb-4">
          <GridArea :area="chartArea" :id="viewingInventoryValue ? inventoryValueId : inventoryWeightId">
            <LoadableComponent :loading="inventoryChartsLoading" type="dashboard-widget">
              <LineChartCard
              :valueKey="viewingInventoryValue ? 'values' : 'weights'"
              :yFormatType="viewingInventoryValue ? 'USD' : 'TONS'"
              :data.sync="inventoryChartData"
              :elementId="`inventory-${viewingInventoryValue ? 'value' : 'weight'}-chart-gridded`"
              :title="$t(viewingInventoryValue ? 'inventoryValueInDollars' : 'inventoryWeightInTons')"
              :tooltip="inventoryUpdatedTooltip">
                <template #actions>
                  <Icon
                  iconColor="white"
                  :icon="viewingInventoryValue ? 'mdi-weight' : 'mdi-currency-usd'"
                  :small="false"
                  @icon-clicked="inventoryLineChartToggled"
                  :tooltipText="$t(viewingInventoryValue ? 'switchToWeight' : 'switchToValue')"
                  margin="mr-3"
                  elementId="inventory-chart-toggle-weight-value"/>
                  <Icon
                  :disabled="inventoryChartData.length === 0"
                  icon="mdi-file-delimited-outline"
                  elementId="inventory-weight-csv"
                  iconColor="white"
                  :small="false"
                  :tooltipText="$t('downloadInventorySummary')"
                  @icon-clicked="openInventoryCsvDownload"/>
                </template>
              </LineChartCard>
            </LoadableComponent>
          </GridArea>
          <GridArea :area="[2, { lg: 12, xl: 6 }]" :id="timeInYardId">
            <LoadableComponent :loading="yardChartsLoading" type="dashboard-widget">
              <TimeInYardCard
              color="black"
              icon="mdi-clock-time-four-outline"
              sub-icon="mdi-update"
              :xValues="filteredYardTimes.dates"
              :yValues="filteredYardTimes.times"
              :loadCounts="filteredYardTimes.loadCounts"
              elementId="yard-time-chart-gridded"
              :title="$t('avgTimeInYard')"
              :csvFilename="yardTimeFilename"/>
            </LoadableComponent>
          </GridArea>
          <GridArea :area="cardArea">
            <StatsCard
            color="success"
            icon="mdi-currency-usd"
            :title="$t('inventoryValue')"
            :content="deckStats.value"
            :loading="!selectedDeck"/>
          </GridArea>
          <GridArea :area="cardArea">
            <StatsCard
              icon="mdi-weight"
              :loading="!deckHistory || !selectedDeck"
              :title="$t('currentInventoryUnits', { units: deckStats.consumptionMode === ConsumptionMode.WeightBased.value ? ` (${$t('tons')})` : '' })"
              :content="currentInventoryContent"/>
          </GridArea>
        </GridContainer>
      </v-col>
    </v-row>
    <Dialog :stateId="dialogId">
      <InventoryCsvDownload
      :selectedLocation="selectedLocation"
      :selectedDeck="selectedDeck"
      :contentType="deckContentType"
      @close="resetDialogs"/>
    </Dialog>
  </v-container>
</template>

<script>
import moment from 'moment'
import { tonStringFromPounds, formatMoney } from '@/utils/NumericMutations.js'
import { mapActions, mapGetters } from 'vuex'
import { uniqueDialogId } from '../../utils/componentHelpers'
import { DeckContentType, ConsumptionMode } from '../../utils/Enumerations'
import { LocalStorageKeys } from '@/utils/LocalStorageActor'

export default {
  name: 'InventorySummary',
  components: {
    Icon: () => import('@/components/helper/Icon.vue'),
    InventoryCsvDownload: () => import('./InventoryCsvDownload.vue'),
    Dialog: () => import('@/components/Dialog.vue'),
    GridArea: () => import('@/components/helper/GridArea.vue'),
    GridContainer: () => import('@/components/helper/GridContainer.vue'),
    DeckSelector: () => import('@/components/dashboard/DeckSelector.vue'),
    TimeInYardCard: () => import('@/components/dashboard/TimeInYardCard.vue'),
    LineChartCard: () => import('@/components/dashboard/LineChartCard.vue'),
    LoadableComponent: () => import('@/components/helper/LoadableComponent.vue'),
    StatsCard: () => import('@/components/dashboard/StatsCard.vue')
  },

  props: {
    isByproducts: {
      type: Boolean,
      required: false,
      default: false
    },
    decksLoading: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    ConsumptionMode,
    yardTimes: [],
    dialogId: uniqueDialogId('dashboard-inventory-summary'),
    yardChartsLoading: true,
    selectedLocation: undefined,
    selectedDeck: undefined,
    deckHistory: undefined,
    inventoryChartsLoading: false,
    inventoryChartData: [],
    filteredYardTimes: {
      dates: [],
      times: [],
      loadCounts: []
    },
    cardArea: [1, { sm: 12, md: 6 }],
    chartArea: [4, { md: 12, lg: 12, xl: 6 }],
    DeckContentType,
    viewingInventoryValue: localStorage.getItem(LocalStorageKeys.DASHBOARD_INVENTORY_LINE_CHART_VIEWING_WEIGHT) === 'false'
  }),

  mounted () {
    this.inventoryChartsLoading = true
  },

  computed: {
    ...mapGetters('user', ['companyInfo']),
    ...mapGetters('locations', ['allLocations', 'locationsLoading']),

    deckArray () {
      return (this.selectedDeck?.deckId === -1 ? this.selectedDeck.allDecks : (this.selectedDeck !== undefined ? [this.selectedDeck] : []))
    },

    inventoryLastRecordedOn () {
      if (!this.deckHistory || !this.deckHistory[0]?.history) return undefined
      const deckHistory = this.deckHistory[0]?.history
      const idx = (deckHistory.length > 2 && moment(deckHistory[0].recordedOn).isBefore(moment(deckHistory[1].recordedOn)))
        ? deckHistory.length - 1
        : 0
      return moment.utc(deckHistory[idx]?.recordedOn).local()
    },

    inventoryUpdatedTooltip () {
      return this.inventoryLastRecordedOn ? `${this.$t('lastUpdated')}: ${this.inventoryLastRecordedOn.local().format('MMM Do YYYY h:mm a')}` : ''
    },

    deckStats () {
      if (this.selectedDeck === undefined) { return [] }
      const deckArray = this.selectedDeck.deckId === -1 ? this.selectedDeck.allDecks : [this.selectedDeck]
      return {
        weight: tonStringFromPounds(deckArray.reduce((a, b) => a + b.onDeck, 0)),
        value: formatMoney(deckArray.reduce((a, b) => a + b.value, 0)),
        consumptionMode: this.getConsumptionMode(deckArray)
      }
    },

    yardTimeFilename () {
      if (this.selectedLocation === undefined) { return '' }
      if (this.selectedLocation.locationId === -1) { return '' }

      return `TimeInYard-${this.selectedLocation.name || ''}-${moment().format()}.csv`.replace(/\s+/g, '')
    },

    loadsOnDeck () {
      return this.selectedDeck.deckId === -1
        ? this.deckHistory.reduce((a, b) => this.selectedDeck.allDecks.map(d => d.deckId).includes(b.deckId) ? a + b?.loadsOnDeck ?? 0 : a, 0)
        : this.deckHistory.find(d => d.deckId === this.selectedDeck.deckId)?.loadsOnDeck ?? 0
    },

    deckContentType () {
      return this.isByproducts ? DeckContentType.Byproducts.value : DeckContentType.Logs.value
    },

    inventoryWeightId () {
      return `${'inventoryWeightChart'}${this.isByproducts ? 'Bp' : ''}`
    },

    inventoryValueId () {
      return `${'inventoryValueChart'}${this.isByproducts ? 'Bp' : ''}`
    },

    timeInYardId () {
      return `${'timeInYardChart'}${this.isByproducts ? 'Bp' : ''}`
    },

    currentInventoryContent () {
      const weight = this.deckStats.weight + `${this.deckStats.consumptionMode === ConsumptionMode.WeightBased.value ? '' : ` ${this.$t('tons').toLowerCase()}`}`
      if (this.deckStats.consumptionMode !== ConsumptionMode.WeightBased.value && this.deckHistory) {
        return [
          {
            value: weight
          },
          {
            value: `${this.loadsOnDeck} ${this.loadsOnDeck === 1 ? `${this.$t('load').toLowerCase()}` : `${this.$t('loads').toLowerCase()}`}`,
            icon: {
              icon: 'mdi-information-outline',
              color: 'info',
              tooltip: this.$t('loadCountOnlyIncludesDataFromTicketBasedDecks')
            }
          }
        ]
      } else {
        return weight
      }
    }
  },

  methods: {
    ...mapActions('dashboard', ['getHarvestedByRegion', 'getYardTimes']),
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),
    ...mapActions('user', ['fetchCompanyInfo']),
    ...mapActions('decks', ['fetchHistory']),

    async deckSelected (deck) {
      this.selectedDeck = deck
      this.deckHistory = await this.fetchHistory({ locationId: deck?.locationId, deckId: deck?.deckId })
      this.setInventoryChartData()
      this.inventoryChartsLoading = false
    },

    async locationSelected (location) {
      this.selectedLocation = location
      this.yardChartsLoading = true
      try {
        this.yardTimes = await this.getYardTimes({ locationId: location.locationId === -1 ? undefined : location.locationId, contentType: this.deckContentType })
        this.setFilteredYardTimes()
      } finally {
        this.yardChartsLoading = false
      }
      /* Chart data is updated on deck selection. This implicitly happens on location selection if there is more than 1 location.
      If only 1 location, switching between the specific location and All won't change the decks, so we must update explicitly. */
      if (this.allLocations.length === 1) this.setInventoryChartData()
    },

    setFilteredYardTimes () {
      this.filteredYardTimes = { dates: [], times: [], loadCounts: [] }
      if (this.yardTimes === undefined) { return }

      this.yardTimes.forEach(({ date, timeInYardSeconds, loadCount }) => {
        if (new Date(date) >= new Date().setDate(new Date().getDate() - 13)) {
          this.filteredYardTimes.dates.push(date)
          this.filteredYardTimes.times.push(timeInYardSeconds)
          this.filteredYardTimes.loadCounts.push(loadCount)
        }
      })
    },

    openInventoryCsvDownload () {
      this.openOrUpdateDialog({ id: this.dialogId, width: '500px' })
    },

    resetDialogs () {
      this.closeDialogsAtOrAbove(this.dialogId)
    },

    getConsumptionMode (deckArray) {
      const consumptionModes = deckArray.map(d => d.consumptionMode)
      if (consumptionModes.every(i => i === ConsumptionMode.TicketBased.value)) {
        return ConsumptionMode.TicketBased.value
      }
      if (consumptionModes.every(i => i === ConsumptionMode.WeightBased.value)) {
        return ConsumptionMode.WeightBased.value
      }
      return -1
    },

    setInventoryChartData () {
      if (!this.deckHistory || !this.deckArray) return
      const specificDeckSelected = this.selectedLocation && (this.selectedLocation.locationId !== -1 || this.selectedDeck.deckId !== -1)
      if (specificDeckSelected) {
        this.inventoryChartData = this.deckArray.map((deck) => {
          const deckHistory = this.deckHistory.find(dh => dh.deckId === deck.deckId).history
          return { // Show individual decks because there is a selected location
            name: deck.name,
            locationName: deck.locationName,
            dates: deckHistory.map(h => moment.utc(h.recordedOn).add(-1, 'days').format('MM/DD/YYYY')),
            weights: deckHistory.map(h => parseFloat((h.onDeck / 2000).toFixed(3))),
            values: deckHistory.map(h => h.value)
          }
        })
      } else {
        this.inventoryChartData = this.deckArray.reduce((acc, deck) => { // Aggregate deck information by location when viewing all locations
          const location = acc.find(i => i.name === deck.locationName)
          const deckHistory = this.deckHistory.find(dh => dh.deckId === deck.deckId).history
          if (location) {
            location.weights = location.weights.map((w, idx) => parseFloat((w + deckHistory[idx].onDeck / 2000).toFixed(3)))
            location.values = location.values.map((v, idx) => v + deckHistory[idx].value)
          } else {
            acc.push({
              name: deck.locationName,
              dates: deckHistory.map(h => moment.utc(h.recordedOn).add(-1, 'days').format('MM/DD/YYYY')),
              weights: deckHistory.map(h => parseFloat((h.onDeck / 2000).toFixed(3))),
              values: deckHistory.map(h => h.value)
            })
          }
          return acc
        }, [])
      }
    },

    inventoryLineChartToggled () {
      this.viewingInventoryValue = !this.viewingInventoryValue
      localStorage.setItem(LocalStorageKeys.DASHBOARD_INVENTORY_LINE_CHART_VIEWING_WEIGHT, !this.viewingInventoryValue)
    }
  }
}
</script>
