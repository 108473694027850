<template>
    <v-select
      color="black"
      :menu-props="{ bottom: true, offsetY: true }"
      return-object
      item-value="advanceId"
      v-model="chosenAdvance"
      select-first
      persistent-placeholder
      :label="$t('advance')"
      :loading="advancesLoading"
      :items="filteredAdvances"
      :disabled="disabled"
      :hint="hint"
      :persistent-hint="persistentHint"
    >
    <template #item="{item}">
      <AdvanceAutocompleteCard :advance="item"/>
    </template>
    <template #selection="{item}">
      <AdvanceAutocompleteCard :advance="item"/>
    </template>
    <template #append-outer>
      <v-icon v-if="!disabled" data-testid="advance-complete-refresh-button" @click="refreshAdvances" color="primary">mdi-refresh</v-icon>
    </template>
  </v-select>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { formatMoney } from '@/utils/NumericMutations.js'
import { utcToLocalDate } from '@/utils/DateFormatter.js'
export default {
  name: 'AdvanceAutocomplete',

  components: {
    AdvanceAutocompleteCard: () => import('./AdvanceAutocompleteCard.vue')
  },

  props: {
    advanceId: {
      type: Number,
      default: undefined
    },
    accountId: {
      type: Number,
      required: true
    },
    filter: {
      type: Function,
      default: _ => true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hint: {
      type: String,
      default: undefined
    },
    persistentHint: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    chosenAdvance (advance) {
      this.$emit('advance-chosen', advance)
    },

    async accountId () {
      this.chosenAdvance = null
      await this.refreshAdvances()
    },

    advanceId (id) {
      if (id === undefined) {
        this.chosenAdvance = undefined
        return
      }

      this.chosenAdvance = this.accountAdvances.find(a => a.advanceId === id)
    },

    async disabled (v) {
      if (!v && !this.chosenAdvance) {
        await this.refreshAdvances()
      }
    }
  },

  computed: {
    ...mapGetters('advances', ['allAdvancesForAccount', 'advancesLoading']),
    filteredAdvances () {
      return this.accountAdvances.filter(this.filter)
    }
  },

  data: () => ({
    chosenAdvance: undefined,
    accountAdvances: []
  }),

  async created () {
    if (!this.disabled) {
      await this.refreshAdvances()
    }
  },

  methods: {
    ...mapActions('advances', ['fetchAdvancesForAccount']),
    formatMoney,
    utcToLocalDate,

    async refreshAdvances () {
      this.accountAdvances = await this.fetchAdvancesForAccount(this.accountId)
      this.chosenAdvance = this.advanceId ? this.accountAdvances.find(a => a.advanceId === this.advanceId) : this.accountAdvances[0]
    }
  }
}
</script>
