import store from '@/store/index.js'
import { sasNeedsRefresh, createResourceUrl } from './SasHelpers'
import ApiServer from './api/ApiServer'
import { BlobServiceClient } from '@azure/storage-blob'
import i18n from '../i18n'
import { SasTokenLocations } from './LocalStorageActor'

class TractFilesClient {
  async getContainerClient () {
    await this.initConnection()
    return this.containerClient
  }

  async getEndpoint () {
    return 'companyinfo/blobinfo/tractfiles'
  }

  async initConnection () {
    const sasCapsule = JSON.parse(localStorage.getItem(SasTokenLocations.TRACT_FILES_SAS))
    if (sasCapsule === null || sasNeedsRefresh(sasCapsule.data[0].blobSasUri)) {
      const endpoint = await this.getEndpoint()
      const response = await ApiServer.get(ApiServer.urlFor(endpoint))
      localStorage.setItem(SasTokenLocations.TRACT_FILES_SAS, JSON.stringify(response))
    }

    const storedResponse = JSON.parse(localStorage.getItem(SasTokenLocations.TRACT_FILES_SAS))
    this.container = storedResponse.data[0].container
    this.location = storedResponse.data[0].location
    this.client = new BlobServiceClient(storedResponse.data[0].blobSasUri)
    this.containerClient = this.client.getContainerClient(this.container)
  }

  getBlobLocation (tractId) {
    return `${this.location}/${tractId}/`
  }

  async getFiles (tract) {
    if (!tract) return []
    const files = []
    try {
      const containerClient = await this.getContainerClient()
      const listOptions = {
        includeMetadata: false,
        includeSnapshots: false,
        includeTags: false,
        includeVersions: false,
        prefix: this.getBlobLocation(tract.tractId)
      }
      const iterator = containerClient.listBlobsFlat(listOptions).byPage()
      const response = (await iterator.next()).value
      for (const blob of response.segment.blobItems) {
        const resourceUrl = createResourceUrl(JSON.parse(localStorage.getItem(SasTokenLocations.TRACT_FILES_SAS)).data[0].blobSasUri, this.container, blob.name)
        const resourceUrlTokens = resourceUrl.pathname.split('/')
        const filename = resourceUrlTokens.pop()
        const filetime = resourceUrlTokens.pop()
        const userId = resourceUrlTokens.pop()
        files.push({
          name: decodeURIComponent(filename),
          type: filename.split('.').pop(),
          user: userId,
          date: filetime,
          ref: resourceUrl.href
        })
      }
    } catch (e) {
      console.error(e)
      return []
    }
    return files
  }

  async deleteFile (fileUrl) {
    try {
      const containerClient = await this.getContainerClient()
      const urlString = (fileUrl instanceof URL)
        ? decodeURI(fileUrl.pathname)
        : decodeURI(new URL(decodeURI(fileUrl)).pathname)
      const blobName = urlString.replace(/^\//, '').split('/').slice(1).join('/')
      const blockBlobClient = await containerClient.getBlockBlobClient(blobName)
      await blockBlobClient.delete()
    } catch (e) {
      console.error(e)
    }
  }

  async uploadFile (tract, tractFile) {
    // Get files to check for maximum, duplicate names
    const files = await this.getFiles(tract)
    if (files.length >= 10) return
    const storedFileName = `${store.getters['user/userInfo'].applicationUserId}/${Date.now()}/${tractFile.name}`
    const fileForUpload = new File([tractFile], storedFileName, { type: tractFile.type })
    if (files.some(f => f.name === fileForUpload.name)) {
      store.dispatch('snackbar/setSnackError', i18n.t('filenameAlreadyExistsSnack', { filename: tractFile.name, tractName: tract.name }))
      return
    }

    try {
      const containerClient = await this.getContainerClient()
      const fullBlobLocation = await this.getBlobLocation(tract.tractId) + fileForUpload.name
      const blockBlobClient = await containerClient.getBlockBlobClient(fullBlobLocation)
      const options = {
        blobHTTPHeaders: {
          blobContentType: fileForUpload.type
        }
      }
      await blockBlobClient.uploadData(fileForUpload, options)
    } catch (e) {
      store.dispatch('snackbar/setSnackError', i18n.t('errorUploadingTractFile', { tractName: tract.name }))
      console.error(e)
    }
  }
}

export const tractFilesClient = new TractFilesClient()
