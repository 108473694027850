<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="12">
          <v-tabs v-model="currentTab" class="mt-4">
          <v-tab>{{ $t('payables') }}</v-tab>
          <v-tab>{{ $t('receivables') }}</v-tab>
          <v-tab>{{ $t('accruals') }}</v-tab>
          <v-spacer/>
          <Icon
          v-if="hasIntegrations"
          :small="false"
          large
          :tooltipText="$t('integrations')"
          icon="mdi-cloud-outline"
          @icon-clicked="openIntegrationDialog"
          />
          <Icon
          :small="false"
          large
          :tooltipText="$t('downloadCsv')"
          icon="mdi-download"
          @icon-clicked="openCsvDialog"/>
          <v-tab-item disabled>
            <PayablesAggregations
            :tickets="brokenDownSnapshot.tickets"
            :corrections="brokenDownSnapshot.corrections"
            :miscPayments="brokenDownSnapshot.miscPayments"
            :propRecoveries="brokenDownSnapshot.recoveries"
            :isByproducts="isByproducts"/>
          </v-tab-item>
          <v-tab-item disabled>
            <ReceivablesAggregations
            :tickets="brokenDownSnapshot.tickets"
            :corrections="brokenDownSnapshot.corrections"
            :isByproducts="isByproducts"/>
          </v-tab-item>
          <v-tab-item disabled>
            <AccrualsAggregations
            :tickets="brokenDownSnapshot.tickets"
            :corrections="brokenDownSnapshot.corrections"
            :miscPayments="brokenDownSnapshot.miscPayments"
            :isByproducts="isByproducts"/>
          </v-tab-item>
        </v-tabs>
        </v-col>
      </v-row>
    </v-col>
    <Dialog :stateId="dialogId" maxWidth="800" @close="closeDialogs">
      <CSVExport
      v-if="csvDialog"
      isAdjustingEntries
      :isByProducts="isByproducts"
      @downloadCsv="downloadCsv"
      @close-csv-export="closeDialogs"
      />
      <SnapshotIntegrationDialog
      v-if="integrationDialog"
      :snapshotRecord="snapshotRecord"
      @click:retry="retrySnapshotIntegration"
      />
    </Dialog>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { uniqueDialogId } from '../../../../utils/componentHelpers'
import { AdjustingEntriesSnapshotIntegrationStatus, FinancialIntegrationType, TicketCorrectionSide, AccountingCategory } from '../../../../utils/Enumerations'
export default {
  name: 'SnapshotBreakdown',

  components: {
    PayablesAggregations: () => import('@/components/accounting/adjusting-entries/v1/PayablesAggregations.vue'),
    ReceivablesAggregations: () => import('@/components/accounting/adjusting-entries/v1/ReceivablesAggregations.vue'),
    AccrualsAggregations: () => import('@/components/accounting/adjusting-entries/v1/AccrualsAggregations.vue'),
    Dialog: () => import('@/components/Dialog.vue'),
    Icon: () => import('@/components/helper/Icon.vue'),
    SnapshotIntegrationDialog: () => import('@/components/accounting/adjusting-entries/v1/SnapshotIntegrationDialog.vue'),
    CSVExport: () => import('@/components/settlements/CSVExport.vue')
  },

  props: {
    snapshot: {
      type: Object,
      required: true
    },
    snapshotRecord: {
      type: Object,
      required: true
    },
    isByproducts: {
      type: Boolean,
      default: true
    },
    snapshotPeriodEndDate: {
      type: Object,
      required: false
    }
  },

  data: () => ({
    currentTab: 0,
    csvDialog: false,
    integrationDialog: false,
    dialogId: uniqueDialogId('snapshot-breakdown'),
    delayedRefreshTimeout: undefined,
    aggregatedSnapshot: {
      logs: {},
      byproducts: {}
    },
    brokenDownSnapshot: {}
  }),

  created () {
    this.breakDownSnapshot(this.snapshot)
  },

  watch: {
    snapshot (ss) {
      this.breakDownSnapshot(ss)
    }
  },

  computed: {
    ...mapGetters('user', ['companyInfo']),

    hasIntegrations () { return this.companyInfo.financialIntegrationType === FinancialIntegrationType.CsvDrop.value }
  },

  methods: {
    ...mapActions('adjusting-entries', ['fetchAdjustingEntriesCSV', 'patchSnapshotRecord']),
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),
    closeDialogs () {
      this.closeDialogsAtOrAbove(this.dialogId)
      this.csvDialog = false
      this.integrationDialog = false
    },

    openCsvDialog () {
      this.csvDialog = true
      this.openOrUpdateDialog({ id: this.dialogId, width: 400 })
    },

    openIntegrationDialog () {
      this.integrationDialog = true
      this.openOrUpdateDialog({ id: this.dialogId, width: 800 })
    },

    async downloadCsv (requestType) {
      const requestObj = {
        snapshotId: this.snapshotRecord.adjustingEntriesSnapshotRecordId,
        periodEndDate: this.snapshotPeriodEndDate
      }
      if (requestType !== 3) {
        requestObj.type = this.getRequestType(requestType)
        await this.fetchAdjustingEntriesCSV(requestObj)
        return
      }
      requestObj.type = 'apcsvexport'
      await this.fetchAdjustingEntriesCSV(requestObj)
      requestObj.type = 'arcsvexport'
      await this.fetchAdjustingEntriesCSV(requestObj)
      requestObj.type = 'jecsvexport'
      await this.fetchAdjustingEntriesCSV(requestObj)
    },

    async retrySnapshotIntegration () {
      const { adjustingEntriesSnapshotRecordId } = this.snapshotRecord
      const body = [
        {
          op: 'add',
          path: '/integrationStatus',
          value: AdjustingEntriesSnapshotIntegrationStatus.Retry.value
        }
      ]
      await this.patchSnapshotRecord({ snapshotRecordId: adjustingEntriesSnapshotRecordId, body })
      this.closeDialogs()
      this.triggerDelayedRefresh()
    },

    triggerDelayedRefresh () {
      this.delayedRefreshTimeout = setTimeout(() => {
        this.delayedRefreshTimeout = undefined
        this.$emit('refresh-snapshot')
      }, 5000)
    },

    getRequestType (type) {
      switch (type) {
        case 0:
          return 'apcsvexport'
        case 1:
          return 'arcsvexport'
        case 2:
          return 'jecsvexport'
        default:
          return undefined
      }
    },
    breakDownSnapshot (snapshot) {
      this.brokenDownSnapshot = {
        tickets: {
          logs: this.buildTickets(snapshot.tickets),
          byproducts: this.buildTickets(snapshot.byproductsTickets),
          lys: this.buildTickets(snapshot.logYardSaleTickets ?? []),
          transfers: this.buildTickets(snapshot.transferTickets ?? [])
        },
        corrections: {
          logs: this.buildCorrections(snapshot.ticketCorrections),
          byproducts: this.buildCorrections(snapshot.byproductsCorrections),
          lys: this.buildCorrections(snapshot.logYardSaleCorrections),
          transfers: this.buildCorrections(snapshot.transferCorrections)
        },
        miscPayments: {
          tractPayments: this.groupMiscPayments(snapshot.tractPayments ?? []),
          advances: this.groupMiscPayments(snapshot.advances ?? [], 'advances'),
          accountPayments: {
            accountPaymentsAP: this.groupMiscPayments((snapshot.accountPayments ?? []).filter(r => r.categoryType === AccountingCategory.Payable.value)),
            accountPaymentsJE: this.groupMiscPayments((snapshot.accountPayments ?? []).filter(r => r.categoryType === AccountingCategory.Accrual.value))
          }
        },
        recoveries: {
          logs: this.buildRecoveries(snapshot.tickets, snapshot.ticketCorrections),
          byproducts: this.buildRecoveries(snapshot.byproductsTickets, snapshot.byproductsCorrections)
        }
      }
    },

    groupMiscPayments: (payments, collectionTitle = 'payments') => Object.values(Object.groupBy(payments, (item) => [item.businessEntityId, item.activityTemplateId].join(', ')))
      .map(gp => {
        const { businessEntity, activity, glCode, glOffset } = gp[0]
        const baseObj = {
          businessEntity,
          activity,
          glCode,
          glOffset,
          amount: gp.reduce((acc, cur) => acc + cur.amount, 0)
        }
        baseObj[collectionTitle] = gp
        return baseObj
      }),

    buildTickets (tickets) {
      tickets ??= []
      const flattenedFinancials = (key) => tickets.flatMap(ticket => {
        // Represents each financial with property "ticket", used for grouping by activity template id
        return ticket[key].map(f => ({
          ...f,
          ticket
        }))
      })

      const groupedFinancials = (financials, totalKey = 'grossAmount') => Object.values(Object.groupBy(financials, (item) => item.activityTemplateId))
        .map(group => {
          // Represents the list of tickets for a given activity, where "grossAmount" is the sum of financials for activities w/ that template
          const uniqueTickets = [...new Map(group.map(item => [item.ticket.ticketId, {
            ...item.ticket,
            recovered: item.recoveries?.reduce((a, b) => a + b.amount, 0) ?? 0,
            grossAmount: item[totalKey]
          }])).values()]

          const groupedFinancial = {
            ...group[0],
            grossAmount: group.reduce((acc, cur) => acc + cur[totalKey], 0),
            tickets: uniqueTickets
          }
          delete groupedFinancial.ticket
          return groupedFinancial
        })

      return {
        payables: groupedFinancials(flattenedFinancials('payables')),
        receivables: groupedFinancials(flattenedFinancials('receivables'), 'amount'),
        accruals: groupedFinancials(flattenedFinancials('journalEntries'), 'amount')
      }
    },

    buildCorrections (corrections) {
      corrections ??= []
      const flattenedFinancials = (key) => corrections.flatMap(correction => {
        return correction[key].map(f => ({
          ...f,
          correction
        }))
      })

      const groupedFinancials = (financials, totalKey = 'grossAmount') => Object.values(Object.groupBy(financials, (item) => item.activityTemplateId))
        .map(group => {
          const uniqueCorrections = [...new Map(group.map(financial => [[financial.correction.ticketId, financial.financialCorrectionSide].join(', '), {
            ...financial.correction,
            recovered: financial.recoveries?.reduce((a, b) => a + b.amount, 0) ?? 0,
            grossAmount: financial[totalKey],
            netWeight: financial.financialCorrectionSide === TicketCorrectionSide.Negation.value ? financial.correction.originalWeight * -1 : financial.correction.correctedWeight
          }])).values()]

          return {
            ...group[0],
            grossAmount: group.reduce((acc, cur) => acc + cur[totalKey], 0),
            tickets: uniqueCorrections
          }
        })

      return {
        payables: groupedFinancials(flattenedFinancials('payables')),
        receivables: groupedFinancials(flattenedFinancials('receivables'), 'amount'),
        accruals: groupedFinancials(flattenedFinancials('journalEntries'), 'amount')
      }
    },

    buildRecoveries (tickets, corrections) {
      const ticketRecoveries = tickets.flatMap(ticket => ticket.payables.flatMap(p => p.recoveries))
      const correctionRecoveries = corrections.flatMap(correction => correction.payables.flatMap(p => p.recoveries))
      const totalRecoveries = ticketRecoveries.concat(correctionRecoveries).map(r => ({ ...r, amount: r.amount * -1 }))

      return Object.values(Object.groupBy(totalRecoveries, item => item.activityTemplateId))
        .map(group => {
          const advances = Object.values(Object.groupBy(group, item => item.advanceId))
            .map(advanceGroup => {
              return {
                ...advanceGroup[0],
                amount: advanceGroup.reduce((acc, cur) => acc + cur.amount, 0)
              }
            })
          return {
            ...group[0],
            recovered: group.reduce((acc, cur) => acc + cur.amount, 0),
            advances
          }
        })
    }
  }
}
</script>
