<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-row justify="end" dense>
          <v-col cols="auto">
            <Icon :icon="groupingIcon" :tooltipText="groupingTooltip" @icon-clicked="groupByClicked" margin="" :small="false"/>
          </v-col>
          <v-col cols="auto">
            <v-menu bottom left v-model="filterOpen" :close-on-content-click="false">
              <template #activator="{on, attrs}">
                <v-icon
                color="primary"
                v-on="on"
                v-bind="attrs">
                  mdi-filter
                </v-icon>
              </template>
              <v-card width="600" v-if="filterOpen">
                <v-card-title class="primary white--text">
                  <span class="heading">{{ $t('bondFilter') }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container fluid>
                    <v-row dense>
                      <v-col cols="12">
                        <DatePicker
                          :dateLabel="$t('asOf')"
                          :startDate="filter.asAt"
                          :max="now"
                          @date-picked="asAtDateChosen"/>
                      </v-col>
                      <v-col cols="10">
                        <v-select
                        :label="$t('tractStatus')"
                        :items="tractStatusOptions"
                        v-model="filter.tractStatuses"
                        :rules="[requiredSelect]"
                        item-text="name"
                        item-value="value"
                        multiple
                        chips
                        deletable-chips
                        :menu-props="{ 'offset-y': true }">
                        <template #selection="{ item }">
                          <v-chip close @click:close="statusChipClosed(item)">
                            <Icon :icon="item.details.icon" :iconColor="item.details.color"/><span>{{ item.name }}</span>
                          </v-chip>
                        </template>
                        <template #item="{ item }">
                          <v-row dense>
                            <v-col cols="auto">
                              <v-icon>{{ filter.tractStatuses.includes(item.value) ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}</v-icon>
                            </v-col>
                            <v-col cols="auto">
                              <Icon :icon="item.details.icon" :iconColor="item.details.color" margin=""/>
                            </v-col>
                            <v-col>
                              <span>{{ item.name }}</span>
                            </v-col>
                          </v-row>
                        </template>
                      </v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-checkbox class="py-0" v-model="hideZero" :label="$t('hideBondsOnTractsWithZero')"/>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions class="pb-4 pt-0 mt-n4">
                  <v-row justify="space-between">
                    <v-col cols="auto">
                      <v-btn color="primary" outlined @click="filterApplied(true)"><span>{{$t('reset')}}</span></v-btn>
                    </v-col>
                    <v-col cols="auto">
                      <v-btn color="primary" :disabled="buttonDisabled" @click="filterApplied(false)"><span>{{$t('apply')}}</span></v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-menu>
            <Icon icon="mdi-refresh" @icon-clicked="refresh" :small="false" margin="ml-2"/>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-row justify="end">
          <v-col class="py-0">
            <v-chip-group class="py-0">
              <v-chip v-for="chip in filterChips" :key="chip.id" close color="secondary" @click:close="filterChipClosed(chip)">
                <span>{{ chip.text }}</span>
              </v-chip>
            </v-chip-group>
          </v-col>
          <v-col cols="auto">
            <span class="title">{{ `${$t('grandTotal')}:` }}</span>
          </v-col>
          <v-col cols="auto">
            <span class="title">{{ formatMoney(totalBondBalance) }}</span>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-data-table
        :headers="tableHeaders"
        :items="bondsGroupedForTable"
        height="max(calc(100vh - 490px), 256px)"
        fixed-header
        show-expand
        single-expand
        :expanded="expanded"
        :item-key="tableItemKey"
        :hide-default-footer="bondsGroupedForTable?.length < 10"
        dense
        :loading="bondsLoading"
        >
          <template #expanded-item="{ item }">
            <td :colspan="tableHeaders.length + 1" style="background-color: #eee;">
              <v-container fluid>
                <v-card outlined>
                  <v-data-table
                  :headers="bondHeaders"
                  :items="item.bonds"
                  :hide-default-footer="item.bonds.length < 10"
                  dense>
                    <template #item.issuedAt="{ item }">
                      <span>{{ utcToLocalDate(item.issuedAt) }}</span>
                    </template>
                    <template #item.amount="{ item }">
                      <span>{{ formatMoney(item.amount) }}</span>
                    </template>
                  </v-data-table>
                </v-card>
              </v-container>
            </td>
          </template>
          <template #item.status="{ item }">
            <TractStatusIcon :status="item.status"/>
          </template>
          <template #item.harvestEndDate="{ item }">
            <span>{{ utcToLocalDate(item.harvestEndDate) }}</span>
          </template>
          <template #item.bondBalance="{ item }">
            <span>{{ formatMoney(item.bondBalance) }}</span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import BondHeaders from '@/headers/Bonds.js'
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'
import { TractStatus } from '@/utils/Enumerations.js'
import { utcToLocalDate } from '@/utils/DateFormatter.js'
import { formatMoney } from '@/utils/NumericMutations.js'
import { sumBy } from 'lodash'
import rules from '@/utils/rules.js'
import { localeCompareAlphanumeric } from '@/utils/base'

export default {
  name: 'Bonds',

  components: {
    Icon: () => import('@/components/helper/Icon.vue'),
    TractStatusIcon: () => import('@/components/tract/TractStatusIcon.vue'),
    DatePicker: () => import('@/components/helper/DatePicker.vue')
  },

  data: () => ({
    requiredSelect: rules.rules.requiredSelect,
    now: moment().format(),
    filter: {
      asAt: moment().format(),
      tractStatuses: [],
      includeZeroBalance: false
    },
    appliedFilter: null,
    expanded: [],
    tractStatusOptions: [
      TractStatus.Initiated,
      TractStatus.Cruised,
      TractStatus.Executed,
      TractStatus.Active,
      TractStatus.Closed
    ],
    filterOpen: false,
    groupByTract: true
  }),

  created () {
    this.filterApplied(true)
  },

  watch: {
    bondsLoading (value) {
      if (!value) {
        this.expandSingle()
      }
    },

    groupByTract () {
      this.expandSingle()
    }
  },

  computed: {
    ...mapGetters('payable', ['allBonds', 'bondsLoading']),

    tableHeaders () {
      return this.groupByTract ? BondHeaders.tractWithBondHeaders() : BondHeaders.accountWithBondHeaders()
    },

    bondsGroupedForTable () {
      return (this.groupByTract ? this.tracts : this.accounts)
    },

    tableItemKey () {
      return this.groupByTract ? 'tractId' : 'accountId'
    },

    bondHeaders () {
      return BondHeaders.bondHeaders(this.groupByTract)
    },

    buttonDisabled () {
      return this.filter.tractStatuses?.length < 1
    },

    filterChips () {
      const chips = []
      if (moment(this.appliedFilter.asAt).isBefore(moment().startOf('day'))) {
        chips.push({
          id: 'date',
          text: `${this.$t('dataAsOf')} ${utcToLocalDate(this.filter.asAt)}`
        })
      }
      if (!this.appliedFilter.includeZeroBalance) {
        chips.push({
          id: 'zero',
          text: this.$t('hideBondsOnTractsWithZero')
        })
      }
      return chips
    },

    groupingIcon () {
      return this.groupByTract ? 'mdi-account' : 'mdi-crosshairs-gps'
    },

    groupingTooltip () {
      return this.$t('groupByCategory', { category: this.$t(this.groupByTract ? 'account' : 'tract') })
    },

    tracts () {
      return Object.entries(Object.groupBy(this.allBonds, b => b.tractName))
        .map(([tractName, tractBonds]) => ({
          name: tractName,
          tractId: tractBonds[0].tractId,
          harvestEndDate: tractBonds[0].tractHarvestEndDate,
          status: tractBonds[0].tractStatus,
          accountName: tractBonds[0].accountName,
          bondBalance: sumBy(tractBonds, b => b.amount),
          bonds: tractBonds
        }))
    },

    accounts () {
      return Object.entries(Object.groupBy(this.allBonds, b => b.accountName))
        .map(([accountName, accountBonds]) => ({
          name: accountName,
          accountId: accountBonds[0].accountId,
          bondBalance: sumBy(accountBonds, b => b.amount),
          bonds: accountBonds.toSorted((bondA, bondB) => {
            const alpha = localeCompareAlphanumeric(bondA.tractName, bondB.tractName)
            if (alpha !== 0) return alpha
            return moment(bondA.issuedAt).isBefore(moment(bondB.issuedAt)) ? -1 : 1
          })
        }))
    },

    totalBondBalance () {
      return sumBy(this.allBonds, b => b.amount)
    },

    hideZero: {
      get () {
        return !this.filter.includeZeroBalance
      },
      set (value) {
        this.filter.includeZeroBalance = !value
      }
    }
  },

  methods: {
    ...mapActions('payable', ['fetchBonds']),
    utcToLocalDate,
    formatMoney,

    refresh () {
      this.fetchBonds(this.filter)
    },

    statusChipClosed ({ value }) {
      this.filter.tractStatuses = this.filter.tractStatuses.filter(ts => ts !== value)
    },

    asAtDateChosen (date) {
      this.filter.asAt = date
    },

    filterApplied (reset = false) {
      if (reset) {
        this.filter.tractStatuses = this.filter.tractStatuses = this.tractStatusOptions.map(ts => ts.value)
        this.filter.asAt = moment().format()
        this.filter.includeZeroBalance = false
      }
      this.appliedFilter = JSON.parse(JSON.stringify(this.filter))
      this.refresh()
      this.filterOpen = false
    },

    filterChipClosed ({ id }) {
      switch (id) {
        case 'date': {
          this.filter.asAt = moment().format()
          this.filterApplied(false)
          break
        }
        case 'zero': {
          this.filter.includeZeroBalance = true
          this.filterApplied(false)
          break
        }
      }
    },

    groupByClicked () {
      this.expanded = []
      this.groupByTract = !this.groupByTract
    },

    expandSingle () {
      if (this.bondsGroupedForTable.length === 1) {
        this.expanded = this.bondsGroupedForTable
      } else {
        this.expanded = []
      }
    }
  }
}
</script>
