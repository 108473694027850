<template>
  <v-row no-gutters>
    <v-col cols="auto" v-if="contractTypeIsLogs">
      <Icon
      @icon-clicked="myTractsClicked"
      :small="false"
      :icon="IconHelpers.getIconForObjectType('myTracts', filter.myTracts)"
      margin="mr-2"
      :tooltipText="filter.myTracts ? $t('allTracts') : $t('myTracts')"
      elementId="my-tracts-contract-filter"/>
    </v-col>
    <v-col cols="auto">
      <v-menu
      bottom
      :close-on-content-click="false"
      v-model="open"
      left>
        <template #activator="{on}">
          <v-icon v-on="on" class="mr-2" color="primary">mdi-filter</v-icon>
        </template>
        <v-card width="450px" v-if="open">
          <v-card-title class="primary white--text">
            <span>
              {{$t('filterContracts')}}
            </span>
            <v-spacer/>
            <Icon
            icon="mdi-refresh"
            iconColor="tertiary"
            dataTestId="contract-filter-refresh-button"
            :small="false"
            :tooltipText="$t('reset')"
            @icon-clicked="resetClicked"/>
          </v-card-title>
          <v-card-text class="mt-3">
            <v-container fluid>
              <v-row dense>
                <span class="text-h6">
                  {{$t('byStatus')}}
                </span>
              </v-row>
              <v-row dense class="mt-n3">
                <v-col cols="6">
                  <v-checkbox
                  v-model="filters.includeOpen"
                  data-testid="filter-open-check"
                  :label="$t('active')"
                  color="primary"/>
                </v-col>
                <v-col cols="6">
                  <v-checkbox
                  :label="$t('onHold')"
                  data-testid="filter-hold-button"
                  v-model="filters.includeOnHold"
                  color="primary"/>
                </v-col>
              </v-row>
              <v-row dense class="mt-n6">
                <v-col cols="6">
                  <v-checkbox
                  :label="$t('expired')"
                  data-testid="filter-expired-button"
                  v-model="filters.includeExpired"
                  color="primary"/>
                </v-col>
                <v-col cols="6">
                  <!-- TODO: Modify v-if to show for Byproducts once Byproduct contracts can have a tract defined. -->
                  <v-checkbox
                  v-if="contractTypeIsLogs"
                  :label="$t('closed')"
                  data-testid="filter-close-button"
                  v-model="filters.includeClosed"
                  color="primary"/>
                </v-col>
              </v-row>
              <div v-if="contractTypeIsLogs">
                <v-row dense class="mt-n3">
                <span class="text-h6">
                  {{$t('byType')}}
                </span>
                </v-row>
                <v-row dense class="mt-n3">
                  <v-col cols="6">
                    <v-checkbox
                    :label="$t('production')"
                    data-testid="filter-production-button"
                    v-model="filters.includeProduction"
                    color="primary"/>
                  </v-col>
                  <v-col cols="6">
                    <v-checkbox
                    :label="$t('woodsSale')"
                    data-testid="filter-woodsale-button"
                    v-model="filters.includeWoodsSale"
                    color="primary"/>
                  </v-col>
                </v-row>
              </div>
              <div v-if="isByproducts">
                <v-row dense>
                  <span class="text-h6">
                    {{ $t('byType') }}
                  </span>
                </v-row>
                <v-row dense class="mt-n3">
                  <v-col cols="6">
                    <v-checkbox
                    :label="$t('byproductSale')"
                    v-model="filters.includeByProduct"
                    color="primary"/>
                  </v-col>
                  <v-col cols="6">
                    <v-checkbox
                    :label="$t('byproductPurchase')"
                    v-model="filters.includeByproductPurchase"
                    color="primary"/>
                  </v-col>
                </v-row>
              </div>
              <div v-if="contractTypeIsLogs">
                <v-row dense>
                  <span class="text-h6">
                    {{$t('byOperation')}}
                  </span>
                </v-row>
                <v-row dense class="mt-n3">
                  <v-col cols="6">
                    <v-checkbox
                    :label="$t('limitToInternal')"
                    data-testid="filter-internal-button"
                    v-model="filters.limitToInternal"
                    color="primary"/>
                  </v-col>
                  <v-col cols="6">
                    <v-checkbox
                    :label="$t('limitToDraft')"
                    data-testid="filter-draft-button"
                    v-model="filters.limitToDraft"
                    color="primary"/>
                  </v-col>
                </v-row>
                <v-row dense class="mt-n6">
                  <v-col cols="6">
                    <v-checkbox
                    :label="$t('limitToPaused')"
                    data-testid="filter-paused-button"
                    v-model="filters.limitToPaused"
                    color="primary"/>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col>
                    <TeamAutocomplete
                    :propTeamIds="filters.teamIds"
                    @team-chosen="teamChosen"
                    fetchTeamsOnCreation
                    multiple/>
                  </v-col>
                </v-row>
            </div>
            <div v-else>
              <v-row dense>
                <span class="text-h6">
                  {{$t('byOperation')}}
                </span>
              </v-row>
              <v-row dense class="mt-n3">
                <v-col cols="6">
                  <v-checkbox
                  :label="$t('limitToDraft')"
                  data-testid="filter-draft-button"
                  v-model="filters.limitToDraft"
                  color="primary"/>
                </v-col>
                <v-col cols="6">
                  <v-checkbox
                  :label="$t('limitToPaused')"
                  data-testid="filter-paused-button"
                  v-model="filters.limitToPaused"
                  color="primary"/>
                </v-col>
              </v-row>
            </div>
            <div v-if="showApprovalsFilter">
              <v-row dense>
                <span class="text-h6">
                  {{$t('byApprovalStatus')}}
                </span>
              </v-row>
              <v-row dense class="mt-n3">
                <v-col cols="6">
                  <v-checkbox
                    :label="$t('toReview')"
                    v-model="toReview"
                    color="primary"
                  />
                </v-col>
                <v-col cols="6">
                  <v-checkbox
                    :label="$t('returned')"
                    v-model="returned"
                    color="primary"
                  />
                </v-col>
              </v-row>
            </div>
            </v-container>
          </v-card-text>
          <v-card-actions class="px-0 pb-4">
            <v-spacer/>
            <v-btn data-testid="filter-submit-button" class="primary white--text" @click="applyButtonClicked">{{$t('filter')}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { SETTING_KEYS, SETTING_TYPES } from '@/utils/UserSettings'
import { ContractApprovalStatus, ContractType, ContractMode } from '@/utils/Enumerations.js'
import IconHelpers from '@/utils/IconHelpers'

export default {
  name: 'ContractFilter',

  components: {
    Icon: () => import('@/components/helper/Icon.vue'),
    TeamAutocomplete: () => import('@/components/autocomplete/TeamAutocomplete.vue')
  },

  props: {
    contractMode: {
      type: Object,
      required: true
    },
    showApprovalsFilter: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    IconHelpers,
    open: false,
    saveChanges: false,
    toReview: false,
    returned: false,
    filters: {
      includeOpen: true,
      includeOnHold: true,
      includeClosed: false,
      includeExpired: true,
      limitToDraft: false,
      limitToPaused: false,
      limitToInternal: false,
      includeProduction: true,
      includeWoodsSale: true,
      includeByProduct: false,
      includeTransfer: false,
      includeLogYardSale: false,
      includeByproductPurchase: false,
      approvalStatus: undefined,
      teams: undefined,
      teamIds: undefined,
      myTracts: false
    }
  }),

  computed: {
    ...mapGetters('contract', ['filter']),
    ...mapGetters('user-settings', ['mutatedUserSettings']),

    contractTypes () {
      return (this.isByproducts)
        ? [ContractType.ByproductSale.value, ContractType.ByproductPurchase.value]
        : [
          this.filters.includeProduction ? 0 : undefined,
          this.filters.includeWoodsSale ? 1 : undefined
        ].filter(t => t !== undefined)
    },

    contractTypeIsLogs () {
      return this.contractMode.value === ContractMode.Logs.value
    },

    isByproducts () {
      return this.contractMode.value === ContractMode.Byproducts.value
    }
  },

  watch: {
    filter: {
      handler (val) {
        this.filters = JSON.parse(JSON.stringify(val))
      },
      deep: true
    },

    filters: {
      handler (val) {
        if (!val.includeOpen && !val.includeOnHold && !val.includeClosed && !val.includeExpired) {
          this.$nextTick(_ => {
            this.filters.includeOpen = true
          })
        }

        if (!val.includeProduction && !val.includeWoodsSale && !val.includeByProduct && !val.includeByproductPurchase && !val.includeTransfer) {
          this.$nextTick(_ => {
            switch (this.contractMode) {
              case ContractMode.Logs:
                this.filters.includeProduction = true
                this.filters.includeWoodsSale = true
                break
              case ContractMode.Byproducts:
                this.filters.includeByProduct = true
                break
              case ContractMode.Transfers:
                this.filters.includeTransfer = true
                break
              case ContractMode.LogYardSale:
                this.filters.includeLogYardSale = true
                break
            }
          })
        }
      },
      deep: true
    },

    'filters.approvalStatus': {
      handler (val) {
        switch (val) {
          case ContractApprovalStatus.PendingReview.value:
            this.toReview = true
            break
          case ContractApprovalStatus.Returned.value:
            this.returned = true
            break
          default:
            this.toReview = false
            this.returned = false
        }
      }
    },

    contractTypes: {
      handler (value) {
        if (!this.isByproducts) {
          this.mutateUserSetting({
            type: SETTING_TYPES.TABLE,
            key: SETTING_KEYS.CONTRACT_FILTER_TYPES,
            value
          })
        }
      }
    },

    open (isOpen) {
      if (!isOpen) {
        if (this.saveChanges) {
          this.doSaveChanges()
        } else {
          setTimeout(() => {
            this.filters = JSON.parse(JSON.stringify(this.filter))
          }, 75)
        }
      }
    },

    toReview (val) {
      if (val) {
        this.returned = false
        this.$nextTick(_ => {
          this.filters.approvalStatus = ContractApprovalStatus.PendingReview.value
        })
      } else {
        this.filters.approvalStatus = undefined
      }
    },

    returned (val) {
      if (val) {
        this.toReview = false
        this.$nextTick(_ => {
          this.filters.approvalStatus = ContractApprovalStatus.Returned.value
        })
      } else {
        this.filters.approvalStatus = undefined
      }
    }
  },

  created () {
    this.filters = JSON.parse(JSON.stringify(this.filter))
    if (this.isByproducts) {
      this.filters.includeProduction = false
      this.filters.includeWoodsSale = false
    }
    this.readUserSettingTypes()
    if (!this.showApprovalsFilter) {
      this.filters.approvalStatus = undefined
    }
  },

  methods: {
    ...mapActions('contract', ['setFilter']),
    ...mapActions('user-settings', ['updateUserSettings']),
    ...mapMutations('user-settings', ['mutateUserSetting']),
    resetFilters () {
      this.filters = {
        includeOpen: true,
        includeOnHold: false,
        includeClosed: false,
        includeExpired: false,
        limitToDraft: false,
        limitToPaused: false,
        limitToInternal: false,
        includeProduction: this.contractMode === ContractMode.Logs,
        includeWoodsSale: this.contractMode === ContractMode.Logs,
        includeByProduct: this.contractMode === ContractMode.Byproducts,
        includeByproductPurchase: this.contractMode === ContractMode.Byproducts,
        includeTransfer: this.contractMode === ContractMode.Transfers,
        includeLogYardSale: this.contractMode === ContractMode.LogYardSale
      }

      this.toReview = false
      this.returned = false
    },

    resetClicked () {
      this.resetFilters()
      this.applyButtonClicked()
    },

    doSaveChanges () {
      this.setFilter(this.filters)
      this.updateUserSettings(this.mutatedUserSettings)
    },

    applyButtonClicked () {
      this.saveChanges = true
      this.open = false
      this.$nextTick(() => { this.saveChanges = false })
    },

    readUserSettingTypes () {
      if (!this.isByproducts) {
        const ts = new Set(this.mutatedUserSettings[SETTING_TYPES.TABLE][SETTING_KEYS.CONTRACT_FILTER_TYPES])
        this.filters.includeProduction = ts.has(0)
        this.filters.includeWoodsSale = ts.has(1)
      }
    },

    teamChosen (teams) {
      this.filters.teams = teams
      this.filters.teamIds = teams.map(t => t.teamId)
    },

    myTractsClicked () {
      this.filters.myTracts = !this.filters.myTracts
      this.doSaveChanges()
    }
  }
}
</script>
