<template>
  <v-container fluid id="receivable-register">
    <v-row align="center">
      <v-col cols="auto" class="pa-0">
        <Selector
          :label="$t('selectEntity')"
          class="mt-2"
          :items="entities"
          @item-selected="entitySelected"
          variant="outlined"
        />
      </v-col>
      <v-spacer/>
      <v-col align="right" class="ma-0 pa-0">
        <span class="mt-4" style="color: black; font-size: 20px">
          {{ grandTotal }}
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-expansion-panels accordion popout v-model="openPanels" tile class="pa-1">
        <v-expansion-panel v-for="(receivable, index) in receivablesByEntity" :key="`receivable-${index}`">
          <v-expansion-panel-header>
            <v-row dense>
              <v-col cols="auto">
                <v-icon small>
                  mdi-account
                </v-icon>
                {{getTitleForPaymentPanel(receivable.accountName, receivable.accountCode, receivable.accountExportCode)}}
              </v-col>
            </v-row>
            <template #actions>
              <v-container>
                <v-row justify="end">
                {{getTotalAmountForRegisterPanel(receivable)}}
                </v-row>
                <v-row justify="end">
                  {{getTotalWeightForReceivablePanel(receivable)}}
                </v-row>
                <v-row justify="end">
                  {{getTotalLoadsForReceivablePanel(receivable)}}
                </v-row>
              </v-container>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="lg_expansion-panel_wrapper">
              <ReceivableRegisterCard
              :receivableRegister="receivable"
              :isByproduct="isByproduct"/>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </v-container>
</template>

<script>
import { ContractType } from '@/utils/Enumerations.js'
import { getEntities, getFinancialsByEntity, ReceivablesHelpers } from '@/utils/RegisterHelpers'
import { colorClassForContractType } from '@/utils/componentHelpers.js'
import { mapGetters } from 'vuex'
export default {
  name: 'ReceivableRegister',

  props: {
    receivableRegisterObject: {
      type: Object,
      required: true
    },
    isByproduct: {
      type: Boolean,
      required: true
    }
  },

  components: {
    Selector: () => import('@/components/core/Selector.vue'),
    ReceivableRegisterCard: () => import('@/components/settlements/accounts-receivable-module/receivable-register/ReceivableRegisterCard.vue')
  },

  data: () => ({
    openPanels: [],
    entities: [],
    selectedEntity: undefined
  }),

  created () {
    this.openPanels = this.receivablesByEntity.length === 1 ? [0] : []
  },

  mounted () {
    this.getEntities()
  },

  watch: {
    entities: {
      handler (val) {
        if (val.length > 0) {
          this.selectedEntity = val[0]
        }
      },
      deep: true
    }
  },

  computed: {
    ...mapGetters('settlements', ['useMetricTons', 'useByproductMetricTons']),
    grandTotal () {
      return ReceivablesHelpers.getGrandTotal(this.receivablesByEntity)
    },

    receivables () {
      const { receivables } = this.receivableRegisterObject
      if (receivables === undefined) { return [] }

      return receivables
    },

    contractColor () {
      return colorClassForContractType(this.isByproduct ? ContractType.ByproductPurchase.value : ContractType.Production.value)
    },

    receivablesByEntity () {
      if (this.selectedEntity === undefined) { return this.receivables }
      return getFinancialsByEntity(this.receivables, this.selectedEntity, 1)
    }
  },

  methods: {
    getEntities () {
      this.entities = getEntities(this.receivables, 1)
    },

    entitySelected (entity) {
      this.selectedEntity = entity
    },

    getTotalWeightForReceivablePanel (receivable) {
      return ReceivablesHelpers.totalWeightStringForAccount(receivable, this.isByproduct ? this.useByproductMetricTons : this.useMetricTons)
    },

    getTitleForPaymentPanel (name, code = '', exportCode = '') {
      let formattedName = name

      if (code !== '') formattedName += ` \u2016 ${code}`
      if (exportCode !== '') formattedName += ` \u2016 ${exportCode}`

      return formattedName
    },

    getTotalLoadsForReceivablePanel (receivable) {
      return ReceivablesHelpers.totalLoadsStringForAccount(receivable)
    },

    getTotalAmountForRegisterPanel (receivable) {
      return ReceivablesHelpers.totalStringForAccount(receivable)
    }
  }
}
</script>
