import PDFMake from 'pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import moment from 'moment'
import { tonStringFromPounds } from '@/utils/NumericMutations.js'
import { utcToLocalDate } from '@/utils/DateFormatter.js'
import i18n from '@/i18n'
import store from '@/store'
import { generateSvg, weightAlert } from '@/images/AngelSvgLibrary'
import { CorrectionType, ContractType, TicketStatus, DefectCategory } from '@/utils/Enumerations.js'
import { fileNameForString } from './GenericSettlementsFunctions'

PDFMake.vfs = pdfFonts.pdfMake.vfs
const emptyLine = ['', '', '']

export async function getTicketPDF (tickets, companyInfo, tracts) {
  const ticketTable = []
  let ticketPDFName = ''
  let pageBreak = false
  let payPeriodEnd = ''

  const locations = await store.dispatch('locations/fetchLocations')
  const dateConfig = store.getters['settlements/dateConfiguration'].payPeriod
  if (dateConfig !== undefined) {
    payPeriodEnd = moment(dateConfig.endDate).subtract(1, 'days').format('YYYY-MM-DD')
  }

  if (tickets.length > 0) {
    if (!(tracts?.length > 0)) {
      tracts = [tickets[0].tract]
    }
    if (tracts.length > 1) {
      ticketPDFName = `tickets_${payPeriodEnd}`
    } else {
      ticketPDFName = fileNameForString(`${tracts[0].name} tickets ${payPeriodEnd}`)
    }
    pageBreak = true
    for (const [index, ticket] of tickets.entries()) {
      if (index === tickets.length - 1) pageBreak = false
      const table = await getTicketTable(ticket, companyInfo, locations, pageBreak)
      ticketTable.push(table)
    }
  } else {
    ticketPDFName = `Ticket_${tickets.ticketNumber}`
    const table = await getTicketTable(tickets, companyInfo, locations, pageBreak)
    ticketTable.push(table)
  }
  const docDefinition = {
    content: ticketTable
  }

  PDFMake.createPdf(docDefinition).download(ticketPDFName)
}

async function getTicketTable (ticket, companyInfo, locations, pageBreak) {
  const defectTable = await getTicketDefectsTable(ticket)
  const correctionTable = await getCorrectionTable(ticket)
  const weightTable = getWeightTable(ticket)
  const contactInfoTable = await getContactInfoTableForTicket(ticket, companyInfo, locations)

  return [
    {
      table: {
        widths: ['auto', 'auto', '*'],
        body: [
          ...correctionTable,
          ...contactInfoTable,
          emptyLine,
          emptyLine,
          [`${i18n.t('ticket')} #:`, ticket.ticketNumber || i18n.t('notAvailable'), ''],
          [ticket.extTicketNumber1 ? `${i18n.t('external')} #1:` : '', ticket.extTicketNumber1 || '', ''],
          [ticket.extTicketNumber2 ? `${i18n.t('external')} #2:` : '', ticket.extTicketNumber2 || '', ''],
          emptyLine,
          [`${i18n.t('date')}:`, moment().format('L - LT'), ''],
          [`${i18n.t('timeIn')}:`, utcToLocalDate(ticket.weighedInAt, 'L - LT'), ''],
          [`${i18n.t('timeOut')}:`, utcToLocalDate(ticket.weighedOutAt, 'L - LT'), ''],
          [`${i18n.t('loadCreated')}:`, utcToLocalDate(ticket.loadCreatedAt, 'L - LT'), ''],
          emptyLine,
          emptyLine,
          [`${i18n.t('product')}:`, (ticket.product === '' || !ticket.product) ? i18n.t('other') : ticket.product, ''],
          ticket.pieces ? [`${i18n.t('pieces')}:`, ticket.pieces || i18n.t('notAvailable'), ''] : undefined,
          ...getContractInformationRows(ticket),
          [`${i18n.t('trailerId')}:`, ticket.trailerIdentifier || i18n.t('notAvailable'), ''],
          emptyLine
        ].filter(row => row !== undefined)
      },
      layout: {
        vLineColor: () => 'white',
        hLineColor: () => 'white'
      }
    },
    {
      table: {
        body: [
          [i18n.t('weight'), defectTable ? i18n.t('defects') : ''],
          [weightTable, defectTable ?? {}]
        ]
      },
      layout: {
        vLineColor: () => 'white',
        hLineColor: () => 'white'
      },
      pageBreak: pageBreak && !ticket.isOverweight ? 'after' : undefined
    },
    ticket.isOverweight ? {
      columns: [
        { svg: generateSvg(weightAlert), width: 15, marginLeft: 5 },
        { text: ': Ticket is overweight and may incur a penalty.', marginLeft: 5 }
      ],
      pageBreak: pageBreak ? 'after' : undefined
    } : undefined
  ].filter(row => row !== undefined)
}

/* TODO add information for Byproduct purchase tracks when implemented */
const getContractInformationRows = (ticket) => {
  if (ticket.contractType === ContractType.Production.value || ticket.contractType === ContractType.WoodsSale.value) {
    const tractInformation = [
      [`${i18n.t('tract')}:`, ticket.tract, ''],
      [`${i18n.t('setting')}:`, ticket.setting, ''],
      ticket.landownerCount > 0
        ? [`${i18n.t('landOwner')}:`, ticket.landownerCount === 1 ? ticket.tractLandOwnerName : i18n.t('multiple'), '']
        : undefined,
      [`${i18n.t('county')}:`, ticket.tractCountrySubdivision2, '']
    ].filter(row => row !== undefined)
    return [
      ...tractInformation,
      [`${i18n.t('account')}:`, ticket.account || i18n.t('notAvailable'), ''],
      [`${i18n.t('logger')}:`, ticket.logger || i18n.t('notAvailable'), ''],
      [`${i18n.t('destination')}:`, ticket.destination || i18n.t('notAvailable'), '']
    ]
  } else {
    return [
      [`${i18n.t('account')}:`, ticket.account, ''],
      [`${i18n.t('fromAccount')}:`, ticket.fromAccount, ''],
      [`${i18n.t('destination')}:`, ticket.destination, '']
    ]
  }
}

const getCorrectionTable = async (ticket) => {
  if (ticket.status !== TicketStatus.Exported.value || ticket.correctionType === null || ticket.correctionType === CorrectionType.RecalculateWithNewContractValues.value) return []

  const corrections = await store.dispatch('ticket/getCorrectionsForTicket', ticket.ticketId)
  const note = corrections[0].correction.notes
  const type = CorrectionType.fromInt(ticket.correctionType)
  return [
    [`**${i18n.t('corrected').toUpperCase()}**`, '', ''],
    [`${i18n.t('correctionType')}:`, type, ''],
    [`${i18n.t('correctionNote')}:`, note, ''],
    emptyLine
  ]
}

function formatWeightString (weightInLbs, formatToTons = false) {
  if (weightInLbs <= 0) {
    return i18n.t('notAvailable')
  }

  return formatToTons
    ? tonStringFromPounds(weightInLbs)
    : weightInLbs
}

const getWeightTable = (ticket) => {
  const netWeight = ticket.inWeight - ticket.outWeight
  const adjNetWeight = netWeight - ticket.defectWeight

  const body = ticket.isOverweight ? [
    ['', 'lb', 't', { text: '', border: [false, false, false, false] }],
    [i18n.t('gross'), { text: formatWeightString(ticket.inWeight), alignment: 'right' }, { text: formatWeightString(ticket.inWeight, true), alignment: 'right' }, { svg: generateSvg(weightAlert), width: 15, border: [false, false, false, false] }],
    [i18n.t('tare'), { text: formatWeightString(ticket.outWeight), alignment: 'right' }, { text: formatWeightString(ticket.outWeight, true), alignment: 'right' }, { text: '', border: [false, false, false, false] }],
    [i18n.t('net'), { text: formatWeightString(netWeight), alignment: 'right' }, { text: formatWeightString(netWeight, true), alignment: 'right' }, { text: '', border: [false, false, false, false] }],
    [i18n.t('totDefect'), { text: formatWeightString(ticket.defectWeight), alignment: 'right' }, { text: formatWeightString(ticket.defectWeight, true), alignment: 'right' }, { text: '', border: [false, false, false, false] }],
    [i18n.t('adjNet'), { text: formatWeightString(adjNetWeight), alignment: 'right' }, { text: formatWeightString(adjNetWeight, true), alignment: 'right' }, { text: '', border: [false, false, false, false] }]
  ]
    : [
      ['', 'lb', 't'],
      [i18n.t('gross'), { text: formatWeightString(ticket.inWeight), alignment: 'right' }, { text: formatWeightString(ticket.inWeight, true), alignment: 'right' }],
      [i18n.t('tare'), { text: formatWeightString(ticket.outWeight), alignment: 'right' }, { text: formatWeightString(ticket.outWeight, true), alignment: 'right' }],
      [i18n.t('net'), { text: formatWeightString(netWeight), alignment: 'right' }, { text: formatWeightString(netWeight, true), alignment: 'right' }],
      [i18n.t('totDefect'), { text: formatWeightString(ticket.defectWeight), alignment: 'right' }, { text: formatWeightString(ticket.defectWeight, true), alignment: 'right' }],
      [i18n.t('adjNet'), { text: formatWeightString(adjNetWeight), alignment: 'right' }, { text: formatWeightString(adjNetWeight, true), alignment: 'right' }]
    ]

  if (ticket.departureWeight > 0) {
    body.splice(1, 0, [
      i18n.t('departure'),
      { text: formatWeightString(ticket.departureWeight), alignment: 'right' },
      { text: formatWeightString(ticket.departureWeight, true), alignment: 'right' },
      ticket.isOverweight ? { text: '', border: [false, false, false, false] } : undefined].filter(c => c !== undefined))
  }

  return {
    table: {
      body
    }
  }
}

const getTicketDefectsTable = async ({ ticketId, defectWeight }) => {
  if (defectWeight <= 0) return

  const defects = await store.dispatch('defects/fetchDefectsForTicketById', ticketId)
  const body = []

  if (defects.length === 0) return

  const isSegmented = defects[0].diameter !== null && defects[0].percentage !== null

  if (isSegmented) {
    body.push([i18n.t('type'), i18n.t('category'), 'lb', 't', i18n.t('diameter'), '%'])
    body.push(...defects.map(defect => [
      defect.name,
      { text: DefectCategory.fromInt(defect.category)[0], alignment: 'right' },
      { text: formatWeightString(defect.totalLBS), alignment: 'right' },
      { text: formatWeightString(defect.totalLBS, true), alignment: 'right' },
      { text: defect.diameter, alignment: 'right' },
      { text: defect.percentage, alignment: 'right' }
    ]))
  } else {
    body.push([i18n.t('type'), i18n.t('category'), i18n.t('quantity'), 'lb', 't'])
    body.push(...defects.map(defect => [
      defect.name,
      { text: DefectCategory.fromInt(defect.category)[0], alignment: 'right' },
      { text: defect.quantity, alignment: 'right' },
      { text: formatWeightString(defect.totalLBS), alignment: 'right' },
      { text: formatWeightString(defect.totalLBS, true), alignment: 'right' }
    ]))
  }

  return {
    table: {
      body
    }
  }
}

const getContactInfoTableForTicket = async ({ destinationAccountId, isExternal }, { mainContact, name: companyName }, locations) => {
  let contact = mainContact

  if (isExternal === false) {
    const location = locations.find(l => l.accountId === destinationAccountId)
    contact = location.ticketContact ?? mainContact
    if (location?.name && location?.name !== companyName) {
      companyName = `${companyName} - ${location.name}`
    }
  }

  const table = [
    [companyName, '', ''],
    [`${contact.firstName} ${contact.lastName}`, '', ''],
    [`${i18n.t('phone')} #: ${formattedPhoneNumber(contact.phoneNumber)}`, '', '']
  ]

  return table
}

function formattedPhoneNumber (number) {
  return /^\d{3}\d{3}\d{4}/.test(number) ? number.replace(/(\d{3})(\d{3})(\d{4})/, '($1)-$2-$3') : number
}
