<template>
  <v-container fluid class="pa-0" id="ticket-filter-chips">
    <v-chip-group>
      <v-chip
      v-for="filter in filterArray.filter(f => f.active)"
      :key="filter.name"
      @click:close="removeFilter(filter)"
      :id="`ticket-filter-chip-${kebabCase(filter.name)}`"
      class="ticket-filter-chip"
      text-color="white"
      color="secondary"
      close>
        <v-avatar left>
          <v-icon>{{filter.icon}}</v-icon>
        </v-avatar>
        <span>
          {{$t(filter.name)}}
        </span>
      </v-chip>
    </v-chip-group>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { kebabCase } from 'lodash'

export default {
  name: 'TicketFilterChips',

  computed: {
    ...mapGetters('ticket', ['filters']),

    filterArray () {
      if (this.filters.advancedSearch.active) {
        return [
          {
            name: 'advancedSearch',
            icon: 'mdi-file-search',
            active: true
          }
        ]
      }
      return Object.values(this.filters).filter(f => f.name !== 'phrase')
    }
  },

  methods: {
    kebabCase,
    ...mapMutations('ticket', ['removeFilter'])
  }
}
</script>
