<template>
  <v-card>
    <v-card-title class="headline white--text primary">
      <span>
        {{ resourceName }}
      </span>
      <v-spacer/>
      <span class="mr-3">{{ formatMoney(total) }}</span>
      <BaseDialogActions hideRefresh />
    </v-card-title>
    <v-card-text class="mt-6">
      <DataTable
        hideHeader
        :items.sync="payables"
        :headers="headers"
        :customCells="customCells"
        :no-data-text="$t('noX', { x: resourceName })"
      >
        <template #amount="{ item }">
          <span>{{formatMoney(item.amount)}}</span>
        </template>
        <template #body.append>
          <tr class="font-weight-bold">
            <td class="text-left">{{ $t('totals') }}</td>
            <td v-for="i in blankColumns" :key="i"></td>
            <td class="text-right">{{formatMoney(total)}}</td>
            <td v-if="headers.some(h => h.value === 'actions')"></td>
          </tr>
        </template>
        <template #actions="{item}">
          <Icon
          :disabled="!canMutate"
          icon="mdi-delete-forever"
          iconColor="error"
          :tooltipText="$t('delete')"
          @icon-clicked="deleteResource(item)"
          />
        </template>
      </DataTable>
    </v-card-text>
    <v-dialog v-model="confirmDelete" width="400">
      <ConfirmDelete
      v-if="confirmDelete"
      :title="resourceSingular"
      @delete="confirmDeleteResource"
      @cancel-delete="closeDialogs"/>
    </v-dialog>
  </v-card>
</template>

<script>
import { formatMoney } from '@/utils/NumericMutations'
export default {
  name: 'SBPMiscPaymentDialog',

  props: {
    payables: {
      type: Array,
      default: () => []
    },
    resourceName: {
      type: String,
      required: true
    },
    resourceSingular: {
      type: String,
      required: false
    },
    headers: {
      type: Array,
      required: true
    },
    canMutate: {
      type: Boolean,
      default: true
    }
  },

  components: {
    DataTable: () => import('@/components/core/table/DataTable.vue'),
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue'),
    Icon: () => import('@/components/helper/Icon.vue'),
    ConfirmDelete: () => import('@/components/helper/ConfirmDelete.vue')
  },

  watch: {
    dialog (val) {
      val || this.close()
    }
  },

  data: () => ({
    dialog: false,
    confirmDelete: false,
    focusedResource: undefined
  }),

  computed: {
    total () {
      return this.payables.reduce((total, curr) => total + curr.amount, 0)
    },
    customCells () {
      return [
        {
          slotName: 'amount',
          value: 'amount'
        },
        {
          slotName: 'actions',
          value: 'actions'
        }
      ]
    },
    blankColumns () {
      return this.headers.length - (this.headers.some(h => h.value === 'actions') ? 3 : 2)
    }
  },

  methods: {
    formatMoney,
    close () {
      this.dialog = false
    },
    deleteResource (item) {
      this.focusedResource = item
      this.confirmDelete = true
    },
    closeDialogs () {
      this.focusedResource = undefined
      this.confirmDelete = false
    },
    confirmDeleteResource () {
      this.$emit('delete', this.focusedResource)
      this.closeDialogs()
    }
  }
}
</script>
