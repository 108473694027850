
<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" lg="9" xl="10">
        <ProductSummaryTable
        :productSummary="receivableSummaryObject.productSummary || []"
        />
      </v-col>
      <v-col cols="12" lg="3" xl="2">
        <ProductSummaryChart :productSummary="receivableSummaryObject.productSummary || []"/>
      </v-col>
    </v-row>
    <v-row>
      <v-expansion-panels multiple popout v-model="openPanels" tile>
        <v-expansion-panel v-for="(buyerSummary, index) in receivableSummaryObject.buyerSummary" :key="`buyerSummary-${index}`">
          <v-expansion-panel-header>
            <v-row dense>
              <v-col cols="auto">
                <v-icon small>
                  mdi-domain
                </v-icon>
                {{buyerSummary.name}}
              </v-col>
            </v-row>
            <template #actions>
              <v-container>
                <v-row justify="end">
                  {{getTotalAmountForBuyerSummaryPanel(buyerSummary)}}
                </v-row>
                <v-row justify="end">
                  {{getTotalWeightForBuyerSummaryPanel(buyerSummary)}}
                </v-row>
                <v-row justify="end">
                  {{getTotalLoadsForBuyerSummaryPanel(buyerSummary)}}
                </v-row>
              </v-container>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="lg_expansion_panel_wrapper">
              <BPReceivableCard
              :buyerSummary="buyerSummary"
              class="mt-2"
              />
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </v-container>
</template>

<script>
import { tonStringFromPounds, formatMoney } from '@/utils/NumericMutations.js'
import { mapGetters } from 'vuex'
export default {
  name: 'BPReceivableSummary',

  props: {
    receivableSummaryObject: {
      type: Object,
      required: true
    }
  },

  components: {
    ProductSummaryTable: () => import('@/components/settlements/accounts-receivable-module/production-summary/ProductSummaryTable.vue'),
    ProductSummaryChart: () => import('@/components/settlements/accounts-receivable-module/production-summary/ProductSummaryChart.vue'),
    BPReceivableCard: () => import('@/components/settlements/accounts-receivable-module/BPReceivableCard.vue')
  },

  data: () => ({
    openPanels: []
  }),

  created () {
    this.openPanels = this.receivableSummaryObject.productSummary.length === 1 ? [0] : []
  },

  computed: {
    ...mapGetters('settlements', ['useByproductMetricTons'])
  },

  methods: {
    formatMoney,
    getTotalWeightForBuyerSummaryPanel (buyerSummary) {
      let totalWeight = 0

      buyerSummary.fromAccountSummaries.forEach((fromAccountSummary) => {
        totalWeight += fromAccountSummary.locationProductSummaries.reduce((a, b) => a + b.weight, 0)
      })

      return this.useByproductMetricTons
        ? this.$t('metricTonsTotal', { totalWeight: tonStringFromPounds(totalWeight, 3, this.useByproductMetricTons) })
        : this.$t('shortTonsTotal', { totalWeight: tonStringFromPounds(totalWeight, 3, this.useByproductMetricTons) })
    },

    getTotalLoadsForBuyerSummaryPanel (buyerSummary) {
      let totalLoads = 0

      buyerSummary.fromAccountSummaries.forEach((fromAccountSummary) => {
        totalLoads += fromAccountSummary.locationProductSummaries.reduce((a, b) => a + b.loads, 0)
      })

      const loadText = totalLoads > 1 ? this.$t('loads') : this.$t('load')
      return `${totalLoads} ${loadText}`
    },

    getTotalAmountForBuyerSummaryPanel (buyerSummary) {
      let totalAmount = 0

      buyerSummary.fromAccountSummaries.forEach((fromAccountSummary) => {
        totalAmount += fromAccountSummary.locationProductSummaries.reduce((a, b) => a + b.amount, 0)
      })

      return formatMoney(totalAmount)
    }
  }
}
</script>
