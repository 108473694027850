<template>
  <canvas id="production-summary-chart" :style="{ maxWidth: maxWidth }"/>
</template>

<script>
import { Chart } from 'chart.js'
import { tonStringFromPounds } from '@/utils/NumericMutations.js'
import { mapGetters } from 'vuex'
import { getChartColors } from '@/components/dashboard/ChartColors'
import { ContractMode } from '@/utils/Enumerations'
export default {
  name: 'ProductSummaryChart',

  props: {
    productSummary: {
      type: Array,
      required: true
    },
    maxWidth: {
      type: String,
      default: '500px'
    }
  },

  data: () => ({
    chartCanvas: false
  }),

  mounted () {
    this.buildChart()
  },

  watch: {
    productSummary: {
      handler () {
        this.buildChart()
      },
      deep: true
    },
    useByproductMetricTons: {
      handler () {
        this.buildChart()
      }
    }
  },

  computed: {
    ...mapGetters('settlements', ['useByproductMetricTons'])
  },

  methods: {
    buildChart () {
      if (this.chart) {
        this.chart.destroy()
      }
      const chartElement = document.getElementById('production-summary-chart')
      this.chart = new Chart(chartElement, {
        type: 'doughnut',
        data: this.getChartData(this.productSummary)
      })
    },

    getChartData (productSummary) {
      const labels = productSummary.map((ps) => ps.productName)
      const weightInfo = productSummary.map((ps) =>
        parseFloat(tonStringFromPounds(ps.weight, 3, this.useByproductMetricTons))
      )
      return {
        labels: labels,
        datasets: [
          {
            backgroundColor: getChartColors(labels.length, ContractMode.Byproducts.value),
            data: weightInfo
          }
        ]
      }
    }
  }
}
</script>
