<template>
  <v-card>
    <v-card-title class="primary headline white--text">
      {{ $t('recentSamsaraIntegrationRequests') }}
      <span/>
      <v-spacer/>
      <BaseDialogActions @refresh="refreshFailures" hideFullscreen/>
    </v-card-title>
    <v-card-text class="mt-3">
      <FormWrapper
      :disabled="readonly"
      :hideButton="hideButton"
      :buttonText="$t('resumeIntegration')"
      @submit="resumeIntegrationClicked">
        <LoadableComponent :loading="loading" :height="375">
          <v-container fluid>
            <DataTable
            :items="samsaraFailures"
            :headers="samsaraIntegrationHeaders()"
            :showSearch="false"
            :showDense="false"
            :customCells="samsaraIntegrationCustomCells()">
              <template #status="{item}">
                <Icon
                v-if="item.status === SamsaraIntegrationRecordStatus.Success.value"
                icon='mdi-check-circle'
                iconColor='success'
                :tooltipText="$t('success')"/>
                <Icon
                v-else
                icon='mdi-alert-circle'
                iconColor='error'
                :tooltipText="$t('failure')"
                @icon-clicked="openDetailDialog(item)"/>
              </template>
              <template #completedAt="{item}">
                <span :id="item.integrationDetail ? 'table-shortcut' : ''" @click="openDetailDialog(item)">
                  {{ utcToLocalDate(item.completedAt, 'L - LT') }}
                </span>
              </template>
              <template #integrationType="{item}">
                <span :id="item.integrationDetail ? 'table-shortcut' : ''" @click="openDetailDialog(item)">
                  {{ SamsaraIntegrationType.fromInt(item.samsaraIntegrationType) }}
                </span>
              </template>
            </DataTable>
          </v-container>
        </LoadableComponent>
      </FormWrapper>
    </v-card-text>
    <v-dialog v-model="detailDialog" max-width="1000">
      <template>
        <v-card>
          <v-card-title class="primary headline white--text">
            <span class="ml-2">
              {{ $t('integrationDetail') }}
            </span>
            <v-spacer/>
            <Icon
            icon="mdi-close"
            iconColor="white"
            :small="false"
            large
            :tooltipText="$t('close')"
            @icon-clicked="detailDialog = false; focusedItem = undefined"/>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-row dense>
                <v-col cols="12">
                  <v-card>
                    <v-card-title>
                      {{ SamsaraIntegrationType.fromInt(focusedItem?.samsaraIntegrationType) }}
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col cols="4">
                          {{ $t('status') }}:
                        </v-col>
                        <v-col>
                          {{ SamsaraIntegrationRecordStatus.fromInt(focusedItem?.status) }}
                        </v-col>
                      </v-row>
                      <v-row class="mt-n4">
                        <v-col cols="4">
                          {{ $t('failureReason') }}:
                        </v-col>
                        <v-col>
                          {{ SamsaraIntegrationFailureReason.fromInt(focusedItem?.integrationFailureReason) }}
                        </v-col>
                      </v-row>
                      <v-row class="mt-n4">
                        <v-col cols="4">
                          {{ $t('completed') }}:
                        </v-col>
                        <v-col>
                          {{ utcToLocalDate(focusedItem?.completedAt, 'L - LT') }}
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" v-if="detailString">
                  <v-card>
                    <v-card-title>
                      <span>{{ $t('detailedResponse') }}</span>
                    </v-card-title>
                    <v-card-text>
                      <div>
                        <pre class="black--text" :style="{ fontSize: '16px', fontWeight: 'bold', whiteSpace: 'pre-wrap' }">{{detailString}}</pre>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import { utcToLocalDate } from '../../../utils/DateFormatter'
import {
  SamsaraIntegrationRecordStatus,
  SamsaraIntegrationType,
  SamsaraIntegrationFailureReason,
  SamsaraIntegrationStatus,
  SamsaraIntegrationStatusReason
} from '../../../utils/Enumerations'
import { samsaraIntegrationHeaders, samsaraIntegrationCustomCells } from '../../../headers/CompanyInfo'
export default {
  name: 'IntegrationRecords',

  components: {
    DataTable: () => import('@/components/core/table/DataTable.vue'),
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue'),
    Icon: () => import('@/components/helper/Icon.vue'),
    FormWrapper: () => import('@/components/core/FormWrapper.vue'),
    LoadableComponent: () => import('@/components/helper/LoadableComponent.vue')
  },

  props: {
    readonly: {
      type: Boolean,
      required: true
    },
    integrationStatus: {
      type: Object,
      required: true
    },
    integrationStatusReason: {
      type: Object,
      required: true
    },
    unsavedChanges: {
      type: Boolean,
      required: true
    }
  },

  data: () => ({
    SamsaraIntegrationRecordStatus,
    SamsaraIntegrationType,
    SamsaraIntegrationFailureReason,
    SamsaraIntegrationStatusReason,
    SamsaraIntegrationStatus,
    samsaraFailures: [],
    detailDialog: false,
    focusedItem: undefined,
    loading: false
  }),

  methods: {
    samsaraIntegrationHeaders,
    samsaraIntegrationCustomCells,
    utcToLocalDate,
    ...mapActions('samsara', ['fetchSamsaraIntegrationRequests']),
    async refreshFailures () {
      this.loading = true
      const failures = await this.fetchSamsaraIntegrationRequests()
      this.samsaraFailures = failures
      this.loading = false
    },

    openDetailDialog (item) {
      if (!item.integrationDetail) return
      this.detailDialog = true
      this.focusedItem = item
    },

    resumeIntegrationClicked () {
      if (this.unsavedChanges) {
        this.setSnackError(this.$t('mustSaveChangesBeforeSync'))
        return
      }

      if (this.integrationStatusReason === SamsaraIntegrationStatusReason.Unauthorized) {
        this.setSnackError(this.$t('samsaraApiTokenMustBeModified'))
        return
      }

      this.$emit('patchIntegration')
    }
  },

  async created () {
    await this.refreshFailures()
  },

  computed: {
    detailString () {
      const detail = this.focusedItem?.integrationDetail || undefined
      try {
        return JSON.stringify(JSON.parse(detail), null, 2).trim()
      } catch (e) {
        if (e instanceof SyntaxError) {
          return detail || undefined
        }
        throw e
      }
    },

    hideButton () {
      return this.integrationStatus === SamsaraIntegrationStatus.Active ||
        this.integrationStatus === SamsaraIntegrationStatus.Paused ||
        this.integrationStatus === SamsaraIntegrationStatus.Pending
    }
  }
}
</script>
