<template>
  <v-container fluid>
    <v-row dense>
      <v-col v-for="contract in payee.contracts" :key="contract.contractId" @click="openPaymentDetailDialog(contract)" cols="12" lg="6" xl="4">
        <v-card id="sbp-contract-card">
          <v-card-title
          :class="`white--text subtitle-1 ${contractHeaderStyle(contract)}`"
          style='color: white; height: 35px;'>
            <Icon
              margin="mt-n3 mr-1"
              dataTestId="tract-name"
              :icon="hasTract(contract) ? 'mdi-crosshairs-gps' : 'mdi-account-arrow-right'"
              :tooltipText="hasTract(contract) ? $t('tract') : $t('fromAccount')"
              iconColor="white"
            />
            <v-tooltip
              :color="contractHeaderStyle(contract)"
              open-on-hover
              top
              max-width="100%">
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-on="on"
                  v-bind="attrs"
                  class="mt-n3"
                  style="width: 50%; white-space: nowrap; word-break: normal; overflow: hidden; text-overflow: ellipsis;">
                  {{ `${hasTract(contract) ? contract.tractName : contract.fromAccountName}` }}
                </span>
              </template>
              <v-row dense align="center">
                <v-col cols="12">
                  <v-row dense align="center" v-for="(ci, i) in contractIconsFor(contract.type, 'contract')" :key="`${ci}-${i}`">
                    <template>
                      <span v-if="i > 0" :key="i" class="mr-1 mt-n1 white--text body-1">{{'| '}}</span>
                      <Icon
                        :key="`i-${i}`"
                        margin="mt-n1 mr-1"
                        dataTestId="tract-name"
                        :icon="ci.icon"
                        :tooltipText="$t(ci.tooltip)"
                        iconColor="white"
                      />
                      <span :key="`s-${i}`" class="mr-1 mt-n1 white--text body-1">{{ ci.value(contract)}}</span>
                    </template>
                  </v-row>
                </v-col>
              </v-row>
            </v-tooltip>
            <v-spacer/>
            <span class="mt-n3">{{`${formatMoney(contract.amount)}${contract.grossAmount !== contract.amount ? ` (${formatMoney(contract.grossAmount)})` : ''}`}}</span>
          </v-card-title>
          <v-card-text class="mt-2" style="overflow-y: auto; height:150px;">
            <v-container>
            <v-row>
              <Icon
                dataTestId="contract-tooltip-destination"
                icon="mdi-domain"
                :tooltipText="$t('destination')"
                iconColor="black"
              />
              <div style="width: 50%; white-space: nowrap; word-break: normal; overflow: hidden; text-overflow: ellipsis;">
                {{ contract.destinationName }}
              </div>
              <v-spacer/>
              <SettlementsContractActions
                :contract="contract"
                :tickets="contract.loadSummaries"
                @unposted="$emit('refresh-settlement')"
                @edited="$emit('refresh-settlement')"
              />
            </v-row>
            <v-row class="mt-6">
              <table>
                  <tr class="font-weight-bold">
                    <td>{{$t('activity')}}</td>
                    <td>{{$t('averageRate')}}</td>
                    <td>{{$t('unit')}}</td>
                  </tr>
                <tr v-for="activity in contract.paymentRollup.slice(0, 2)" :key="`${contract.payeeId}-${activity.activityId}`">
                  <td>{{activity.activityName}}</td>
                  <td class="text-right">{{formatMoney(activity.averageRate)}}</td>
                  <td class="text-right">{{getUnitTextForActivity(activity, contract)}}</td>
                </tr>
              </table>
            </v-row>
            <v-row>
              <span class="font-italic ml-2" v-if="contract.paymentRollup.length > 2">{{$t('clickToShowAllActivities')}}</span>
              <v-spacer></v-spacer>
              <span class="font-italic">{{ $t('contractTicketCount', { ticketCount: contract.loadSummaries.length }) }}</span>
            </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col v-for="(pt, i) of payableTypes" :key="i" @click="pt.click" cols="12" md="6" lg="4" xl="4">
        <v-card :id="`sbp-${kebabCase(pt.title)}-card`">
          <v-card-title
          class="primary white--text subtitle-1"
          style="height: 35px;">
          <v-row class="mt-n3 mx-1">
            <span>{{ $t(pt.title) }}</span>
            <v-spacer></v-spacer>
            <span>{{formatMoney(pt.collection.map(pt.amount).reduce((p, c) => p + c, 0))}}</span>
          </v-row>
          </v-card-title>
          <v-card-actions>
            <v-row>
              <v-col width="100%">
                {{ $t(pt.label ?? pt.title) }}:  {{ pt.count }}
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <Dialog
      :stateId="dialogId"
      maxWidth="80vw"
      @dialog-closing="close"
    >
      <SBPRecoveryDialog v-if="recoveryDialog" :payee="payee" @close="close" :isExportBatch="isExportBatch"/>
      <SettlementsCorrectionsDialog v-if="correctionsDialog" :corrections="payee.corrections" :name="payee.payeeName"  :isByproduct="isByproduct" @close="close" includeGross/>
      <SBPAdvanceDialog v-if="advanceDialog" :payee="payee" @close="close" />
      <SBPMiscPaymentDialog v-if="tractPaymentDialog" :payee="payee" @close="close" :payables="payee.tractPayables" :resourceName="$t('tractPayables')" :headers="tractPaymentDialogHeaders()"/>
      <SBPMiscPaymentDialog
      v-if="accountPaymentDialog"
      :payee="payee"
      @close="close"
      :payables="payee.accountPayables"
      :resourceName="$t('accountPayments')"
      :resourceSingular="$t('accountPayment')"
      :headers="accountPaymentDialogHeaders({ includeActions: !isExportBatch })"
      :canMutate="userAssignedClaim(UserClaims.AccountManager)"
      @delete="deleteAccountPayment"/>
      <SettlementActivityBreakdown v-if="paymentDetailDialog" :propContractInfo="focusedContract" :propPayee="payee" @close="close" :isExportBatch="isExportBatch" @refresh-settlement="$emit('refresh-settlement')"/>
    </Dialog>
  </v-container>
</template>

<script>
import { formatMoney } from '@/utils/NumericMutations'
import { PayOn, PayBy, ContractMode, ActivityModifier } from '@/utils/Enumerations.js'
import { contractIconsFor } from '../ContractIcons'
import { tractPaymentDialogHeaders, accountPaymentDialogHeaders } from '@/headers/Cycles.js'
import { mapActions, mapGetters } from 'vuex'
import { colorClassForContractType } from '@/utils/componentHelpers'
import { ContractType, UserClaims } from '@/utils/Enumerations'
import { userAssignedClaim } from '../../../../utils/ClaimUtility'
import { kebabCase } from 'lodash'

export default {
  name: 'SettlementsByPayeeTable',

  props: {
    payee: {
      type: Object,
      required: true
    },
    isByproduct: {
      type: Boolean,
      default: false
    }
  },

  components: {
    SBPRecoveryDialog: () => import('@/components/settlements/accounts-payable-module/settlements-by-payee/SBPRecoveryDialog.vue'),
    SBPAdvanceDialog: () => import('@/components/settlements/accounts-payable-module/settlements-by-payee/SBPAdvanceDialog.vue'),
    SettlementsCorrectionsDialog: () => import('@/components/settlements/accounts-payable-module/settlements-by-payee/SettlementsCorrectionsDialog.vue'),
    SBPMiscPaymentDialog: () => import('@/components/settlements/accounts-payable-module/settlements-by-payee/SBPMiscPaymentDialog.vue'),
    SettlementActivityBreakdown: () => import('@/components/settlements/accounts-payable-module/settlements-by-contract/SettlementActivityBreakdown.vue'),
    Icon: () => import('@/components/helper/Icon.vue'),
    Dialog: () => import('@/components/Dialog.vue'),
    SettlementsContractActions: () => import('@/components/settlements/SettlementsContractActions.vue')
  },

  data: () => ({
    dialogId: 'settlement-by-payee',
    activityBreakdownDialog: false,
    recoveryDialog: false,
    advanceDialog: false,
    paymentDetailDialog: false,
    tractPaymentDialog: false,
    correctionsDialog: false,
    accountPaymentDialog: false,
    dialog: false,
    focusedContractId: null,
    focusedSettlement: null,
    focusedContract: undefined,
    UserClaims
  }),

  computed: {
    ...mapGetters('settlements', ['currentSubModule', 'dateConfiguration']),
    tractPaymentsTotal () {
      return this.payee.tractPayables.map(payable => payable.amount).reduce((prev, curr) => prev + curr, 0)
    },

    advancesTotal () {
      return this.payee.advances.map(advance => advance.amount).reduce((prev, curr) => prev + curr, 0)
    },

    correctionsTotal () {
      return this.payee.corrections.map(correction => correction.amount).reduce((prev, curr) => prev + curr, 0)
    },

    isExportBatch () {
      return this.dateConfiguration?.exportBatch?.exportBatchId !== undefined
    },

    recoveriesTotal () {
      return this.payee.recoveryInfo.map(recovery => recovery.recovered).reduce((prev, curr) => prev + curr, 0)
    },

    focusedContractMode () {
      const focusedContract = this.payee.contracts.find(c => this.focusedContractId === c.contractId)
      return (focusedContract)
        ? ((focusedContract.type === ContractType.Transfers) ? ContractMode.Transfers : ContractMode.Logs)
        : undefined
    },

    payableTypes () {
      return [
        {
          title: 'tractPayments',
          collection: this.payee.tractPayables,
          amount: tp => tp.amount,
          click: this.openTractPaymentDialog,
          count: this.payee.tractPayables?.length
        },
        {
          title: 'advances',
          collection: this.payee.advances,
          amount: a => a.amount,
          click: this.openAdvanceDialog,
          count: this.payee.advances?.length
        },
        {
          title: 'recoveries',
          label: 'recoveredOnAdvances',
          collection: this.payee.recoveryInfo,
          amount: a => a.recovered,
          click: this.openRecoveryDialog,
          count: this.payee.recoveryInfo?.length
        },
        {
          title: 'corrections',
          collection: this.payee.corrections,
          amount: a => a.amount,
          click: this.openCorrectionsDialog,
          count: (new Set(this.payee.corrections?.map(c => c.ticketNumber))).size
        },
        {
          title: 'accountPayments',
          collection: this.payee.accountPayables,
          amount: ap => ap.amount,
          click: this.openAccountPaymentDialog,
          count: this.payee.accountPayables?.length
        }
      ].filter(pt => pt.collection?.length > 0)
    }
  },

  watch: {
    payee: {
      handler () {
        if (this.paymentDetailDialog) this.refreshPaymentDetailDialog()
        if (this.accountPaymentDialog && this.payee.accountPayables.length === 0) {
          this.close()
        }
      },
      deep: true
    }
  },

  methods: {
    kebabCase,
    ...mapActions('settlements', ['fetchSettlementsByPayee']),
    ...mapActions('settlements', ['toggleContractStatus', 'refreshModuleData', 'clearStaleSubModuleData']),
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),
    ...mapActions('account', ['deleteAccountPayable']),
    formatMoney,
    contractIconsFor,
    tractPaymentDialogHeaders,
    accountPaymentDialogHeaders,
    userAssignedClaim,
    getContractTicketTotal (contract) { return this.formatMoney(contract.paymentRollup.map(payment => payment.payout).reduce((prev, curr) => prev + curr, 0)) },

    getContractRate (contract) { return this.formatMoney(contract.averageRate) },

    openRecoveryDialog () {
      this.closeSubdialogs()
      this.recoveryDialog = true
      this.openOrUpdateDialog({ id: this.dialogId, width: '70%' })
    },

    openAdvanceDialog () {
      this.closeSubdialogs()
      this.advanceDialog = true
      this.openOrUpdateDialog({ id: this.dialogId, width: '70%' })
    },

    openTractPaymentDialog () {
      this.closeSubdialogs()
      this.tractPaymentDialog = true
      this.openOrUpdateDialog({ id: this.dialogId, width: '70%' })
    },

    openCorrectionsDialog () {
      this.closeSubdialogs()
      this.correctionsDialog = true
      this.openOrUpdateDialog({ id: this.dialogId, width: '70%' })
    },

    openPaymentDetailDialog (contract) {
      if (this.focusedContractId !== null || this.focusedSettlement !== null) {
        return
      }
      this.close()
      this.focusedContract = contract
      this.paymentDetailDialog = true
      this.openOrUpdateDialog({ id: this.dialogId, width: '70%' })
    },

    openAccountPaymentDialog () {
      this.closeSubdialogs()
      this.accountPaymentDialog = true
      this.openOrUpdateDialog({ id: this.dialogId, width: '70%' })
    },

    refreshPaymentDetailDialog () {
      const refreshedContract = this.payee?.contracts?.find(c => c.contractId === this.focusedContract?.contractId)
      if (refreshedContract === undefined && this.paymentDetailDialog) {
        this.close()
        return
      }
      this.focusedContract = refreshedContract
    },

    getUnitTextForActivity ({
      payOn,
      payBy,
      modifier,
      grossTons,
      defectTons,
      mismanufactureDefectTons,
      natureDefectTons,
      netTons
    }, contractInfo) {
      if (payBy === PayBy.Load.value) return this.$t('payByLoad')
      if (modifier === ActivityModifier.DistanceTraveled.value) { return this.$t('distanceInMiles', { distance: contractInfo.distance }) }

      let weight = 0
      switch (payOn) {
        case PayOn.Gross.value: weight = grossTons; break
        case PayOn.Net.value: weight = netTons; break
        case PayOn.Defect.value: weight = defectTons; break
        case PayOn.NatureDefect.value: weight = natureDefectTons; break
        case PayOn.MismanufacturedDefect.value: weight = mismanufactureDefectTons; break
        default: weight = netTons
      }

      return `${PayOn.fromInt(payOn)}: ${weight.toFixed(3)}t`
    },

    closeSubdialogs () {
      this.recoveryDialog = false
      this.advanceDialog = false
      this.paymentDetailDialog = false
      this.tractPaymentDialog = false
      this.correctionsDialog = false
      this.focusedContractId = null
      this.focusedSettlement = null
      this.accountPaymentDialog = false
    },

    close () {
      this.closeSubdialogs()
      this.closeDialogsAtOrAbove('settlement-by-payee')
    },

    contractHeaderStyle ({ type }) {
      return colorClassForContractType(type)
    },

    hasTract ({ type }) {
      return type === ContractType.WoodsSale.value || type === ContractType.Production.value
    },

    finishEditing () {
      this.refreshModuleData()
      this.close()
    },

    async deleteAccountPayment (accountPayment) {
      await this.deleteAccountPayable({
        accountId: accountPayment.accountId,
        accountPayableId: accountPayment.accountPayableId
      })
      this.$emit('refresh-settlement')
    }
  }
}
</script>

<style scoped>
table td {
  padding: 0px 12px;
  border-collapse:separate;
}
</style>
