<template>
  <GridChartCard
  :title="title"
  ref="chartCard"
  :hasData="xValues.length > 0"
  @download-csv="data.generateCSV(destinationName)"
  @draw="createChart"
  :tooltip="tooltip"
  >
    <template #actions>
      <Icon
      v-if="allowToggle"
      iconColor="white"
      :tooltipText="isTons ? $t('loadCount') : $t('tons')"
      :small="false"
      margin="mr-3"
      @icon-clicked="isTons = !isTons"
      :disabled="xValues.length === 0"
      :icon="isTons ? 'mdi-pound' : 'mdi-weight'"
      elementId="toggle-tons-load-count"/>
    </template>
  </GridChartCard>
</template>

<script>
import { Chart } from 'chart.js'
import { BarChartLoadsByProduct } from '@/model/BarChart.js'
import { getChartColors } from './ChartColors'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'BarChartCardLoadsByProduct',

  components: {
    GridChartCard: () => import('./GridChartCard.vue'),
    Icon: () => import('@/components/helper/Icon.vue')
  },

  props: {
    data: {
      type: BarChartLoadsByProduct,
      required: true
    },
    destinationName: String,
    contractMode: {
      type: Object
    },
    allowToggle: {
      type: Boolean,
      default: true
    },
    customTitle: {
      type: Function,
      required: false,
      default: undefined
    },
    tooltip: {
      type: String,
      required: false,
      default: undefined
    },
    loadStatus: {
      type: String,
      required: false,
      default: undefined
    },
    isInteractive: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    isTons: false,
    chart: null
  }),

  watch: {
    yValues: {
      handler (_) {
        this.$nextTick(this.$refs.chartCard.draw)
      },
      deep: true
    },
    isTons: {
      handler () {
        this.$nextTick(this.$refs.chartCard.draw)
      }
    }
  },

  computed: {
    ...mapGetters('dashboard', ['interactiveChartTooltipFooter']),

    xValues () {
      return this.data.xValues
    },

    yValues () {
      return this.isTons ? this.data.yValuesTons : this.data.yValuesLoads
    },

    elementId () {
      return this.data.elementId
    },

    title () {
      const unit = this.isTons ? 'Tons' : 'Loads'

      const t = (this.customTitle !== undefined)
        ? this.customTitle(unit)
        : `${this.loadStatus ? `${this.loadStatus + ' '}` : ''}${unit} by Product`

      return (this.destinationName !== '')
        ? `${t} (${this.destinationName})`
        : t
    }
  },

  methods: {
    ...mapActions('dashboard', ['getWidgetInteractionStatus']),

    async onDataClicked (e) {
      if (this.isInteractive) {
        const element = this.chart.getElementsAtEventForMode(e, 'point', true)
        const data = this.chart.config._config.data
        const xAxisValue = data.labels[element[0]?.index]
        if (await this.getWidgetInteractionStatus({ widgetId: this.widgetId, x: xAxisValue })) this.$emit('data-clicked', { xAxisValue: xAxisValue })
      }
    },

    createChart (canvas) {
      if (!canvas) return

      const chart = new Chart(canvas, {
        type: 'bar',
        data: {
          labels: this.xValues,
          datasets: [{
            label: '',
            data: this.yValues,
            backgroundColor: getChartColors(this.xValues.length, this.contractMode.value)
          }]
        },
        options: {
          maintainAspectRatio: false,
          responsive: true,
          resizeDelay: 500,
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              titleFont: {
                family: 'Roboto, sans-serif',
                size: '14',
                weight: 'bolder'
              },
              bodyFont: {
                family: 'Roboto, sans-serif'
              },
              footerColor: '#FFFFFFAA',
              footerFont: {
                family: 'Roboto, sans-serif',
                weight: 'normal',
                style: 'italic'
              }
            }
          },
          scales: {
            x: {
              stacked: true
            },
            y: {
              stacked: true,
              ticks: {
                stepSize: 1
              }
            }
          },
          onClick: (e) => this.onDataClicked(e)
        }
      })
      if (this.isInteractive) chart.options.plugins.tooltip.callbacks.footer = () => this.interactiveChartTooltipFooter
      this.chart = chart
    }
  }
}
</script>
