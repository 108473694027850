<template>
  <v-card>
    <v-card-title class="primary">
      <span class="headline white--text">{{ $t('editingCompanyConfig') }}</span>
      <v-spacer/>
      <Icon
      iconColor="white"
      icon="mdi-close"
      :small="false"
      large
      :tooltipText="$t('close')"
      @icon-clicked="$emit('close')"
      />
    </v-card-title>
    <v-card-text>
      <FormWrapper
      :buttonText="$t('done')"
      @submit="done()">
        <v-container fluid grid-list-xl>
          <v-row align="center">
            <v-col cols="12">
              <v-select
              v-model="config.selectedTimeZone"
              item-text="name"
              item-value="name"
              return-object
              item-color="primary"
              color="black"
              :items="timezones"
              :label="$t('companyTimezone')"
              />
            </v-col>
            <v-col>
              <v-select
              :items="FinancialIntegrationType.enums"
              v-model="config.financialIntegrationType"
              :label="$t('financialIntegration')"
              :menu-props="{ bottom: true, offsetY: true }"
              return-object
              item-text="name">
                <template #append-outer>
                  <Icon
                  dataTestId="accountingcodes-download-button"
                  :small="false"
                  :disabled="!enableDownloadIcon"
                  icon="mdi-file-delimited-outline"
                  iconColor="primary"
                  :tooltipText="$t('accountingCodesCSV')"
                  @icon-clicked="csvDialog = true"
                  />
                </template>
              </v-select>
            </v-col>
            <v-col cols="12">
              <v-select
              v-model="config.adjustingEntriesFrequency"
              :label="$t('adjustingEntriesFrequency')"
              :items="AdjustingEntriesFrequency.enums"
              return-object>
                <template #selection="{item}">
                  {{ $t(item.label) }}
                </template>
                <template #item="{item}">
                  {{ $t(item.label) }}
                </template>
              </v-select>
            </v-col>
            <v-col cols="12">
              <v-select
              v-model="config.localDailyTicketReportHour"
              :label="dailyTicketReportSelectionLabel"
              :items="[...Array(24).keys()]">
                <template #item="{item}">
                  {{ moment(item, 'H').format('LT') }}
                </template>
                <template #selection="{item}">
                  {{ moment(item, 'H').format('LT') }}
                </template>
              </v-select>
            </v-col>
            <v-col cols="12">
              <v-select
              v-model="config.accountPayableTemplateDayOfWeek"
              item-color="secondary"
              :label="$t('accountPayableCreationDay')"
              item-text="name"
              return-object
              color="black"
              :items="SystemDayOfWeek.enums"
              />
            </v-col>
            <v-col cols="12">
              <v-select
              return-object
              v-model="config.accountPayableTemplateLocalHour"
              :label="$t('accountPayableCreationTime')"
              :items="[...Array(24).keys()]">
                <template #item="{item}">
                  {{ moment(item, 'H').format('LT') }}
                </template>
                <template #selection="{item}">
                  {{ moment(item, 'H').format('LT') }}
                </template>
              </v-select>
            </v-col>
            <v-col cols="12">
              <v-row>
                <v-checkbox
                :label="$t('replicateBiData')"
                v-model="config.performSecondaryBiPump"/>
              </v-row>
              <v-row>
                <v-checkbox
                :label="$t('requireApprovalForContractModifications')"
                v-model="config.requireApprovalForContractModifications"
                />
              </v-row>
              <v-row>
                <v-checkbox
                :label="$t('integrateWithSamsara')"
                v-model="config.samsaraIntegration"
                />
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </FormWrapper>
    </v-card-text>
    <v-dialog v-model="csvDialog" width="400">
      <RadioOptionDialog
      v-if="csvDialog"
      :options="CSVDownloadOptions"
      :title="$t('downloadCSV')"
      :submitButtonText="$t('download')"
      @close-dialog="csvDialog = false"
      @optionChosen="downloadManager"/>
    </v-dialog>
  </v-card>
</template>

<script>
import { isMobile } from '@/utils/DeviceUtility.js'
import { FinancialIntegrationType, AdjustingEntriesFrequency, SystemDayOfWeek } from '@/utils/Enumerations.js'
import moment from 'moment'
import { utcToLocalDate } from '@/utils/DateFormatter.js'
import { mapActions } from 'vuex'
import { saveAs } from 'file-saver'
import JSZip from 'jszip'
import { generateCsvString } from '@/utils/CSVUtility'
import { fileNameForString } from '@/utils/pdf-formatters/GenericSettlementsFunctions.js'
import timezones from './timezones.json'
import { cloneDeep } from 'lodash'

const PRODUCT_CODES = 'productCodes'
const BYPRODUCT_CODES = 'byproductCodes'
const EXPORT_CODES = 'exportCodes'
const GL_CODES = 'glCodes'
const ALL = 'all'

export default {
  name: 'CompanyConfigForm',

  components: {
    Icon: () => import('@/components/helper/Icon.vue'),
    FormWrapper: () => import('@/components/core/FormWrapper.vue'),
    RadioOptionDialog: () => import('@/components/helper/RadioOptionDialog.vue')
  },

  props: {
    propOptions: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    FinancialIntegrationType,
    AdjustingEntriesFrequency,
    SystemDayOfWeek,
    timezones,
    config: {},
    csvDialog: false
  }),

  created () {
    this.config = cloneDeep(this.propOptions)
  },

  computed: {
    dailyTicketReportSelectionLabel () {
      let label = this.$t('dailyTicketReportTime')
      if (this.config?.lastDailyTicketReportCreatedAt) label += ` (${this.$t('lastReportX', { x: utcToLocalDate(this.config?.lastDailyTicketReportCreatedAt) })})`
      return label
    },

    enableDownloadIcon () {
      return this.config.financialIntegrationType.value !== FinancialIntegrationType.None.value
    },

    CSVDownloadOptions () {
      return [PRODUCT_CODES, BYPRODUCT_CODES, EXPORT_CODES, GL_CODES, ALL]
    }
  },

  methods: {
    ...mapActions('product', ['fetchProducts']),
    ...mapActions('account', ['fetchAccounts']),
    ...mapActions('activity-templates', ['fetchActivityTemplates']),
    isMobile,
    moment,
    done () {
      this.$emit('done-editing', this.config)
    },

    async downloadManager (type) {
      let downloadRequest
      switch (type) {
        case PRODUCT_CODES:
          const products = await this.fetchProducts({
            includeProduction: true,
            includeByProducts: false
          })
          const productCodesCSV = this.downloadProductCodesCSV(products)
          downloadRequest = {
            blob: productCodesCSV.blob,
            filename: productCodesCSV.filename
          }
          break
        case BYPRODUCT_CODES:
          const byproducts = await this.fetchProducts({
            includeProduction: false,
            includeByProducts: true
          })
          const byproductCodesCSV = this.downloadByProductCodesCSV(byproducts)
          downloadRequest = {
            blob: byproductCodesCSV.blob,
            filename: byproductCodesCSV.filename
          }
          break
        case EXPORT_CODES:
          const accounts = await this.fetchAccounts()
          const exportCodesCSV = this.downloadExportCodesCSV(accounts)
          downloadRequest = {
            blob: exportCodesCSV.blob,
            filename: exportCodesCSV.filename
          }
          break
        case GL_CODES:
          const activityTemplates = await this.fetchActivityTemplates()
          const glCodesCSV = this.downloadGLCodesCSV(activityTemplates)
          downloadRequest = {
            blob: glCodesCSV.blob,
            filename: glCodesCSV.filename
          }
          break
        case ALL:
          this.downloadAllFiles()
          break
      }

      if (!downloadRequest) return

      saveAs(downloadRequest.blob, downloadRequest.filename)
    },

    async downloadAllFiles () {
      const requestObjProducts = {
        includeProduction: true,
        includeByProducts: true
      }

      const zip = new JSZip()

      const products = await this.fetchProducts(requestObjProducts)

      const productCodes = this.downloadProductCodesCSV(products.filter(r => !r.isByProduct))
      const byproductCodes = this.downloadByProductCodesCSV(products.filter(r => r.isByProduct))
      zip.file(productCodes.filename, productCodes.blob)
      zip.file(byproductCodes.filename, byproductCodes.blob)

      const accounts = await this.fetchAccounts()
      const exportCodes = this.downloadExportCodesCSV(accounts)
      zip.file(exportCodes.filename, exportCodes.blob)

      const activityTemplates = await this.fetchActivityTemplates()
      const glCodes = this.downloadGLCodesCSV(activityTemplates)
      zip.file(glCodes.filename, glCodes.blob)

      const downloadableZip = await zip.generateAsync({ type: 'blob' })
      saveAs(downloadableZip, `Accounting-Codes-${moment().format()}`)
    },

    downloadProductCodesCSV (products) {
      const csvString = generateCsvString(products, [
        { label: 'name', value: a => a.name },
        { label: 'code', value: a => a.code ? a.code : '' }
      ], { emptyValue: this.$t('notAvailable') })
      const blob = new Blob([csvString], { type: 'text/plain;charset=utf-8' })
      const filename = fileNameForString(`Accounting-Product-Codes-${moment().format()}`, 'csv')
      return { blob, filename }
    },

    downloadByProductCodesCSV (products) {
      const csvString = generateCsvString(products, [
        { label: 'name', value: a => a.name },
        { label: 'code', value: a => a.code ? a.code : '' }
      ], { emptyValue: this.$t('notAvailable') })
      const blob = new Blob([csvString], { type: 'text/plain;charset=utf-8' })
      const filename = fileNameForString(`Accounting-Byproduct-Codes-${moment().format()}`, 'csv')
      return { blob, filename }
    },

    downloadExportCodesCSV (excodes) {
      const csvString = generateCsvString(excodes, [
        { label: 'name', value: a => a.name },
        { label: 'code', value: a => a.exportCode ? a.exportCode : '' }
      ], { emptyValue: this.$t('notAvailable') })
      const blob = new Blob([csvString], { type: 'text/plain;charset=utf-8' })
      const filename = fileNameForString(`Accounting-Export-Codes-${moment().format()}`, 'csv')
      return { blob, filename }
    },

    downloadGLCodesCSV (glCodes) {
      const csvString = generateCsvString(glCodes, [
        { label: 'name', value: a => a.name },
        { label: 'glCode', value: a => a.glCode },
        { label: 'glOffset', value: a => a.glOffset ? a.glOffset : '' }
      ], { emptyValue: this.$t('notAvailable') })
      const blob = new Blob([csvString], { type: 'text/plain;charset=utf-8' })
      const filename = fileNameForString(`Accounting-GL-Codes-${moment().format()}`, 'csv')
      return { blob, filename }
    }
  }
}
</script>
